import Tooltip from '@mui/material/Tooltip';
import { ArrowLeft, Close, Refresh } from '@mui/icons-material';
import React from 'react';
import CollapseIcon from 'src/icons/CollapseIcon';
import ExpandIcon from 'src/icons/ExpandIcon';
import * as S from './PanelHeader.style';

export interface PanelHeaderProps {
  tooltipText: string | null;
  title: string;
  onBack?: () => any;
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  hasRefreshButton?: boolean;
  showBackButton?: boolean;
  showCloseBtn?: boolean;
  isExpanded?: boolean;
  headerExpandedChildren?: React.ReactNode;
  onExpand?: () => void;
  onRefresh?: () => void;
  associatedObjectTitle?: string;
  showTooltipBtn?: boolean;
  panelHeaderStyle?: React.CSSProperties;
}

export const PanelHeader: React.FunctionComponent<PanelHeaderProps> = ({
  title,
  onClose,
  onBack,
  isExpanded,
  onExpand,
  onRefresh,
  hasRefreshButton,
  headerExpandedChildren,
  showBackButton = false,
  showCloseBtn = true,
  associatedObjectTitle,
  panelHeaderStyle,
}) => {
  const handleBackClick = () => onBack && onBack();
  const handleCloseClick = (e: any) => onClose && onClose(e);
  const handleRefreshClick = () => onRefresh && onRefresh();

  return (
    <S.Header data-testid="panel-header">
      <S.Row canWidthExpand={onExpand && showCloseBtn && hasRefreshButton}>
        {showBackButton && (
          <S.IconContainer onClick={handleBackClick} data-testid="back-button">
            <ArrowLeft
              style={{ fontSize: 'var(--inspector-icon-font-size)', color: 'var(--foreground-inspector-icon)' }}
            />
          </S.IconContainer>
        )}
        {showBackButton && associatedObjectTitle ? (
          <div>
            <Tooltip placement="bottom" title={title || ''}>
              <S.Title data-testid="inspectorTitle" color="inherit" style={panelHeaderStyle}>
                {title}
              </S.Title>
            </Tooltip>
            <Tooltip placement="bottom" title={associatedObjectTitle || ''}>
              <S.Title data-testid="associatedObjectTitle" color="inherit">
                {associatedObjectTitle}
              </S.Title>
            </Tooltip>
          </div>
        ) : (
          <Tooltip placement="bottom" title={title || ''}>
            <S.Title data-testid="inspectorTitle" color="inherit" style={panelHeaderStyle}>
              {title}
            </S.Title>
          </Tooltip>
        )}
      </S.Row>
      <S.Row canWidthExpand={onExpand && showCloseBtn && hasRefreshButton}>
        {isExpanded && headerExpandedChildren}
        {onExpand && (
          <S.IconContainer data-testid={isExpanded ? 'collapse-button' : 'expand-button'} onClick={onExpand}>
            {isExpanded ? (
              <CollapseIcon fillColor="var(--foreground-inspector-icon)" />
            ) : (
              <ExpandIcon fillColor="var(--foreground-inspector-icon)" />
            )}
          </S.IconContainer>
        )}

        {showCloseBtn && (
          <S.IconContainer onClick={handleCloseClick} data-testid="onclose-button">
            <Close
              style={{
                fontSize: 'var(--inspector-icon-font-size)',
                color: 'var(--forground-global-search-close-icon)',
              }}
            />
          </S.IconContainer>
        )}
        {hasRefreshButton && (
          <S.IconContainer onClick={handleRefreshClick}>
            <Refresh
              style={{ fontSize: 'var(--inspector-icon-font-size)', color: 'var(--foreground-inspector-icon)' }}
            />
          </S.IconContainer>
        )}
      </S.Row>
    </S.Header>
  );
};
