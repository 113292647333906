import React from 'react';
import * as S from './DropdownButton.style';

export const DropdownButton = ({
  onTextClick,
  text,
  items,
  dark,
  buttonDisabled,
  dropdownArrowStyle,
  dropdownStyle,
  dropdownTextStyle,
  ...props
}: {
  onTextClick: (e: React.MouseEvent) => void;
  text: string;
  items: any[];
  dark?: boolean;
  buttonDisabled?: boolean;
  dropdownArrowStyle?: any;
  dropdownStyle?: any;
  dropdownTextStyle?: any;
}) => (
  <S.DropdownButton data-testid="grid-mode-selection" {...props} dark={dark} style={dropdownStyle}>
    <S.DropdownButtonText
      data-testid="dropdown-button-text"
      onClick={onTextClick}
      $showBorder={items.length !== 0}
      disabled={buttonDisabled}
      style={dropdownTextStyle}
    >
      {text}
    </S.DropdownButtonText>
    {items.length !== 0 && <S.ArrowDropDownIcon data-testid="arrow-icon" style={dropdownArrowStyle} />}
  </S.DropdownButton>
);
