import React from 'react';
import LoadingIcon, { LoadingIconProps } from 'src/LoadingIcon/LoadingIcon';
import styled from 'styled-components';

export interface LoadingWithOverlay {
  testId?: string;
  overlayColor: string;
  style?: React.CSSProperties;
}

type LoadingWithOverlayProps = LoadingWithOverlay & LoadingIconProps;
const LoadingWithOverlay: React.FunctionComponent<LoadingWithOverlayProps> = ({
  show = false,
  type,
  size,
  text,
  overlayColor,
  style,
}) => {
  const overlayBackground = overlayColor;

  return show ? (
    <LoadingWithOverlayContainer data-testid="loading-with-overlay" style={style}>
      <LoadingOverlay data-testid="loading-overlay" background={overlayBackground}>
        <LoadingIcon type={type} size={size} show={show} />
        {text && <p>{text}</p>}
      </LoadingOverlay>
    </LoadingWithOverlayContainer>
  ) : null;
};

const LoadingWithOverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  z-index: 10;
`;

const LoadingOverlay = styled.div<{ background: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--text);
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
`;

export default LoadingWithOverlay;
