/* eslint-disable no-confusing-arrow */
import { navigate } from '@reach/router';
import { Route } from '@virtus/components/page/models/route';
import isEqual from 'lodash/isEqual';
import { ComponentType } from 'react';
import { Action } from 'redux';
import { ClientViewConfiguration, ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { NavigationProps } from 'src/components/screen-wrapper';
import { RootState } from 'src/reducers/rootReducer';

export interface CustomGlideRoute {
  fullPath: string;
  isHighlighted: boolean;
  activeView: string | null;
  tabName?: string[];
  routes?: any[];
}

export interface TabState {
  moduleTabs: CustomGlideRoute[];
  currentPath: string;
  routes: any;
  activeViewUri: string;
}

interface TabActionType extends Action {
  path: string;
  uri?: string;
  data: any;
  routes?: any;
}

export const initialState = () => {
  const defaultPath = '/';
  return {
    moduleTabs: [{ fullPath: defaultPath, isHighlighted: true, activeView: '' }],
    currentPath: defaultPath,
    activeViewUri: '',
    routes: [],
  };
};

export enum TabsAction {
  OPEN_TAB = 'OPEN_TAB',
  CLOSE_TAB = 'CLOSE_TAB',
  MODIFY_TAB_NAME = 'MODIFY_TAB_NAME',
  ADD_ROUTE = 'ADD_ROUTE',
  CHANGE_VIEW = 'CHANGE_VIEW',
  SHOW_DASHBOARD = 'SHOW_DASHBOARD',
}

export interface ModuleRoutes extends Route {
  component?: ComponentType<NavigationProps & any>;
  uri: string;
  activeView?: string;
  category?: string;
  views?: any;
  subroutes: Route[];
}

const getSubRoute = (subRoute: Route, route: ModuleRoutes) => {
  const subRoute_copy = { ...subRoute };
  subRoute_copy.path = route.path;
  return subRoute_copy;
};

export const getConfigDefaultView = (routes: ModuleRoutes[]) => {
  let defaultSubRoute = {} as Route;
  let firstCVC = {} as Route;
  let default_view_count = 0;
  let cvcCount = 0;
  for (const route of routes) {
    if (route.subroutes) {
      for (const subRoute of route.subroutes) {
        if (cvcCount++ === 0) {
          firstCVC = getSubRoute(subRoute, route);
        }
        if (subRoute.is_default_view) {
          if (++default_view_count > 1) break;
          defaultSubRoute = getSubRoute(subRoute, route);
        }
      }
    }
    if (default_view_count > 1) break;
  }

  if (default_view_count > 1) {
    console.warn(`invalid config.....redirecting to first default view ${defaultSubRoute.path}`);
    return defaultSubRoute;
  } else if (default_view_count === 1) {
    console.info(`redirecting to default route. ${defaultSubRoute.path}`);
    return defaultSubRoute;
  } else {
    console.warn(`No default route found...redirecting to first CVC. ${firstCVC.path}`);
    return firstCVC;
  }
};

export const getActiveViewUri = (
  state: any,
  previousTabActiveViewUri: string,
  _path: string,
  path: string,
  default_view_route: Route,
) => {
  if (_path && _path != path) {
    return getDefaultView(state.routes, _path);
  } else
    return (
      (_path === '' && state.currentPath === '/' && default_view_route?.uri) ||
      (state.currentPath !== path && previousTabActiveViewUri) ||
      getDefaultView(state.routes, _path)
    );
};

export const getRoute = (routes: any, fullPath: string): ModuleRoutes =>
  routes.find((c: any) => c?.path === fullPath?.replace('/', ''));

export const getDefaultView = (routes: any, routepath: string) => {
  if (!routes) {
    console.error('no routes for default');
    return '';
  }
  let activeView = '';
  let matchedParentRoute: any = null;
  let prevPath = '';
  const lastViewSelectedUri = localStorage.getItem('last_view_selected');
  let activeViewUri = '';
  let prevCategoryPath = '';
  routes.forEach((route: ModuleRoutes) => {
    if (route?.path?.lastSplitValue() === routepath) {
      matchedParentRoute = { ...route };
    }
    route.subroutes?.forEach((subroute: any) => {
      if (subroute?.path?.lastSplitValue() === routepath) {
        activeView += `${subroute?.uri}`;
      } else if (subroute.uri == lastViewSelectedUri) {
        prevPath = subroute?.path?.lastSplitValue();
        prevCategoryPath = route?.path?.lastSplitValue();
      }
    });
  });
  if (activeView == '' && (prevPath == routepath || prevCategoryPath == routepath)) {
    activeViewUri = lastViewSelectedUri || '';
  } else if (activeView == '' && (prevPath != routepath || prevCategoryPath != routepath)) {
    activeViewUri = matchedParentRoute?.uri || '';
  }
  return activeView ? activeView : activeViewUri;
};
export const tabs = (state: any = initialState(), action: TabActionType) => {
  switch (action.type) {
    case TabsAction.OPEN_TAB: {
      let path = '';
      /*
        path can be a path for main route path or fullpath to subroute
        it can be either '/Instrument' or  '/Instrument/equity_universe'. so we will fisrt try to get main route path i.e. 'Instrument'
      */
      // console.log('[tabs] action.path', action.path);
      let _path = action.path.lastSplitValue();
      // TODO: remove the logic of checking activeView (querystring) once legacy Hypo page is migrated to glide-view completely
      if (action.path.includes('?activeView')) {
        _path = action.path.substring(1);
      }

      const _route = state.routes?.find((route: any) => {
        return route.subroutes?.find((subroute: any) => subroute?.path.lastSplitValue() == _path);
      });

      const default_view_route = getConfigDefaultView(state.routes);
      path = _path === '' ? default_view_route?.path : _route?.path || _path;

      // Below variable is used to store previously active uri. It is useful to retain previous view when we switch tabs
      const previousTabActiveViewUri =
        state.moduleTabs.find((tab: CustomGlideRoute) => tab.fullPath === path)?.activeView || '';
      const activeViewUri = getActiveViewUri(state, previousTabActiveViewUri, _path, path, default_view_route);
      localStorage.setItem('last_view_selected', activeViewUri);
      let moduleTabsCopy = [...state.moduleTabs];
      moduleTabsCopy = moduleTabsCopy.map(tab => ({
        ...tab,
        isHighlighted: false,
      }));

      const index = moduleTabsCopy.findIndex(tab => tab.fullPath === path?.split('?')[0]);
      navigate(path?.split('?')[0]);
      if (index === -1) {
        const newTab = {
          fullPath: path?.split('?')[0],
          isHighlighted: true,
          activeView: activeViewUri,
        };
        return {
          ...state,
          activeViewUri,
          moduleTabs: state.currentPath === '/' ? [newTab] : [...moduleTabsCopy, newTab],
          currentPath: path,
        };
      } else {
        moduleTabsCopy[index].isHighlighted = true;
        moduleTabsCopy[index].activeView = activeViewUri;
        return {
          ...state,
          activeViewUri,
          moduleTabs: moduleTabsCopy,
          currentPath: path,
        };
      }
    }
    case TabsAction.CHANGE_VIEW: {
      const activeView = action.data.activeView;
      const updatedModuleTabs = state.moduleTabs.map((tab: CustomGlideRoute) =>
        tab.fullPath === action.data.fullPath ? { ...tab, activeView } : tab,
      );
      localStorage.setItem('last_view_selected', activeView);
      return {
        ...state,
        activeViewUri: activeView,
        moduleTabs: updatedModuleTabs,
      };
    }
    case TabsAction.CLOSE_TAB: {
      const tab = action.data;
      const newTabs = [...state.moduleTabs];
      const currentTab = newTabs.find((el: CustomGlideRoute) => el.isHighlighted);
      let newPath: string = state.currentPath;
      let newActiveViewUri = state.activeViewUri;
      const index = newTabs.indexOf(tab);

      newTabs.splice(index, 1);
      if (isEqual(currentTab, tab)) {
        newTabs[0].isHighlighted = true;
        newPath = newTabs[0].fullPath;
        newActiveViewUri = newTabs[0].activeView;
        navigate(newPath);
      }
      localStorage.setItem('last_view_selected', newActiveViewUri);
      return {
        ...state,
        activeViewUri: newActiveViewUri,
        moduleTabs: newTabs,
        currentPath: newPath,
      };
    }
    case TabsAction.MODIFY_TAB_NAME: {
      const updatedModuleTabs = state.moduleTabs.map((tab: CustomGlideRoute) =>
        tab.fullPath === action.data.fullPath ? { ...tab, tabName: action.data.tabName } : tab,
      );
      return {
        ...state,
        moduleTabs: updatedModuleTabs,
      };
    }
    case TabsAction.ADD_ROUTE:
      return { ...state, routes: action?.routes };
    default:
      return state;
  }
};

const defaultViews = { '': '' };
export const tabSelector = (state: RootState): TabState => state.tabs;
export const activeTabSelector = (state: RootState) => state.tabs.activeViewUri || '';
export const selectCVC = (state: RootState, clientViewUri?: string): ClientViewConfigurationData => {
  const activeViewUri = clientViewUri ? clientViewUri : activeTabSelector(state);
  const config = state.entities.clientViewConfigurations?.find(
    (cvc: ClientViewConfiguration) => cvc.uri === activeViewUri,
  );
  const web_summary_panel = config?.data?.web_summary_panel ? JSON.parse(config?.data?.web_summary_panel) : null;
  return config ? { ...config.data, web_summary_panel, uri: activeViewUri } : {};
};

export const selectCVCUri = (state: RootState, uri: string): ClientViewConfigurationData => {
  const config = state.entities.clientViewConfigurations.find((cvc: ClientViewConfiguration) => cvc.uri === uri);
  const web_summary_panel = config?.data?.web_summary_panel ? JSON.parse(config?.data?.web_summary_panel) : null;
  return config ? { ...config.data, web_summary_panel, uri: uri } : {};
};

export const selectClientViewBottomPanel = (state: RootState): ClientViewConfiguration[] => {
  const bottom = state.entities.clientViewConfigurations.filter((cvc: ClientViewConfiguration) => {
    return (
      cvc.data.is_instance_sensitive &&
      // We're restricting panel loading to bottom region for now
      (cvc.data.browser_region === 'BottomRegion' || cvc.data.client_region === 'BottomRegion')
    );
  });
  return bottom;
};

export const selectInstanceSensitiveBottomPanel = (state: RootState, fieldName: string): ClientViewConfiguration[] => {
  const instanceSensitiveBottomPanels = state.entities.clientViewConfigurations.filter(
    (cvc: ClientViewConfiguration) =>
      cvc.data.is_instance_sensitive &&
      // We're restricting panel loading to bottom region for now
      // Browser region is a web only prop that takes precedence on the client region
      (cvc.data.browser_region === 'BottomRegion' || cvc.data.client_region === 'BottomRegion') &&
      cvc?.data?.instance_sensitive_field_name?.includes(fieldName),
  );
  if (instanceSensitiveBottomPanels.length === 1) {
    return instanceSensitiveBottomPanels[0];
  }
  if (instanceSensitiveBottomPanels.length > 1) {
    console.error('multiple context bottom panels matched, only one CVC should match', instanceSensitiveBottomPanels);
  }
  return [];
};

export const subRoutesSelector = (state: RootState, path: string): { [viewKey: string]: any } =>
  state.tabs.routes?.find((item: any) => item.path === path)?.subroutes || [];
export const viewsSelector = (state: RootState, path: string): { [viewKey: string]: any } =>
  state.tabs.routes?.find((item: any) => item.path === path)?.views || defaultViews;
export const bottomPanelSelector = (state: RootState) => {
  const activeViewUri = activeTabSelector(state);
  return state.entities?.views?.[activeViewUri]?.clientViewConfiguration?.bottom_panel_static_fields;
};
