import styled from 'styled-components';
import {
  StyledCalendarIcon,
  DateInputWrapper,
  DateInputElement,
} from 'src/components/forms/form-elements/form-fields/DateInput/DateInput.style';
import { PrimaryButton } from '@virtus/components/Buttons';

export const StyledRowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 5px 0 0 0;
  height: calc(100% - 40px);
`;

export const HeaderPanel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--bgLight);
  justify-content: end;
  border: 1px solid #c1c1c1;
`;

export const DateFieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
`;

export const DateDropDown = styled(PrimaryButton)`
  width: 28px;
  min-width: 28px;
  background-color: var(--background-dx-grid-checkbox);
  border: var(--foreground-form-input-edit-border);
  margin-left: 3px;
`;

export const CalendarIcon = styled(StyledCalendarIcon)`
  margin-left: 5px;
  fill: var(--text);
`;
export const DateInputContainer = styled(DateInputWrapper)`
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  background-color: var(--bgLight);
  border-radius: 2px;
  border: var(--foreground-form-input-edit-border);
  &:hover {
    background-color: var(--background-tab-group);
  }
`;
export const DateInput = styled(DateInputElement)`
  padding: 0 5px;
  color: var(--foreground-form);
`;

export const DateButton = styled.button<{ error?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 150px;
  height: 28px;
  border-radius: 2px;
  background-color: var(--dateButtonBg);
  color: var(--foreground-form);
  border: ${props => (props.error ? '2px solid var(--red)' : 'none')};
  :disabled {
    background-color: transparent;
  }
`;

export const DatePickerWrapper = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  position: absolute;
  z-index: 10;
  top: 45px;
  right: 0;
`;

export const Loader = styled.div`
  display: inline-block;
  position: relative;
  top: 35%;
`;

export const DateWrapper = styled.div`
  display: flex;
  padding: 2px;
`;

export const DeleteIconWrapper = styled.div`
  display: inline-flex;
  align-self: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin: auto;
  margin-right: 0;
`;
