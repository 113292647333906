import styled from 'styled-components';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Lock, Info } from '@mui/icons-material';

export const Column = styled.div`
  color: var(--foreground-form);
  font-size: var(--form-label-font-size);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
  letter-spacing: 0px;
  line-height: 1.8;
`;

export const RowButton = styled(Button)`
  color: var(--white);
  min-width: 29px;
  padding: 6px 4px;
  opacity: ${props => (props.disabled ? '0' : '1')};
  transition: 0.2s all;
`;

export const InfoButton = styled(RowButton)`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;
export const InfoIcon = styled(Info)`
  width: 18px;
  height: 18px;
`;

export const LockIcon = styled(Lock)`
  width: 18px;
  height: 18px;
  color: var(--accent-grey);
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: 322px;

  & ~ #dropdown-menu-wrapper {
    height: 25px;
  }
`;

export const Row = styled.div<{ isDragging?: boolean }>`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--foreground-column-manager-row-border);
  background-color: ${props => (props.isDragging ? 'var(--background-dx-grid-select-row)' : 'transparent')};
  &:hover .infoButton {
    opacity: 1;
  }
`;

export const BlueCheck = styled(Checkbox)`
  color: var(--foreground-dx-grid-filter-search);
  padding: 0;
  & .MuiSvgIcon-root {
    font-size: 16px;
  }
`;
