import { actionTypes } from 'redux-query';
import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
export interface MutationAPI {
  data: { [key: string]: any };
  queryKey: string;
  updatedDataFormat?: string;
  httpMethod?: 'POST' | 'PUT' | 'DELETE';
  optimisticUpdateData?: {
    optimisticUpdateCallBack: any;
    optimisticUpdateKey: string;
  };
  rollBack?: { rollbackKey: string; rollbackCallBack?: any };
  url?: string;
  callBackTransform?: {
    transformFunc?: (body: any) => void;
    transformReturnKey: string;
  };
  objectUpdating?: string;
  updateData?: {
    updateCallBack?: any;
    updateKey: string;
  };
}
export const mutationApiCall = ({
  data,
  updatedDataFormat,
  httpMethod,
  queryKey,
  optimisticUpdateData,
  rollBack,
  url,
  callBackTransform,
  objectUpdating,
  updateData,
}: MutationAPI) => {
  const getData = (data: any, format?: string) => {
    const bodyData = { ...data };
    if (format) {
      bodyData['format'] = format;
    }
    return bodyData;
  };
  const queryConfig = {
    url: url ? url : endpoints.update.root,
    options: { method: httpMethod ? httpMethod : 'PUT' },
    body: getData(data, updatedDataFormat),
    force: true,
    queryKey: queryKey,
    meta: getMetaDetails(objectUpdating ?? ''),
    update: getUpdateDetails(updateData),
    transform: (body: any) => {
      if (callBackTransform && callBackTransform.transformFunc) {
        const transformedData = callBackTransform.transformFunc(body);
        return {
          [callBackTransform.transformReturnKey]: transformedData,
        };
      }
      return {};
    },
    optimisticUpdate: getOptimisticUpdateDetails(optimisticUpdateData),
    rollback: getRollbackDetails(rollBack),
  };
  return queryConfig;
};
const getMetaDetails = (objectUpdating: string) => {
  if (objectUpdating) {
    return {
      notification: {
        [actionTypes.MUTATE_START]: {
          title: `Saving ${objectUpdating}`,
        },
        [actionTypes.MUTATE_SUCCESS]: {
          title: `${objectUpdating} updated`,
        },
      },
    };
  }
  return {};
};
const getRollbackDetails = (rollBack: any) => {
  if (rollBack) {
    if (rollBack.rollbackCallBack && rollBack.rollbackKey) {
      return {
        [rollBack.rollbackKey]: (_prev: any, _next: any) => rollBack.rollbackCallBack(_prev, _next),
      };
    } else if (!rollBack.rollbackCallBack && rollBack.rollbackKey) {
      return {
        [rollBack.rollbackKey]: (_prev: any, _next: any) => _prev,
      };
    }
  }
  return {};
};
const getUpdateDetails = (updateData: any, returnKey?: string) => {
  if (updateData) {
    if (updateData.updateCallBack) {
      return {
        [updateData.updateKey ? updateData.updateKey : returnKey]: (_prev: any, _next: any) =>
          updateData.updateCallBack(_prev, _next),
      };
    } else if (!updateData.updateCallBack && updateData.updateKey) {
      return {
        [updateData.updateKey ? updateData.updateKey : returnKey]: (_prev: any, _next: any) => _next,
      };
    }
  }
  return {};
};
const getOptimisticUpdateDetails = (optimisticUpdateData: any) => {
  if (optimisticUpdateData && optimisticUpdateData.optimisticUpdateKey) {
    return {
      [optimisticUpdateData.optimisticUpdateKey]: (prevValues: any) =>
        optimisticUpdateData.optimisticUpdateCallBack(prevValues),
    };
  }
  return {};
};
export const mutationSelector = (state: RootState, entityKey: string) => state.entities[entityKey];

export const mutationPending = (state: RootState, queryKey: string) =>
  state.queries[queryKey] && state.queries[queryKey].isPending;

export const mutationStatus = (state: RootState, queryKey: string) =>
  state.queries[queryKey] && state.queries[queryKey].status;
