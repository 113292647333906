import { InputBase } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Search as SearchIcon } from '@mui/icons-material';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';

export const Input = styled(InputBase)<{ error?: boolean; darktext?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 2px;
  color: ${props => (props.darktext ? 'var(--foreground-form-input-search-dark)' : 'var(--foreground-form)')};
  background-color: transparent;
  font-size: var(--form-input-font-size);
  border: ${props => (props.error ? '2px solid var(--foreground-error)' : 'none')};
`;

export const SearchFieldWithLink = withStyles(Input, {
  disabled: {
    color: 'var(--foreground-form-input-search-link)',
    cursor: 'pointer',
    WebkitTextFillColor: 'unset !important',
  },
  input: {
    textTransform: 'capitalize',
  },
});

export const Search = withStyles(Input, {
  disabled: {
    color: 'var(--foreground-form)',
    WebkitTextFillColor: 'unset !important',
  },
  input: {
    textTransform: 'capitalize',
  },
});

export const SearchContainer = styled.div<{
  disabled?: boolean;
  $hasFocus?: boolean;
  $editIsEnabled?: boolean;
  is_search_enable?: boolean;
  $error?: boolean;
}>`
  display: flex;
  align-items: center;
  flex: 1;
  height: 28px;
  background-color: ${props => (props.disabled ? 'transparent' : 'inherit')};
  padding-left: 5px;
  border-radius: 3px;
  border: ${props => {
    if (props.$error) return '2px solid var(--foreground-error)';
    if ((props.$editIsEnabled || props.is_search_enable) && !props.$hasFocus)
      return 'var(--foreground-form-input-edit-border)';
    if ((props.$editIsEnabled || props.is_search_enable) && props.$hasFocus)
      return 'var(--foreground-form-input-focus-border)';
    return 'none';
  }};
  position: relative;
  bottom: 1px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
  margin-left: 2px;
  font-size: var(--search-icon-font-size);
  color: var(--foreground-form);
`;

export const SpinnerWrapper = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 22px;
`;

export const StyledAddCircleIcon = styled(AddIcon)`
  margin-left: 5px;
  margin-right: 10px;
  font-size: 24px;
  color: var(--foreground-form);
  background-color: 'transparent';
  :hover {
    cursor: pointer;
  }
`;
