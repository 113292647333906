import { Dialog as MUIDialog } from '@mui/material';
import styled from 'styled-components';

export const ModalButtonText = styled.span`
  text-decoration: underline;
  color: var(--foreground-form-link);
  font-weight: normal;
  text-align: left;
`;
export const ItemTextStyled = styled.div`
  color: var(--foreground-form);
  font-size: var(--form-input-font-size);
  font-weight: normal;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding-left: 5px;
`;

export const ModalButton = styled.div`
  color: var(--default-blue);
  cursor: pointer;
  z-index: 1000;
`;

export const Dialog = styled(MUIDialog)<{ display_type?: string }>`
  height: calc(${props => (props.display_type === 'preview' ? 85 : 100)}vh - 40px);
  margin: 40px auto;
  width: ${props => (props.display_type === 'preview' ? 80 : 100)}vw;
  & > div {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    & > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;
