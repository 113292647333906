import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

export const get = (instrument_behaviour_uri: string) => ({
  url: endpoints.order.instrumentBehaviourActions,
  transform: (body: any) => ({ instrumentBehaviourActions: body }),
  options: { method: 'GET' },
  body: { behaviour_uri: instrument_behaviour_uri },
  queryKey: `getActionArguments-${instrument_behaviour_uri}`,
  force: false, // Cache the request!
  update: {
    instrumentBehaviourActions: (a: any, c: any) => ({ [instrument_behaviour_uri]: c, ...a }),
  },
});

export const selector = (state: RootState) => state.entities.instrumentBehaviourActions;
export const pending = (state: RootState) =>
  state.queries.getActionArguments && state.queries.getActionArguments.isPending;
