import ToolBar from '@virtus/components/tool-bar';
import { ToolBarProps } from '@virtus/components/tool-bar/tool-bar';
import React from 'react';
import { connect } from 'react-redux';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { RootState } from 'src/reducers';
import { selectViewComponent } from 'src/reducers/components';

export interface GlideToolbarProps extends Pick<ToolBarProps, 'extraIconButtons' | 'links' | 'onClickTab'> {
  pageToolBarProps?: any;
  views: { [viewKey: string]: any };
  activeViewKey: string;
}

interface ReduxProps {
  viewInspectorComponent: any;
  columnManagerComponent: any;
  layoutManagerComponent: any;
  searchInspectorComponent: any;
}

export enum ThemeNames {
  light = 'light',
  dark = 'dark',
}

export const GlideToolbar = ({
  views,
  activeViewKey,
  onClickTab,
  extraIconButtons,
  links,
  pageToolBarProps,
  viewInspectorComponent,
  columnManagerComponent,
  searchInspectorComponent,
  layoutManagerComponent,
}: GlideToolbarProps & ReduxProps) => {
  const customParseViews = (views: any) =>
    views.map((view: any) => {
      const { id, name, loading = false } = view;
      return {
        id,
        name,
        loading,
      };
    });

  const labels = Object.entries(views).map(([key, value]: any, index) => ({
    key,
    id: index,
    name: value.displayName,
  }));

  const onClickTabHandler = (id: number) => {
    const selectedTab = labels.find(label => label.id === id);
    onClickTab(selectedTab?.key);
  };

  const toolbarProps: ToolBarProps = {
    labels,
    active: labels.findIndex(label => label.key === activeViewKey.lastSplitValue()),
    links,
    onClickTab: onClickTabHandler,
    extraIconButtons,
    customParseViews,
    viewContainerStyles: { width: '65vw' },
  };

  return (
    <ToolBar
      {...toolbarProps}
      {...pageToolBarProps}
      isInspectorCollapsed={viewInspectorComponent?.isCollapsed}
      isColumnManagerVisible={columnManagerComponent?.visible}
      isSearchInspectorVisible={searchInspectorComponent?.visible}
      isLayoutManagerVisible={layoutManagerComponent?.visible}
    />
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  viewInspectorComponent: selectViewComponent(state, VIEW_INSPECTOR),
  columnManagerComponent: selectViewComponent(state, 'columnManagerInspector'),
  searchInspectorComponent: state.components.searchInspector,
  layoutManagerComponent: state.components.layoutManager,
});

export default connect(mapStateToProps)(GlideToolbar);
