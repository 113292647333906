/* tslint:disable:max-line-length */
import * as React from 'react';
import {
  CustomCellRenderKeyProps,
  DxColumn,
  DxDataType,
  DxSummary,
  DxSummaryType,
  ILinkedColumn,
  SchemaType,
} from 'src/DxDataGrid/DxDataGrid';
import { parseDataTypeValue } from 'src/parsers/parseDataTypeValueVirtus';

// Retro-compatibility
export { parseDataTypeValue } from 'src/parsers/parseDataTypeValueVirtus';
export { getSummaryVirtus } from './summary/summaryVirtus';

type CustomNexusDataType = 'id' | 'dateandtime' | 'percent' | 'text' | 'currency' | 'date';

export type NexusDataType =
  | CustomNexusDataType
  | 'varchar'
  | 'money'
  | 'float'
  | 'char'
  | 'decimal'
  | 'int'
  | 'bigint'
  | 'smalldatetime'
  | 'datetime'
  | 'bit'
  | 'tinyint'
  | 'boolean'
  | 'nvarchar'; // maps to string, similar to varchar with extended charset

const VERTICAL = 'vertical';
const VALUE = 'value';
const PROPERTY = 'property';
const DATA_TYPE_NAME = 'data_type_name';

export interface BasicLookup {
  // new model received from back end
  Code: string;
  // same param in CAPS
  CODE?: string;
  Description: string;
  ['Display Order']: number;
  Type: string;
  display_order: number;
  field_type: string;
  group_code_id: any | null;
  inherited_type_id: any | null;
  lookup_code: string;
  lookup_code_desc: string;
  lookup_code_id: number;
  lookup_type_id: number;
}
export interface SnapshotLookup extends BasicLookup {
  // this is the name of the column it should be linked to
  field_name?: string;
}

export type LookupDataSource = { data: BasicLookup[]; schema: any[] };

export interface VirtusDataSource {
  schema: NexusSchema[];
  data: any[];
  sqlCommandText?: string;
  lookups?: LookupDataSource[];
  schemaType?: SchemaType;
}

export interface NexusSchema {
  ColumnName: string;
  DataTypeName: NexusDataType;
  ColumnSize: number;
  NumericPrecision: number;
  NumericScale: number;
  Format?: string;
  Width?: number;
  Hidden?: boolean;
  IsReadOnly?: boolean;
  Caption?: string;
  DataField?: string;
  fixed?: boolean;
  fixedPosition?: string;
}

export const KEY_IDENTIFIER_VIRTUS: string = 'Identifier';

export type DataType = 'id' | 'string' | 'number' | 'date' | 'datetime' | 'boolean';

export const dataTypeMap: { [key in NexusDataType]: DataType } = {
  text: 'string',
  varchar: 'string',
  nvarchar: 'string',
  currency: 'number',
  money: 'number',
  float: 'number',
  char: 'string',
  decimal: 'number',
  percent: 'number',
  int: 'number',
  bigint: 'number',
  smalldatetime: 'date',
  datetime: 'date',
  date: 'date',
  bit: 'boolean',
  boolean: 'boolean',
  tinyint: 'boolean',
  id: 'id',
  dateandtime: 'datetime',
};

export interface VirtusSummaryOptions {
  summaryKeyProps?: DxSummary[];
  summaryDataTypeProps?: SummaryDataTypeProps[];
}

export interface SummaryDataTypeProps {
  dataType: NexusDataType;
  summaryType: DxSummaryType;
  name?: string;
  displayFormat?: string;
}

const parseLookupsToDxFilter = <T extends BasicLookup>(lookups: T[]) =>
  lookups.reduce((prev: any, l: T) => {
    const sl = l as SnapshotLookup;
    if (sl) {
      prev.push({
        text: `${sl.Code} - ${sl.Description}` || '',
        value: sl.Description || '',
      });
    }
    return prev;
  }, []);

export const mapSchemaVirtus = (
  data: VirtusDataSource,
  customCellRenderKeyProps: CustomCellRenderKeyProps,
  linkedColumns?: ILinkedColumn[],
  displayType?: string,
): DxColumn[] => {
  const schema: DxColumn[] = [];

  const gridDisplayType = displayType ? displayType.toLowerCase() : '';

  const snapshotLookups =
    data &&
    data?.lookups?.reduce((lookups: any, currLookupSet: { data: BasicLookup[]; schema: any[] }) => {
      const fieldName: any = (currLookupSet.data[0] as SnapshotLookup).field_name;
      return { ...lookups, [fieldName]: currLookupSet.data };
    }, {});

  // Create DxColumns from schema
  data.schema.forEach((col: NexusSchema) => {
    let column: DxColumn;
    let colWidth: number = -1;
    let isVisible: boolean = !col.ColumnName.includes('_');

    if (col.Hidden !== undefined) {
      isVisible = !col.Hidden;
    }

    if (col.Width !== undefined) {
      colWidth = col.Width;
    }

    column = {
      key: col.ColumnName,
      dataField: col.DataField != null ? col.DataField : col.ColumnName,
      dataType: dataTypeMap[col.DataTypeName as NexusDataType] as DxDataType,
      visible: isVisible,
      minWidth: undefined,
    };

    if (snapshotLookups && snapshotLookups[col.ColumnName]) {
      const filterSet = parseLookupsToDxFilter(snapshotLookups[col.ColumnName]);
      column = {
        ...column,
        headerFilter: {
          dataSource: filterSet,
        },
      };
    }

    if (colWidth !== -1) {
      column = { ...column, width: colWidth };
    }

    if (col.Caption != null) {
      column = { ...column, caption: col.Caption };
    }

    if (col.IsReadOnly) {
      column = { ...column, allowEditing: false };
    }

    if (col.fixed || (gridDisplayType === VERTICAL && col.ColumnName === PROPERTY)) {
      column.fixed = true;
      // default position
      column.fixedPosition = 'left';
    }

    column.cellRender = (cellData: { value: any; data: any; column: DxColumn }): any => {
      if (cellData.value != null) {
        return generateLink(
          gridDisplayType,
          col.ColumnName,
          col.DataTypeName as NexusDataType,
          col.NumericPrecision,
          cellData.value,
          cellData.data,
          linkedColumns,
          col.Format,
          data.schemaType,
        );
      }
    };

    // extend column custom properties
    if (customCellRenderKeyProps && customCellRenderKeyProps[column.key]) {
      column = { ...column, ...customCellRenderKeyProps[column.key] };
    }
    schema.push(column);
  });

  return schema;
};

function getLinkedColumnIndex(columnName: string, linkedColumns?: ILinkedColumn[]): number {
  let linkedColumnIndex = -1;

  if (linkedColumns !== undefined) {
    linkedColumnIndex = linkedColumns.findIndex(item => item.columnName === columnName);
  }

  return linkedColumnIndex;
}

function getLinkedColumnOnClick(linkedColumnIndex: number, linkedColumns?: ILinkedColumn[]): any {
  let onClickFunction: any;

  if (linkedColumnIndex !== -1 && linkedColumns !== undefined) {
    onClickFunction = linkedColumns[linkedColumnIndex].onClick;
  }

  return onClickFunction;
}

function generateLink(
  gridDisplayType: string,
  columnName: string,
  dataType: NexusDataType,
  numericPrecision: number,
  cellValue: any,
  rowData: any,
  linkedColumns?: ILinkedColumn[],
  format?: string,
  schemaType?: SchemaType,
): any {
  let linkedColumnIndex =
    gridDisplayType === VERTICAL && columnName.toLowerCase() === VALUE
      ? getLinkedColumnIndex(rowData.property, linkedColumns)
      : getLinkedColumnIndex(columnName, linkedColumns);

  // do not generate the link if condition is false
  if (linkedColumns && linkedColumnIndex !== -1 && linkedColumns[linkedColumnIndex].condition !== undefined) {
    if (linkedColumns[linkedColumnIndex].condition(rowData) === false) {
      linkedColumnIndex = -1;
    }
  }

  if (linkedColumnIndex !== -1) {
    const onClickFunction = getLinkedColumnOnClick(linkedColumnIndex, linkedColumns);
    return (
      <a href="" onClick={e => onClickFunction(rowData, e)}>
        {formatValue(gridDisplayType, columnName, dataType, numericPrecision, cellValue, rowData, format, schemaType)}
      </a>
    );
  }

  return formatValue(gridDisplayType, columnName, dataType, numericPrecision, cellValue, rowData, format, schemaType);
}

function formatValue(
  gridDisplayType: string,
  columnName: string,
  dataType: NexusDataType,
  numericPrecision: number,
  cellValue: any,
  rowData: any,
  format?: string,
  schemaType?: SchemaType,
): any {
  let formattedValue: any;
  let cellDataType: NexusDataType = dataType;

  if (cellValue == null) {
    formattedValue = cellValue;
  } else {
    if (gridDisplayType === VERTICAL && columnName.toLowerCase() === VALUE && rowData[DATA_TYPE_NAME] !== undefined) {
      cellDataType = rowData.data_type_name;
      format = rowData.format;
    }

    if (schemaType === SchemaType.GENESIS && format) {
      formattedValue = formatNumericValue(cellValue, format);
    } else {
      formattedValue = parseDataTypeValue(cellDataType, cellValue, numericPrecision, columnName);
    }
  }

  if (gridDisplayType === VERTICAL) {
    return <span style={getStyle(cellDataType, gridDisplayType)}>{formattedValue}</span>;
  }

  return <span>{formattedValue}</span>;
}

function getStyle(dataType: string, gridDisplayType: string): any {
  const defaultStyle = {
    textAlign: 'left',
    whiteSpace: 'unset',
  };

  const booleanStyle = {
    textAlign: 'center',
  };

  const numberStyle = {
    textAlign: 'right',
  };

  const numberVerticalStyle = {
    display: 'table',
    textAlign: 'right',
    width: '100%',
  };

  let dataStyle: any;

  switch (dataType.toLowerCase()) {
    case 'decimal':
    case 'float':
      dataStyle = gridDisplayType === VERTICAL ? defaultStyle : numberStyle;
      break;
    case 'money':
    case 'numeric':
      dataStyle = gridDisplayType === VERTICAL ? numberVerticalStyle : numberStyle;
      break;
    case 'tinyint':
    case 'boolean':
    case 'bit':
      dataStyle = gridDisplayType === VERTICAL ? defaultStyle : booleanStyle;
      break;
    default:
      dataStyle = defaultStyle;
      break;
  }

  return dataStyle;
}

/**
 * nf (special code) - no format
 *
 * if the number is too large to handle by javascript, it will converted to scientific notation
 *
 * n - defines decimal value without comma separation
 * { number: 1000.99, format: 'n0', result: '1001' },
 * { number: 1000.99, format: 'n1', result: '1001.0' },
 * { number: 1000.99, format: 'n2', result: '1000.99' },
 * { number: 1000.99, format: 'n3', result: '1000.990' },
 *
 * d - defines decimal value with comma separation
 * { number: 1000.99, format: 'd0', result: '1,001' },
 * { number: 1000.99, format: 'd1', result: '1,001.0' },
 * { number: 1000.99, format: 'd2', result: '1,000.99' },
 * { number: 1000.99, format: 'd3', result: '1,000.990' },
 *
 * f - defines floating precision, precede any format with f to truncate trailing zeros
 * { number: 1000.99, format: 'fn0', result: '1001' },
 * { number: 1000.99, format: 'fn1', result: '1001' },
 * { number: 1000.99, format: 'fd2', result: '1,000.99' },
 * { number: 1000.99, format: 'fd3', result: '1,000.99' },
 * { number: 1000.1234, format: 'fd3', result: '1,000.123' },
 */
const formatNumericValue = (value: any, format: string) => {
  if (format === 'nf' || isNaN(Number(value)) || format.length < 2) {
    return value;
  }
  const numberValue = Number(value);
  if (numberValue.toLocaleString('en-US', { useGrouping: false, maximumSignificantDigits: 21 }).length > 16) {
    return numberValue.toExponential().toString();
  }
  let formattedValue = value;
  let options = {};
  if (format.startsWith('f') && format.length >= 3) {
    let precision = Number(format.substr(2));
    precision = !isNaN(precision) ? precision : 2;
    options = { ...options, maximumFractionDigits: precision };
    format = format.substr(1);
  } else {
    let precision = Number(format.substr(1));
    precision = !isNaN(precision) ? precision : 2;
    options = { ...options, minimumFractionDigits: precision, maximumFractionDigits: precision };
  }
  if (format[0] === 'n') {
    options = { ...options, useGrouping: false };
  } else if (format[0] === 'd') {
    options = { ...options, useGrouping: true };
  }
  formattedValue = numberValue.toLocaleString('en-US', options);
  return formattedValue;
};
