import styled from 'styled-components';

export const SummaryPanelComponent = styled.div`
  height: 36px;
  background-color: var(--background-summary-panel);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  border-bottom: var(--foreground-form-border);
`;

export const FundSelectorWrapper = styled.div`
  margin-right: 20px;
`;
export const DatePickerWrapper = styled.div`
  display: flex;

  & [class^='FormElementsstyle'][data-testid='formgroup-date-input'] {
    border-bottom: none;
    margin-bottom: 0px;

    & [class^='DateInputstyle__DateInputWrapper'] {
      bottom: 0px;
      top: 1.8px;
    }
  }
`;
