import { useRef, useEffect } from 'react';

// A hook that adds the event listener, and removal on cleanup.

export const useEventListener = (
  eventName: 'keydown' | 'popstate',
  handler: (event: any) => void,
  element: any = document,
) => {
  const savedHandler = useRef<any>({ current: {} });

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Make sure element supports addEventListener
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: any) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
