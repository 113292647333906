import React from 'react';
import { AntSwitch } from './switch.styled';
// import { Switch as AntSwitch } from '@mui/material';
export interface SwitchProps {
  onChange?: (_checked: boolean) => void;
  checked?: boolean;
}

export const Switch = React.memo(({ onChange, checked = false }: SwitchProps) => {
  const handleOnChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(_event.target.checked);
    }
  };

  return <AntSwitch disableRipple checked={checked} onChange={handleOnChange} />;
});
