import React from 'react';

interface IErrorBoundary {
  children: React.ReactNode;
}

/*
This allows to catch render error in templates.
 */
class ErrorBoundary extends React.Component<IErrorBoundary> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: object, errorInfo: object) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.info('[ErrorBoundary] caught error: ', error);
    console.info('[ErrorBoundary] caught errorInfo: ', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
