export type GlideLabelType = {
  button_text: string;
  tooltip_text: string;
  title_text: string;
  data_test_id: string;
};

export type GlideLabels = {
  [key: string]: GlideLabelType;
};

export const GLIDE_LABELS: GlideLabels = {
  portfolio_modelling: {
    button_text: '',
    tooltip_text: 'Portfolio Modeling',
    title_text: 'Portfolio Modeling',
    data_test_id: 'portfolio-modelling-btn',
  },
};
