import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import Select, { MenuItem } from './components/Select';

export const SelectInput = ({
  field,
  value = '',
  onChange,
  error,
  //formValues,
  customWidth,
}: FormElementInputComponent) => {
  if (field?.hidden) return null;
  const getOptionsValue = (options: MenuItem[]) => {
    let optionVal = '';
    if (value) {
      // optionVal = value.includes('/') ? value.split('/')[1] : value;
      // for auto populating default value
      optionVal = value;
    } else if (typeof field?.defaultValue === 'object') {
      optionVal = field.defaultValue[Object.keys(field.defaultValue)[0]];
    } else if (field?.defaultValue) {
      // optionVal = field?.defaultValue.includes('/') ? field?.defaultValue.split('/')[1] : field?.defaultValue;
      // for auto populating default value
      optionVal = field?.defaultValue;
    }
    if (optionVal.includes('lookups/')) {
      return options.find(option =>
        option.value.startsWith('lookups/')
          ? option.value === optionVal
          : option.value === optionVal.replace('lookups/', ''),
      )?.value;
    } else {
      return options.find((option: MenuItem) => option.name === optionVal || option.value === optionVal)?.value;
    }
  };

  const selectedValue = field.options ? getOptionsValue(Object.values(field.options)) : '';

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      <Select
        dataTestId={`${field.name}-select`}
        disabled={field.disabled || field.readonly}
        value={selectedValue || ''}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={onChange}
        menuItems={field.options ? Object.values(field.options) : []}
        editIsEnabled={!field.disabled && !field.readonly}
      />
    </FormErrorWrapper>
  );
};
