import CheckBox from 'devextreme-react/check-box';
import { formatNumber } from '@virtus/glide/src/utils/formatters';
import { GroupItem, Summary } from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import { CustomCellRenderKeyProps, DxColumn, DxDataType, DxSummary, DxSummaryType } from 'src/DxDataGrid/DxDataGrid';
import { ValidationRules } from 'src/DxDataGrid/DxDataGrid.model';
import { PriceChangeCellRender } from '../cellRenders/price-change-cell-render/price-change-cell-render';
import { updateOnLoadCellValues } from '@virtus/glide/src/utils/cell-calculation-field-rule';
import { getGlideFormatParams } from '@virtus/glide/src/utils/formatter';
import { ObjectTypeUriCellRender } from '../cellRenders/object-type-cell-render/object-type-cell-render';
import { DateInputComponent } from '../cellRenders/date-input-cell-render/date-input';

export enum FieldRuleType {
  Filter = 'Filter',
  ReadOnly = 'Read Only',
  Required = 'Required',
  Valid = 'Valid',
  Value = 'Value',
  Hidden = 'Hidden',
}

type FieldRule = {
  ruleFn: (...args: any[]) => boolean | string | number | undefined | null;
  ruleType: FieldRuleType;
};

export interface GlideDataSource {
  schema: GlideSchema[];
  data: any;
  fieldRules?: {
    [fieldName: string]: FieldRule[];
  };
  webLayouts?: any; // This is actually GlideLayoutData but can't be imported so mapSchemaGlide needs to be imported to Glide package!
  [key: string]: any;
}

export enum GlideDataType {
  DateTime = 'DateTime',
  Object = 'Object',
  ObjectCollection = 'ObjectCollection',
  String = 'String',
  LargeString = 'LargeString',
  Decimal = 'Decimal',
  Bit = 'Bit',
}

export enum GroupItemSummaryType {
  Sum = 'sum',
  Average = 'avg',
  Custom = 'custom',
  Max = 'max',
  Min = 'min',
  Count = 'count',
}

export const HIDE_FIELDS_SUMMARY_LIST = [
  'Market Map Last Price',
  'Price',
  'Price (Traded)',
  'Spot Days',
  'FX Rate',
  'Floor',
  'PX Mid(BBG)',
  'PX Mid(Markit)',
  'PX Ask(BBG)',
  'PX Ask(Markit)',
  'PX Bid(BBG)',
  'PX Bid(Markit)',
  'Coupon',
  'All in rate',
  'Spread',
  'Target Price',
  'Commitment Percentage',
  'PIK Rate',
];

interface SummaryDataTypeProps {
  dataType?: GlideDataType;
  summaryType?: DxSummaryType;
  totalItemSummary?: any;
}

export interface GlideSummaryOptions {
  summaryKeyProps?: DxSummary[];
  summaryDataTypeProps?: SummaryDataTypeProps;
}

export type GlideDataTypeMap = { [key in GlideDataType]: DxDataType | 'ObjectCollection' };

export const glideDataTypeMap: GlideDataTypeMap = {
  DateTime: 'date',
  Object: 'object', // Should have been resolved
  String: 'string',
  LargeString: 'string',
  Decimal: 'number',
  // Exception: we will resolve object collections through Glide Table Modal
  ObjectCollection: 'ObjectCollection', // Should have been resolved
  Bit: 'boolean',
};

export interface GlideSchema {
  data_type: GlideDataType;
  display_name: string; // ⚠️ ♻️  Will be deleted when migration have finished (we will use caption). We'll use caption (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
  caption?: string;
  description?: string;
  accessor?: string; // ⚠️ ♻️  Will be deleted when migration have finished (we will use dataField). We'll use dataField (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
  dataField?: string;
  category?: string;
  object_type?: string;
  format?: string;
  editCellComponent?: DxColumn['editCellComponent'];
  validationRules?: ValidationRules[];
  editProps?: {
    apiRules: any[];
    parentValues: object;
  };
  lookups?: any;
  calculateCellValue?: (e: any) => void;
  calculation_script_js?: string;
  style?: { [key: string]: { is_editable: boolean } };
  aggregation_calculation?: keyof typeof GroupItemSummaryType;
  display_multiplier?: string;
  utc_conversion_enabled?: boolean;
  enable_link?: boolean;
}

/**
 * Returns custom component for column editCellComponent.
 * @param props
 * @returns
 */
const EditCellComponent = (props: any) => {
  const checkboxValue = props.data.value != null ? props.data.value : undefined;
  const [value, setValue] = useState(checkboxValue);
  const onChange = (e: any) => {
    props.data.setValue(e.value, e.value ? 'Yes' : 'No');
    setValue(e.value);
  };
  return <CheckBox onValueChanged={onChange} value={value} />;
};

/**
 * Generate the summary: Use the default id, optionally add custom key summary and allow for default summary for data types
 * @param dataSourceSchema
 * @param options
 * @param calculateCustomSummary
 */
export const getSummaryGlide = (
  dataSourceSchema: GlideSchema[],
  { summaryKeyProps, summaryDataTypeProps }: GlideSummaryOptions,
  calculateCustomSummary?: any,
) => {
  return (
    <Summary
      recalculateWhileEditing
      skipEmptyValues
      calculateCustomSummary={!summaryKeyProps ? undefined : calculateCustomSummary}
    >
      {/* Add custom summary key */}
      {summaryKeyProps &&
        summaryKeyProps.map((gi: DxSummary) => (
          <GroupItem name={gi.name} key={gi.column} column={gi.column} alignByColumn summaryType={gi.summaryType} />
        ))}
      {summaryDataTypeProps && summaryDataTypeProps.totalItemSummary}

      {/* Config driven default summary grouping */}
      {dataSourceSchema &&
        dataSourceSchema
          .filter(
            (col: GlideSchema) =>
              col.aggregation_calculation &&
              col.aggregation_calculation !== 'Custom' &&
              HIDE_FIELDS_SUMMARY_LIST.indexOf(col.display_name) < 0,
          )
          .map((col: GlideSchema) => {
            const customizeTextHandler = (data: any): string => {
              if (col.format) {
                const val = formatNumber({
                  value: data.value,
                  formatString: col.format.toLowerCase(),
                  isCellEditable: col.style ? Object.values(col.style)[0].is_editable : true,
                });
                return `${val}`;
              }
              return `${Math.round(data.value * 100) / 100}`;
            };

            return (
              <GroupItem
                key={col.display_name}
                name={col.display_name}
                column={col.display_name}
                alignByColumn
                summaryType={GroupItemSummaryType[col.aggregation_calculation!]}
                customizeText={customizeTextHandler}
                skipEmptyValues={!(GroupItemSummaryType[col.aggregation_calculation!] === 'avg')}
              />
            );
          })}
    </Summary>
  );
};

export const mapSchemaGlide = (
  dataSource: GlideDataSource,
  customCellRenderKeyProps: CustomCellRenderKeyProps,
  customComponents: any,
) => {
  const schema: DxColumn[] = [];
  // Todo: memoize schema!
  // Create Dx Columns from schema
  dataSource?.schema?.forEach(
    ({
      display_name, // ⚠️ ♻️ This is the same than caption, will be deleted (we will use caption). Migration in progress (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
      format,
      data_type,
      accessor, // ⚠️ ♻️ This is the same than dataField, will be deleted (we will use dataField). Migration in progress (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
      dataField,
      fixed,
      fixedPosition,
      sortOrder,
      sortIndex,
      minWidth = 100,
      caption,
      validationRules,
      lookups,
      editProps,
      calculateCellValue,
      style,
      object_type,
      calculation_script_js,
      display_multiplier,
      utc_conversion_enabled,
    }: GlideSchema &
      Pick<
        DxColumn,
        'fixed' | 'fixedPosition' | 'sortIndex' | 'sortOrder' | 'minWidth' | 'caption' | 'validationRules'
      >) => {
      let column: DxColumn = {
        key: caption || display_name, // ⚠️ ♻️ Remove accessor and replace display_name by caption when migration have finished (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
        dataField: dataField || accessor || display_name, // ⚠️ ♻️ Remove accessor and replace display_name by caption when migration have finished (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
        dataType: glideDataTypeMap[data_type] as DxDataType,
        fixed,
        fixedPosition,
        minWidth: minWidth || 100,
        sortOrder,
        allowEditing: style ? Object.values(style)[0].is_editable : true,
        sortIndex,
        caption: caption || display_name, // ⚠️ ♻️ Remove display_name and use caption when migration have finished (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
        validationRules,
        lookups: lookups && typeof lookups === 'string' ? { values: JSON.parse(lookups) } : undefined,
        editProps,
        calculateCellValue,
        objectType: object_type && object_type.includes('/') ? object_type.split('/')[1] : '',
        format,
        displayMultiplier: display_multiplier,
        isScheduleUTC: utc_conversion_enabled,
        filterOperations: ['=', '<>', 'isblank', 'isnotblank'],
      };
      const groupCellRender = (cellData: any): any => {
        const { value } =
          cellData?.summaryItems?.find(
            ({ summaryType }: { summaryType: string }) => summaryType === GroupItemSummaryType.Count,
          ) || {};
        return (
          <div className="numeric-cell">
            {column.key}: {cellData.text} {value ? ` (Count: ${value})` : ''}
          </div>
        );
      };

      // Custom cell render based on Glide schema
      switch (column.dataType) {
        // Displaying using locale date has performances issues which leads to crash with DX grid
        // https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/97584

        case glideDataTypeMap.DateTime:
          column.format = format;
          column.cellRender = (cellData: any): any => {
            const hasValueAndFormat = cellData.value && column.format;
            if (hasValueAndFormat) {
              // Below line is used to identify if we need to show DateTime or only Date
              // As we get data_type as 'DateTime' and used format if need to show time
              let columnFormat = column.format;
              if (columnFormat.display_name) {
                columnFormat = columnFormat.display_name;
              }
              const hasTime = columnFormat.split(' ').length > 1;
              const formattedValue = hasTime
                ? new Date(cellData.value).toLocaleString(navigator.language)
                : new Date(cellData.value).toLocaleDateString(navigator.language);
              return <div>{formattedValue}</div>;
            }
            if (cellData.value) {
              return new Date(cellData.value).toLocaleString(navigator.language);
            }
          };
          column.editCellRender = (cellData: any): any => {
            return <DateInputComponent cellData={cellData} />;
          };
          break;

        case glideDataTypeMap.ObjectCollection: {
          const TableModal: any = customComponents && customComponents[glideDataTypeMap.ObjectCollection];
          if (TableModal) {
            column.cellRender = (cellData: any): any => {
              if (cellData.value) {
                return (
                  <TableModal
                    isTable
                    key={cellData.data._uri}
                    uris={cellData.value}
                    title={cellData.data['Display Name']}
                    fieldName={column.dataField}
                  />
                );
              }
            };
          }
          column.filterOperations = ['=', '<>', '<', '>', '<=', '>=', 'between'];
          column.headerFilter = {
            search: { enabled: false },
            dataSource: (e: any) => {
              e.dataSource.paginate = false;
              e.dataSource.postProcess = (result: any) => {
                const dataSet = new Set(result.map((item: any) => (item.key ? item.key.length : 0)));
                return [...dataSet]
                  .sort((a: any, b: any) => a - b)
                  .map(value => ({
                    key: value,
                    text: value,
                    value: value,
                  }));
              };
            },
          };
          column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
            const column = this as any;
            if (selectedFilterOperation) {
              return [column.calculateDisplayValue, selectedFilterOperation, filterValue];
            }
            // eslint-disable-next-line prefer-rest-params
            return column.defaultCalculateFilterExpression(...arguments);
          };
          column.calculateDisplayValue = (rowData: any) => {
            return rowData[column.key] ? rowData[column.key].length : 0;
          };
          break;
        }
        case glideDataTypeMap.String: {
          if (column.isScheduleUTC) {
            column.calculateCellValue = (rowData: any) => {
              const time = rowData[column.dataField];
              const regex = /[a-zA-Z]/;
              if (time && !regex.test(time)) {
                const [hour, min] = time?.split(':').map(Number);
                const date = `${new Date().getMonth()}/${new Date().getDate()}/${new Date().getFullYear()} ${hour}:${min} UTC`;
                return `${String(new Date(date).getHours()).padStart(2, '0')}:${String(
                  new Date(date).getMinutes(),
                ).padStart(2, '0')}`;
              } else return rowData[column.dataField];
            };
          }
          break;
        }
        case glideDataTypeMap.LargeString: {
          // set the fix column width for large string to manage it for grids
          column.width = 200;
          column.filterOperations = [
            'contains',
            'notcontains',
            'startswith',
            'endswith',
            '=',
            '<>',
            'isblank',
            'isnotblank',
          ];
          break;
        }
        case glideDataTypeMap.Decimal:
          column.groupCellRender = (cellData: any): any => groupCellRender(cellData);
          column.filterOperations = ['=', '<>', '<', '>', '<=', '>=', 'between'];
          if (format) {
            const formatParams = getGlideFormatParams(format);
            column.headerFilter = {
              search: { enabled: !formatParams.formatType.includes('p') },
              dataSource: (e: any) => {
                e.dataSource.postProcess = (result: any) => {
                  return result.map((item: any) => {
                    // This specific null check case is to handle '(Blanks)' item of the filter.
                    let formattedText = !item.value && item.value !== 0 ? item.text : item.value;
                    if (!isNaN(+formattedText)) {
                      formattedText = formatNumber({
                        value: !item.value && item.value !== 0 ? item.text : item.value,
                        formatString: format.toLowerCase(),
                        isCellEditable: column.allowEditing,
                        displayMultiplier: Number(column.displayMultiplier),
                      });
                    }
                    return {
                      value: item.value,
                      text: formattedText,
                    };
                  });
                };
              },
            };
          }
          if (column.key === 'Market Map Last Price') {
            column.dataType = 'String';
            column.alignment = 'right';
            column.width = 200;
            column.cellRender = (cellData: any) => <PriceChangeCellRender cellData={cellData} format={format} />;

            column.headerFilter = {
              dataSource: (e: any) => {
                e.dataSource.postProcess = (result: any) => {
                  return result.map((item: any) => {
                    const newText = !item.value && item.value !== 0 ? item.text : item.value;
                    return {
                      key: item.key,
                      value: item.value,
                      text: newText,
                    };
                  });
                };
              },
            };
            column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
              const self = this as any;
              const _filterValue =
                filterValue && selectedFilterOperation ? filterValue.toString().split(' ')[0] : filterValue;
              return [self.customizeText, selectedFilterOperation || 'contains', _filterValue];
            };
            column.customizeText = (rowData: any) => {
              return rowData[column.key]
                ? typeof rowData[column.key] === 'number'
                  ? rowData[column.key]
                  : +rowData[column.key].split(' ')[0]
                : rowData[column.key];
            };
          } else {
            // According to the DevExtreme documentation, it is recommended to change the function name
            // from "calculateDisplayValue" to "customizeText" in order to avoid any sorting issues.
            column.customizeText = (cellData: any) => {
              if (cellData.target === 'search') return cellData.valueText;
              const formattedVal = format
                ? formatNumber({
                    value: Number(cellData.value ?? ''),
                    formatString: format.toLowerCase(),
                    isCellEditable: column.allowEditing,
                    displayMultiplier: Number(column.displayMultiplier),
                  })
                : Number(cellData.value ?? '').toLocaleString(navigator.language, {
                    minimumFractionDigits: 2,
                  });
              // Commenting this code it causes sorting issue
              // Percent Values multiplied by 100 when they are for readonly mode and not for batch editing
              // if (format?.toLocaleLowerCase().includes('p')) {
              //   return `${(cellData.value * 100).toFixed(+format?.charAt(format.length - 1))}%`;
              // }
              return formattedVal;
            };
          }
          column.calculateCellValue = (rowData: any) => {
            if (!Object.keys(rowData).length) return;
            if (calculation_script_js) {
              const value = updateOnLoadCellValues(rowData, calculation_script_js, dataSource.schema);
              return value ?? rowData[column.dataField];
            }
            // Percent Values to display in Edit mode by multiplied by 100
            if (format?.toLocaleLowerCase().includes('p')) {
              return Number(`${((rowData[column.dataField] ?? '') * 100).toFixed(+format?.charAt(format.length - 1))}`);
            } else if (format?.toLocaleLowerCase().includes('n') && display_multiplier) {
              return Number(`${(rowData[column.dataField] ?? '') * Number(display_multiplier)}`);
            }

            return rowData[column.dataField];
          };

          column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            const _filterValue =
              filterValue && selectedFilterOperation ? filterValue.toString().split(' ')[0] : filterValue;
            return [self.calculateCellValue, selectedFilterOperation || '=', _filterValue];
          };
          break;
        case glideDataTypeMap.Object:
          if (column.lookups) {
            column.headerFilter = {
              dataSource: () => {
                return column.lookups?.values?.reduce(
                  (acc, curr) => [...acc, { text: curr['display_name'], value: curr['uri'] }],
                  [],
                );
              },
            };
          }
          column.filterOperations = [
            'contains',
            'notcontains',
            'startswith',
            'endswith',
            '=',
            '<>',
            'isblank',
            'isnotblank',
          ];
          column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
            const column = this as any;
            // const customCalculateCellDefaultFilter = (rowData: any) => rowData[column.dataField];

            return selectedFilterOperation
              ? [column.calculateCellValue, selectedFilterOperation, filterValue]
              : [column.calculateCellValue, 'contains', filterValue];
          };
          if (column?.lookups) {
            column.calculateCellValue = (rowData: any) => {
              return rowData[column.dataField] && column?.lookups
                ? column?.lookups?.values?.find(value => rowData[column.dataField] === value['uri'])['uri']
                : rowData[column.dataField];
            };
          } else {
            column.calculateCellValue = (cellData: any): any => {
              const hasUriAndDisplayName = cellData[column.dataField]?.display_name && cellData[column.dataField]?.uri;
              if (hasUriAndDisplayName) {
                return cellData[column.dataField]?.display_name;
              } else {
                if (typeof cellData[column.dataField] === 'string' && cellData[column.dataField]) {
                  return cellData[column.dataField];
                } else return null;
              }
            };
          }

          column.groupCellRender = (cellData: any): any => groupCellRender(cellData);
          if (!column?.lookups) {
            // column.calculateDisplayValue = (rowData: any) => rowData[column.dataField];
            column.minWidth = 150;
            column.cellRender = (cellData: any): any => {
              const hasUriAndDisplayName =
                cellData.data[column.dataField]?.display_name && cellData.data[column.dataField]?.uri;
              // hideLink isn't a custom attribute inside of devextreme gridref obj or any other api
              // using this to hide the links present on GOM when its in edit mode only.
              if (cellData.component.hideLink) {
                return (
                  cellData.data[column.dataField]?.display_name ||
                  cellData.data[column.dataField]?.uri ||
                  cellData.row?.data[column.dataField]
                );
              } else if (hasUriAndDisplayName) {
                return <ObjectTypeUriCellRender cellData={cellData} />;
              } else {
                return cellData.row?.data[column.dataField];
              }
            };
          }
          break;
        case glideDataTypeMap.Bit:
          column.cellRender = (cellData: any) => {
            const checkboxValue = cellData.value != null ? cellData.value : undefined;
            return <CheckBox value={checkboxValue} readOnly={true} />;
          };
          column.editCellComponent = (props: any) => <EditCellComponent {...props} />;
          break;
      }

      // extend column custom properties
      if (customCellRenderKeyProps && (customCellRenderKeyProps as any)[column.key]) {
        column = { ...column, ...(customCellRenderKeyProps as any)[column.key] };
      }

      schema.push(column);
    },
  );
  return schema;
};

/**
 * Method to transform all the decimal and percentage columns in excel cell while exporting data of DxDataGrid.
 * All the decimal columns original value will get transformd exaclty in the same manner how they have been transformed on UI DxDataGrid Cells.
 */
export const glideExcelExport = (data: any, dataSource: GlideDataSource) => {
  if (data.rowType === 'data') {
    const filteredSchema = dataSource?.schema?.filter(
      column => column.display_name === data.column.dataField || column.display_name === data.column.caption,
    )[0] as GlideSchema;
    let cellVal = data.value;
    const { format, data_type, style } = filteredSchema ?? {};
    if (format && data_type === GlideDataType.Decimal && (cellVal || cellVal === 0)) {
      // Percentage column values contains '%' symbol,
      // the formatNumber function not able to convert it, hence truncating the '%' symbol from cellVal.
      if (cellVal.endsWith('%')) cellVal = cellVal.slice(0, -1);
      const formattedValue = formatNumber({
        value: cellVal,
        formatString: format.toLowerCase(),
        isCellEditable: style ? Object.values(style)[0]?.is_editable : false,
      });
      data.value = formattedValue;
    }
  }
};
