import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import styled from 'styled-components';

const StyledSvgIcon = styled(SvgIcon)`
  font-size: var(--inspector-icon-font-size) !important;
`;

export type FillProps = { fillColor?: string };

/* eslint-disable max-len */
const HelpIcon: React.SFC<SvgIconProps & FillProps> = props => (
  <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={props.fillColor || 'var(--text)'}
        d="M12 4c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 13.6h-1.6V16h1.6v1.6zm1.656-6.2l-.72.736c-.576.584-.936 1.064-.936 2.264h-1.6V14c0-.88.36-1.68.936-2.264l.992-1.008c.296-.288.472-.688.472-1.128 0-.88-.72-1.6-1.6-1.6-.88 0-1.6.72-1.6 1.6H8.8a3.2 3.2 0 1 1 6.4 0c0 .704-.288 1.344-.744 1.8z"
      />
    </g>
  </StyledSvgIcon>
);

export default HelpIcon;
