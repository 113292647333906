import React from 'react';
import { Modal } from '@virtus/components';
import * as S from './overlay-notifications.style';
export interface NotificationMessagesProps {
  show: boolean;
  notification: any;
  title: string;
  onCloseCallback: () => void;
  primaryButton: { text: string; action: () => void };
}

const NotificationOverlayMessages = ({
  show,
  notification,
  title,
  primaryButton,
  onCloseCallback,
}: NotificationMessagesProps) => {
  const messagesList: any = () => {
    return (
      notification &&
      notification?.messageList &&
      Object.entries(notification?.messageList).map((eachGroupedMessage: any, index: number) => {
        const [key, value] = eachGroupedMessage;
        return (
          <S.MessagesMainWrapper key={'group-' + index.toString()}>
            <S.GroupHeading>{key}</S.GroupHeading>
            {value.map((eachMessage: any, index: number) => (
              <S.MessagesListWrapper key={'listitem-' + index.toString()}>{eachMessage}</S.MessagesListWrapper>
            ))}
          </S.MessagesMainWrapper>
        );
      })
    );
  };
  return (
    <Modal title={title} onClose={onCloseCallback} show={show} isDraggable={true} primaryButton={primaryButton}>
      {messagesList()}
    </Modal>
  );
};

export default NotificationOverlayMessages;
