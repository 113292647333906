import React from 'react';
import { Background, DrawerContainer, DrawerContent, PaperStyled, StyledContent } from 'src/Inspector/styles';
import Loading from 'src/Loading';
import { LoadingIconSizes, LoadingIconType } from 'src/LoadingIcon/LoadingIcon';
import { PanelHeader, PanelHeaderProps } from 'src/Panel/PanelHeader';
import styled from 'styled-components';

export interface InspectorProps extends Omit<PanelHeaderProps, 'tooltipText'> {
  dataTestId?: string;
  isExpanded: boolean;
  loading: boolean;
  loadingIcon: {
    type: LoadingIconType;
    size: LoadingIconSizes;
  };
  children?: any;
  open?: boolean;
  type?: string;
  portfolio?: any;
  height?: string;
  style?: React.CSSProperties;
  paperProps?: any;
  tooltipText?: string;
  associatedObjectTitle?: string;
  panelHeaderStyle?: React.CSSProperties;
}

const DEFAULT_INSPECTOR_WIDTH = '430px';
const getInspectorWidth = (isExpanded: boolean) => (isExpanded ? `100vw` : DEFAULT_INSPECTOR_WIDTH);

const Inspector: React.FunctionComponent<InspectorProps> = ({
  open = true,
  style,
  loading,
  title,
  onBack,
  onClose,
  children,
  showBackButton = false,
  showCloseBtn,
  loadingIcon,
  isExpanded,
  headerExpandedChildren,
  onExpand,
  dataTestId,
  paperProps,
  tooltipText,
  associatedObjectTitle,
  panelHeaderStyle,
}) => {
  if (!open) return null;

  return (
    <DrawerContainer
      variant="persistent"
      open={open}
      anchor="right"
      style={style}
      PaperProps={paperProps || { component: PaperStyled as unknown }}
    >
      <Background>
        <DrawerContent width={getInspectorWidth(isExpanded)} data-testid={dataTestId || 'inspector'}>
          <InspectorWrapper>
            <PanelHeader
              showBackButton={showBackButton}
              onBack={onBack}
              title={title}
              onClose={onClose}
              showCloseBtn={showCloseBtn}
              tooltipText={tooltipText || title}
              isExpanded={isExpanded}
              headerExpandedChildren={headerExpandedChildren}
              onExpand={onExpand}
              associatedObjectTitle={associatedObjectTitle}
              panelHeaderStyle={panelHeaderStyle}
            />

            <StyledContent>
              {loading ? <Loading show={true} type={loadingIcon.type} size={loadingIcon.size} full /> : <>{children}</>}
            </StyledContent>
          </InspectorWrapper>
        </DrawerContent>
      </Background>
    </DrawerContainer>
  );
};

const InspectorWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  position: relative;
  left: 5px;
`;

export default Inspector;
