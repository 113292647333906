import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const MenuClose: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path fill="#FFF" d="M13.41 7.41L9.83 11H21v2H9.83l3.59 3.59L12 18l-6-6 6-6 1.41 1.41zM5 6v12H3V6h2z" />
    </g>
  </SvgIcon>
);

export default MenuClose;
