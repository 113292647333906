export const appsLinks = {
  nexus: {
    mock: '',
    local: 'http://localhost:4001',
    dev: 'https://nexusdev.virtusconnect.com',
    qa: 'https://nexusstaging.virtusconnect.com',
    prod: 'https://nexus.virtusconnect.com',
    commerzbank: 'https://nexusdev.virtusconnect.com',
    BSP: 'https://nexusdev.virtusconnect.com',
    demo: 'https://nexusdemo.virtusconnect.com',
    poc: '',
  },
  glide: {
    // Internal envs
    mock: '',
    local: 'http://localhost:5001',
    dev: 'https://glide-web-dev.alphakinetic.com/',
    qa: 'https://glide-web-qa.alphakinetic.com/',
    // TODO: we should use a sandbox here used for regression testing (internal env)
    demo: 'https://glide-web-demo.alphakinetic.com',
    prod: 'https://glide-web-demo.virtusconnect.com',
    // Client envs
    commerzbank: 'https://glide.virtusconnect.com',
    BSP: 'https://glide-web-bsp.virtusconnect.com',
    poc: 'https://glide-web-poc.alphakinetic.com',
  },
  genesis: {
    mock: '',
    local: '',
    dev: 'https://genesisdev.virtusconnect.com',
    qa: 'https://genesisstaging.virtusconnect.com',
    prod: 'https://genesis.virtusconnect.com',
    commerzbank: 'https://genesis.virtusconnect.com',
    BSP: 'https://genesis.virtusconnect.com',
    demo: '',
    poc: '',
  },
};
