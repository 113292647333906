import { SnackbarContent as MUISnackbarContent } from '@mui/material';
import { NotificationType } from 'src/SnackBarNotification/SnackBarContent';
import styled from 'styled-components';

export const SnackbarContent = styled(MUISnackbarContent)`
  /* Override MUI default */
  max-width: fit-content; /* disable MUI break points */
  & > div {
    padding: 0;
    font-size: var(--snackBarContentFontSize);
  }

  button:hover {
    background-color: transparent;
  }

  [aria-label='close'] {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  /* Styled content */
  color: var(--snackbar-notification-text);
  margin: 1em 1.4em;
  padding: 0;
  max-height: fit-content;
  background: var(--background-snackbar-container-info);

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    color: var(--default-blue);
    width: 100%;
    cursor: pointer;
    li {
      margin-left: 1rem;
    }
    li:first-of-type {
      margin-left: 0;
    }
  }
`;

export const Container = styled.div`
  font-size: var(--containerFontSize);
  display: flex;
  flex-direction: row;
`;

const headerBgColor: { [key in NotificationType]: string } = {
  info: 'var(--background-snackbar-header-info)',
  error: 'var(--background-snackbar-header-error)',
  warning: 'var(--background-snackbar-header-warning)',
  success: 'var(--background-snackbar-header-success)',
};

const bodyBgColor: { [key in NotificationType]: string } = {
  info: 'var(--background-snackbar-container-info)',
  error: 'var(--background-snackbar-container-error)',
  warning: 'var(--background-snackbar-container-warning)',
  success: 'var(--background-snackbar-container-success)',
};

const bodyBorderColor: { [key in NotificationType]: string } = {
  info: 'var(--snackbar-border-info)',
  error: 'var(--snackbar-border-error)',
  warning: 'var(--snackbar-border-warning)',
  success: 'var(--snackbar-border-success)',
};

export const Header = styled.div<{ type: NotificationType }>`
  display: flex;
  width: 4px;
  align-items: center;
  justify-content: center;
  background: ${props => headerBgColor[props.type || 'info']};
`;

const svgWidth: { [key in NotificationType]: string } = {
  info: 'var(--snackbar-svg-width-info)',
  error: 'var(--snackbar-svg-width-error)',
  warning: 'var(--snackbar-svg-width-error)',
  success: 'var(--snackbar-svg-width-error)',
};

const svgHeight: { [key in NotificationType]: string } = {
  info: 'var(--snackbar-svg-height-info)',
  error: 'var(--snackbar-svg-height-error)',
  warning: 'var(--snackbar-svg-height-error)',
  success: 'var(--snackbar-svg-height-error)',
};

export const Body = styled.div<{ type: NotificationType }>`
  flex-direction: column;
  padding: 6.4px 10px 0px 10px;
  background: ${props => bodyBgColor[props.type || 'info']};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: ${props => bodyBorderColor[props.type]};
  span > svg {
    top: 141px;
    left: 60px;
    width: ${props => svgWidth[props.type]};
    height: ${props => svgHeight[props.type]};
  }
`;

export const Title = styled.div`
  font-weight: bold;
  margin: 3px 12em 0px 6px;
  letter-spacing: 0px;
  height: 22px;
  font-family: var(--snackbar-border-font-family);
  font-size: var(--snackbar-border-font-size);
`;

export const Message = styled.div`
  white-space: pre-line;
  margin: 8px 0px 6px 0px;
  font-family: var(--snackbar-border-font-family);
  font-size: var(--snackbar-border-font-size);
`;

export const StyledBodyIcon = styled.span`
  flex-direction: row;
  display: flex;
  padding-top: 3px;
`;
