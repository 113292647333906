import { OrderDetails } from 'src/models/actions';
import { getFormElementType, getOptions } from 'src/mappers/action-arguments/action-entity';
import { ViewElement } from 'src/models/api/viewElement';
import { GlideOrderDetails, WorkflowHistoryElement } from 'src/models/order/glideOrderDisplayView.api.model';
import SearchService from 'src/services/search.service';
import {
  getFieldsFromView,
  getFormFieldValue,
  isFieldDisabled,
  isFieldReadonly,
  parseWorkflowTransitions,
} from '../common-mapper-functions';

const generateColumnsFromTableView = (tableView: any, hiddenFields: string[] = []) =>
  Object.entries(tableView)
    .sort((a: any[], b: any[]) => a[1].ordering - b[1].ordering)
    .map(([_key, value]: any) =>
      Object.entries(value.field).map(([fieldKey, fieldValue]: any) => {
        const style: APIFormFieldStyle = value.style
          ? (Object.values(value.style)[0] as APIFormFieldStyle)
          : { is_editable: true, is_hidden: false };
        const displayName = fieldValue.display_name;
        const dataType = fieldValue.data_type.replace('lookups/', '');
        const formElementType = getFormElementType({
          dataType,
          fieldName: displayName,
          hasLookUps: fieldValue.hasOwnProperty('lookups'),
        });
        return {
          name: fieldKey.replace('fields/', ''),
          displayName: fieldValue.display_name,
          field: {
            formElementType,
            name: fieldKey.replace('fields/', ''),
            displayName,
            dataType,
            // Virtus requirement
            readonly: !style.is_editable,
            required: false,
            disabled: true,
            hidden: style.is_hidden || hiddenFields.includes(displayName),
            defaultValue: '',
            disableErrorWarning: false,
          },
          footer: '',
        };
      }),
    )
    .map(e => e[0]);

const generateField = (field: ViewElement) => {
  const firstField = Object.values(field.field)[0];
  const dataType = firstField.data_type.replace('lookups/', '');
  const formElementType = getFormElementType({
    dataType,
    fieldName: firstField.display_name,
    hasLookUps: firstField.hasOwnProperty('lookups'),
  });
  const name = firstField.display_name;

  const disabled = isFieldDisabled(field);
  const readonly = isFieldReadonly(field);

  const required = Boolean(field.required_field) && !readonly;

  if (formElementType === 'tableModal') {
    const columns = generateColumnsFromTableView(field.view);
    const data = generateDataFromTableValues(field.values);
    return {
      [field.display_name]: {
        name,
        displayName: name,
        dataType,
        columns,
        data,
        formElementType,
      },
    };
  }

  const searchType = firstField.object_type ? firstField.object_type.replace('object_types/', '') : '';
  const defaultValue = field.value; // ⚠️ Don't know yet if value will come as part of field or firstField

  return {
    [field.display_name]: {
      name,
      displayName: name,
      dataType,
      defaultValue: getFormFieldValue({ dataType, defaultValue }),
      formElementType,
      required,
      readonly,
      disabled,
      searchType,
      searchService: SearchService().search,
      options: getOptions(firstField.lookups),
    },
  };
};

const parseWorkflowHistory = (workflowHistory: WorkflowHistoryElement[]) => {
  const result: any = {};
  if (workflowHistory) {
    const lastStatusIndex = workflowHistory.length - 1;
    workflowHistory.forEach((workflowStatus: WorkflowHistoryElement, index: number) => {
      result[workflowStatus.value] = {
        completed: true,
        active: index === lastStatusIndex,
        person: workflowStatus.user,
        date: workflowStatus.effective_date,
      };
    });
  }
  return result;
};

type APIFormFieldStyle = { is_editable: boolean; is_hidden: boolean };

const generateDataFromTableValues = (tableValues: any) =>
  Object.entries(tableValues).map(([uri, value]: any) => ({
    [uri]: {
      ...value.data,
    },
  }));

export const parseAssociatedInfo = (executionInfo: any) =>
  executionInfo.reduce(
    (acc: any, item: any) => ({
      ...acc,
      ...generateField(item),
    }),
    {},
  );

export const mapOrderDetailsToFormElements = (rowDetails: GlideOrderDetails) => {
  const workflow = parseWorkflowHistory(rowDetails.data.workflow_status_history);
  const workflowActions = parseWorkflowTransitions(rowDetails.data.workflow_transitions);

  const { workflow_status_history, workflow_transitions, ...orderDisplayGroups } = rowDetails.data;
  //Already mappedFieldRules in API response transform function.
  const mappedFieldRules = rowDetails.fieldRules;

  const formGroups = Object.entries(orderDisplayGroups).reduce(
    (acc, [groupName, values]) => ({ ...acc, [groupName]: getFieldsFromView(values, mappedFieldRules) }),
    {},
  );

  const mappedOrderDetails: OrderDetails = {
    workflow,
    workflowActions,
    formGroups,
    displayView: rowDetails.displayView,
    uri: rowDetails.uri,
  };

  return mappedOrderDetails;
};
