import { mutationApiCall } from 'src/api/mutations/mutationfunctions';

export const updateObject = (prevData: any, updatedData: any) => {
  const newData = prevData.data.map((item: any) => {
    const newItem = updatedData.find((updatedItem: any) => updatedItem['Trade Field'] === item['Trade Field']);
    return (newItem ?? newItem) || item;
  });
  return { ...prevData, data: newData };
};

export const post = (glideTimeSeriesData: { [key: string]: any }, updatedData: any[]) => {
  return mutationApiCall({
    data: { ...glideTimeSeriesData },
    queryKey: 'updateGlideTimeSeries',
    optimisticUpdateData: {
      optimisticUpdateCallBack: (prevData: any) => updateObject(prevData, updatedData),
      optimisticUpdateKey: 'glide_time_series',
    },
    rollBack: { rollbackKey: 'glide_time_series' },
    objectUpdating: 'Capital Structure',
  });
};
