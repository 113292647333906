import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DropdownMenu from '@virtus/components/DropdownMenu';
import DateRangeBox from 'devextreme-react/date-range-box';
import { locale } from 'devextreme/localization';
import * as S from './date-filter-selection.style';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'src/reducers';
import { selectCVC } from 'src/reducers/tabs';
import { DateFilterPayload, dateFilterSelectionAction } from 'src/sagas/glide-view.saga';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';

const msInDay = 1000 * 60 * 60 * 24;

const convertRangeToDays = ([startDate, endDate]: [Date, Date]) => {
  const diffInDay = Math.floor(Math.abs((endDate.valueOf() - startDate.valueOf()) / msInDay));
  return diffInDay;
};

interface DateFilterSelectionProps {
  type: string;
  start_date: number;
  end_date: number;
}

interface DateFilterSelectionReduxDispatch {
  applyDateFilter: ({ type, start_date, end_date }: DateFilterPayload) => void;
}

interface DateFilterSelectionReduxProps {
  clientViewConfiguration: ClientViewConfigurationData;
}

export const DateFilterSelection = ({
  type,
  start_date,
  end_date,
  clientViewConfiguration,
  applyDateFilter,
}: DateFilterSelectionProps & DateFilterSelectionReduxDispatch & DateFilterSelectionReduxProps) => {
  const [value, setValue] = useState(type);
  const [anchorEl, setAnchorEl] = useState<Element | ((el: Element) => Element) | null>(null);
  const [startDate, setStartDate] = useState(start_date);
  const [endDate, setEndDate] = useState(end_date);
  const [selectedDays, setSelectedDays] = useState(convertRangeToDays([new Date(startDate), new Date(endDate)]));

  useEffect(() => {
    locale(navigator.language);
  }, []);

  useEffect(() => {
    setValue(value);
    setStartDate(start_date);
    setEndDate(end_date);
    setSelectedDays(convertRangeToDays([new Date(start_date), new Date(end_date)]));
  }, [clientViewConfiguration.uri]);

  const handleDatePickerSelection = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSelectionMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const radioControl = (
    <Radio
      size="small"
      sx={{
        '&.Mui-checked': {
          color: 'var(--background-snackbar-header-success)',
        },
      }}
    />
  );

  const dateRadioGroup = (
    <S.DateRadioGroupWrapper>
      <FormControl>
        <RadioGroup
          aria-labelledby="date-filter-radio-group"
          name="date-filter-radio-group"
          value={value}
          onChange={handleDateSelectionMode}
          row
        >
          <FormControlLabel value="single" control={radioControl} label="Single" />
          <FormControlLabel value="range" control={radioControl} label="Range" />
        </RadioGroup>
      </FormControl>
    </S.DateRadioGroupWrapper>
  );

  const handleClose = () => setAnchorEl(null);

  const onCurrentValueChange = ({ value: [startDate, endDate] }: any) => {
    let daysCount = 0;
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      daysCount = convertRangeToDays([new Date(startDate), new Date(endDate)]);
      setSelectedDays(daysCount);
    }
  };

  const handleRangeDateSelection = () => {
    setAnchorEl(null);
    applyDateFilter({ type, start_date: startDate, end_date: endDate });
  };

  const displayRangeValues = value === 'range' && startDate && endDate && (
    <>
      <FormLabel>Start date: {new Date(startDate).toLocaleDateString(navigator.language)}</FormLabel>
      <FormLabel>End date: {new Date(endDate).toLocaleDateString(navigator.language)}</FormLabel>
      <FormLabel>Days difference: {selectedDays}</FormLabel>
    </>
  );

  const rangePicker = (
    <S.DateRangeWrapper>
      <DateRangeBox
        multiView={false}
        showClearButton
        defaultValue={[startDate, endDate]}
        useMaskBehavior
        onValueChanged={onCurrentValueChange}
      />
      <S.LoadDataButton
        data-testid="load-range-data"
        onClick={handleRangeDateSelection}
        disabled={!startDate || !endDate}
      >
        Load Data
      </S.LoadDataButton>
    </S.DateRangeWrapper>
  );

  return (
    <>
      <S.DateFilterSection>
        {displayRangeValues}
        <FormLabel>Select Dates</FormLabel>
        <S.DateFilterIcon
          className="dx-icon dx-icon-event"
          onClick={handleDatePickerSelection}
          title="Select Dates"
        ></S.DateFilterIcon>
      </S.DateFilterSection>
      <DropdownMenu
        hideButton
        onClose={handleClose}
        open={!!anchorEl}
        customAnchorEl={anchorEl}
        keepMenuOpen
        id="date-range-filter-dropdown"
      >
        {dateRadioGroup}
        {value === 'range' && rangePicker}
      </DropdownMenu>
    </>
  );
};

const mapStateToProps = (state: RootState): DateFilterSelectionReduxProps => ({
  clientViewConfiguration: selectCVC(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DateFilterSelectionReduxDispatch => ({
  applyDateFilter: ({ type, start_date: startDate, end_date: endDate }) =>
    dispatch(dateFilterSelectionAction({ type, start_date: startDate, end_date: endDate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilterSelection);
