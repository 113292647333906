import styled from 'styled-components';

export const ComplianceCellStyle = styled.span<{ color?: string }>`
  color: ${props => props.color};
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: center;
  align-self: center;
`;

export const ComplianceTestResult = styled.span<{ color?: string }>`
  width: 100%;
  text-align: right;
  float: right;
`;
