import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const SaveIcon: React.SFC<SvgIconProps> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.383" height="18.239" viewBox="0 0 17.383 18.239" {...props}>
    <g id="Group_700" data-name="Group 700" transform="translate(-139.35 -439.04)">
      <path
        id="Path_447"
        data-name="Path 447"
        d="M1.325,18.239A1.353,1.353,0,0,1,0,16.858V1.364A1.346,1.346,0,0,1,1.306,0H13.519l3.863,4.069v12.8a1.347,1.347,0,0,1-1.308,1.371Zm0-16.989a.1.1,0,0,0-.075.1V16.875a.1.1,0,0,0,.094.114H16.056a.093.093,0,0,0,.076-.1V4.567L12.982,1.25Z"
        transform="translate(139.35 439.04)"
        fill="#285BC9"
      />
      <path
        id="Path_448"
        data-name="Path 448"
        d="M14.576,18.238H2.8l0-6.434a1.464,1.464,0,0,1,1.461-1.463h8.88a1.431,1.431,0,0,1,1.431,1.436ZM4.052,16.988h9.274V11.775a.184.184,0,0,0-.052-.131.181.181,0,0,0-.129-.053H4.267a.213.213,0,0,0-.213.212Z"
        transform="translate(139.35 439.04)"
        fill="#285BC9"
      />
      <path
        id="Path_449"
        data-name="Path 449"
        d="M9.641,6.42h-4A1.436,1.436,0,0,1,4.207,4.985V0h6.861l0,4.986A1.435,1.435,0,0,1,9.641,6.42M5.457,1.25V4.986a.184.184,0,0,0,.184.184h4a.182.182,0,0,0,.182-.183l0-3.737Z"
        transform="translate(139.35 439.04)"
        fill="#285BC9"
      />
    </g>
  </svg>
);

export default SaveIcon;
