// Required to map to correct category
export enum ANALYST_COMMENT_CATEGORY_MAP {
  Blog = 'Blog',
  KPIs = 'KPI',
  KPI = 'KPI',
  Covenant = 'Covenant',
  Covenants = 'Covenant',
}

// Required to map to correct type to store data in state as fieldName is KPIs in creditDisplayView
export enum ANALYST_COMMENT_TYPE_MAP {
  Blog = 'Blog',
  KPIs = 'KPIs',
  KPI = 'KPIs',
  Covenants = 'Covenants',
  Covenant = 'Covenants',
}

// Required to update collection via glide/object/update
export enum ANALYST_COMMENT_TYPE_REQUEST_MAP {
  Blog = 'analyst_comments',
  KPIs = 'kpis',
  Covenants = 'covenants',
}
