import { isEmptyString } from 'src/utils/isEmptyString';

export default function percentFormat(value: any, precision: number = 3) {
  // control the null, undefined, objects, dates, arrays and no numeric strings
  if (value == null || typeof value === 'object' || isNaN(value)) return '';
  // control the empty strings
  if (typeof (value as any) === 'string' && isEmptyString(value)) return '';

  return Number(value).toFixed(precision);
}
