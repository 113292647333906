import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Close } from '@mui/icons-material';
import Refresh from '@mui/icons-material/Refresh';
import React, { ReactNode } from 'react';
import ExpandIcon from 'src/icons/ExpandIcon';
import HelpIcon from 'src/icons/HelpIcon';
import styled from 'styled-components';
export interface InspectorHeaderProps {
  onClose: () => any;
  onHelp: () => any;
  onExpand?: () => void;
  onRefresh?: () => void;
  refresh?: boolean;
  children?: ReactNode;
  title: string;
  classes?: any;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bgInspectorFullscreenContent);
  padding: 16px 20px;
  height: 48px;
  z-index: 1;
`;

const Title = styled(Typography)`
  color: var(--text);
  text-transform: capitalize;
  font-size: var(--titleFontSize);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconButtonStyled = styled(IconButton)`
  color: var(--bgLight);
  padding: 5px;
  width: 36px;
  height: 34px;
`;

const InspectorHeader: React.FunctionComponent<InspectorHeaderProps> = ({
  title,
  onClose,
  onHelp,
  onExpand,
  children,
  onRefresh,
  refresh,
}) => {
  return (
    <Header data-testid="inspector-header">
      <Tooltip title={title} placement="top" style={{ flex: 1 }}>
        <Title color="inherit">{title || ''}</Title>
      </Tooltip>
      <Row>
        {children && <div>{children}</div>}
        <IconButtonStyled onClick={onHelp} color="inherit">
          <HelpIcon />
        </IconButtonStyled>
        {onExpand && (
          <IconButtonStyled onClick={onExpand} color="inherit">
            <ExpandIcon />
          </IconButtonStyled>
        )}
        <IconButtonStyled onClick={onClose} color="inherit">
          <Close />
        </IconButtonStyled>
        {refresh && (
          <IconButtonStyled onClick={onRefresh} color="inherit">
            <Refresh />
          </IconButtonStyled>
        )}
      </Row>
    </Header>
  );
};

export default InspectorHeader;
