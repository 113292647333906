import React, { SyntheticEvent, useRef } from 'react';
import { EndMonth, NumberOfYears, StartMonth } from './constants';

// calculating number of past and future year to display in year select dropdown
const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - NumberOfYears, StartMonth);
const toMonth = new Date(currentYear + NumberOfYears, EndMonth);

type DateFilterProps = {
  date: Date | undefined;
  localeUtils: any;
  onChange: (e: SyntheticEvent, year: number, month: number) => void;
};

const YearMonthForm: React.FunctionComponent<DateFilterProps> = ({ date, onChange }) => {
  const monthRef = useRef<HTMLSelectElement>(null);
  const yearRef = useRef<HTMLSelectElement>(null);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const years = [];
  date = date ? date : new Date();

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e: SyntheticEvent) => {
    e.stopPropagation();
    onChange(e, +yearRef.current!.value, +monthRef.current!.value);
  };
  return (
    <div className="DayPicker-Caption">
      <div className="DatePicker-select-wraper">
        <select
          name="month"
          className="DatePicker-select"
          onChange={handleChange}
          value={date.getMonth()}
          ref={monthRef}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          className="DatePicker-select"
          onChange={handleChange}
          value={date.getFullYear()}
          ref={yearRef}
        >
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default YearMonthForm;
