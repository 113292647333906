import { ArrowBack, Close } from '@mui/icons-material';
import React from 'react';
import ExpandIcon from '@virtus/components/icons/ExpandIcon';
import * as S from './style';

interface HeaderProps {
  onBack: () => void;
  title: string;
  displayType?: string;
  handleExpansion?: () => void;
}

export const Header = React.memo(({ onBack, title, displayType = 'preview', handleExpansion }: HeaderProps) => {
  const HeaderIcon = (Icon: React.ComponentType<any>) => {
    return (
      <S.IconButton data-testid="back-btn" onClick={onBack}>
        <Icon style={{ fontSize: 'var(--inspector-icon-font-size)' }} />
      </S.IconButton>
    );
  };

  return (
    <S.Header>
      <S.TitleWrapper>
        {displayType === 'fullscreen' && HeaderIcon(ArrowBack)}
        <S.Title>{title?.replace(/_/g, ' ')}</S.Title>
      </S.TitleWrapper>
      {displayType === 'preview' && (
        <S.CloseWrapper>
          <S.IconButton onClick={handleExpansion}>
            <ExpandIcon fillColor="var(--foreground-form-header)" />
          </S.IconButton>
          {HeaderIcon(Close)}
        </S.CloseWrapper>
      )}
    </S.Header>
  );
});
