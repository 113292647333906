import { putResolve, takeLatest } from 'redux-saga/effects';
import { Glide, DisplayViewApi } from 'src/api/queries';
import { requestAsync } from 'redux-query';
import { DISPLAY_VIEW } from 'src/utils/constants';

export enum CreditDetailsSagaAction {
  FETCH_CREDIT_URI = 'FETCH_CREDIT_URI',
  FETCH_CREDIT_URI_FOR_COMMENTARY = 'FETCH_CREDIT_URI_FOR_COMMENTARY',
}

export function* fetchCreditDetailsNames(action: any): any {
  const body = yield putResolve(requestAsync(Glide.get({ uri: action.payload.uri, field: 'credit_detail' })));
  const creditDetailsUri = body && body.entities.field_uris['credit_detail'];
  yield putResolve(
    requestAsync(
      DisplayViewApi.getDisplayViewGroup({
        viewName: DISPLAY_VIEW.CREDIT,
        uri: creditDetailsUri,
      }),
    ),
  );
}

export function* fetchCreditDetailsUriForCommentary(action: any) {
  yield putResolve(requestAsync(Glide.get({ uri: action.payload.uri, field: 'credit_detail' })));
}

export function* watchFetchCreditDetails() {
  yield takeLatest(CreditDetailsSagaAction.FETCH_CREDIT_URI, fetchCreditDetailsNames);
}

export function* watchFetchCreditDetailsUri() {
  yield takeLatest(CreditDetailsSagaAction.FETCH_CREDIT_URI_FOR_COMMENTARY, fetchCreditDetailsUriForCommentary);
}

export default [watchFetchCreditDetails, watchFetchCreditDetailsUri];
