import { RootState } from 'src/reducers/rootReducer';
import { getDisplayViewGroup, displayViewGroupSelector, isPending, GetDisplayViewType } from '../display-view';

export type getInstrumentDisplayViewAPIBodyType = {
  display_name: string;
  data: string;
  uri: string;
  display_view: any;
};

export const transformInstrumentDisplayView = (body: getInstrumentDisplayViewAPIBodyType) => ({
  data: JSON.parse(body.data),
  displayName: body.display_name,
  uri: body.uri,
  displayView: JSON.parse(body.display_view),
});

export const get = ({ uri, fetchOptions, activeView }: GetDisplayViewType) =>
  getDisplayViewGroup({
    viewName: activeView,
    uri,
    fetch_options: fetchOptions,
    transformFn: transformInstrumentDisplayView,
  });

export const selector = (state: RootState, activeView: string) => displayViewGroupSelector(state, activeView);

export const pending = (state: RootState, activeView: string) => isPending(state, activeView);
