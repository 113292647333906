import React from 'react';
import * as S from './proposed-orders.style';
import { ProposedOrder } from '@virtus/components/icons';

export interface GetProposedOrderBody {
  selected_funds: string[];
  search_limit?: number;
}

const ProposedOrders = () => {
  return (
    <S.IconButton>
      <ProposedOrder />
    </S.IconButton>
  );
};

export default ProposedOrders;
