import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
export const ModellingIcon: React.SFC<SvgIconProps> = ({ ...props }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 19 19"
    {...props}
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <rect fill="#fff" opacity={0} strokeWidth={0} width="19" height="19" />
      <path
        strokeWidth={0}
        fill="#285bc5"
        d="M12.12,9.66v-.69l.11-.1.77-.59-.7-1.23-1.05.44-.13-.11s-.09-.07-.13-.1c-.05-.03-.1-.06-.15-.09h0s-.1-.06-.15-.08c-.06-.03-.11-.05-.16-.07h-.02l-.15-.07-.04-.17-.12-.95h-1.4l-.14,1.1-.15.1c-.06.02-.11.05-.17.07-.05.02-.1.05-.15.08h0c-.05.03-.1.06-.15.1s-.1.07-.15.11h0s-.15.1-.15.1l-1.06-.44-.7,1.23.87.71v.87l.04.16-.91.69.7,1.23,1.04-.44.13.11s.08.07.13.1c.05.03.1.06.15.09h0s.1.06.15.08c.05.03.11.05.17.07h.02l.16.07.04.18.11.94h1.43l.14-1.15.15-.05c.06-.02.12-.05.18-.07.05-.03.11-.06.16-.09h0c.05-.02.1-.05.15-.09.05-.04.1-.07.14-.11l.13-.08,1.03.44.72-1.23-.88-.71v-.34ZM10.7,10.7c-.33.33-.73.49-1.2.49s-.87-.16-1.2-.49-.49-.73-.49-1.2.16-.87.49-1.2.73-.49,1.2-.49.87.16,1.2.49c.33.33.49.73.49,1.2s-.16.87-.49,1.2Z"
      />
      <g>
        <path
          strokeWidth={0}
          fill="#285bc5"
          d="M8.08,17.02l-.03-.02c.25.08.51.14.78.17.27.04.54.06.81.06.61,0,1.19-.09,1.74-.28.56-.19,1.07-.45,1.52-.79.45-.34.84-.75,1.18-1.23.33-.47.59-.99.76-1.56l1.13.31v.05c-.21.67-.52,1.3-.93,1.87-.4.57-.87,1.07-1.42,1.48-.55.41-1.16.74-1.84.97-.67.23-1.38.35-2.12.35h-.03c-.34,0-.67-.02-.99-.07-.31-.04-.61-.11-.89-.2l-.05-.02c-.49-.14-.94-.34-1.37-.6-.44-.25-.84-.55-1.21-.89-.36-.34-.68-.73-.96-1.16-.29-.42-.52-.87-.71-1.36l-.81,1.72-1.08-.51,1.76-3.66,3.68,1.74-.51,1.06-1.99-.94.02.03c.28.83.73,1.55,1.36,2.16.63.61,1.37,1.05,2.22,1.3Z"
        />
        <path
          strokeWidth={0}
          fill="#285bc5"
          d="M6.8,2.41l-.03.02c-.61.34-1.13.79-1.58,1.33s-.78,1.17-.99,1.88l-1.14-.33.02-.05c.21-.67.51-1.3.91-1.87.41-.57.89-1.07,1.44-1.48.55-.41,1.16-.74,1.84-.97.67-.23,1.38-.35,2.12-.35s1.38.11,2.02.31c.64.21,1.23.5,1.76.88.54.38,1.01.83,1.42,1.36.41.52.73,1.09.98,1.72l.81-1.72,1.08.51-1.76,3.66-3.68-1.74.51-1.06,1.99.94-.02-.03c-.18-.54-.43-1.03-.76-1.47-.33-.45-.72-.84-1.17-1.17-.45-.33-.94-.58-1.48-.77-.54-.18-1.11-.26-1.71-.26-.47,0-.93.06-1.37.17-.44.12-.84.28-1.22.49Z"
        />
      </g>
    </g>
  </svg>
);
