import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ArrowDownIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 19 19" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <rect fill="#fff" style={{ opacity: 0, strokeWidth: '0px' }} width="19" height="19" />
      <path
        fill="#012834"
        style={{ strokeWidth: '0px' }}
        d="M4.4,7.48l1.07-1.05,4.03,4.04,4.03-4.04,1.07,1.05-5.1,5.1-5.1-5.1Z"
      />
    </g>
  </SvgIcon>
);

export default ArrowDownIcon;
