import styled from 'styled-components';
import { Dialog as MUIDialog } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Button } from '@mui/material';

export const Dialog = styled(MUIDialog)<{ display_type?: string; groupslength: number }>`
  height: calc(${props => (props.groupslength > 1 ? 90 : 85)}vh - 40px);
  margin: 40px auto;
  width: ${props => (props.groupslength > 1 ? 100 : 90)}vw;
  & > div {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    & > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;
export const AuditIconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: var(--foreground-tab-close-icon);
`;

export const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--text);
`;
export const Title = styled.h4`
  color: var(--text);
  font-size: var(--titleFontSize);
  white-space: nowrap;
  font-weight: bold;
  text-transform: initial;
  padding-left: 10px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bgInspectorFullscreenContent);
  padding: 16px 20px;
  height: 48px;
  z-index: 1;
`;
