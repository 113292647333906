const getNumericValue = (value: number) => value && value.toLocaleString('en-US', { minimumFractionDigits: 2 });

export default {
  fund_allocations: [
    {
      column: 'Trade Value',
      summaryType: 'sum',
      customizeText: (data: any) => getNumericValue(Math.round(data.value * 100) / 100),
    },
    {
      column: 'Expected Notional',
      summaryType: 'sum',
      customizeText: (data: any) => getNumericValue(Math.round(data.value * 100) / 100),
    },
    {
      column: 'Target Notional',
      summaryType: 'sum',
      customizeText: (data: any) => getNumericValue(Math.round(data.value * 100) / 100),
    },
    {
      column: 'Allocation %',
      summaryType: 'sum',
      customizeText: (data: any) => `${getNumericValue(Math.round(data.value * 100) / 100)}%`,
    },
    {
      column: 'PositionAmount',
      summaryType: 'sum',
      customizeText: (data: any) => getNumericValue(Math.round(data.value * 100) / 100),
    },
    {
      column: undefined,
      showInColumn: 'Fund',
      displayFormat: 'Total',
    },
  ],
};
