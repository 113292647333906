import { Export, FieldChooser, FieldPanel, PivotGrid, StateStoring } from 'devextreme-react/pivot-grid';
import PivotGridDataSource, { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source';
import { PivotGridFieldChooser } from 'devextreme-react/pivot-grid-field-chooser';
import {
  StyledDxPivotGrid,
  StyledPivotGridFieldChooser,
  StyledPivotGridWrapper,
  StyledPivotViewWrapper,
  StyledSearchIcon,
  StyledSettingsItem,
  StyledSettingsItemWrapper,
  StyledTabWrapper,
  StyledToolBarWrapper,
  StyledWrapper,
} from './dx-pivot-grid.styles';
import './themes/dx-pivot-grid-theme.light.css';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getPivotFieldsFromSchema } from './utils/mapSchemaToFields';
import {
  defaultView,
  DxPivotGridDataType,
  PivotArea,
  PivotDataType,
  PivotGridField,
  PivotGridNewOrderFilter,
  PivotLayout,
} from './model/dx-pivot-grid.model';
import { PivotToolBar } from './toolbar/pivot-toolbar';
import TabPanel from 'devextreme-react/tab-panel';
import generateTreeViewData from './utils/getPivotDataTreeView';
import dxPivotGrid, { dxPivotGridPivotGridCell } from 'devextreme/ui/pivot_grid';
import { exportPivotGrid } from 'devextreme/excel_exporter';
// @ts-ignore //if removed, the file-saver gives error to install dev dependencies but after its installed there comes error for starting the application.
import { saveAs } from 'file-saver';
import { dxElement } from 'devextreme/core/element';
import GridTemplates from 'src/components/grids/grids.template';
import { Template } from 'devextreme-react/core/template';
import LayoutsTemplateRender from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts';
import { dispatchActions } from 'src/app/store';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { DataSource } from '@virtus/components/DxDataGrid/DxDataGrid';
import { debounce, groupBy } from 'lodash';
import { RootState } from 'src/reducers';
import { ComponentPivotGridLayout, selectViewComponent } from 'src/reducers/components';
import { connect } from 'react-redux';
import './themes/dx-pivot-grid-theme-blue-light-compact.css';
import './themes/dx-pivot-grid-theme-override.css';
import { Dispatch } from 'redux';
import TextBox from 'devextreme-react/text-box';
import { PivotGridActions } from './pivot-grid.saga';
import DropdownMenu, { DropdownMenuItem } from '@virtus/components/DropdownMenu';
import { Switch } from '@virtus/components/Switch';
import { createExportObject } from './utils/PivotExportObject';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

interface ReduxProps {
  gridLayout: ComponentPivotGridLayout;
}

export interface GridViewReduxDispatch {
  search: () => void;
  stateChange: (pivotGridState: PivotLayout) => void;
  unmountPivotGrid: () => void;
}

export interface DxPivotCell {
  component?: dxPivotGrid;
  element?: dxElement;
  model?: any;
  area?: string;
  cellElement?: dxElement;
  cell?: dxPivotGridPivotGridCell;
  rowIndex?: number;
  columnIndex?: number;
  cancel?: boolean;
}

export interface DxPivotGridProps {
  pivotGridData: DataSource;
  refreshHandler: () => void;
  children?: ReactNode;
  showFieldChooser?: boolean;
  showAllFieldsColumn?: boolean;
  storageKey?: string;
  enableStorage?: boolean;
  getCustomToolBarItems?: () => any;
  toolbarIconsOrder?: string[];
  toolbarItemsFromConfig?: any[];
  onCellPrepared?: (e: any) => void;
  onCellClick?: (e: any) => void;
  expandAllFields?: boolean;
  clientViewConfiguration: ClientViewConfigurationData;
  onSettingsClick?: (_pivotGridRef?: any) => void;
  showRowGrandTotals?: boolean;
  showColGrandTotals?: boolean;
}

const fieldsToIgnore = (): PivotGridField[] => {
  return [
    {
      dataField: '_uri',
      dataType: DxPivotGridDataType.string,
      visible: false,
    },
    {
      dataField: '_date',
      dataType: DxPivotGridDataType.string,
      visible: false,
    },
  ];
};

const FieldChooserDataSource = [
  {
    id: 0,
    key: 'list_view',
    display_name: 'List View',
  },
  {
    id: 1,
    key: 'tree_view',
    display_name: 'Tree View',
  },
];

const PIVOT_RESIZE_HANDLER = {
  DEFAULT_VALUE: 50,
  MIN_VALUE: 20,
  MAX_VALUE: 80,
};

export const DxPivotGrid = React.memo(
  React.forwardRef<PivotGrid, DxPivotGridProps & ReduxProps & GridViewReduxDispatch>(
    (
      {
        children,
        pivotGridData,
        showAllFieldsColumn,
        refreshHandler,
        getCustomToolBarItems,
        showFieldChooser = true,
        toolbarIconsOrder,
        expandAllFields,
        clientViewConfiguration,
        onSettingsClick,
        search,
        stateChange,
        unmountPivotGrid,
        gridLayout,
        showRowGrandTotals = true,
        showColGrandTotals = true,
      },
      ref,
    ) => {
      let pivotRef = useRef<PivotGrid | null>(null);
      pivotRef = ref ? (ref as any) : pivotRef;

      const dataSource = useMemo(() => {
        const fields =
          pivotGridData &&
          ([
            ...getPivotFieldsFromSchema({
              data: pivotGridData,
              defaultView: clientViewConfiguration?.pivot_default_view_definition
                ? JSON.parse(clientViewConfiguration?.pivot_default_view_definition)
                : defaultView,
            }),
            ...fieldsToIgnore(),
          ] as any);

        return new PivotGridDataSource({
          fields: fields,
          store: pivotGridData?.data,
          retrieveFields: false,
        });
      }, []);

      const getPivotGridExportFileName = () => {
        const filename = clientViewConfiguration.display_name;
        return `${filename}.xlsx`;
      };

      const [showALlFieldsPane, setShowAllFieldsPane] = useState(false);
      const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
      const { toolbarButtonsFromConfig, toolbarTemplates } = GridTemplates({
        clientViewConfiguration,
      });
      const [selectedRowPath, setSelectedRowPath] = useState<any>({});
      const [showRowTotals, setShowRowTotals] = useState(true);
      const [showColumnTotals, setShowColumnTotals] = useState<boolean>(true);
      const [anchorEl, setAnchorEl] = useState<Element | ((el: Element) => Element) | null>(null);
      const [sliderPosition, setSliderPosition] = useState(PIVOT_RESIZE_HANDLER.DEFAULT_VALUE);

      useEffect(() => {
        setShowRowTotals(clientViewConfiguration?.show_row_totals ?? true);
        setShowColumnTotals(clientViewConfiguration?.show_column_totals ?? true);
      }, [clientViewConfiguration?.show_column_totals, clientViewConfiguration?.show_column_totals]);

      const debounceRepaint = debounce(() => {
        pivotRef?.current?.instance?.repaint();
      }, 500);

      const handleSliderWidthChange = (e: any) => {
        setSliderPosition(e.target.value);
        (pivotRef.current?.instance.element().querySelectorAll('table.dx-word-wrap >tr >td') as NodeList).forEach(
          (item, index) => {
            if (index % 2 === 0) {
              (item as HTMLElement).style.width = `${100 - e.target.value}%`;
            } else {
              (item as HTMLElement).style.width = `${e.target.value}%`;
            }
          },
        );
        debounceRepaint();
      };

      const handleClose = () => setAnchorEl(null);

      const renderSliderComponent = () => (
        <>
          <Box sx={{ width: 100 }} title="Slide left or right to see more">
            <Slider
              aria-label="pivot-grid-width"
              value={sliderPosition}
              size="small"
              onChange={handleSliderWidthChange}
              min={PIVOT_RESIZE_HANDLER.MIN_VALUE}
              max={PIVOT_RESIZE_HANDLER.MAX_VALUE}
            />
          </Box>
        </>
      );

      const widthSliderTemplate = <Template name="pivotGridResizeSliderTemplate" render={renderSliderComponent} />;

      const allToolbarTemplates = useMemo(() => {
        const settingsHandler = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

        const handleRowTotal = (_checked: boolean) => {
          setShowRowTotals(showRowTotals => !showRowTotals);
        };

        const handleColumnTotal = (_checked: boolean) => {
          setShowColumnTotals(showColumnTotals => !showColumnTotals);
        };

        const layoutTemplate = (
          <Template
            name="layouts"
            render={() => (
              <LayoutsTemplateRender
                onSettingsClick={onSettingsClick}
                clientViewUri={clientViewConfiguration.uri}
                layoutStyle={{ height: '23px', border: 'var(--foreground-dx-grid-action-drop-down-border)' }}
              />
            )}
          />
        );
        const searchTemplate = (
          <Template
            name="pivotGridSearchPanel"
            render={() => (
              <>
                <StyledSearchIcon />
                <TextBox
                  showClearButton={true}
                  placeholder="Search..."
                  valueChangeEvent="keyup"
                  onValueChanged={onValueChangedHandler}
                  className="pivot-grid-search"
                />
              </>
            )}
          />
        );

        const settingsTemplate = (
          <Template
            name="settingsTemplate"
            render={() =>
              !clientViewConfiguration.is_pivot_total_button_disabled && (
                <>
                  <i
                    className="dx-icon dx-icon-preferences"
                    onClick={settingsHandler}
                    style={{ cursor: 'pointer' }}
                  ></i>
                  <DropdownMenu
                    hideButton
                    onClose={handleClose}
                    open={!!anchorEl}
                    customAnchorEl={anchorEl}
                    keepMenuOpen
                  >
                    <DropdownMenuItem>
                      <StyledSettingsItemWrapper className="settings-item-menu">
                        <StyledSettingsItem>
                          <label> Hide Row totals</label>
                          <Switch checked={!showRowTotals} onChange={handleRowTotal} />
                        </StyledSettingsItem>
                      </StyledSettingsItemWrapper>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <StyledSettingsItemWrapper className="settings-item-menu">
                        <StyledSettingsItem>
                          <label> Hide Column totals</label>
                          <Switch checked={!showColumnTotals} onChange={handleColumnTotal} />
                        </StyledSettingsItem>
                      </StyledSettingsItemWrapper>
                    </DropdownMenuItem>
                  </DropdownMenu>
                </>
              )
            }
          />
        );

        toolbarTemplates.push(layoutTemplate, searchTemplate, settingsTemplate, widthSliderTemplate);
        return toolbarTemplates;
      }, [dataSource, toolbarTemplates]);

      const getToolBarItems = useMemo(() => {
        const LayoutToolbarItem = [
          {
            name: 'layout',
            location: 'after',
            template: 'layouts',
          },
        ];
        if (getCustomToolBarItems) {
          return [...LayoutToolbarItem, ...getCustomToolBarItems()];
        }
        return LayoutToolbarItem;
      }, []);

      useEffect(() => {
        // @ts-ignore
        window['gridInstances'][clientViewConfiguration.uri] = pivotRef;
        return () => {
          unmountPivotGrid();
        };
      }, []);

      const onCellClickCallback = (gridRowData: any) => {
        const groupedGridData = groupBy(gridRowData, PivotGridNewOrderFilter);
        if (gridRowData?.length && Object.keys(groupedGridData).length === 1) {
          dispatchActions.components.update('global', { orders: { disabled: false, rowData: gridRowData[0] } });
        } else {
          dispatchActions.components.update('global', { orders: { disabled: true, rowData: {} } });
        }
      };
      const onCellClick = (e: any) => {
        if (e?.cell?.rowType === PivotDataType.T) return;
        const pivotGridDataSource = e.component.getDataSource();
        const cellElement = {
          ...e.cell,
          rowPath: e?.cell?.rowPath ?? e?.cell?.path,
        };
        const drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(cellElement);
        drillDownDataSource.load();
        const items = drillDownDataSource.items();
        const path = (e.cell.rowPath || e.cell.path || []).join('/');
        if (Object.keys(selectedRowPath).includes(path)) setSelectedRowPath({ [path]: true });
        else setSelectedRowPath({ [path]: !selectedRowPath[path] });
        (pivotRef as any)?.current?.instance?.repaint();
        onCellClickCallback && onCellClickCallback(items);
      };
      const isRowSelected = (rowPath: any) => {
        const path: string[] = [];
        let selected = false;
        rowPath.some((value: any) => {
          path.push(value);
          const pathValue = path.join('/');
          selected = selectedRowPath[pathValue];
          return selected;
        });
        return selected;
      };
      const highlightDataRow = (e: DxPivotCell) => {
        if (e?.cell?.rowType === PivotDataType.T || e?.cell?.type === PivotDataType.T || e.area === PivotArea.R) return;

        if (isRowSelected(e?.cell?.rowPath || e?.cell?.path || []) && e.cellElement) {
          (e.cellElement as any).style.cssText = `
              background-color: var(--background-dx-grid-select-row);
              color: var(--foreground-form);
            `;
        }
        //this is hide #multipleValues display from the pivot grid.
        if (e.cell!.value === '#mutlipleValues') {
          (e.cellElement as any).childNodes.forEach(
            (node: HTMLElement) =>
              (node.style.cssText = `
          display: none;
          `),
          );
        }
      };
      const highlightTotalRow = (e: DxPivotCell) => {
        if (
          e.area == PivotArea.D &&
          (e.cell?.rowType == PivotDataType.GT || e.cell?.rowType == PivotDataType.T) &&
          (e.cell?.columnType == PivotDataType.GT || e.cell?.columnType == PivotDataType.T) &&
          e.cell?.rowPath?.length
        ) {
          (e.cellElement as any).style.cssText = `
          border-top:var(--foreground-dx-grid-total-cell-border);
            background: var(--background-dx-grid-total-cell);
            font-weight: 700;
            letter-spacing: 0px;
            opacity: 1;
          `;
        }
        //this is hide #multipleValues display from the pivot grid.
        if (e.cell!.value === '#mutlipleValues') {
          (e.cellElement as any).childNodes.forEach(
            (node: HTMLElement) =>
              (node.style.cssText = `
          display: none;
          `),
          );
        }
        if (isRowSelected(e?.cell?.rowPath || e?.cell?.path || []) && e.cellElement) {
          if (e.cellElement && e.cellElement.classList && e.cellElement.classList.contains('dx-grandtotal')) {
            (e.cellElement as any).style.cssText = `
            background-color: var(--background-dx-grid-select-row-total);
            color: var(--foreground-form);
          `;
          }
        }
      };
      const onCellPrepared = (e: DxPivotCell) => {
        highlightTotalRow(e);
        highlightDataRow(e);
      };

      const PivotFieldChooser = (
        <PivotGridFieldChooser
          height="100%"
          className="side-field-chooser"
          dataSource={dataSource}
          layout={1}
          applyChangesMode="instantly"
          allowSearch
        />
      );

      const fieldChooserRender = (e: any) => {
        const _isTreeView = e.key === 'tree_view';
        if (selectedTabIndex === e.id) {
          if (_isTreeView) {
            generateTreeViewData({ data: pivotGridData, dataSource });
          } else {
            const fields = dataSource
              .fields()
              .map((item: PivotGridDataSourceField) => ({ ...item, displayFolder: undefined }));
            dataSource.fields(fields);
          }
        }

        return PivotFieldChooser;
      };

      const expandAllPivotRows = (pivotRef: any) => {
        if (!pivotRef?.current) return;
        // beginUpdate() prevents the pivot grid from refreshing until the endUpdate() method is called.
        (pivotRef?.current as any).instance.beginUpdate();
        const _dataSource = (pivotRef?.current as any).instance.getDataSource();
        const fields = _dataSource.fields();
        fields.forEach((f: any) => {
          if (f.area == 'row') {
            _dataSource.expandAll(f.index);
          }
        });
        // Refreshes the pivot grid after a call of the beginUpdate() method.
        (pivotRef?.current as any).instance.endUpdate();
      };

      useEffect(() => {
        window.requestAnimationFrame(() => {
          if (expandAllFields) {
            expandAllPivotRows(pivotRef);
          }
        });
      }, [expandAllFields]);

      const toggleAllFieldsPane = useCallback(() => setShowAllFieldsPane(prev => !prev), []);

      const toggleFieldChooser = useCallback(() => {
        if (pivotRef?.current) {
          (pivotRef?.current as any).instance.getFieldChooserPopup().show();
        }
      }, []);

      const fieldChooserTitleRender = (data: any) => data?.display_name || 'None';

      const onSelectionChanged = (e: any) => {
        if (e.name === 'selectedIndex' && e.previousValue === selectedTabIndex) {
          setSelectedTabIndex(e.value);
        }
      };
      const fieldChooserTabPanel = (
        <StyledTabWrapper>
          <TabPanel
            height="100%"
            selectedIndex={selectedTabIndex}
            dataSource={FieldChooserDataSource}
            onOptionChanged={onSelectionChanged}
            itemTitleRender={fieldChooserTitleRender}
            itemRender={fieldChooserRender}
            animationEnabled
          />
        </StyledTabWrapper>
      );

      const setSearchValue = useRef(debounce(search, 500));

      const onValueChangedHandler = (data: any) => {
        dispatchActions.components.updateView('gridLayout', clientViewConfiguration.uri, {
          searchText: data.value,
        });
        setSearchValue.current();
      };

      const saveState = useCallback((state: PivotLayout) => {
        stateChange(state);
      }, []);

      const loadState = useCallback(() => {
        const gridState =
          gridLayout?.selectedLayout?.data?.json_layout || pivotRef.current?.instance.getDataSource().state();
        return Promise.resolve(gridState);
      }, []);

      const handleExportPivotData = useCallback(
        async (e: DxPivotCell) => {
          const excelModule = await import('exceljs');
          const pivotGrid = pivotRef?.current;
          const workbook = new excelModule.Workbook();
          const worksheet = workbook.addWorksheet('PivotGrid');
          exportPivotGrid(createExportObject(pivotGrid as PivotGrid, worksheet))
            .then(() => {
              return workbook.xlsx.writeBuffer();
            })
            .then((buffer: any) => {
              const fileName = getPivotGridExportFileName();
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
            });
          e.cancel = true;
        },
        [dataSource],
      );

      return (
        <StyledWrapper>
          <StyledToolBarWrapper>
            <PivotToolBar
              getGridTemplates={allToolbarTemplates}
              getCustomToolBarItems={getToolBarItems}
              refreshHandler={refreshHandler}
              toggleAllFieldsPane={toggleAllFieldsPane}
              toggleFieldChooser={toggleFieldChooser}
              toolbarIconsOrder={toolbarIconsOrder}
              toolbarItemsFromConfig={toolbarButtonsFromConfig}
              useAllFieldsPane={false}
              exportPivotData={handleExportPivotData}
            />
          </StyledToolBarWrapper>
          <StyledPivotViewWrapper>
            {showAllFieldsColumn && showALlFieldsPane ? (
              <StyledPivotGridFieldChooser>{fieldChooserTabPanel}</StyledPivotGridFieldChooser>
            ) : null}
            <StyledPivotGridWrapper data-testid="virtus-pivot-grid" className="dx-swatch-custom-pivot-grid">
              <StyledDxPivotGrid
                ref={pivotRef}
                dataSource={dataSource}
                allowSortingBySummary
                allowSorting
                allowFiltering
                allowExpandAll={false}
                onCellPrepared={onCellPrepared}
                onCellClick={onCellClick}
                showColumnTotals={showColumnTotals}
                showRowTotals={showRowTotals}
                showRowGrandTotals={showRowGrandTotals}
                showColumnGrandTotals={showColGrandTotals}
              >
                <FieldPanel showColumnFields showDataFields showFilterFields showRowFields allowFieldDragging visible />
                <FieldChooser
                  data-testid="pivot-field-chooser"
                  height={500}
                  width={500}
                  layout={0}
                  allowSearch
                  enabled={showFieldChooser}
                />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customSave={saveState}
                  customLoad={loadState}
                  savingTimeout={500}
                />
                <Export enabled={false} />
                {children}
              </StyledDxPivotGrid>
            </StyledPivotGridWrapper>
          </StyledPivotViewWrapper>
        </StyledWrapper>
      );
    },
  ),
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  gridLayout: selectViewComponent(state, 'gridLayout'),
});

const mapDispatchToProps = (dispatch: Dispatch): GridViewReduxDispatch => ({
  search: () => dispatch({ type: PivotGridActions.PIVOT_GRID_SEARCH, payload: null }),
  stateChange: pivotGridState =>
    dispatch({ type: PivotGridActions.PIVOT_GRID_STATE_CHANGE, payload: { state: pivotGridState } }),
  unmountPivotGrid: () => dispatch({ type: PivotGridActions.PIVOT_GRID_UNMOUNTING, payload: null }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DxPivotGrid);
