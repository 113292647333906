import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export type FillProps = { fillColor?: string };

const IconError: React.SFC<SvgIconProps & FillProps> = props => (
  <SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>Error</title>
    <path
      d="M32 30.489h-32l16-28.978zM3.556 28.444h24.889l-12.444-22.578zM14.311 22.684h3.378v3.147h-3.378v-3.147zM14.311 12.16h3.378v8.942h-3.378v-8.942z"
      fill={props.fillColor || 'var(--background-snackbar-header-error)'}
    ></path>
  </SvgIcon>
);

export default IconError;
