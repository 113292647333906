import { put, takeLatest } from 'redux-saga/effects';
import { TabsAction } from 'src/reducers/tabs';
import { GlideViewActions } from '../glide-view.saga';

export enum PortfolioSagaAction {
  FETCH_PROPOSED_ORDERS = 'FETCH_PROPOSED_ORDERS',
}

interface ActionPayload {
  payload: { clientViewConfiguration: any };
}

export function* fetchProposedOrders(action: ActionPayload) {
  const clientViewUri = 'instance/client_view_configuration/proposed_order_blotter';
  yield put({
    type: TabsAction.CHANGE_VIEW,
    data: { fullPath: 'Portfolio-Management', activeView: clientViewUri },
  });
  yield put({
    type: GlideViewActions.FETCH_CLIENT_VIEW,
    payload: { clientViewUri, clientViewConfiguration: action.payload.clientViewConfiguration },
  });
}

export function* watchFetchProposedOrder() {
  yield takeLatest<any>(PortfolioSagaAction.FETCH_PROPOSED_ORDERS, fetchProposedOrders);
}
export default [watchFetchProposedOrder];
