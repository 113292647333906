import styled from 'styled-components';
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 5px;
  position: relative;
  line-height: 14px;
  border-bottom: var(--foreground-form-border);
`;
