import styled from 'styled-components';
import Radio from '@mui/material/Radio';

export const StyledRadio = styled(Radio)`
  padding: 3.5px 7px 3.5px 9px;
  & svg {
    fill: ${props => {
      if (props.required) return 'var(--background-radio-required-checked)';
      if (props.disabled) return 'var(--foreground-radiogroup-input-disabled)';
      if (props.checked) return 'var(--background-radio-checked)';
      return 'var(--background-radio-default-checked)';
    }};
    width: 14px;
    height: 14px;
  }

  &:disabled + label {
    color: 'var(--foreground-radiogroup-input-disabled)';
  }

  :hover {
    background-color: transparent;
    & svg {
      outline: 2px auto var(--foreground-radiogroup-input-focus-border);
      border-radius: 50%;
    }
  }
`;

export const StyledRadioSpan = styled.span<{ item: any }>`
  font-size: var(--form-label-font-size);
  color: ${props => (props.item.disabled ? 'var(--foreground-radiogroup-input-disabled)' : 'var(--foreground-header)')};
`;
