/* eslint-disable no-confusing-arrow */
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import React, { useEffect, useState } from 'react';
import { DropdownButton } from './components/DropdownButton/DropdownButton';
import * as S from './Select.style';
import { Search } from '@virtus/components/Search/Search';

interface SearchProps {
  isSearchable: boolean;
  filter?: string;
  isSearchDisabled?: boolean;
}
interface ButtonProps {
  isDisabled?: boolean;
}
interface Actions {
  text: string;
  onClick: (uri?: string) => void;
  bgColor?: string;
  testId?: string;
}
export interface SelectTemplateRenderProps {
  actionsCollection: Actions[];
  options?: {
    search?: SearchProps;
    button?: ButtonProps;
    uri?: string;
  };
  dropdownListType?: 'grid-action-col';
  dropdownStyle?: any;
  dropdownArrowStyle?: any;
  dropdownTextStyle?: any;
}

export const SelectTemplateRender = ({
  actionsCollection,
  options,
  dropdownStyle,
  dropdownArrowStyle,
  dropdownTextStyle,
}: SelectTemplateRenderProps) => {
  const [items, setItems] = useState([{}]);
  const subMenuActions = actionsCollection.slice(1);
  useEffect(() => {
    setItems(subMenuActions);
  }, [actionsCollection]);

  const onFilterChange = (value: string) => {
    const filteredData: any = subMenuActions?.filter(
      (eachItem: any) => eachItem?.text?.toLowerCase().indexOf(value.toLowerCase()) > -1,
    );
    setItems(filteredData);
  };

  const onSearchClick = (e: React.SyntheticEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onTextClickCallback = () => {
    if (options && options?.button?.isDisabled) return;
    if (actionsCollection && actionsCollection.length && actionsCollection[0].hasOwnProperty('onClick'))
      actionsCollection[0].onClick(options?.uri);
  };

  return (
    <DropdownMenu
      button={
        <DropdownButton
          onTextClick={onTextClickCallback}
          text={actionsCollection[0]?.text}
          data-testid={actionsCollection[0]?.testId}
          items={subMenuActions}
          buttonDisabled={options?.button?.isDisabled}
          dropdownArrowStyle={dropdownArrowStyle}
          dropdownStyle={dropdownStyle}
          dropdownTextStyle={dropdownTextStyle}
        />
      }
      id="selectedRowAction"
      isDropdownVisibleOnBtnClick={false}
    >
      {options?.search && (
        <S.SearchContainer>
          <Search
            data-testid="layouts-filter-input"
            style={{ input: { backgroundColor: 'var(--label)', marginRight: '8px', width: '130px' } }}
            onChange={onFilterChange}
            onClick={onSearchClick}
            disableFlag={options?.search?.isSearchDisabled}
          />
        </S.SearchContainer>
      )}
      {items &&
        Object.keys(items).length > 0 &&
        items.map((item: any) => (
          <DropdownMenuItem
            data-testid={item.testId}
            key={`${item.text}-menu-item`}
            onClick={() => item.onClick(options?.uri)}
          >
            <DropdownMenuItemText>{item.text}</DropdownMenuItemText>
          </DropdownMenuItem>
        ))}
    </DropdownMenu>
  );
};

export default SelectTemplateRender;
