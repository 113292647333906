import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FunctionComponent } from 'react';

/* eslint-disable max-len */
const SvgGenesis: FunctionComponent<SvgIconProps> = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g fill="#F57051" fillRule="evenodd">
      <path
        d="M2.796 11.236l2.856-2.857a1.01 1.01 0 0 1 1.428 0l2.856 2.857a1.01 1.01 0 0 1 0 1.428L7.08 15.52a1.01 1.01 0 0 1-1.428 0l-2.856-2.856a1.01 1.01 0 0 1 0-1.428z"
        fillRule="nonzero"
        opacity={0.8}
      />
      <path d="M8.32 5.652l2.856-2.856a1.01 1.01 0 0 1 1.428 0l2.857 2.856a1.01 1.01 0 0 1 0 1.428l-2.857 2.856a1.01 1.01 0 0 1-1.428 0L8.32 7.08a1.01 1.01 0 0 1 0-1.428z" />
      <path
        d="M13.874 11.236l2.856-2.857a1.01 1.01 0 0 1 1.428 0l2.857 2.857a1.01 1.01 0 0 1 0 1.428l-2.857 2.856a1.01 1.01 0 0 1-1.428 0l-2.856-2.856a1.01 1.01 0 0 1 0-1.428z"
        opacity={0.35}
      />
      <path
        d="M8.32 16.76l2.856-2.856a1.01 1.01 0 0 1 1.428 0l2.857 2.856a1.01 1.01 0 0 1 0 1.428l-2.857 2.856a1.01 1.01 0 0 1-1.428 0L8.32 18.188a1.01 1.01 0 0 1 0-1.428z"
        opacity={0.5}
      />
    </g>
  </svg>
);

export default SvgGenesis;
