import { ClickAwayListener, Drawer, Paper } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { Close, Search } from '@mui/icons-material';
import React, { useReducer } from 'react';
import BackIcon from 'src/icons/BackIcon';
import styled from 'styled-components';
import { Route } from '../models/route';
import SideMenuItem from './SideMenuItem';
import SideMenuList from './SideMenuList';

const searchRoutes = (_routes: Route[], searchText: string, _categories: Route[], foundRoutes: any[] = []) => {
  _routes.forEach((route: Route) => {
    if (!route.subroutes) {
      if (route.name.toLowerCase().includes(searchText.toLowerCase())) {
        const parentCategory = _categories.find(x => x.name == route.category);

        // Checking category is already foud routes array.
        if (parentCategory && !foundRoutes.find(x => x.category == route.category && x.name == route.category)) {
          // Adding parent category
          foundRoutes.push(parentCategory);
        }
        foundRoutes.push(route);
      }
    } else {
      searchRoutes(route.subroutes, searchText, _categories, foundRoutes);
    }
  });
  return foundRoutes;
};

interface HeaderState {
  search: string;
  navigationRoutes: {
    previous?: Route[];
    current: Route;
  };
}

const searchRoutesReducer = (_state: HeaderState, action: any) => {
  const { search, routes } = action.payload;
  switch (action.type) {
    case 'openSubroute': {
      const previous = _state.navigationRoutes.previous
        ? [..._state.navigationRoutes.previous, ...[_state.navigationRoutes.current]]
        : [];
      return {
        ..._state,
        ...{
          navigationRoutes: {
            previous,
            current: routes,
          },
        },
      };
    }
    case 'onSubrouteBack': {
      const previous = _state.navigationRoutes.previous ? [..._state.navigationRoutes.previous] : [];
      const current = previous.pop();
      return {
        ..._state,
        ...{
          navigationRoutes: {
            previous,
            current,
          },
        },
      };
    }
    case 'setSearchValue': {
      const { subroutes, ...nextRoutes } = routes;
      const filteredRoutes =
        search.length > 0 ? searchRoutes(routes.subroutes, search, routes.subroutes) : routes.subroutes;
      nextRoutes.subroutes = filteredRoutes;
      return {
        search,
        navigationRoutes: { previous: [], current: nextRoutes },
      };
    }
    case 'resetSearch':
      return {
        search: '',
        navigationRoutes: { current: routes, previous: [] },
      };
    default:
      return _state;
  }
};

interface SidemenuProps {
  isOpen: boolean;
  onClose: () => void;
  routes: any;
  onRouteChange: (path: string, routes?: any) => void;
  currentPath?: string;
}

const Sidemenu = ({ isOpen, onClose, routes, onRouteChange, currentPath }: SidemenuProps) => {
  const initialState = {
    search: '',
    navigationRoutes: {
      previous: [],
      current: routes,
    },
  };
  const [state, dispatch] = useReducer(searchRoutesReducer, initialState);
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    return dispatch({
      type: 'setSearchValue',
      payload: { search: value, routes },
    });
  };

  const onMenuBack = () => dispatch({ type: 'onSubrouteBack', payload: { routes } });

  const isParentRoute = () => {
    return state.navigationRoutes.previous && state.navigationRoutes.previous.length > 0 ? true : false;
  };

  const isClearSearch = () => {
    return state.search.length > 0 ? true : false;
  };

  return (
    <DrawerContainer
      PaperProps={{ component: DrawerPaper }}
      variant="persistent"
      anchor="left"
      open={isOpen}
      onClose={onClose}
    >
      {isOpen && (
        <ClickAwayListener
          onClickAway={() => {
            dispatch({ type: 'resetSearch', payload: { routes } });
            onClose();
          }}
        >
          <Background tabIndex={0} role="button" data-testid="sidemenu-content">
            <DrawerContent isSubMenu={isParentRoute()}>
              <MenuLevel>
                <SearchContainer>
                  <SearchIconContainer>
                    <SearchIcon />
                  </SearchIconContainer>
                  <InputContainer>
                    <Input
                      id="search"
                      data-testid="sidemenu-search-input"
                      value={state.search}
                      autoComplete="off"
                      placeholder="Search"
                      onChange={onSearchChange}
                    />
                    {isClearSearch() && (
                      <CloseIcon
                        data-testid="sidemenu-clear-btn"
                        onClick={() =>
                          dispatch({
                            type: 'resetSearch',
                            payload: { routes },
                          })
                        }
                      />
                    )}
                  </InputContainer>
                </SearchContainer>
                {state.navigationRoutes.current && (
                  <MenuLevelContent>
                    {state.navigationRoutes.previous && state.navigationRoutes.previous.length > 0 && (
                      <SideMenuItem
                        path=""
                        fullPath=""
                        data-testid="sidemenu-back-button"
                        icon={{ component: BackIcon, enableInSideBar: true }}
                        name={state.navigationRoutes.current.context || state.navigationRoutes.current.name}
                        context={state.navigationRoutes.current.context}
                        onClick={onMenuBack}
                        isSubMenuItem={isParentRoute()}
                        isSubMenuBackItem={true}
                        isView={false}
                      />
                    )}
                    <SideMenuList
                      routes={state.navigationRoutes.current}
                      isSubMenuItem={isParentRoute()}
                      isSubMenuBackItem={false}
                      currentPath={currentPath}
                      onClick={(fullPath?: string, subroute?: Route) => {
                        if (subroute && subroute.subroutes) {
                          return dispatch({ type: 'openSubroute', payload: { routes: subroute } });
                        }
                        if (fullPath) {
                          dispatch({ type: 'resetSearch', payload: { routes } });
                          onRouteChange(fullPath, routes);
                        }

                        onClose();
                      }}
                    />
                  </MenuLevelContent>
                )}
              </MenuLevel>
            </DrawerContent>
          </Background>
        </ClickAwayListener>
      )}
    </DrawerContainer>
  );
};

export default Sidemenu;

const SearchContainer = styled.div`
  display: flex;
  height: 32px;
  padding-left: 12px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const SearchIcon = styled(Search)`
  color: var(--foreground-side-megamenu-icon);
  font-size: var(--side-megamenu-icon-font-size);
`;

const CloseIcon = styled(Close)`
  color: var(--foreground-side-megamenu-icon);
  position: relative;
  cursor: pointer;
  right: 21px;
  margin-top: -1px;
  font-size: var(--side-megamenu-icon-font-size);
`;

const Input = styled(InputBase)`
  color: var(--foreground-text);
  width: 231px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  border: var(--foreground-side-megamenu-input-border);
`;

const DrawerContainer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  left: 0;
  > div {
    border: 0;
  }
`;

const DrawerPaper = styled(Paper)`
  top: 42px;
  left: 15px;
  height: 80%;
  border-radius: 3px;
  opacity: 1;
`;

const DrawerContent = styled.div<{ isSubMenu: boolean }>`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: ${props => {
    return props.isSubMenu ? 'var(--background-sub-megamenu)' : 'var(--background-side-megamenu)';
  }};
`;

const MenuLevel = styled.div`
  display: flex;
  flex-direction: column;
  border-left: none;
  height: 100%;
  min-width: 275px;
  width: auto;
  overflow: hidden;
  border: var(--foreground-side-megamenu-border);
  border-radius: var(--side-megamenu-border-radius);
`;

const MenuLevelContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
