import { Delete, Done, Share } from '@mui/icons-material';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ConfirmInputButton } from '@virtus/components/Buttons';
import { GlideLayoutData } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import { CreateCopyIcon } from '@virtus/components/icons';
import { Inspector } from '@virtus/components/Inspector';
import InspectorFooter from '@virtus/components/Inspector/components/InspectorFooter';
import { InspectorProps } from '@virtus/components/Inspector/Inspector';
import { Search } from '@virtus/components/Search/Search';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { LayoutRow, MenuOption } from './components/layout-row/layout-row';
import * as S from './layout-manager-inspector.style';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import { Dispatch } from 'redux';
import {
  createLayoutAction,
  CreateLayoutActionPayload,
  deleteLayoutAction,
  duplicateLayoutAction,
  DuplicateLayoutActionPayload,
  LayoutUrisAction,
  selectLayoutPermissions,
  setLayoutAction,
  SetLayoutActionParams,
  shareLayoutAction,
  UpdateActionPayload,
  updateLayoutAction,
} from 'src/sagas/layouts/layouts.saga';
import { View } from 'src/api/queries/display-view';
import { ViewBlotter } from 'src/api/queries';
import { selectCVC } from 'src/reducers/tabs';
import { ComponentGridLayout, Components, selectComponents } from 'src/reducers/components';
import { WebLayoutPermission } from 'src/api/layouts.selector';
import { onSearchInput } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
import { dispatchActions } from 'src/app/store';

type LayoutManagerType = {};

interface ReduxProps {
  clientViewConfiguration: ClientViewConfigurationData;
  clientViewData: View;
  components: Components;
  getLayoutPermissions: (layout: GlideLayoutData) => WebLayoutPermission | undefined;
  currentInspectorForm: any;
}

export interface LayoutManagerInspectorProps
  extends Omit<InspectorProps, 'title' | 'onExpandChange' | 'isExpanded' | 'loading'> {
  clientViewUri: string;
  style?: React.CSSProperties;
}

interface ReduxDispatch {
  createLayout: ({ clientViewUri, name, duplicateLayout }: CreateLayoutActionPayload) => void;
  deleteLayout: ({ clientViewUri, layoutUri }: LayoutUrisAction) => void;
  duplicateLayout: ({ clientViewUri, layout }: DuplicateLayoutActionPayload) => void;
  setLayout: ({ layout, clientViewUri }: SetLayoutActionParams) => void;
  shareLayout: ({ clientViewUri, layoutUri }: LayoutUrisAction) => void;
  updateLayout: ({ clientViewUri, name, isRenamed }: UpdateActionPayload) => void;
}

const LayoutManagerInspectorComponent = ({
  style,
  getLayoutPermissions,
  clientViewUri,
  createLayout,
  duplicateLayout,
  updateLayout,
  clientViewData,
  deleteLayout,
  components,
  clientViewConfiguration,
  shareLayout,
  currentInspectorForm,
  ...props
}: LayoutManagerInspectorProps & LayoutManagerType & ReduxProps & ReduxDispatch) => {
  const [isCreatingANewLayout, setIsCreatingANewLayout] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');
  const [layoutDeleteTarget, setLayoutDeleteTarget] = useState<GlideLayoutData | undefined>(undefined);
  const viewGridLayout: ComponentGridLayout = components.viewComponents[clientViewUri]?.gridLayout;

  const onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      submitNewLayout();
    }
    if (event.key === 'Escape') {
      setIsCreatingANewLayout(false);
      setNewLayoutName('');
    }
  };

  const submitNewLayout = () => {
    createLayout({ name: newLayoutName, clientViewUri });
    setNewLayoutName('');
    setIsCreatingANewLayout(false);
    dispatchActions.components.updateView('gridLayout', clientViewUri, {
      filteredLayouts: null,
    });
  };

  const createNewLayoutHandler = () => {
    if (currentInspectorForm?.isFormDirty) {
      displayConfirmationDialog();
    } else {
      submitNewLayout();
    }
  };
  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: submitNewLayout,
    ...CONFIRMATION_DIALOG,
  });

  const handleOnDelete = (layout: GlideLayoutData) => {
    setLayoutDeleteTarget(layout);
    handleOnDeleteLayoutWithConfirmation();
  };

  const deleteSelectedRow = () => {
    if (!layoutDeleteTarget) return;
    deleteLayout({ layoutUri: layoutDeleteTarget.uri, clientViewUri });
    setLayoutDeleteTarget(undefined);
  };

  const onDuplicateClick = (layout: GlideLayoutData) => duplicateLayout({ clientViewUri, layout });

  const onShareClick = (layout: GlideLayoutData) => shareLayout({ layoutUri: layout.uri, clientViewUri });
  const iconStyle = {
    width: '16px',
    marginRight: '5px',
    color: 'var(--foreground-layout-drop-icon-panel)',
  };

  const searchStyle = {
    icon: {
      color: 'var(--foreground-search-panel)',
      position: 'relative',
      left: '92%',
      fontSize: '20px',
      bottom: '1px',
    } as React.CSSProperties,

    row: {
      marginTop: '10px',
      marginLeft: '-30px',
      marginBottom: '10px',
    } as React.CSSProperties,

    input: {
      height: '23px',
      textIndent: '0px',
    } as React.CSSProperties,
  };

  const menuOptions = {
    duplicate: {
      onClick: onDuplicateClick,
      text: 'Duplicate',
      icon: <CreateCopyIcon fill="var(--foreground-layout-drop-icon-panel)" style={iconStyle} />,
    },
    delete: {
      onClick: handleOnDelete,
      text: 'Delete',
      icon: (
        <Delete
          style={{
            ...iconStyle,
            ...{ color: 'var(--foreground-layout-drop-icon-panel)' },
          }}
        />
      ),
    },
    share: {
      onClick: onShareClick,
      text: 'Share',
      icon: <Share style={iconStyle} />,
    },
  };

  const getMenuOptions = (layout: GlideLayoutData): MenuOption[] => {
    const layoutPermissions = getLayoutPermissions(layout);
    const options = [menuOptions.duplicate];

    if (!layout.data.permissions?.includes('instance/permissions/readonly')) {
      options.push(menuOptions.share);
    }

    if (
      (layout.data.permissions?.includes('instance/permissions/byowner') && layoutPermissions?.isUserOwnedLayout) ||
      layoutPermissions?.isAdminUser ||
      layoutPermissions?.isClientAdminUser
    ) {
      options.push(menuOptions.delete);
    }

    return options;
  };

  const onRenameClick = (layoutToRename: GlideLayoutData, newName: string) => {
    updateLayout({ layout: layoutToRename, name: newName, clientViewUri, isRenamed: true });
  };

  const {
    DialogComponent: DeleteRowConfirmDialog,
    onDispatcherClick: handleOnDeleteLayoutWithConfirmation,
  } = useConfirmationDialog({
    onClick: deleteSelectedRow,
    bodyTextContent: 'Are you sure you want delete this layout?',
    acceptButton: { text: 'Delete Layout' },
    cancelButton: { text: 'Cancel' },
  });

  if (!viewGridLayout?.filteredLayouts && !clientViewData?.webLayouts) return null;

  return (
    <Inspector
      dataTestId="layout-manager-inspector"
      title="Layout Manager"
      isExpanded={false}
      loading={false} //isSavingLayout
      {...props}
      // Custom styling props
      style={{ ...style, position: 'relative', height: '100%' }}
      panelHeaderStyle={{ fontSize: 'var(--tab-font-size)', letterSpacing: '0px', marginLeft: '7px' }}
    >
      <DeleteRowConfirmDialog />
      <S.SearchWrapper>
        <Search
          resetSerachText={viewGridLayout?.filteredLayouts ? false : true}
          onChange={onSearchInput(clientViewData, clientViewUri, viewGridLayout?.selectedLayout)}
          style={{ ...searchStyle }}
        />
      </S.SearchWrapper>
      <S.ScrollableContent>
        <DragDropContext onDragEnd={() => console.warn('[layout-manager-inspector] not implemented')}>
          <Droppable droppableId="droppable">
            {dropProvided => (
              <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                {(viewGridLayout?.filteredLayouts || clientViewData?.webLayouts).map((layout, index: number) => (
                  <Draggable key={layout.uri} draggableId={layout.uri ?? index} index={index}>
                    {dragProvided => (
                      <div
                        key={layout.uri}
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        <LayoutRow
                          active={viewGridLayout?.selectedLayout?.data?.name === layout.data.name}
                          style={{
                            backgroundColor: layout?.data?.permissions?.includes('instance/permissions/readonly')
                              ? 'var(--background-layout-item-shared)'
                              : undefined,
                          }}
                          title={
                            layout?.data?.permissions?.includes('instance/permissions/readonly')
                              ? `Layout shared by ${layout.data.owner.replace('users/', '')}`
                              : ''
                          }
                          dataTestId="layout-element"
                          layout={layout}
                          moreMenuOptions={getMenuOptions(layout)}
                          onRename={onRenameClick}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </S.ScrollableContent>
      <InspectorFooter
        hideConfirm
        hideActions={false}
        hideCancel
        isLight={true}
        actions={
          isCreatingANewLayout ? (
            <>
              <QuitInspector />
              <S.NewLayoutWrapper>
                <S.InputContainer>
                  <StyledInput
                    data-testid="create-new-layout-input"
                    value={newLayoutName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewLayoutName(e.target.value)}
                    onKeyDown={onKeyDown}
                    // width="100vh"
                    placeholder="New layout"
                    autoFocus
                  />
                </S.InputContainer>
                <ConfirmInputButton
                  data-testid="confirm-btn"
                  onClick={createNewLayoutHandler}
                  disabled={newLayoutName === '' || newLayoutName === 'Default'}
                >
                  <Done style={{ color: 'var(--text)', fontSize: '16px' }} />
                </ConfirmInputButton>
              </S.NewLayoutWrapper>
            </>
          ) : (
            <S.SecondaryButtonStyled data-testid="create-new-layout-btn" onClick={() => setIsCreatingANewLayout(true)}>
              + Create Layout
            </S.SecondaryButtonStyled>
          )
        }
      ></InspectorFooter>
    </Inspector>
  );
};

const mapStateToProps = (state: RootState, ownProps: { clientViewUri: string }): ReduxProps => ({
  clientViewConfiguration: selectCVC(state, ownProps.clientViewUri),
  clientViewData: ViewBlotter.selectClientViewData(state, ownProps.clientViewUri),
  components: selectComponents(state),
  getLayoutPermissions: layout => selectLayoutPermissions(state, layout),
  currentInspectorForm: selectCurrentForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  createLayout: ({ clientViewUri, name }) => dispatch(createLayoutAction({ clientViewUri, name })),
  deleteLayout: ({ clientViewUri, layoutUri }) => dispatch(deleteLayoutAction({ clientViewUri, layoutUri })),
  duplicateLayout: ({ clientViewUri, layout }) => dispatch(duplicateLayoutAction({ clientViewUri, layout })),
  setLayout: ({ layout, clientViewUri }) => dispatch(setLayoutAction({ layout, clientViewUri })),
  shareLayout: ({ clientViewUri, layoutUri }) => dispatch(shareLayoutAction({ clientViewUri, layoutUri })),
  updateLayout: ({ clientViewUri, name, isRenamed, layout }) =>
    dispatch(updateLayoutAction({ clientViewUri, name, isRenamed, layout })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutManagerInspectorComponent);
