import { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import styled from 'styled-components';

export const LayoutRow = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text);
  cursor: pointer;
  padding-left: 7px;
  font-size: var(--layoutFontSize);
  background-color: ${props => (props.active ? 'var(--background-layout-item-selected)' : 'inherit')};
  border-left: ${props => (props.active ? '5px solid var(--background-layout-item-row)' : '5px solid transparent')};
  border-bottom: var(--foreground-column-manager-row-border);
  height: auto;
`;

export const LayoutRowStyled = styled(LayoutRow)`
  color: var(--foreground-form);
`;

export const LayoutDropdownMenuItemText = styled(DropdownMenuItemText)`
  color: var(--foreground-layout-drop-icon-panel);
`;

export const LayoutDropdownMenuItem = styled(DropdownMenuItem)`
  &:hover {
    background-color: var(--background-layout-item-hover);
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const LayoutEditBtn = styled.button`
  background-color: var(--background-layout-edit-icon);
  cursor: pointer;
  height: 18px;
  width: 18px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 9px;
`;

export const EditLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 3px;
`;
