import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledDiv = styled.div<{ disabled?: boolean }>`
  padding-left: ${props => (props.disabled ? '5px' : '0px')};
  & span svg {
    font-size: var(--form-checkbox-icon-font-size);
  }
`;
