// import CalendarIcon from '@mui/icons-material/CalendarToday';
import styled from 'styled-components';
import { CalendarIcon } from '@virtus/components/icons';

export const StyledCalendarIcon = styled(CalendarIcon)<{ disabled?: boolean }>`
  height: 20px;
  width: 20px;
  color: ${props => (props.disabled ? 'var(--foreground-calendar-disabled)' : 'var(--calendar-icon-active)')};
  position: relative;
  top: 1px;
`;

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .DayPicker-Day.DayPicker-Day--disabled {
    pointer-events: none;
    cursor: not-allowed !important;
  }
`;

export const CalendarContainer = styled.div`
  height: 22px;
  width: 0px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & :hover {
    background-color: #e0e5e6;
  }
`;

export const DateButton = styled.button<{
  $error?: boolean;
  $hasFocus: boolean;
  $editIsEnabled: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 7px;
  width: 100%;
  height: 28px;
  border-radius: 2px;
  background-color: inherit;
  color: var(--calendar-icon-active);
  border: ${props => {
    if (props.$error) return '2px solid var(--foreground-error)';
    if (props.$editIsEnabled && !props.$hasFocus) return 'var(--foreground-form-input-edit-border)';
    if (props.$editIsEnabled && props.$hasFocus) return 'var(--foreground-form-input-focus-border)';
    return 'none';
  }};
  cursor: pointer;
  :disabled {
    background-color: transparent;
    pointer-events: none;
  }
  position: relative;
  bottom: 1px;
`;

export const DateInputElement = styled.input`
  margin: 0;
  padding: 0;
  color: var(--foreground-form);
  font-size: var(--calendar-font-size);
  background-color: transparent;
  border: none;
  height: 28px;
  width: 100%;
  cursor: pointer;
`;

export const MultipleCalendarWrapper = styled.div<{ align?: string }>`
  width: fit-content;
  background-color: var(--background-calendar);
  z-index: 10;
  margin: 0;
  padding: 0;
  top: 33px;
  left: ${props => (props.align === 'right' ? '-25px' : '-168px')};
  position: absolute;
  color: var(--foreground-form);
  border: var(--foreground-calendar-border);
  & div.DayPicker,
  & div.DayPicker-Caption div,
  & div.DayPicker-Weekday abbr {
    font-size: var(--calendar-font-size);
  }
  & div.DayPicker-Weekday abbr {
    color: var(--foreground-form);
  }
`;
