/*
⚠️⚠ TECH DEBT: DO NOT USE OR MAINTAIN ️
SHARED STATES NEED TO BE HANDLED in STORE!!
Migrate to reducer if further maintenance is needed to save time and avoid
bugs!
*/
/**
 * Refer below URL for details on marketmap muse API, field values
 * https://smart.infotecnet.com/SMART.NET/MMPortal/MMPortalHomePage.aspx
 *
 * try/catch statements are used so that entire application would not break in case muse object is undefined or any issue while calling the API
 */

import { useEffect, useCallback, useState } from 'react';
import { marketmapOutputFormat } from 'src/utils/constants';
import {
  MarketmapCredentialsType,
  MarketmapLivePriceDataType,
  MarketmapLoginData,
  Muse,
} from 'src/models/marketmap/marketmap.model';
import config from 'src/config';
import { useSelector, useDispatch, batch } from 'react-redux';
import { selectComponents, updateComponentAction, ComponentName } from 'src/reducers/components';
declare let muse: Muse | any;

/**
 * At present we have live streaming only at client universe screen and once user focuses out of that grid
 * I am unsubscribing to live stream data to avoid memory leaks
 * @var reqCountId - for tracking number of request Id's currently in use, globally in Glide application
 * will be useful for scenerios where we want to consume more than one market map API at the same time
 * or two grids having live streaming
 * or live price is on along with some historical data
 */
const generateMonitorPricePayload = (tickers: string[], reqCountId: number, fieldsToMonitor: (string | number)[]) => {
  let uniqueId = reqCountId;
  const livePriceKeyArr: number[] = [];
  let marketmapPayload = {};
  tickers.forEach((ticker: string) => {
    uniqueId++;
    if (ticker) {
      livePriceKeyArr.push(uniqueId);
      marketmapPayload = {
        ...marketmapPayload,
        ...{
          [uniqueId]: {
            Symbol: ticker,
            Fields: [...fieldsToMonitor],
          },
        },
      };
    }
  });
  return {
    uniqueId,
    marketmapPayload,
    livePriceKeyArr,
  };
};

export const useMarketmap = () => {
  const [livePrices, setLivePrices] = useState<any>(null);
  const [loggedInError, setLoggedInError] = useState<string>('');
  const { livePriceToggleState, marketMap } = useSelector(selectComponents);
  const { reqCountId, isMarketmapConnectedToServer, isLoggedInToMarketmap, livePriceKeys } = livePriceToggleState;
  const dispatch = useDispatch();

  const dispatchUpdateComponentState = useCallback(
    (props: any, componentName: ComponentName = 'livePriceToggleState') => {
      dispatch(updateComponentAction(componentName, props));
    },
    [dispatch],
  );

  /**
   * Method to establish connection with market map server
   * If one server fails we have another one as fallback in all the environment
   */
  const connectToMarketMapTerminal = useCallback(() => {
    try {
      muse
        .config({
          log: 0,
          servers: config.marketmapURLS,
        })
        .done(() => {
          // setIsMarketmapServerConnected(true);
          dispatchUpdateComponentState({ isMarketmapConnectedToServer: true });
        })
        .fail(() => {
          dispatchUpdateComponentState({ isMarketmapConnectedToServer: false });
          throw new Error('[Muse] Failed to connect to marketmap terminals, please refresh page again... ');
        });
    } catch (error) {
      console.error('[Muse] Failed to connect to marketmap terminals, please refresh page again... ');
      dispatchUpdateComponentState({ isMarketmapConnectedToServer: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * connect with marketmap terminal only once until session expires or user refreshes page
   */
  useEffect(() => {
    if (!isMarketmapConnectedToServer && marketMap.visible) {
      connectToMarketMapTerminal();
    }
    /**
     * Avoiding any memory leaks by terminate - monitoring field values when user navigates to some other pages/tabs
     */
    return () => {
      if (reqCountId > 0) {
        stopMonitorLivePrices();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketMap.visible]);

  /**
   * @param marketmapUser - object which holds {userid, password, group} required to login to marketmap
   * Marketmap API's can only be consumed when user is authorized
   */
  const loginToMarketmap = useCallback((marketmapUser: MarketmapCredentialsType) => {
    const { marketmapGroup, marketmapU, marketmapP } = marketmapUser;
    try {
      muse.connect(marketmapGroup, marketmapU, marketmapP, (loginData: MarketmapLoginData) => {
        if (loginData && loginData.isConnected) {
          batch(() => {
            dispatchUpdateComponentState(marketmapUser, 'marketmapCredentials');
            dispatchUpdateComponentState({ isLoggedInToMarketmap: true });
          });
        } else {
          console.error(`[Muse] ${loginData.message}`);
          displayAuthenticationError(loginData.message);
        }
      });
    } catch (error) {
      console.error('[Muse] Failed to login to marketmap, please try again !!!');
      displayAuthenticationError('Failed to login to marketmap, please try again !!!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayAuthenticationError = useCallback(
    (errMsg: string) => {
      dispatchUpdateComponentState({ isLoggedInToMarketmap: false });
      setLoggedInError(errMsg);
    },
    [dispatchUpdateComponentState],
  );

  /**
   * @param tickers - list of tickers/symbols
   * @param fieldsToMonitor - list of marketmap fields
   * Method to register and fetch different field values from market map
   * It's not just last price, it can be any valid field like ask, bid, net change etc.
   * Marketmap recognizes each field with a unique number (as per their doc/excel)
   */
  const monitorLivePrices = useCallback(
    (tickers: string[], fieldsToMonitor: (string | number)[]) => {
      const { uniqueId, marketmapPayload, livePriceKeyArr } = generateMonitorPricePayload(
        tickers,
        reqCountId,
        fieldsToMonitor,
      );
      const requestQuote = {
        OutputFormat: marketmapOutputFormat.OBJECT_FORMAT,
        ...marketmapPayload,
      };
      dispatchUpdateComponentState({ reqCountId: uniqueId, livePriceKeys: [...livePriceKeyArr] });
      try {
        muse.monitorPrices(
          JSON.stringify(requestQuote),
          (livePricesData: { [key: number]: MarketmapLivePriceDataType }) => {
            if (livePricesData) {
              setLivePrices(livePricesData);
            }
          },
        );
      } catch (error) {
        console.error('[Muse] Failed to monitor prices, please refresh the page !!!');
      }
    },
    [dispatchUpdateComponentState, reqCountId],
  );

  /**
   * Method to stop monitoring all field values based on Id's
   * `{"Id": [1, 2, 3 .....]}`
   */
  const stopMonitorLivePrices = useCallback(() => {
    if (livePriceKeys.length > 0) {
      try {
        muse.stopMonitorPrices(`{"Id": [${livePriceKeys}]}`);
      } catch (error) {
        console.error('[Muse] Failed to stop monitoring prices, please refresh the page !!!');
      }
      /**
       * As of now making request count to zero, if required in future this can be well maintained by
       * local state variables to track number of id's currently in use for live price
       * or number of id's currently in use for historical data
       */
      dispatchUpdateComponentState({ reqCountId: 0, livePriceKeys: [] });
    }
  }, [dispatchUpdateComponentState, livePriceKeys]);

  const resetFormError = useCallback(() => {
    setLoggedInError('');
  }, []);

  const turnOffLivePriceButton = useCallback(() => {
    // Setting all live prices flag false as this is called when MM authentication form is closed
    // This function should not be called when stopping live pricing on any page
    const LivePricesOff = { ...livePriceToggleState.marketMapToggleStatus };
    // @ts-ignore
    Object.keys(LivePricesOff).forEach((key: string) => (LivePricesOff[key as string] = false));
    dispatchUpdateComponentState({ showMMAuthenticationForm: false, marketMapToggleStatus: LivePricesOff });
    resetFormError();
  }, [dispatchUpdateComponentState, resetFormError, livePriceToggleState.marketMapToggleStatus]);

  const toggleLivePrice = useCallback(
    (currentId: string, checked: boolean) => {
      if (!isMarketmapConnectedToServer && marketMap.visible) connectToMarketMapTerminal();
      if (!checked) stopMonitorLivePrices();
      if (!loggedInError) {
        if (!isLoggedInToMarketmap)
          dispatchUpdateComponentState({
            marketMapToggleStatus: { ...livePriceToggleState.marketMapToggleStatus, [currentId]: true },
          });
        if (currentId && isLoggedInToMarketmap) {
          dispatchUpdateComponentState({
            marketMapToggleStatus: { ...livePriceToggleState.marketMapToggleStatus, [currentId]: checked },
          });
        }
      }
      dispatchUpdateComponentState({ showMMAuthenticationForm: checked });
    },
    [
      connectToMarketMapTerminal,
      dispatchUpdateComponentState,
      isLoggedInToMarketmap,
      isMarketmapConnectedToServer,
      livePriceToggleState.marketMapToggleStatus,
      loggedInError,
      stopMonitorLivePrices,
      marketMap.visible,
    ],
  );

  const setMarketmapCredentials = useCallback(
    (marketmapUser: MarketmapCredentialsType) => {
      resetFormError();
      if (isMarketmapConnectedToServer) {
        loginToMarketmap(marketmapUser);
      }
    },
    [isMarketmapConnectedToServer, loginToMarketmap, resetFormError],
  );

  return {
    loginToMarketmap,
    monitorLivePrices,
    stopMonitorLivePrices,
    resetFormError,
    turnOffLivePriceButton,
    toggleLivePrice,
    setMarketmapCredentials,
    livePrices,
    livePriceKeys,
    loggedInError,
  };
};
