import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
import { GlideObject } from 'src/models/glide/glideObject';
import { actionTypes } from 'redux-query';
import { ClientRegion } from 'src/utils/constants';

export interface CommentaryData {
  uri: any;
  type: string;
}
export type UpdateCommentsProps = {
  object_uri: string;
  [key: string]: object | string;
};

export interface DefaultCommentaryRequestProps {
  type: string;
  creditUri: string;
  fromModal: boolean;
  inspectorOpen: boolean;
}
export interface CreditDetailsUpdateProps {
  type: string;
  creditData: any;
  uri: string;
  action: string;
}

export interface DeleteCommentaryRequestProps extends DefaultCommentaryRequestProps {
  uri: string;
}
export interface AddCommentaryRequestProps extends DefaultCommentaryRequestProps {
  body: any;
}

const defaultGlideTimeSeries = { data: [], schema: [] };

const defaultCommentaryData = {};
export const creditDashboardKey = `instance/client_view_configuration/credit_dashboard`;

const isMatchedObject = (data: any, type: string) => {
  const { field } = data;
  if (!field) return false;
  const fieldObj: any = Object.values(field)[0];
  return fieldObj?.display_name === type;
};

export const updateCreditDetailsView = ({ type, creditData, uri, action }: CreditDetailsUpdateProps) => {
  const commentaryDataKey = Object.keys(creditData).find(key => {
    const value = creditData[key];
    return (
      value &&
      value.some(
        (cdNested: any) =>
          cdNested.client_region === ClientRegion.BrowserRightRegion && isMatchedObject(cdNested, type),
      )
    );
  });
  if (commentaryDataKey) {
    const updatedCreditData = {
      ...creditData,
      [commentaryDataKey]: creditData[commentaryDataKey].map((item: any) => {
        if (item.client_region === ClientRegion.BrowserRightRegion && isMatchedObject(item, type)) {
          switch (action) {
            case 'ADD':
              return { ...item, value: [...(item.value || []), uri] };
            case 'DELETE':
              return { ...item, value: item.value.filter((commentaryUri: string) => commentaryUri !== uri) };
            default:
              return item;
          }
        }
        return item;
      }),
    };

    return updatedCreditData;
  }

  return { ...creditData };
};

export const transformGlideListObject = (data: GlideObject[], type: string) => {
  const listObject: any = data.map((eachListData: GlideObject, index: number) => ({
    id: index,
    title: eachListData.data.analyst_comment ? eachListData.data.analyst_comment.replace(/<[^>]+>/g, '') : '',
    username: eachListData.data.analyst,
    comments: eachListData.data.analyst_comment,
    category: eachListData.data.analyst_comment_type,
    commentdate: eachListData.data.hasOwnProperty('analyst_comment_date')
      ? new Date(eachListData.data.analyst_comment_date).toISOString()
      : new Date(eachListData.date).toISOString(),
    commenturi: eachListData.uri,
    displayname: eachListData.data.display_name,
    credit_detail: eachListData.data.credit_detail,
  }));

  return { [type]: listObject };
};

export const getGlideCommentaryData = ({ uri, type }: CommentaryData) => {
  return {
    url: `${endpoints.gget.root}`,
    transform: (body: GlideObject[]) => ({
      glide_commentary_data: { ...transformGlideListObject(body, type) },
    }),
    body: {
      uri: uri || [],
    },
    force: true,
    options: { method: 'POST' },
    params: {},
    queryKey: 'getGlideCommentaryData',
    update: {
      glide_commentary_data: (prev: any, next: any) => ({ ...prev, ...next }),
    },
  };
};

export const updateAnalystCommentsCreditDetails = (body: UpdateCommentsProps) => ({
  url: `${endpoints.update.rootObject}`,
  options: {
    method: 'POST',
    // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
    headers: { 'X-Http-Method-Override': 'PUT' },
  },
  queryKey: 'updateAnalystCommentsCreditDetails',
  body,
  force: true,
});

export const CommentarySelector = (state: RootState, type: string) => {
  return (state.entities.glide_commentary_data && state.entities.glide_commentary_data[type]) || defaultCommentaryData;
};

export const CommentaryUrisSelector = (state: RootState) => state.entities.commentaryUris || defaultGlideTimeSeries;
export const updateComments = (existing: any, body: any, type: string) => {
  if (!existing[type]) {
    return existing; // Return existing data if the specified type is not present
  }
  const updatedData = existing[type].map((d: any) => {
    const commentUri = Object.keys(body)[0];
    if (d.commenturi === commentUri) {
      const { analyst_comment_type, analyst_comment, analyst, display_name } = body[commentUri];
      const title = analyst_comment.replace(/<[^>]+>/g, '');

      return {
        ...d,
        category: analyst_comment_type,
        comments: analyst_comment,
        username: analyst,
        displayname: display_name,
        title,
      };
    } else {
      return d;
    }
  });

  return { ...existing, [type]: updatedData };
};

export const updateCommentary = (body: any, type: string) => ({
  url: `${endpoints.update.root}`,
  options: {
    method: 'POST',
    // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
    headers: { 'X-Http-Method-Override': 'PUT' },
  },
  body,
  force: true,
  queryKey: 'updateCommentary',
  update: {
    glide_commentary_data: (existing: any) => {
      return updateComments(existing, body, type);
    },
  },
  meta: {
    notification: {
      [actionTypes.MUTATE_SUCCESS]: 'Comment has been updated.',
    },
  },
});

export const updateCommentaryAfterAdd = (
  body: any,
  comment_type: string,
  fromModal: boolean,
  inspectorOpen: boolean,
  creditUri: string,
) => {
  let newUri = '';

  const updateGlideCommentaryData = (existing: any, next: any) => {
    if (next && next.uri && next.uri.length > 0 && next.uri[0]) {
      const { analyst_comment, analyst_comment_type, analyst, display_name } = body.object_data;
      const commentDate = new Date().toISOString();
      const newComment = {
        id: existing[comment_type].length,
        title: analyst_comment.replace(/<[^>]+>/g, ''),
        comments: analyst_comment,
        commentdate: commentDate,
        commenturi: next.uri[0],
        username: analyst,
        displayname: display_name,
        category: analyst_comment_type,
      };

      existing[comment_type].push(newComment);
      newUri = next.uri[0];

      return { ...existing, [comment_type]: [...existing[comment_type]] };
    }
  };

  if (fromModal && !inspectorOpen) {
    return {
      glide_commentary_data: updateGlideCommentaryData,
    };
  }

  return {
    glide_commentary_data: updateGlideCommentaryData,
    views: (prev: any) => {
      if (prev[creditDashboardKey]?.inspectorData?.uri !== creditUri) {
        return prev;
      }

      const newData = updateCreditDetailsView({
        type: comment_type,
        creditData: prev[creditDashboardKey]?.inspectorData?.data,
        uri: newUri,
        action: 'ADD',
      });
      newUri = '';

      return {
        ...prev,
        [creditDashboardKey]: {
          ...prev[creditDashboardKey],
          inspectorData: { ...prev[creditDashboardKey].inspectorData, data: newData },
        },
      };
    },
  };
};

//Submit new comments Blogs/KPIs
export const submitNewComments = ({
  body,
  type,
  inspectorOpen,
  creditUri,
  fromModal = false,
}: AddCommentaryRequestProps) => ({
  url: `${endpoints.new.root}`,
  options: {
    method: 'POST',
  },
  transform: (body: any) => ({
    glide_commentary_data: body,
  }),
  body,
  force: true,
  queryKey: 'gNew',
  update: {
    ...updateCommentaryAfterAdd(body, type, fromModal, inspectorOpen, creditUri),
  },
  meta: {
    notification: {
      [actionTypes.MUTATE_SUCCESS]: 'Comment has been Added.',
    },
  },
});

export const newCommentaryUri = (state: RootState) => state.entities.newCommentaryUris;
export const isNewCommentaryPending = (state: RootState) => state.queries.gNew && state.queries.gNew.isPending;
export const isCommentaryDirty = (state: RootState) => state.components.isCommentaryDirty.dirty;
export const isPending = (state: RootState) =>
  state.queries.getGlideCommentaryData && state.queries.getGlideCommentaryData.isPending;

const optimisticUpdateAfterDelete = (
  uri: string,
  type: string,
  fromModal: boolean,
  inspectorOpen: boolean,
  creditUri: string,
) => {
  const updateGlideCommentaryData = (prevData: any) => {
    prevData[type] = prevData[type].filter((item: any) => item.commenturi !== uri);
    return { ...prevData, [type]: prevData[type] };
  };

  if (fromModal && !inspectorOpen) {
    return {
      glide_commentary_data: updateGlideCommentaryData,
    };
  }
  return {
    glide_commentary_data: updateGlideCommentaryData,
    views: (prevData: any) => {
      if (prevData[creditDashboardKey] && prevData[creditDashboardKey]?.inspectorData?.uri !== creditUri) {
        return prevData;
      }
      return {
        ...prevData,
        [creditDashboardKey]: {
          ...prevData[creditDashboardKey],
          inspectorData: {
            ...prevData[creditDashboardKey].inspectorData,
            data: updateCreditDetailsView({
              creditData: prevData[creditDashboardKey].inspectorData.data,
              action: 'DELETE',
              type,
              uri,
            }),
          },
        },
      };
    },
  };
};

export const deleteCommentary = ({
  uri,
  type,
  inspectorOpen,
  creditUri,
  fromModal = false,
}: DeleteCommentaryRequestProps) => ({
  url: `${endpoints.delete.root}`,
  options: {
    method: 'POST',
    // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
    headers: { 'X-Http-Method-Override': 'DELETE' },
  },
  body: {
    uris: [uri],
  },
  force: true,
  queryKey: 'deleteCommentary',
  optimisticUpdate: {
    ...optimisticUpdateAfterDelete(uri, type, fromModal, inspectorOpen, creditUri),
  },
  rollback: {
    // @ts-ignore
    glide_commentary_data: (initialData: any, _: any) => initialData,
    views: (initialData: any, _: any) => initialData,
  },
  meta: {
    notification: {
      [actionTypes.MUTATE_SUCCESS]: 'Comment has been Deleted.',
    },
  },
});
