import React from 'react';
import { CellData } from '../../dxgrid-client-view/dxgrid-client-view.model';
import * as S from './diplay-overlays-cell-render.styles';

interface DisplayOverlaysCellProps {
  cellData: CellData;
}

export const DisplayOverlaysCellRender: React.FC<DisplayOverlaysCellProps> = ({ cellData }) => {
  return <S.DisplayOverlaysCell>{cellData?.value?.length || '0'}</S.DisplayOverlaysCell>;
};
