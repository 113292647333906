import { isDataCell, isTotalCell } from './mapSchemaToFields';

export const createExportObject = (pivotGrid: any, worksheet: any) => {
  return {
    component: pivotGrid?.instance,
    worksheet: worksheet,
    exportDataFieldHeaders: true,
    exportColumnFieldHeaders: true,
    exportRowFieldHeaders: true,
    exportFilterFieldHeaders: true,
    encodeExecutableContent: true, // ⚠️ Pen Test Issue Vulnerability ID 1000128532 - Formula Injection
    customizeCell: async (options: any) => {
      const { pivotCell, excelCell } = options;
      if (isDataCell(pivotCell) || isTotalCell(pivotCell)) {
        const formattedValue = pivotCell?.text;
        excelCell.value = formattedValue;
      }
    },
  };
};
