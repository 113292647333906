import { Button, IconButton, Select, Toolbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import { ArrowDropDown, HelpOutline } from '@mui/icons-material';
import axios from 'axios';
import React from 'react';
import DividerIcon from 'src/icons/DividerIcon';
import { Route } from 'src/page/models/route';
import styled from 'styled-components';
import AppMenu, { AppLink, AppNames } from './components/AppMenu/AppMenu';
import NavigationBarMenu, {
  MenuItemStyledRoutes,
  MenuItemText,
  TopRightMenuItemProps,
} from './components/NavigationBarMenu/NavigationBarMenu';
import DefaultConfirmDialog from '../withConfirmationDialogOnClick/DefaultConfirmDialog';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';
import { SearchIcon } from 'src/Search/Search.style';
import DOMPurify from 'dompurify';

interface NavigationBarProps {
  routes: Route;
  classes?: any;
  appMenuValue: any;
  enabledApps: AppNames[];
  appMenuLinks?: AppLink[];
  notifications?: number;
  misc?: any;
  topRightMenuItems?: TopRightMenuItemProps[];
  onLogin?: () => void;
  onLogout?: () => void;
  onRouteChange?: (path: string) => void;
  isLoggedIn: boolean | undefined;
  topRightButtonText: string;
  style?: any;
  path: string;
  navigationBarToolbarDataTestId?: string;
  navigationBarSelectMenuDataTestId?: string;
  navigationBarMenuItemDataTestId?: string;
  isHelpVisible?: boolean;
  helpURL?: string;
  activeViewUri?: string;
  currentPath?: string;
  isInspectorFormDirty?: boolean;
  extraIconButtons?: any;
}

const helpLink = `Virtus/Buy_Side_Portfolio_Manager_Documentation/Product_Documentation/User_Manual`;
function HelpLogin(helpURL?: string) {
  console.info('Test Components-Glide-CI');
  axios
    .post(`${helpURL}`, {
      timestamp: String(Math.ceil(Date.now() / 1000)),
      path: `${helpLink}/${encodeURIComponent(window.location.pathname.substring(1).split('-').join('_'))}`,
    })
    .then(res => {
      const encodedURI = encodeURI(res.data.login_url);
      const safeURI = DOMPurify.sanitize(encodedURI);
      window.open(safeURI, '_blank', 'rel=noopener noreferrer');
    });
}

const matchAnAlias = (aliases: string[], currentPath: string) =>
  Boolean(aliases.findIndex((alias: string) => alias.substr(1) === currentPath) > -1);

const getActiveSubroutesFromPath = (subroutes: Route[], pathname: string) => {
  const currentRoutePathSplitted = pathname.startsWith('/') ? pathname.substr(1).split('/') : pathname.split('/');
  const deepLevel = currentRoutePathSplitted.length;

  let childSubroutes: Route[] | undefined = subroutes;
  const activeSubroutes: number[] = [];

  for (let i = 0; i < deepLevel; i++) {
    if (childSubroutes) {
      const activeSubroute: number = childSubroutes.findIndex(subroute => {
        if (subroute.path === currentRoutePathSplitted[i]) {
          return true;
        }
        if (subroute.aliases && subroute.aliases.length) {
          return matchAnAlias(subroute.aliases, currentRoutePathSplitted[i]);
        }
        return false;
      });
      activeSubroutes.push(activeSubroute);
      childSubroutes = childSubroutes[activeSubroute] ? childSubroutes[activeSubroute].subroutes : undefined;
    }
  }
  return activeSubroutes;
};

const getDefaultRoutes = ({ routes, path }: NavigationBarProps) => {
  if (routes && routes.subroutes) {
    const routeIndexes: number[] = getActiveSubroutesFromPath(routes.subroutes, path);
    const defaultRoutes = [routes];
    routeIndexes.forEach((routeIndex: number, index: number) => {
      defaultRoutes[index].active = routeIndex;
      defaultRoutes.push(defaultRoutes[index].subroutes![routeIndex]);
    });
    return defaultRoutes;
  }
  return [];
};

export class NavigationBar extends React.Component<NavigationBarProps> {
  state = {
    selectedRoutes: getDefaultRoutes(this.props),
    displayConfirmationDialog: false,
    data: undefined,
  };

  onCancelHandler = () => {
    this.setState({ displayConfirmationDialog: false });
  };

  onAcceptHandler = (data: any) => {
    this.setState({ displayConfirmationDialog: false });

    let { event } = data;
    if (!event) {
      event = (this.state.data as any).event;
    }

    if (this.props.onRouteChange) {
      this.props.onRouteChange(event.target.value);
    }
  };

  handleSelectRoute = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ data: { event } });
    if (this.props.isInspectorFormDirty) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ displayConfirmationDialog: true });
    } else {
      this.onAcceptHandler({ event });
    }
  };

  componentDidUpdate(prevProps: NavigationBarProps) {
    if (this.props.path !== prevProps.path) {
      this.setState({ selectedRoutes: getDefaultRoutes(this.props) });
    }
  }

  getDropdownVal = (selectedRoute: any) => {
    if (selectedRoute.active !== undefined && selectedRoute.active > -1) {
      return selectedRoute.subroutes[selectedRoute.active].uri;
    } else if (
      this.props.activeViewUri &&
      selectedRoute?.subroutes.filter((item: any) => item.uri === this.props.activeViewUri).length
    ) {
      return this.props.activeViewUri;
    } else return null;
  };

  renderRoutes = (classes: any, navigationBarSelectMenuDataTestId: string, navigationBarMenuItemDataTestId: string) =>
    this.state.selectedRoutes.map(
      (selectedRoute: Route, index: number) =>
        selectedRoute &&
        selectedRoute.subroutes && (
          <RouteSection key={selectedRoute.name}>
            {index == 1 &&
              React.createElement(DividerIcon, { style: { fontSize: 'var(--app-bar-divider-icon-font-size)' } })}

            <SelectStyled
              data-testid={navigationBarSelectMenuDataTestId}
              value={this.getDropdownVal(selectedRoute) || ''}
              IconComponent={ArrowDropDownStyled}
              disableUnderline={true}
              onChange={(event: any) => this.handleSelectRoute(event)}
              // classes={{ selectMenu: classes?.selectMenu as Partial<SelectClasses> }}
              MenuProps={{
                classes: { paper: classes?.dropdownStyle },
              }}
              variant="standard"
            >
              {selectedRoute.subroutes.map(route =>
                this.renderRouteOption(route, { selected: classes?.selected }, navigationBarMenuItemDataTestId),
              )}
            </SelectStyled>
          </RouteSection>
        ),
    );

  renderRouteOption = (route: Route, classes: any, navigationBarMenuItemDataTestId: string) => (
    <MenuItemStyledRoutes
      key={route.name}
      value={route.uri}
      classes={classes}
      width="auto"
      data-testid={navigationBarMenuItemDataTestId}
    >
      <MenuItemText>
        {route.icon &&
          route.icon.component &&
          route.icon.enableInTopBar &&
          React.createElement(route.icon.component, route.icon.topBarProps)}
        {route.name}
      </MenuItemText>
    </MenuItemStyledRoutes>
  );

  renderNotifications = (notifications: number | undefined) => (
    <NotificationBadge>{notifications || 0}</NotificationBadge>
  );

  render() {
    const {
      classes,
      isLoggedIn,
      onLogin,
      notifications,
      topRightButtonText,
      topRightMenuItems,
      misc,
      appMenuValue,
      enabledApps,
      appMenuLinks,
      routes,
      navigationBarToolbarDataTestId = 'navigation-bar-toolbar',
      navigationBarSelectMenuDataTestId = 'navigation-bar-select-menu',
      navigationBarMenuItemDataTestId = 'navigation-bar-select-menu-item',
      isHelpVisible,
      helpURL,
      extraIconButtons,
    } = this.props;

    return (
      <>
        <DefaultConfirmDialog
          show={this.state.displayConfirmationDialog}
          showCloseButton={true}
          onAccept={this.onAcceptHandler}
          onCancel={this.onCancelHandler}
          {...CONFIRMATION_DIALOG}
        />
        <NavigationBarStyled style={this.props.style}>
          <ToolbarStyled>
            <BrandContainer>
              {routes && routes.mainLogo && React.createElement(routes.mainLogo.component, routes.mainLogo.props)}
              <VerticalSeparator></VerticalSeparator>
              <StyledProductName id="product-Name">Buy Side Portfolio Manager</StyledProductName>
            </BrandContainer>
            <ToolbarContent data-testid={navigationBarToolbarDataTestId}>
              <RouteSelection>
                {isLoggedIn &&
                  routes &&
                  this.renderRoutes(classes, navigationBarSelectMenuDataTestId, navigationBarMenuItemDataTestId)}
              </RouteSelection>
              <FlexRowVerticalCenter>
                <HelpContainer>
                  {isLoggedIn && isHelpVisible && (
                    <Tooltip key="help-icon-navigation-bar" title="Help">
                      <HelpIcon data-testid="help-icon-navigation-bar" onClick={() => HelpLogin(helpURL)} />
                    </Tooltip>
                  )}
                </HelpContainer>
                <MiscContainer>
                  {isLoggedIn && misc}
                  {extraIconButtons &&
                    extraIconButtons.map((iconButton: any) => (
                      <Tooltip key={iconButton.testId} title={iconButton.tooltip}>
                        <SearchIconContainer>
                          <SearchButton data-testid={iconButton.testId} onClick={iconButton.onClick}>
                            <SearchIconStyled>
                              <img src={iconButton.Icon} />
                            </SearchIconStyled>
                          </SearchButton>
                        </SearchIconContainer>
                      </Tooltip>
                    ))}
                  {isLoggedIn && <AppMenu enabledApps={enabledApps} value={appMenuValue} appMenuLinks={appMenuLinks} />}
                </MiscContainer>
                {isLoggedIn ? (
                  <>
                    {notifications && this.renderNotifications(notifications)}

                    {topRightMenuItems && (
                      <NavigationBarMenu
                        buttonText={topRightButtonText}
                        topRightMenuItems={topRightMenuItems}
                        buttonIcon={ArrowDropDown}
                        isInspectorFormDirty={this.props.isInspectorFormDirty}
                      />
                    )}
                  </>
                ) : (
                  <Button color="inherit" onClick={onLogin} data-testid="Header-login-btn">
                    Login
                  </Button>
                )}
              </FlexRowVerticalCenter>
            </ToolbarContent>
          </ToolbarStyled>
        </NavigationBarStyled>
      </>
    );
  }
}

const styles = {
  selected: {
    backgroundColor: 'var(--background-app-bar-dropdown-items-hover) !important',
  },
  dropdownStyle: {
    marginTop: 10,
    borderRadius: '0px',
    maxWidth: '400px',
    left: 247,
  },
  selectMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    paddingRight: '18px',
  },
};

export default withStyles(NavigationBar, styles);

const SelectStyled = styled(Select)`
  border-radius: 12px;
  font-size: var(--app-bar-items-font-size);
  font-weight: bold;
  background-color: var(--background-app-bar-items);
  color: var(--foreground-text);
  height: 24px;
  min-width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RouteSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ArrowDropDownStyled = styled(ArrowDropDown)`
  color: var(--foreground-text);
  font-size: 22px;
  right: 5px;
`;

const NavigationBarStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ToolbarStyled = styled(Toolbar)`
  width: 100%;
  padding: 0px;
  min-height: 45px;
`;

const FlexRowVerticalCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RouteSelection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
`;

const ToolbarContent = styled.div`
  width: 73%;
  display: flex;
  justify-content: space-between;
`;

const NotificationBadge = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: center;
`;

const HelpContainer = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  border-radius: 50%;
  width: 28px;
  justify-content: center;
  &:hover {
    background-color: var(--background-apps-icon-hover);
  }
`;

const MiscContainer = styled.div`
  display: flex;
  margin-right: 12px;
  align-items: center;
`;

const HelpIcon = styled(HelpOutline)`
  color: var(--foreground-text);
  font-size: var(--app-bar-help-icon-font-size);
  cursor: pointer;
  position: relative;
`;

const SearchIconStyled = styled(SearchIcon)`
  color: var(--foreground-search-icon);
  width: 20px;
  height: 20px;
  opacity: 1;
  right: 0px;
  top: 0px;
  margin-top: 0px;
`;

const SearchButton = styled(IconButton)`
  width: 22px;
  height: 22px;
  margin-top: 2px;
`;

const SearchIconContainer = styled.div`
  position: relative;
  left: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: var(--foreground-text);
    background-color: var(--background-apps-icon-hover);
    border-radius: 50%;
  }
`;

const VerticalSeparator = styled.div`
  border-left: var(--foreground-vertical-separator-border);
  height: 23px;
  opacity: 80%;
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledProductName = styled.div`
  color: var(--foreground-text);
  font-size: var(--product-name-font-size);
  letter-spacing: 0px;
  opacity: 1;
  height: 27px;
  position: relative;
  top: 1px;
  font-weight: bold;
  padding-right: 0px !important;
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  width: 27%;
`;
