import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';
import Search from '@mui/icons-material/Search';
import { Button } from '@mui/material';

export const ListContainer = styled.div`
  overflow-y: auto;
  padding-left: 12px;
  height: calc(100% - 82px);
`;

export const StaticHeader = styled.div`
  border-bottom: var(--foreground-form-border);
  height: 45px;
  background: var(--background-dx-pivotgrid-header);
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  width: 20px;
  color: var(--foreground-form);
  right: 30px;
  margin-top: 1px;
  height: 20px;
`;

export const LeftContainer = styled.div`
  width: 9px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 10px 0;
  width: 432px;
  position: relative;
  background: var(--background-form);
  border-left: var(--foreground-form-border);
`;

export const Root = styled.div`
  background: 0% 0% no-repeat padding-box padding-box rgb(238, 238, 238);
  border: var(--foreground-form-border);
  width: 441px;
  display: flex;
  flex-direction: row;
  /* overflow: hidden auto; */
`;

export const Row = styled.div`
  display: flex;
  height: 23px;
  margin-top: 10px;
  margin-left: 12px;
  margin-bottom: 10px;
  align-items: center;
`;

export const Input = styled(InputBase)<{ margin: string; label: string } & any>`
  color: var(--foreground-form);
  width: 100%;
  height: 23px;
  padding-left: 8px;
  border: var(--foreground-form-border);
  border-radius: 3px;
  font-size: 14px;
  background-color: var(--foreground-text);
  &.Mui-focused {
    border: var(--foreground-form-input-focus-border);
  }
  input {
    padding: 0px;
    height: 23px;
    ::placeholder {
      color: var(--foreground-form);
      font-size: var(--placeholder-font-size);
      text-align: left;
      letter-spacing: 0px;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
  border-bottom: var(--foreground-form-border);
  height: 37px;
`;

export const Tab = styled.div`
  color: var(--foreground-form-link);
  font-size: var(--tab-font-size);
  margin-right: 20px;
  cursor: pointer;
  transition: 0.1s all;
  letter-spacing: 0px;
  padding-bottom: 3px;

  &:hover {
    box-shadow: var(--tab-hover-box-shadow);
  }
  &:first-child {
    margin-left: 12px;
  }
  &.selected {
    border-bottom: var(--foreground-tab-active-border-bottom);
    font-weight: bold;
    color: var(--foreground-form);
  }
  &.disabled {
    color: var(--foreground-tab-disabled);
    cursor: default;
    &:hover {
      box-shadow: none;
    }
  }
`;
export const IconButton = styled(Button)`
  padding: 0;
  border-radius: 50%;
  min-height: 22px;
`;
export const IconBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 34px;
  position: absolute;
  right: 0px;
  top: 2px;
  & :hover {
    background: var(--background-sub-tab-active-hover);
    border-radius: 50%;
  }
`;
