import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authActions, GlideConfig, IDPConfig } from 'src/auth';
import { authSelectors, GlideSession } from 'src/auth/reducer';
import { getType } from 'typesafe-actions';
import jwt_decode from 'jwt-decode';
import { PayloadAction } from 'typesafe-actions/dist/types';
import getGlideAPIClient from 'src/core-services/GlideAPIClient';
import { logoutRedirect } from '../idpWrapper';

type initializeGlideAuthInterface = {
  token?: string;
  username?: string;
  glidePassword?: string;
};

export const glideAuthRequest = ({
  baseURL,
  token,
  username,
}: initializeGlideAuthInterface & GlideConfig): Promise<any> => {
  const authUrl = `${baseURL}/glide/authenticate`;
  // Keep Axios here as fetch is not supported by Electron (Cypress)!

  const clientEnv = localStorage.getItem('client_env');
  const config: AxiosRequestConfig = {
    url: authUrl,
    method: 'GET',
    headers: {},
    params: {
      user_name: username,
      b2c_token: token,
      use_header_authorization: true,
    },
  };

  if (clientEnv !== null) {
    config.headers['Client-Env'] = clientEnv;
  }

  return axios({
    ...config,
  })
    .then((response: AxiosResponse) => ({
      response: {
        headers: response.headers,
        body: response.data,
      },
    }))
    .catch((error: AxiosError) => ({
      error,
    }));
};

function* glideLogin(action: PayloadAction<string, any>): any {
  const code = yield select(authSelectors.getCode);
  const glideConfig = action.payload.glide;
  const idpConfig = action.payload.idp;
  const { error, response } = yield call(issueAccessToken, false, code, glideConfig, idpConfig);
  if (response) {
    yield setGlideSession(response.body, glideConfig.baseURL, idpConfig.clientEnvironment);
  } else if (error) {
    const payload: any = {
      statusCode: error?.response?.status,
      statusMessage: error?.response?.data?.error_message ?? 'Glide authentication error',
    };
    yield put(authActions.glideLoginError(payload));
  }
}

function* refreshSession(action: PayloadAction<string, any>): any {
  const token = yield select(authSelectors.getToken);
  const refreshToken = yield select(authSelectors.getRefreshToken);
  const glideConfig = action.payload.glide;
  const idpConfig = action.payload.idp;
  const logOutResponse = yield call(logout, token, glideConfig, idpConfig);
  if (logOutResponse) {
    const { error, response } = yield call(issueAccessToken, true, refreshToken, glideConfig, idpConfig);
    if (response) {
      yield setGlideSession(response.body, glideConfig.baseURL, idpConfig.clientEnvironment);
    } else if (error) {
      const payload: any = {
        statusCode: error?.response?.status,
        statusMessage: error?.response?.data?.error_message ?? 'Glide authentication error',
      };
      yield put(authActions.glideLoginError(payload));
    }
  }
}

function* setGlideSession(session: any, glideBaseURL: string, clientEnvironment: string) {
  getGlideAPIClient(glideBaseURL).setAuthHeaders(session, clientEnvironment);
  localStorage.setItem('Client_Env', clientEnvironment);
  yield put(authActions.glideLoginSuccess(prepareGlideSession(session, clientEnvironment)));
}

const prepareGlideSession = (session: any, clientEnvironment: string) => {
  const idToken: any = jwt_decode(session.idToken);
  const glideSession: GlideSession = {
    token: session.accessToken,
    environment: clientEnvironment,
    expiresIn: session.expiresIn,
    user: `{
          "data": {"display_name":"${idToken.sub}",
          "roles": ${JSON.stringify(session.roles)}}
        }`,
    username: idToken.sub?.toLowerCase(),
    refreshToken: session.refreshToken,
  };
  return glideSession;
};

// Returns the access token based on code
const issueAccessToken = (isRefreshSession: boolean, code: string, glideConfig: GlideConfig, idpConfig: IDPConfig) => {
  const url = `${glideConfig.baseURL}/glide/authenticate`;
  const tokenRequest: Record<string, string> = {
    method: 'weblogin',
    serviceProviderId: idpConfig.serviceProviderId,
  };

  if (isRefreshSession) {
    tokenRequest.refreshToken = code;
  } else {
    tokenRequest.deviceCode = code;
  }

  return axios
    .post(url, tokenRequest, { headers: { 'Content-Type': 'text/plain', 'Client-Env': idpConfig.clientEnvironment } })
    .then((response: AxiosResponse) => ({
      response: {
        headers: response.headers,
        body: response.data,
      },
    }))
    .catch((error: AxiosError) => ({
      error,
    }));
};

function* glideLogout(action: PayloadAction<string, any>): any {
  const token = yield select(authSelectors.getToken);
  const glideConfig = action.payload.glide;
  const idpConfig = action.payload.idp;
  const response = yield call(logout, token, glideConfig, idpConfig);
  if (response) {
    yield logoutRedirect();
  }
}

function logout(token: any, glideConfig: GlideConfig, idpConfig: IDPConfig) {
  return axios
    .post(
      `${glideConfig.baseURL}/glide/logout`,
      {
        method: 'logout',
        authentication: token,
      },
      {
        headers: {
          'Content-Type': 'text/plain',
          'Client-Env': idpConfig.clientEnvironment,
        },
      },
    )
    .then(res => {
      return res.data;
    });
}

function* watchGlideLogin() {
  yield takeLatest(getType(authActions.glideLogin), glideLogin);
}

function* watchGlideLogout() {
  yield takeLatest(getType(authActions.glideLogout), glideLogout);
}

function* watchGlideRefreshToken() {
  yield takeLatest(getType(authActions.refreshToken), refreshSession);
}

export default [watchGlideLogin, watchGlideLogout, watchGlideRefreshToken];
