import React, { useEffect, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { MenuItem } from './Select.model';
import {
  MenuItemStyled,
  MenuItemTextStyled,
  SelectStyled,
  CheckboxStyled,
  MenuItemForSearch,
  StyledSearchIcon,
} from './Select.style';
import { StyledDoneIcon } from 'src/components/forms/form-elements/FormElements.style';
import { CheckboxFilledIcon, CheckboxUncheckedIcon } from '@virtus/components/icons/CheckboxIcons';
import { SearchInputStyle } from '../../../SearchInput';
import { useDebounce } from '@virtus/components/hooks/useDebounce';

type SelectType = {
  disabled?: boolean;
  dataTestId?: string;
  menuItems: MenuItem[];
  onChange: (e: any) => void;
  classes?: any;
  value?: string | string[];
  error?: boolean;
  style?: any;
  open?: boolean;
  editIsEnabled?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  multiple?: boolean;
  renderValues?: (data: any) => string;
  is_search_enable?: boolean;
};

const Select = ({
  disabled,
  menuItems,
  onChange,
  value,
  classes: { selectMenu, icon, dropdownStyle },
  error,
  style,
  dataTestId,
  open,
  onClose,
  onOpen,
  multiple,
  renderValues,
  editIsEnabled,
  is_search_enable,
}: SelectType) => {
  const [isOpen, setOpen] = useState(open || false);
  const [hasFocus, setFocus] = useState(false);
  const [hasSearchFocus, setSearchFocus] = useState(false);
  const [isDropdownExpanded, setDropdownExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<MenuItem[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== value && !disabled) {
      const search_value = menuItems.filter(item =>
        item.name.toLocaleLowerCase().includes(debouncedSearchTerm.toLocaleLowerCase()),
      );
      setSearchResults(search_value.sort((a, b) => a.name.localeCompare(b.name)));
    } else {
      setSearchResults(menuItems.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [debouncedSearchTerm]);

  const isDoneIconVisible = (value: any, item: any) => {
    return !multiple && !Array.isArray(value) && value?.toLowerCase() == item.value.toLowerCase() && isDropdownExpanded;
  };
  const onCloseHandler = () => {
    setOpen(false);
    setDropdownExpanded(false);
    onClose && onClose();
  };

  const onOpenHandler = () => {
    setOpen(true);
    setDropdownExpanded(true);
    onOpen && onOpen();
  };

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSearchTerm(e.target.value);
  };

  return (
    <SelectStyled
      open={isOpen}
      data-testid={dataTestId || 'select'}
      disabled={disabled}
      value={value}
      disableUnderline
      onChange={onChange}
      onClose={onCloseHandler}
      onOpen={onOpenHandler}
      error={error}
      classes={{ selectMenu }}
      IconComponent={disabled ? () => null : undefined}
      inputProps={{ classes: { icon } }}
      MenuProps={{ classes: { paper: dropdownStyle } }}
      style={style}
      multiple={multiple}
      renderValue={renderValues}
      $editIsEnabled={editIsEnabled}
      $hasFocus={hasFocus}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      variant="standard"
    >
      {is_search_enable && (
        <MenuItemForSearch value="" onClick={onOpenHandler} disableRipple={true} id="search-input">
          <SearchInputStyle.SearchContainer
            disabled={disabled}
            $editIsEnabled={editIsEnabled}
            $hasFocus={hasSearchFocus}
            is_search_enable={is_search_enable}
          >
            <SearchInputStyle.Search
              id="search-input-field"
              data-testid="select_search"
              autoComplete="off"
              placeholder="Search"
              value={searchTerm}
              onKeyDown={(e: React.KeyboardEvent) => {
                e.stopPropagation();
              }}
              onChange={searchChangeHandler}
              // hasFocus={hasSearchFocus}
              onFocus={(e: React.FocusEvent) => {
                e.stopPropagation();
                setSearchFocus(true);
              }}
              onBlur={(e: React.FocusEvent) => {
                e.stopPropagation();
                setSearchFocus(false);
              }}
            />
            {debouncedSearchTerm.length === 0 && <StyledSearchIcon />}
          </SearchInputStyle.SearchContainer>
        </MenuItemForSearch>
      )}
      {searchResults.map((item: MenuItem) => (
        <MenuItemStyled key={item.value} value={multiple ? item.name : item.value}>
          {multiple && (
            <CheckboxStyled
              checked={Array.isArray(value) && value?.includes(item.name)}
              checkedIcon={<CheckboxFilledIcon disabled={false} />}
              icon={<CheckboxUncheckedIcon disabled={false} />}
            />
          )}
          <MenuItemTextStyled disabled={disabled}>{item.name}</MenuItemTextStyled>
          {isDoneIconVisible(value, item) && <StyledDoneIcon style={{ display: 'none' }}></StyledDoneIcon>}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  );
};

const styles = {
  disabled: {
    color: 'var(--accent-grey)',
  },
  dropdownStyle: {
    marginTop: 0,
    borderRadius: '0px',
    backgroundColor: 'var(--background-form-dropdown-items)',
    maxWidth: '400px',
    color: 'var(--foreground-form)',
    border: 'var(--foreground-form-dropdown-boder)',
    '& ul li svg': {
      display: 'inline-block !important',
    },
    '& ul li div': {
      padding: '0px',
      height: '26px',
    },
    '& ul li:last-child': {
      paddingBottom: '10px',
      height: '16px',
      boxSizing: 'content-box',
    },
  },
  icon: {
    color: 'var(--foreground-form)',
  },
  selectMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    paddingRight: '18px',
    '&:hover': {
      borderBottom: '0px',
    },
  },
};

export default withStyles(Select, styles);
