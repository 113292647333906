import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { CheckboxWrapper, StyledDiv } from '../CheckboxInput/CheckboxInput.style';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { StyledRadio, StyledRadioSpan } from './RadioGroupInput.style';

export const RadioGroupInput = ({ field, value, onChange, error, RadioButtons }: FormElementInputComponent) => {
  const isDisabled = field.disabled;
  const isRequired = field.required;
  const isReadonly = field.readonly;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={isDisabled}
      required={isRequired}
      readonly={isReadonly}
      disableErrorWarning={field.disableErrorWarning}
    >
      <CheckboxWrapper>
        <StyledDiv>
          <RadioGroup aria-label={field.name} name={field.name} value={value} onChange={onChange}>
            {RadioButtons?.map(item => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={
                  <StyledRadio
                    checked={item.value === value}
                    disableRipple={true}
                    required={isRequired}
                    // onError={getBooleanValue(error)}
                  />
                }
                label={<StyledRadioSpan item={item}>{item.display_name}</StyledRadioSpan>}
                disabled={item.disabled}
                style={item.hasOwnProperty('visible') && !item?.visible ? { display: 'none' } : {}}
              />
            ))}
          </RadioGroup>
        </StyledDiv>
      </CheckboxWrapper>
    </FormErrorWrapper>
  );
};
