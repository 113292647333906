import styled, { css } from 'styled-components';

export const fullSize = css`
  width: 100%;
  height: 100%;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  ${fullSize}
  background: var(--background-glide-commentary-list-item);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  ${fullSize}
  /* ${flexColumn} */
  justify-content: flex-end;
  align-items: center;
  background-color: var(--bgInspectorDefaultInspector);
  overflow: hidden;
  border: var(--foreground-dx-popup-border);
`;

export const Footer = styled.div`
  bottom: 0;
  height: 57px;
  width: 100%;
  background: var(--background-form-footer);
  padding: 0 20px;
  border-top: var(--foreground-form-border);
`;
