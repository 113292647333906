import DataGrid from 'devextreme-react/data-grid';
import styled from 'styled-components';

/**
 * !!STYLES HERE AFFECT GRIDS IN ALL APPS!!
 * Do not change unless you want to set new styles for all apps
 * it requires approval from all leads (dev, product, design) from Nexus/Glide/Genesis.
 * Please discuss on #dx-datagrid first
 *
 * If you want to override grid styling for your app. You can include a css in your App.ts
 * ie: glide/src/app/App/App.css
 */
export const StyledDxDataGrid = styled(DataGrid)<{ borderTopColor?: string }>`
  width: 100%;
  border: none !important;
  table {
    width: 100%;
    z-index: 0;
    position: relative;
  }

  .dxgrid-header {
    display: flex;
    flex-direction: column;
    margin: 5px 0px 5px 0px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .dxgrid-header > .dxgrid-header-title {
    font-size: var(--headerTitleFontSize);
    font-weight: bold;
  }
  .dxgrid-header > .dxgrid-header-description {
    margin-top: -2px;
  }

  .dx-datagrid {
    z-index: 0;
  }

  .dx-header-row td {
    text-align: left;
    .dx-column-indicators {
      float: right;
    }
  }

  .dx-button-mode-contained {
    border: none;
  }

  .dx-datagrid-header-panel .dx-toolbar {
    margin-bottom: 0;
    padding: 0 10px;
    overflow-y: hidden;
  }

  .dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
    white-space: normal;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row > td,
  .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    vertical-align: middle;
  }

  .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
    display: inline-block !important;
  }

  .dx-datagrid-search-panel {
    height: 28px;
    display: flex;
    align-items: center;
  }

  .dx-dropdowneditor-button {
    min-width: 0;
  }

  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines):not(.dx-row-focused) > td,
  .dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines):not(.dx-row-focused) > td {
    border-bottom: 1px solid var(--dataGridInnerBorder);
    border-top: 1px solid var(--dataGridInnerBorder);
  }

  /* This style is related to layouts dropdown component in DXGrid header*/
  .dx-toolbar-label > div,
  .dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item {
    height: 28px;
  }

  .dx-toolbar .dx-toolbar-items-container {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dx-toolbar-before,
  .dx-toolbar-after {
    position: absolute;
  }
`;
