import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

export const DrawerContainer = styled(Drawer)<{ style: object } & any>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
`;

export const DrawerContent = styled.div<{ width: string }>`
  display: flex;
  font-size: var(--inspector-drawer-font-size);
  flex-direction: column;
  background-color: var(--background-search-inspector);
  width: ${props => props.width};
  height: 100%;
  border-left: none;
  box-shadow: 0 8px 10px -5px var(--drawerContentX), 0 16px 24px 2px var(--drawerContentY),
    0 6px 30px 5px var(--drawerContentZ);
  overflow: hidden;
  border-left: var(--foreground-form-border);
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (props?.style?.height ? props.style.height : 'calc(100vh - 40px)')};
  background-color: var(--background-search-inspector);
`;

export const PaperStyled = styled(Paper)<{ paperStyles?: CSSStyleDeclaration }>`
  border-left: none;
  margin-top: 40px;
  z-index: 0;
  border-radius: 0;
  box-shadow: none;
`;

export const Container = styled.div`
  color: var(--foreground-text);
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--inspector-content-font-size);
  height: 100%;
  width: 424px;
  background: var(--background-form);
  border-left: var(--foreground-form-border);
`;

export const Field = styled.h5`
  display: flex;
  flex-direction: row;
  color: var(--foreground-text);
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: var(--foreground-form-row-border);
  height: 30px;
  margin: 0px 10px 0px 5px;
`;

export const HeaderLabel = styled.span`
  color: var(--foreground-form-header);
  width: 45%;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: var(--form-label-font-size);
  margin: 0px 5px 0px 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeaderValue = styled.span`
  color: var(--foreground-form-header);
  padding: 5px;
  width: 50%;
  font-size: var(--form-input-font-size);
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
