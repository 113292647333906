import React from 'react';

const DeleteIcon: React.SFC<any> = () => {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect fill="#fff" style={{ opacity: 0, strokeWidth: '0px' }} width="19" height="19" />
        <path
          fill="#285bc5"
          style={{ strokeWidth: '0px' }}
          d="M15.99,7.25h-1.13v10.52H4.14V7.25h-1.13v-3.7h3.72V1.23h5.55v2.32h3.72v3.7ZM14.88,4.7H4.12v1.45h10.75v-1.45ZM13.74,7.25H5.26v9.41h8.48V7.25ZM6.74,14.91h.99v-6h-.99v6ZM7.72,3.55h3.56v-1.34h-3.56v1.34ZM9.03,8.91v6h.99v-6h-.99ZM11.26,14.91h.99v-6h-.99v6Z"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
