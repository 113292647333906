import styled from 'styled-components';
import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';
import { SummaryPanelComponent } from '../summary-panel.style';

export const DateRadioGroupWrapper = styled(SummaryPanelComponent)`
  justify-content: center;
`;

export const DateFilterSection = styled(SummaryPanelComponent)`
  label {
    margin-right: 20px;
  }
`;

export const DateFilterIcon = styled.i`
  cursor: pointer;
  margin-right: 15px;
`;

export const DateRangeWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  div.dx-daterangebox {
    display: flex;
    align-items: center;
  }
`;

export const LoadDataButton = styled(BasicButton)`
  background-color: var(--background-layout-create-button);
  color: var(--white);
  margin-top: 25px;
  &:hover {
    background-color: var(--background-layout-create-button-hover);
  }
  &.Mui-disabled {
    opacity: 0.5;
    background-color: var(--background-layout-create-button);
    color: var(--white);
  }
`;
