import { putResolve, takeLatest } from 'redux-saga/effects';
import { Glide, DisplayViewApi } from 'src/api/queries';
import { requestAsync } from 'redux-query';
import { DISPLAY_VIEW } from 'src/utils/constants';

export enum LegalEntitiesDetailsSagaAction {
  FETCH_LEGAL_ENTITIES_URI = 'FETCH_LEGAL_ENTITIES_URI',
}

export function* fetchIssuerDetails(action: any): any {
  const body = yield putResolve(requestAsync(Glide.get({ uri: action.payload.uri, field: 'issuer' })));
  const legalEntitiesUri = body && body.entities.field_uris['issuer'];
  yield putResolve(
    requestAsync(
      DisplayViewApi.getDisplayViewGroup({
        viewName: DISPLAY_VIEW.DEALS,
        uri: legalEntitiesUri,
      }),
    ),
  );
}

export function* watchFetchIssuerDetails() {
  yield takeLatest(LegalEntitiesDetailsSagaAction.FETCH_LEGAL_ENTITIES_URI, fetchIssuerDetails);
}

export default [watchFetchIssuerDetails];
