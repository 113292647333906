import React from 'react';
import * as S from './compliance-cell-render.style';

interface ComplianceCellRenderProps {
  renderValue: string;
}

export const ComplianceTestResult = ({ renderValue }: ComplianceCellRenderProps) => {
  return <S.ComplianceTestResult>{renderValue}</S.ComplianceTestResult>;
};
