/* eslint-disable max-len */
export const filterIcon = `<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
<defs>
  <style>
    .cls-1 {
      fill: #fff;
      opacity: 0;
    }

    .cls-1, .cls-2 {
      stroke-width: 0px;
    }

    .cls-2 {
      fill: #285bc5;
    }
  </style>
</defs>
<g id="Layer_1-2" data-name="Layer 1">
  <g>
    <rect class="cls-1" width="19" height="19"/>
    <path class="cls-2" d="M17.38,1.41l-5.76,7.34v8.84l-4.23-2.53v-6.31L1.62,1.41h15.76ZM15.03,2.58H3.97l4.32,5.48h2.42l4.32-5.48ZM10.51,15.56v-6.49h-2.03v5.35l2.03,1.14Z"/>
  </g>
</g>
</svg>`;

export const resetIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18.001" viewBox="0 0 18 18.001">
<path id="Path_1569" data-name="Path 1569" d="M1520.979,4067.735a8.98,8.98,0,0,1,7.228,3.647l1.772-1.773v5.25h-5.25l2.2-2.2a7.214,7.214,0,1,0,1.175,5.2h1.805a9,9,0,1,1-8.927-10.124Z" transform="translate(-1511.979 -4067.735)" fill="#285bc5"/>
</svg>
`;

export const auditIcon = `<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
        opacity: 0;
      }

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #285bc5;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <rect class="cls-1" width="19" height="19"/>
      <path class="cls-2" d="M17.71,1.29v16.42H1.29V1.29h16.42ZM16.57,2.42H2.42v14.17h14.15V2.42ZM7,10.57c-.49,0-.96-.09-1.41-.28-.45-.2-.83-.46-1.16-.79-.33-.33-.59-.71-.77-1.16-.2-.43-.3-.9-.3-1.41s.1-.98.3-1.42c.19-.45.45-.83.77-1.16.33-.33.71-.59,1.16-.77.45-.19.91-.28,1.41-.28s.98.09,1.42.28c.45.19.83.45,1.16.77.33.33.59.71.77,1.16.2.45.3.92.3,1.42s-.1.97-.3,1.41c-.19.45-.45.83-.77,1.16-.33.33-.71.59-1.16.79-.45.19-.92.28-1.42.28ZM7,4.3c-.73,0-1.35.26-1.86.77-.52.52-.77,1.14-.77,1.86s.26,1.35.77,1.86c.52.52,1.14.77,1.86.77s1.37-.26,1.88-.77c.52-.52.77-1.14.77-1.86-.01-.36-.08-.71-.22-1.03-.13-.32-.32-.6-.55-.83l-1.88,1.86v-2.64ZM8.99,15.71h1v-4.41h-1v4.41ZM10.89,15.69h1v-5.29h-1v5.29ZM12.79,9.39v6.29h1v-6.29h-1ZM14.69,15.71h1v-7.42h-1v7.42Z"/>
    </g>
  </g>
</svg>`;

export const exportIcon = encodeURIComponent(`<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30'>
<g fill='none' fill-rule='evenodd'>
    <path d='M0 0h24v24H0z'/>
    <path fill='#000' d='M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/>
</g>
</svg>`);

export const columnManagementIcon = `<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
<defs>
  <style>
    .cls-1 {
      fill: #fff;
      opacity: 0;
    }

    .cls-1, .cls-2 {
      stroke-width: 0px;
    }

    .cls-2 {
      fill: #285bc5;
    }
  </style>
</defs>
<g id="Layer_1-2" data-name="Layer 1">
  <g>
    <rect class="cls-1" width="19" height="19"/>
    <path class="cls-2" d="M11.93,18h-4.89l-.28-2.58c-.11-.05-.21-.09-.3-.14-.1-.05-.18-.09-.27-.14l.04.02-.48-.28-2.4,1L.9,11.67l2.08-1.62v-1.07l-2.08-1.65L3.34,3.13l2.44,1c.06-.05.13-.09.2-.14.08-.05.15-.09.22-.12l.02-.02c.08-.05.17-.09.25-.13.08-.04.17-.09.25-.13l.32-2.58h4.85l.34,2.56c.09.05.19.1.28.14.09.05.17.1.26.16.09.05.18.09.25.13.07.04.14.09.21.13l2.4-1,2.45,4.21-2.12,1.6v1.05l2.13,1.67-2.54,4.2-2.38-1c-.07.05-.15.1-.22.14-.08.05-.16.09-.24.12-.07.05-.15.1-.23.14-.08.05-.17.09-.25.12l-.3,2.6ZM8.05,16.88h2.88l.28-2.33.3-.11c.13-.05.25-.1.36-.15.11-.05.21-.12.32-.19l-.02.02c.11-.06.21-.12.31-.2.1-.07.19-.14.28-.21l.27-.16,2.08.89,1.46-2.49-1.78-1.44v-2.08l.23-.2,1.55-1.19-1.4-2.49-2.12.89-.27-.21c-.08-.07-.17-.14-.27-.2-.1-.07-.2-.13-.3-.19h-.02c-.1-.06-.2-.12-.31-.17-.11-.05-.22-.1-.33-.15h-.04l-.3-.14-.09-.34-.25-1.92h-2.83l-.28,2.22-.3.2c-.12.05-.23.1-.34.14-.11.05-.21.1-.3.16h.02c-.11.06-.21.12-.31.2-.1.07-.2.14-.29.21l.02-.02-.3.21-2.13-.89-1.4,2.49,1.76,1.44v1.76l.07.32-1.83,1.39,1.4,2.49,2.1-.89.27.21c.08.07.17.14.27.2.09.07.2.13.3.19h.02c.09.06.2.12.3.17.11.05.22.1.34.15h.04l.32.14.07.36.21,1.9ZM7.22,11.77c-.62-.62-.93-1.38-.93-2.27s.31-1.64.93-2.27,1.38-.93,2.27-.93,1.64.31,2.27.93.93,1.38.93,2.27-.31,1.64-.93,2.27c-.62.62-1.38.93-2.27.93s-1.64-.31-2.27-.93ZM7.94,7.95c-.43.43-.64.94-.64,1.55s.21,1.12.64,1.55c.43.43.94.64,1.55.64s1.12-.21,1.55-.64c.43-.43.64-.94.64-1.55s-.21-1.12-.64-1.55c-.43-.43-.94-.64-1.55-.64s-1.12.21-1.55.64Z"/>
  </g>
</g>
</svg>`;
