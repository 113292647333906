import styled from 'styled-components';
import { zIndex } from 'src/utils/constants';
import Button from '@mui/material/Button';
import React, { FunctionComponent } from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { dispatchActions } from 'src/app/store';
import { RootState } from 'src/reducers';
import { ComponentName, Components, selectComponents } from 'src/reducers/components';
import { activeTabSelector } from 'src/reducers/tabs';
import { connect } from 'react-redux';
import { VIEW_INSPECTOR } from '../inspectors/glide-object-inspector/view-inspector';

export const CollapseWrapper = styled.div<{ isViewInspectorExpanded: boolean }>`
  z-index: ${props => (props.isViewInspectorExpanded ? zIndex.CollapseWrapper : '0')};
  width: ${props => (props.isViewInspectorExpanded ? '100%' : 'var(--width-inspector)')};
  background: var(--background-form-footer);
`;

export const CollapseButtonWrapper = styled.div<{ isExpanded: boolean; isViewInspectorExpanded: boolean }>`
  right: ${props => (props.isExpanded ? '469px' : '1px')};
  display: ${props => (props.isViewInspectorExpanded ? 'none' : 'block')};
  height: 100%;
`;

export const CollapseButton = styled(Button)`
  height: 100%;
  width: 25px;
  min-width: 18px;
  padding: 0px;
  z-index: ${zIndex.CollapseWrapperBtn};
  border-radius: inherit;
  background: var(--background-collapser-toggle);
  border: var(--foreground-collapser-toggle-border);
  opacity: 1;
  &:hover {
    background: var(--background-collapser-toggle);
  }
  & > span.MuiButton-label {
    height: 270px;
    background: var(--background-collapser-toggle-container);
    border: var(--foreground-collapser-toggle-container);
    opacity: 1;
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }
`;

interface ReduxProps {
  clientViewUri: string;
  components: Components;
}

interface CollapserProps {
  componentName: ComponentName;
  children: React.ReactNode;
  isGlobalViewInspector?: boolean;
}

const Collapser: FunctionComponent<CollapserProps & ReduxProps> = ({
  componentName,
  children,
  clientViewUri,
  components,
  isGlobalViewInspector,
}) => {
  const component = !isGlobalViewInspector
    ? components.viewComponents?.[clientViewUri]?.[componentName] || components?.[componentName]
    : components?.global?.[componentName];
  const isCollapsed = component.isCollapsed;
  const isViewInspectorExpanded = component.isExpanded;
  const onTogglePanel = () => {
    if (isGlobalViewInspector)
      dispatchActions.components.update('global', { [VIEW_INSPECTOR]: { isCollapsed: !isCollapsed } });
    else dispatchActions.components.updateView(componentName, clientViewUri, { isCollapsed: !isCollapsed });
  };

  return (
    <>
      <CollapseButtonWrapper isExpanded={!isCollapsed} isViewInspectorExpanded={isViewInspectorExpanded}>
        <CollapseButton onClick={onTogglePanel} disableRipple>
          {/* Adding span to persist the existing style as its removed in mui 5 */}
          <span className="MuiButton-label">
            {isCollapsed ? (
              <ChevronLeft style={{ color: 'var(--foreground-collapser-toggle-container-icon)' }} />
            ) : (
              <ChevronRight style={{ color: 'var(--foreground-collapser-toggle-container-icon)' }} />
            )}
          </span>
        </CollapseButton>
      </CollapseButtonWrapper>
      {!isCollapsed && <CollapseWrapper isViewInspectorExpanded={isViewInspectorExpanded}>{children}</CollapseWrapper>}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: selectComponents(state),
  clientViewUri: activeTabSelector(state),
});

export default connect(mapStateToProps)(Collapser);
