import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1080deg);
  }
`;

export const StyledLoadingIcon = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: var(--text);
  }
  svg {
    animation: ${rotate} 3s infinite ease-out;
  }
`;
