import IconButton from '@mui/material/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import styled from 'styled-components';

export type InspectorNavigationProps = {
  disableUp?: boolean;
  disableDown?: boolean;
  onNavigationUp: () => void;
  onNavigationDown: () => void;
};

const Navigation = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ArrowDown = styled(KeyboardArrowDown)<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? 'rvar(--inspectorSubtitle)' : 'var(--text)')};
`;

const ArrowUp = styled(KeyboardArrowUp)<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? 'var(--inspectorSubtitle)' : 'var(--text)')};
`;

const IconButtonStyled = styled(IconButton)`
  padding: 5px;
  width: 36px;
  height: 34px;
`;

const InspectorNavigation: React.SFC<InspectorNavigationProps> = ({
  disableUp,
  disableDown,
  onNavigationUp,
  onNavigationDown,
}) => {
  return (
    <Navigation>
      <IconButtonStyled onClick={onNavigationUp} disabled={disableUp}>
        <ArrowUp disabled={disableUp} />
      </IconButtonStyled>
      <IconButtonStyled onClick={onNavigationDown} disabled={disableDown}>
        <ArrowDown disabled={disableDown} />
      </IconButtonStyled>
    </Navigation>
  );
};

export default InspectorNavigation;
