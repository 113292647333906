export type MultiplierType = Array<{ [key: string]: string }>;

export const formatMultiplierXMLParser = (aMultiplierXMLString: string): MultiplierType => {
  const parser = new DOMParser();
  const XMLMultiplier = parser.parseFromString(aMultiplierXMLString, 'text/xml');
  if (XMLMultiplier && XMLMultiplier.documentElement) {
    const multipliers = Object.values(XMLMultiplier.documentElement.children);
    const result = Object.values(multipliers).map((multiplier: any) => ({
      key: multiplier.attributes[0].value,
      value: multiplier.attributes[1].value,
    }));
    return result;
  }
  return [];
};
