// import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';
import styled from 'styled-components';
import { SecondaryButton as SB } from '@virtus/components/Buttons';

export interface IOptionalStyles {
  noPadding?: boolean;
  noBackground?: boolean;
}

export const GlideDataContent = styled.div<{ $optionalStyles: IOptionalStyles }>`
  background: ${({ $optionalStyles }) => {
    return $optionalStyles.noBackground ? 'inherit' : 'var(--background-form)';
  }};
  display: flex;
  flex-direction: column;
  color: var(--foreground-form);
  width: 100%;
  padding: ${({ $optionalStyles }) => ($optionalStyles.noPadding ? '0' : '0px 5px 0px 5px')};
  label {
    text-transform: capitalize;
  }
`;

export const CancelButton = styled(SB)<{ backgroundcolor?: string }>`
  background-color: ${props => props?.backgroundcolor || 'var(--error)'};
  &:hover {
    background-color: ${props => props?.backgroundcolor || 'var(--errorLight)'};
  }
`;
