import React from 'react';
import { ModellingIcon } from '@virtus/components/icons';
import styled from 'styled-components';

export const IconButton = styled.button`
  height: 18px;
  width: 18px;
  line-height: 18px;
  background: transparent;
  padding-inline: 0;
  border: none;

  &:disabled svg {
    opacity: 0.6;
  }
`;

export interface ModalActionButton {
  text: string;
  action: (e: any) => void;
  disabled?: boolean;
}
export interface ModellingProps {
  onIconClick: () => void;
  isDisabledIcon?: boolean;
  isActiveIcon?: boolean;
}

const Modelling: React.FunctionComponent<ModellingProps> = ({
  onIconClick,
  isDisabledIcon = false,
  isActiveIcon = false,
}) => {
  const onModellingClick = () => {
    if (isDisabledIcon) return;
    if (onIconClick) {
      onIconClick();
    }
  };

  return (
    <IconButton className={isActiveIcon ? 'icon-active' : ''} disabled={isDisabledIcon}>
      <ModellingIcon onClick={onModellingClick} />
    </IconButton>
  );
};

export default Modelling;
