// eslint-disable (Handler for Glide meant for dev only)
import { IDPConfig } from '@virtus/common/auth';
import { User } from '@virtus/common/auth/msalWrapper';
import { authActions, authSelectors, GlideAuthError, GlideSession, GlideUser } from '@virtus/common/auth/reducer';
import PageError from '@virtus/components/page/page-error';
import React, { createContext, useEffect, SetStateAction, Dispatch as SetDispatch, useState } from 'react';
import { connect } from 'react-redux';
import { GlideAppConfig } from 'src/config';
import IdleTimer from 'react-idle-timer';
import { RootState } from 'src/reducers';
import DefaultConfirmDialog from '@virtus/components/withConfirmationDialogOnClick/DefaultConfirmDialog';
import { IDLE_TIMEOUT_OUT_CONFIGURATION, IDLE_TIME_OUT_DIALOG } from 'src/utils/constants';

interface ReduxProps {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isGlideAuthenticated: boolean;
  user: User | GlideUser | null;
  glideAuthError: GlideAuthError | any;
  readonly glideSession: any;
  isLogout: boolean;
}

interface OwnProps {
  children: (state: AuthenticationHandlerRenderProps) => React.ReactNode;
  config: GlideAppConfig;
  setClientEnv?: SetDispatch<SetStateAction<boolean>>;
}

interface UserDispatch {
  login: (arg?: any) => void;
  glideLogin: (arg: any) => void;
  logout: (arg?: any) => void;
  refreshSession: (arg?: any) => void;
}

interface ReduxDispatch {
  initializeAuth: (arg: IDPConfig) => void;
}

type Dispatch = ReduxDispatch & UserDispatch;

export type AuthenticationHandlerRenderProps = {
  isAuthenticated: boolean;
  isGlideAuthenticated: boolean;
  user: User | GlideUser | null;
  glideSession: GlideSession;
} & UserDispatch;

export type AuthenticationHandlerProps = ReduxProps & Dispatch & OwnProps;

export const AuthContext = createContext({});

let totTimeOut: number;
let timeOut: number;
let notificationTimeOut: number;
let idleStopperRef: any;
let idleStopperWaitRef: any;

const AuthenticationHandlerGlide = ({
  children,
  glideSession,
  isAuthenticated,
  logout,
  initializeAuth,
  login,
  refreshSession,
  glideLogin,
  isInitialized,
  user,
  isGlideAuthenticated,
  config,
  glideAuthError,
  setClientEnv,
  isLogout,
}: AuthenticationHandlerProps) => {
  const logoutChannel = new BroadcastChannel('stayOn');

  logoutChannel.onmessage = (event: MessageEvent) => {
    if (event.data === 'stayOn') {
      continueUsingApp();
      logoutChannel.close();
    }
  };

  totTimeOut = config.idp.refreshTokenInterval * 60 * 1000;
  notificationTimeOut = config.idp.idleNotificationTimeout * 60 * 1000;
  timeOut =
    config.idp.idleTimeout > 0
      ? config.idp.idleTimeout * 60 * 1000 - config.idp.idleNotificationTimeout * 60 * 1000
      : 0;
  useEffect(() => {
    initializeAuth(config.idp);
  }, [config, initializeAuth]);

  useEffect(() => {
    if (!isLogout) {
      if (isInitialized && !user) {
        login(config.idp);
      } else if (user && !isGlideAuthenticated) {
        glideLogin(config);
      }
    }
  });

  useEffect(() => {
    let refreshTokenRef: any;
    if (glideSession.expiresIn && totTimeOut > 0) {
      refreshTokenRef = window.setInterval(() => {
        const currentDateInEphoc = new Date().getTime();
        if (glideSession.expiresIn && currentDateInEphoc >= Number(glideSession.expiresIn)) {
          refreshSession(config);
        }
      }, totTimeOut);
    }
    return () => {
      clearTimeout(refreshTokenRef);
    };
  }, [glideSession]);

  const [isIdleTimeOutDialog, setIsIdleTimeOutDialog] = useState(false);
  const [mins, setMins] = useState(IDLE_TIMEOUT_OUT_CONFIGURATION.Minutes);
  const [secs, setSecs] = useState(IDLE_TIMEOUT_OUT_CONFIGURATION.Seconds);
  const seconds: number = 60;

  const bodyTitle = `Your session is about to end due to inactivity.
  You will be logged out in "<b> ${mins < 10 ? '0' + mins : mins} Min
  ${secs < 10 ? '0' + secs : secs} sec </b>". `;
  const bodyContentAlign = 'left';
  let counter: number = 0;
  let offsetMinutes: number = IDLE_TIMEOUT_OUT_CONFIGURATION.OffsetMinutes;

  let dSec: number = seconds;
  let dMin: number = offsetMinutes;

  const startTimer = () => {
    if (dSec == 0 && dMin == 0) {
      logOut();
      return;
    }

    //debugger;
    counter = counter + 1;
    if (counter > 60) {
      counter = 0;
      offsetMinutes -= 1;
    }

    dSec = dSec < 0 ? 59 : Math.floor(seconds - counter);
    dMin = dMin <= 0 ? 0 : Math.floor((offsetMinutes * 60 - counter) / 60);

    setSecs(dSec);
    setMins(dMin);

    if (dSec == 0 && dMin == 0) {
      setIsIdleTimeOutDialog(false);
      logOut();
      return;
    }
    if (dSec == seconds) {
      setMins(mins - 1);
    }
  };

  const logOut = () => {
    setIsIdleTimeOutDialog(false);
    logout(config);
  };

  const continueUsingApp = () => {
    setIsIdleTimeOutDialog(false);
    if (idleStopperRef) {
      logoutChannel.postMessage('stayOn');
      window.clearInterval(idleStopperRef);
    }

    if (idleStopperWaitRef) {
      window.clearTimeout(idleStopperWaitRef);
    }
  };

  const handleOnIdle = () => {
    setSecs(IDLE_TIMEOUT_OUT_CONFIGURATION.Seconds);
    setMins(IDLE_TIMEOUT_OUT_CONFIGURATION.Minutes);
    setIsIdleTimeOutDialog(true);
    idleStopperRef = window.setInterval(startTimer, 1000);
    idleStopperWaitRef = window.setTimeout(logOut, notificationTimeOut);
  };

  return (
    <>
      <DefaultConfirmDialog
        show={isIdleTimeOutDialog}
        headerText={IDLE_TIME_OUT_DIALOG.headerText}
        bodyTitle={bodyTitle}
        bodyTextContent={IDLE_TIME_OUT_DIALOG.bodyTextContent}
        cancelButton={IDLE_TIME_OUT_DIALOG.cancelButton}
        acceptButton={IDLE_TIME_OUT_DIALOG.acceptButton}
        onAccept={continueUsingApp}
        onCancel={logOut}
        showCloseButton={IDLE_TIME_OUT_DIALOG.showCloseButton}
        bodyContentAlign={bodyContentAlign}
      />

      {timeOut > 0 && (
        <div>
          <IdleTimer
            timeout={timeOut}
            crossTab={{ type: 'localStorage', emitOnAllTabs: true }}
            onIdle={handleOnIdle}
            debounce={250}
          />
        </div>
      )}
      {glideAuthError && (
        <PageError
          statusCode={glideAuthError.statusCode}
          statusMessage={glideAuthError.statusMessage}
          footerMessage="Please contact support: support@alphakinetic.com"
          setClientEnv={setClientEnv}
        />
      )}
      {isInitialized &&
        (user || isGlideAuthenticated) &&
        children({
          glideLogin,
          glideSession,
          isAuthenticated,
          isGlideAuthenticated,
          login,
          logout,
          refreshSession,
          user,
        })}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  isInitialized: authSelectors.getStatus(state) === 'initialized',
  user: authSelectors.getUser(state),
  isAuthenticated: authSelectors.isAuthenticated(state),
  isGlideAuthenticated: authSelectors.isGlideAuthenticated(state),
  glideAuthError: authSelectors.glideAuthError(state),
  glideSession: authSelectors.glideSession(state),
  isLogout: authSelectors.getStatus(state) === 'logout',
});

const mapDispatchToProps: Dispatch = {
  initializeAuth: authActions.initialize,
  login: authActions.login,
  logout: authActions.glideLogout,
  glideLogin: authActions.glideLogin,
  refreshSession: authActions.refreshToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandlerGlide);
