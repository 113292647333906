import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import InspectorGroup from 'src/Inspector/components/InspectorGroup';
import LoadingIcon from 'src/LoadingIcon';
import { PanelHeader } from 'src/Panel/PanelHeader';
import { SearchResult, SearchTypes } from 'src/PanelSearch/models';
import SelectMenu from 'src/SelectMenu';
import * as S from './PanelSearch.style';
import { useSearchWarning } from 'src/hooks/useSearchWarning';

type RowAction = {
  name: string;
  button: (props: any) => JSX.Element;
};

export interface PanelSearchProps {
  results: {
    data: any[];
  };
  loading: boolean;
  toggleVisibility: () => any;
  onClickSearchResult: (result: SearchResult) => any;
  onSubmit: (searchTerm: string, searchType: string) => any;
  isVisible: boolean;
  selectStyle?: React.CSSProperties;
  hideHeader?: boolean;
  searchType?: 'Hypo Orders' | 'All' | 'Instruments' | 'Orders';
  rowActions?: {
    before?: RowAction[];
    after?: RowAction[];
  };
  styles?: {
    searchBox?: React.CSSProperties;
    panelSearch?: React.CSSProperties;
  };
  filterResults?: (results: any[], addedRowsUris?: string[]) => any;
  addedRowsUris?: string[];
}

const PanelSearch: FunctionComponent<PanelSearchProps> = ({
  searchType: propsSearchType,
  hideHeader,
  rowActions,
  results,
  onSubmit,
  toggleVisibility,
  isVisible,
  loading,
  onClickSearchResult,
  styles,
  filterResults,
  addedRowsUris,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  // Dirty when the search term does not reflect results anymore (new search)
  const [dirty, setDirty] = useState<boolean>(false);
  const [searchType, setSearchType] = useState(propsSearchType || SearchTypes.All);
  const [searchDisable, setSearchDisable] = useState<boolean>(true);

  const searchError = useSearchWarning(searchTerm);

  useEffect(() => {
    setDirty(false);
  }, [results]);

  useEffect(() => {
    if (searchTerm.length > 2) {
      setSearchDisable(false);
    } else {
      setSearchDisable(true);
    }
  }, [searchTerm]);

  const clickSearchResultHandler = (result: SearchResult) => {
    setShowBackButton(true);
    onClickSearchResult(result);
  };
  const filterByType = (_type: string) => {
    return searchType === SearchTypes.All ? _type !== SearchTypes.All : _type === searchType;
  };

  const filterHypoOrders = (_type: string) => {
    return searchType === 'Hypo Orders' ? _type === SearchTypes.Hypo_Orders : _type !== SearchTypes.Hypo_Orders;
  };
  const renderGroupResult = (searchResultsByType: { [key: string]: any }) => {
    const groups = Object.keys(searchResultsByType);

    return (
      <S.RowsWrapper>
        {groups
          .filter(filterHypoOrders)
          .filter(filterByType)
          .map((searchResultType: string) => {
            const searchResultsForType = searchResultsByType[searchResultType] || [];
            return (
              <S.StyledGroup>
                <InspectorGroup
                  key={searchResultType}
                  testId={searchResultType}
                  title={`${searchResultType.replace('_', ' ')} (${searchResultsForType.length})`}
                  disabled={searchResultsForType.length === 0}
                  isExpanded={searchResultsForType.length > 0}
                >
                  <S.InspectorGroupStyled>
                    {searchResultsForType.map((result: SearchResult) => (
                      <S.SearchResultContainer key={result.uri}>
                        {rowActions?.before?.map(action => (
                          <action.button key={`${action.name}-before`} rowProps={result} />
                        ))}
                        <S.StyledResult onClick={() => clickSearchResultHandler(result)} key={result.uri}>
                          {result.name}
                        </S.StyledResult>
                        {rowActions?.after?.map(action => (
                          <action.button key={`${action.name}-after`} rowProps={result} />
                        ))}
                      </S.SearchResultContainer>
                    ))}
                  </S.InspectorGroupStyled>
                </InspectorGroup>
              </S.StyledGroup>
            );
          })}
      </S.RowsWrapper>
    );
  };

  const renderResults = () => {
    if (!dirty && !loading && results.data && results.data.length) {
      const searchResultByTypes: any = {};
      const filteredResults = filterResults ? filterResults(results.data, addedRowsUris) : results.data;
      Object.keys(SearchTypes).forEach((resultType: any) => {
        searchResultByTypes[SearchTypes[resultType as keyof typeof SearchTypes]] = filteredResults.filter(
          (result: any) => {
            /* Used when looking for hypo orders (Scenario Object Manager).
             * Hypo orders are of type orders so we need to differentiate here
             */
            if (result.type === 'orders' && searchType === SearchTypes.Hypo_Orders) {
              return true;
            }
            return result.type === resultType.toLowerCase();
          },
        );
      });

      searchResultByTypes[SearchTypes.All] = results.data;

      return renderGroupResult(searchResultByTypes);
    }

    return (
      <S.SearchResultsPlaceholder data-testid="panel-search-empty-results">
        <LoadingIcon size="extraLarge" show={loading} type="Glide" />
      </S.SearchResultsPlaceholder>
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && searchTerm.length < 3) {
      e.preventDefault();
    }
  };

  if (!isVisible) return null;
  return (
    <S.StyledSearchContainer>
      <S.StyledPanelSearch style={styles?.panelSearch}>
        {!hideHeader && (
          <PanelHeader
            showBackButton={showBackButton}
            tooltipText="Search for assets and issuers"
            onClose={toggleVisibility}
            title="OMS Search"
          />
        )}
        <S.StyledContainer>
          <S.StyledForm
            style={styles?.searchBox}
            onSubmit={(event: SyntheticEvent) => {
              event.preventDefault();
              onSubmit(searchTerm, searchType);
            }}
          >
            {!propsSearchType && (
              <SelectMenu
                value={searchType}
                onChange={(e: any) => {
                  setSearchType(e.target.value);
                  if (!searchDisable) {
                    onSubmit(searchTerm, e.target.value);
                  }
                }}
                name="searchResultType"
                /*  Hypo Orders should not be an option in Global Search,
                 *  but is needed in Scenarios Object Manager Search
                 */
                options={Object.values(SearchTypes).filter(resultType => resultType != SearchTypes.Hypo_Orders)}
                style={S.StyledSearchSelectMenu}
                styledinputheight="24px"
                styledinputborder="var(--foreground-form-border)"
                styledinputcolor="var(--foreground-search-panel)"
                customSelectStyles={{
                  icon: { color: 'var(--foreground-search-panel)', right: '0px' },
                  select: {
                    paddingLeft: '8px',
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                }}
              />
            )}
            <S.StyledSearchInput>
              <S.StyledInput
                id="Search"
                label="Search"
                className="search-input"
                error={Boolean(searchError)}
                onChange={(e: any) => {
                  setSearchTerm(e.target.value);
                  setDirty(true);
                }}
                placeholder="Type minimum 3 char to search"
                data-testid="panel-search-text-input"
                autoFocus={true}
                flex={1}
                onKeyDown={handleKeyDown}
              />
              <S.StyledSearchSubmitButton
                disabled={searchDisable}
                type="submit"
                data-testid="panel-search-input-submit"
              >
                <S.StyledSearchIcon disabled={searchDisable} />
              </S.StyledSearchSubmitButton>
            </S.StyledSearchInput>
          </S.StyledForm>
          {!loading && results.data && results.data.length == 0 && (
            <S.SearchResultInfo>
              <S.SearchResultText>Execute search to display results.</S.SearchResultText>
            </S.SearchResultInfo>
          )}
          {renderResults()}
        </S.StyledContainer>
      </S.StyledPanelSearch>
    </S.StyledSearchContainer>
  );
};

export default PanelSearch;
