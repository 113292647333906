import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

export interface SearchQueryProps {
  assetName: any;
  assetType: string;
  cache?: boolean;
}

export const get = ({ assetName, assetType, cache = true }: SearchQueryProps) => {
  const body = { search_term: assetName, search_type: assetType.toLowerCase().split(' ').join('_') };
  if (assetName) {
    body.search_term = assetName;
  }

  return {
    url: endpoints.search.root,
    transform: (body: any) => {
      return { searchAssets: { data: JSON.parse(body.search_results) } };
    },
    options: { method: 'GET' },
    // Cache the request
    force: !cache,
    body,
    queryKey: 'search-assets',
    update: {
      searchAssets: (_: any, next: any) => next,
    },
  };
};

export const selector = (state: RootState) => state.entities.searchAssets;
export const pending = (state: RootState) => state.queries['search-assets'] && state.queries['search-assets'].isPending;
