import { Box, Button, Dialog } from '@mui/material';
import { ArrowLeft, Close, FullscreenExit, Help } from '@mui/icons-material';
import React from 'react';
import { DangerButton, PrimaryButton, TertiaryButton } from 'src/Buttons';
import styled from 'styled-components';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import ExpandIcon from 'src/icons/ExpandIcon';
import CancelButton from 'src/Buttons/CancelButton/CancelButton';

const PaperComponent = (props: PaperProps) => {
  const nodeRef = React.useRef(null);
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} nodeRef={nodeRef}>
      <Box ref={nodeRef} sx={{ width: '664px' }}>
        <Paper {...props} />
      </Box>
    </Draggable>
  );
};

const HelpIcon = styled(Help)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--text);
`;

const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--foreground-tab-close-icon);
`;

const IconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: var(--foreground-tab-close-icon);
`;

const StyledDialog = styled.div`
  position: relative;
  width: auto;
  height: auto;
  background: #1b252f;
  color: var(--text);
  overflow: auto;
`;

const ButtonSpacing = styled.div`
  width: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  width: 100%;
  background: var(--background-modal-header);
  padding-left: 20px;
  padding-right: 10px;
  color: var(--foreground-header);
  border-bottom: var(--foreground-form-border);
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: var(--background-modal-footer);
  display: flex;
  width: 100%;
  min-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const IconBtn = styled(IconButton)`
  color: var(--bgLight);
  cursor: pointer;
  padding: 5px;
  width: 36px;
  height: 34px;
`;

const IconButtonStyled = styled(IconButton)`
  padding: 0;
  color: var(--text);
  width: 36px;
  height: 34px;
  &[disabled] {
    color: var(--iconButtonStyled);
  }
`;

const Title = styled.h4<{ isDraggable: boolean | undefined }>`
  font-size: var(--modal-title-font-size);
  font-weight: bold;
  cursor: ${props => (props.isDraggable ? 'move' : `default`)};
`;

interface ModalButton {
  text: string;
  action: (e: any) => void;
  disabled?: boolean;
}

export interface ModalProps {
  title?: string;
  helpText?: string;
  onClose: (_: any, reason?: string) => void;
  onHelp?: () => void;
  children: React.ReactChild;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  fullWidth?: boolean;
  show: boolean;
  headerStyle?: any;
  footerStyle?: any;
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
  dangerButton?: ModalButton;
  cancelButton?: ModalButton;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  dataTestId?: boolean;
  ariaLabelledBy?: string;
  isDraggable?: boolean;
  disableBackdropClick?: boolean;
  dialogStyle?: any;
  showBackButton?: boolean;
  isFullScreen?: boolean;
  onExpand?: () => void;
  backButtonCallback?: () => void;
  hideCloseIcon?: boolean;
}

const Modal = ({
  title,
  helpText,
  onHelp = () => alert('You hit help'),
  onClose,
  children,
  show,
  primaryButton,
  secondaryButton,
  dangerButton,
  cancelButton,
  footer,
  header,
  headerStyle,
  footerStyle,
  maxWidth,
  fullWidth,
  hideHeader,
  hideFooter,
  dataTestId,
  ariaLabelledBy,
  isDraggable,
  // disableBackdropClick = false,
  dialogStyle,
  showBackButton = false,
  backButtonCallback,
  isFullScreen,
  onExpand,
  hideCloseIcon = false,
}: ModalProps) => {
  return (
    <Dialog
      data-testid={dataTestId || 'modal'}
      open={show}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby={ariaLabelledBy || 'create-portfolio-group'}
      PaperComponent={isDraggable ? PaperComponent : undefined}
      disableEscapeKeyDown
      disableEnforceFocus={true}
      fullScreen={isFullScreen}
    >
      <StyledDialog style={dialogStyle}>
        {!hideHeader && (
          <Header data-testid="modal-header" style={headerStyle}>
            {header || (
              <>
                {showBackButton && (
                  <IconBtn onClick={backButtonCallback} data-testid="back-button" color="inherit">
                    <ArrowLeft />
                  </IconBtn>
                )}
                <Title data-testid="dialog-title" id="draggable-dialog-title" isDraggable={isDraggable}>
                  {title}
                </Title>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {helpText && (
                    <IconButton data-testid="modal-help" onClick={onHelp} size="large">
                      <HelpIcon />
                    </IconButton>
                  )}
                  {onExpand && (
                    <IconButtonStyled onClick={onExpand} color="inherit">
                      {(isFullScreen && <FullscreenExit />) || <ExpandIcon fillColor="var(--text)" />}
                    </IconButtonStyled>
                  )}
                  {!hideCloseIcon && (
                    <IconButton onClick={onClose} size="large">
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              </>
            )}
          </Header>
        )}

        {children}
        {!hideFooter && (
          <Footer data-testid="modal-footer" style={footerStyle}>
            {footer || (
              <>
                {cancelButton && (
                  <CancelButton
                    backgroundColor={cancelButton.text?.toLowerCase() === 'cancel' ? 'var(--error)' : ''}
                    onClick={cancelButton.action}
                  >
                    {cancelButton.text}
                  </CancelButton>
                )}
                {secondaryButton && (
                  <TertiaryButton onClick={secondaryButton.action}>{secondaryButton.text}</TertiaryButton>
                )}
                <ButtonSpacing />
                {primaryButton && (
                  <PrimaryButton onClick={primaryButton.action} disabled={primaryButton.disabled}>
                    {primaryButton.text}
                  </PrimaryButton>
                )}
                {dangerButton && (
                  <DangerButton onClick={dangerButton.action} disabled={dangerButton.disabled}>
                    {dangerButton.text}
                  </DangerButton>
                )}
              </>
            )}
          </Footer>
        )}
      </StyledDialog>
    </Dialog>
  );
};

export default Modal;
