import List from '@mui/material/List';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Route } from 'src/page/models/route';
import SideMenuItem from './SideMenuItem';

const SideMenuList: React.SFC<{
  routes: Route;
  onClick: (fullPath?: string, subroute?: Route) => void;
  isSubMenuItem: boolean;
  isSubMenuBackItem: boolean;
  currentPath?: string;
}> = ({ routes, onClick, isSubMenuItem, isSubMenuBackItem, currentPath }) => {
  const subroutes = routes.subroutes && sortBy(routes.subroutes, ['category']);

  return (
    <List data-testid="sidemenu-list">
      {subroutes?.map((route, index) => (
        <SideMenuItem
          data-testid="sidemenu-item"
          {...route}
          key={`route_${route.name}_${index}`}
          onClick={onClick}
          isSubMenuItem={isSubMenuItem}
          isSubMenuBackItem={isSubMenuBackItem}
          currentPath={currentPath}
          isView={route.subroutes ? false : true}
        />
      ))}
    </List>
  );
};

export default SideMenuList;
