import React from 'react';
import * as S from './bottom-panel.style';
import { RootState } from 'src/reducers';
import { connect } from 'react-redux';
import { dispatchActions } from 'src/app/store';
import { ComponentProps, selectComponents } from 'src/reducers/components';

interface BottomPanelProps {
  children: any;
  clientViewUri: string;
  displayRowSelectMsg?: boolean;
  rowSelectMsg?: string;
}

interface ReduxProps extends ComponentProps {
  readonly isExpanded?: boolean;
}

interface ReduxDispatch {
  setExpandedState: (isExpanded: boolean, clientViewUri: string) => void;
}

const BottomPanel = ({
  children,
  setExpandedState,
  displayRowSelectMsg,
  rowSelectMsg,
  clientViewUri,
  components,
}: BottomPanelProps & ReduxProps & ReduxDispatch) => {
  const toggleCollapse = () =>
    setExpandedState(
      !components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'],
      clientViewUri,
    );

  return (
    <S.BottomPanelWrapper
      $isExpanded={
        (components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.[
          'isExpanded'
        ] as boolean) ?? false
      }
    >
      <S.ChevronDownButton
        data-testid="bottom-panel-button"
        onClick={toggleCollapse}
        style={{ zIndex: 1, cursor: 'pointer' }}
      >
        {components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'] ? (
          <S.ArrowDown fontSize="medium" />
        ) : (
          <S.ArrowUp fontSize="medium" />
        )}
      </S.ChevronDownButton>
      {displayRowSelectMsg ? (
        <S.RowSelectMessage>{rowSelectMsg ? rowSelectMsg : 'Please select a row'}</S.RowSelectMessage>
      ) : (
        children
      )}
    </S.BottomPanelWrapper>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: selectComponents(state),
});

const mapDispatchToProps = (_dispatch: any): ReduxDispatch => ({
  setExpandedState: (isExpanded: boolean, clientViewUri: string) =>
    dispatchActions.components.updateView('bottomPanelExpandedState', clientViewUri, { isExpanded }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomPanel);
