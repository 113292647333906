import React, { useCallback, useMemo } from 'react';
import { GridViewProps } from '../glide-object-manager/components/grid-view/model';
import { dispatchActions } from 'src/app/store';
import { backDropClick } from 'src/utils/constants';
import { Dialog, AuditIconButton, CloseIcon, Header, Title } from './audit-trail.style';
import { GridView } from '../glide-object-manager/components/grid-view/grid-view';
import { uniqueId } from 'lodash';

interface ReduxProps {
  dataSource: any;
  components?: any;
}
const AuditDialog = ({ dataSource, components }: ReduxProps) => {
  if (!components.auditTrail.visible) {
    return null;
  }
  if (components.auditTrail.visible === true) {
    components.auditTrail?.data.forEach((obj: any) => {
      Object.assign(obj, { _uri: obj._uri + uniqueId() });
    });
  }
  const dataGridProps: GridViewProps = useMemo(
    () => ({
      dataGrid: {
        dataSource: {
          schema: components.auditTrail?.schema,
          data: components.auditTrail?.data,
        },
      },
      id: 'data-grid-audit-bottom-panel',
      isFilterButtonLeftAligned: true,
      showRefreshButton: false,
      exportFileName: 'Audit Trail',
    }),
    [dataSource?.data, dataSource?.schema],
  );
  const closeGOMDialog = () => {
    dispatchActions.components.update('auditTrail', { visible: false, data: '', schema: '', title: '' });
  };

  const onCloseAudit = useCallback((_event?: any, reason?: any) => {
    if (reason === backDropClick) return;
    //@ts-ignore
    else {
      closeGOMDialog();
    }
  }, []);
  return (
    <>
      <Dialog open={true} onClose={onCloseAudit} groupslength={0}>
        <Header>
          <Title>{'Audit Trail : ' + components.auditTrail.title}</Title>
          <AuditIconButton onClick={onCloseAudit} size="large">
            <CloseIcon />
          </AuditIconButton>
        </Header>
        <GridView {...dataGridProps} />
      </Dialog>
    </>
  );
};
export default AuditDialog;
