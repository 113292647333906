import styled from 'styled-components';

export const PositionWrapper = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.color};
`;
export const Indicator = styled.div<{ type: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${(props: { type: string }) => (props.type === 'Buy' ? `var(--green)` : `var(--red)`)};
  margin-right: 5px;
`;
