import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const VirtusIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path fill="#FFF" d="M8.6 7.4L10 6l6 6-6 6-1.4-1.4 4.6-4.6z" />
    </g>
  </SvgIcon>
);

export default VirtusIcon;
