import styled from 'styled-components';
import { zIndex } from 'src/utils/constants';
import { DropdownMenuItem } from '@virtus/components/DropdownMenu';
import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';

const getDarkBackground = (dark?: boolean) => (dark ? 'var(--background-layout-dark)' : 'var(--foreground-text)');
export const LayoutButton = styled.button<{ active?: boolean; dark?: boolean }>`
  display: flex;
  align-items: center;
  border: ${props => (props.dark ? 'none' : 'var(--foreground-dx-grid-action-drop-down-border)')};
  color: ${props => (props.active || props.dark ? 'var(--foreground-text)' : 'var(--foreground-form)')};
  background: ${props => (props.active ? 'var(--background-layout-item-selected)' : getDarkBackground(props.dark))};
  height: 28px;
  white-space: nowrap;
  cursor: pointer;
  min-width: 100px;
  width: auto;
`;

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  & :hover {
    background: var(--background-sub-tab-active-hover);
    border-radius: 50%;
  }
`;

export const LayoutDropdownButtonWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 150px;
  border: ${props => (props.isOpen ? 'var(--foreground-form-border)' : 'none')};
`;

export const SaveCancelBtnWrapper = styled.div`
  position: absolute;
  z-index: 1;
  cursor: default;
  width: 56px;
  height: 28px;
  display: inline-flex;
  background: var(--background-form-footer);
  box-shadow: var(--save-cancle-button-box-shadow);
  border: var(--foreground-auto-complete-border);
  opacity: 1;
`;

export const LayoutButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;

  .save-cancel-btn-wrapper {
    display: none;
    z-index: ${zIndex.SaveCancelWrapper};
  }

  &:hover .save-cancel-btn-wrapper {
    display: flex;
  }
`;

export const LayoutDropdownFooterStyled = styled.div`
  background-color: var(--background-dropdown-layout);
  min-width: 240px;
  border-top: var(--foreground-form-border);
`;

export const LayoutDropdownStyled = styled.div`
  background-color: var(--background-dropdown-layout);
  min-width: 240px;
  overflow: overlay;
  max-height: 195px;
  height: auto;
`;

export const LayoutDropdownMenuItem = styled(DropdownMenuItem)`
  min-width: 240px;
  max-width: 400px;
  background-color: var(--background-dropdown-layout);
  border-bottom: var(--foreground-form-border);
  &:hover {
    background-color: var(--foreground-dropdown);
  }
`;

export const LayoutDropdownMenuItemStyled = styled(DropdownMenuItem)<{ isPublicLayout?: boolean; active?: boolean }>`
  height: '32px';
  background-color: ${props =>
    (props.isPublicLayout ? 'var(--background-layout-item-shared)' : '') ||
    (props.active ? 'var(--background-layout-item-selected)' : 'inherit')};
  &:hover {
    background-color: var(--background-layout-item-hover);
  }
`;

export const LayoutDropdownMenuItemFooter = styled(DropdownMenuItem)<{ webLayout: boolean }>`
  min-width: 100px;
  max-width: 400px;
  padding-top: ${props => (props.webLayout ? '10px' : '0')};
  padding-bottom: 10px;
  &:hover {
    background-color: var(--background-dropdown-layout);
  }
`;

export const CreateLayoutButton = styled(BasicButton)`
  background-color: var(--background-layout-create-button);
  color: var(--foregound-dropdown-item-text);
  &:hover {
    background-color: var(--background-layout-create-button-hover);
  }
`;
