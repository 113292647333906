import React from 'react';
import { useDispatch } from 'react-redux';
import SelectTemplateRender from 'src/components/Select/Select';
import { actions } from 'src/reducers/actions';
import { SelectWrapper } from './actions-collection-web.style';
import { dispatchActions } from 'src/app/store';
import { GlideObject } from 'src/models/glide/glideObject';

export interface ActionCollectionWeb {
  actions: {
    text: string;
    onClick: () => void;
    testId: string;
  }[];
  options?: {
    search?: {
      filter?: string;
      isSearchable: boolean;
      isSearchDisabled?: boolean;
    };
    button?: { isDisabled?: boolean };
  };
}

export interface ActionsCollectionDropdownProps {
  type: string;
  actionCollectionsWeb?: GlideObject[];
  rowData?: any;
  disableOrderDropdown?: boolean;
  hideBorder?: boolean;
}

export const ActionsCollectionWebDropdown = ({
  type,
  rowData,
  actionCollectionsWeb = [],
  disableOrderDropdown,
  hideBorder,
}: ActionsCollectionDropdownProps) => {
  const dispatch = useDispatch();
  const getInstrumentsBehaviourUri = () => {
    if (!rowData || !Object.keys(rowData).length) return;
    const behaviourSplit = rowData['Instrument Behaviour'].split(' ');
    const filteredAction = actionCollectionsWeb.filter(
      (action: GlideObject) => action.data.display_name.indexOf(behaviourSplit[0]) > -1,
    );
    if (filteredAction.length) executeNewOrderAction(filteredAction[0].uri, true);
  };

  const executeNewOrderAction = (uri: string, isRowSelected: boolean) => {
    if (!rowData) return;
    dispatch({
      type: actions.action.EXECUTE_ACTION,
      payload: {
        targetUri: isRowSelected ? rowData['_uri'] : uri,
        arguments: isRowSelected
          ? {
              'instance/argument/create_new_order_instrument_behaviour': uri,
            }
          : {
              'instance/argument/create_new_order_status': 'lookups/order_status_incomplete',
            },
        actionUri: 'instance/actions/create_new_order_with_instrument_behaviour',
      },
    });
    // If row is not selected then it is global order. As row data is instrument data so it can`t be considered as global order
    if (!isRowSelected) dispatchActions.components.update('global', { orders: { active: true } });
  };

  const showImportInstrumentOverlay = () =>
    dispatchActions.components.update('global', { importInstrumentOverlay: { visible: true } });

  const initialActions: { [key: string]: any } = {
    orderdropdown: [
      {
        text: 'New Order',
        onClick: getInstrumentsBehaviourUri,
        testId: `new_order_action`,
      },
    ],
    addinstruments: [
      {
        text: 'Add Instrument',
        onClick: showImportInstrumentOverlay,
        testId: `portfolioAddDropdownBtn`,
      },
    ],
  };

  const dropdownActionCollections = actionCollectionsWeb.map(action => ({
    text: action.data.display_name,
    onClick: () => executeNewOrderAction(action.uri, false),
    testId: `${action.uri.lastSplitValue()}_action`,
  }));

  const actionsItems: ActionCollectionWeb = {
    actions: [...initialActions[type], ...dropdownActionCollections],
    options: {
      search: { isSearchable: true },
      button: { isDisabled: disableOrderDropdown },
    },
  };
  const dropdownArrowStyles = {
    background: 'var(--background-form-dropdown-icon)',
    height: '23px',
    '&:hover': {
      background: 'var(--background-dx-pivotgrid-arrowdropdown-hover)',
    },
  };
  const dropdownStyle = {
    fontSize: hideBorder ? 'var(--add-instrument-text-font-size)' : 'var(--dx-grid-font-size)',
    border: hideBorder ? 'none' : 'var(--foreground-dx-grid-action-drop-down-border)',
    background: hideBorder ? 'none' : 'var(--white)',
    color: 'var(--foregound-dropdown-item-text)',
    height: '23px',
  };
  const dropdownTextStyle = {
    borderRight: 'none',
  };
  return (
    <SelectWrapper>
      <SelectTemplateRender
        actionsCollection={actionsItems.actions}
        options={actionsItems?.options}
        dropdownArrowStyle={dropdownArrowStyles}
        dropdownStyle={dropdownStyle}
        dropdownTextStyle={dropdownTextStyle}
      />
    </SelectWrapper>
  );
};
