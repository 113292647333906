import AppBar from '@mui/material/AppBar';
import { Menu as MenuIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { AppLink, AppNames } from 'src/NavigationBar/components/AppMenu/AppMenu';
import { TopRightMenuItemProps } from 'src/NavigationBar/components/NavigationBarMenu/NavigationBarMenu';
import NavigationBar from 'src/NavigationBar/NavigationBar';
import Sidemenu from 'src/page/Sidemenu/Sidemenu';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';
import { useConfirmationDialog } from 'src/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import styled from 'styled-components';

export interface HeaderProps {
  menuButtonColor?: string;
  appButton?: any;
  isLoggedIn?: boolean;
  topRightMenuItems?: TopRightMenuItemProps[];
  open?: boolean;
  path: string;
  onLogin?: () => void;
  onRouteChange?: (path: string) => void;
  routes?: any;
  sideMenuRoutes?: any;
  misc?: any;
  extraIconButtons?: any;
  appMenuValue?: any;
  appMenuLinks?: AppLink[];
  enabledApps?: AppNames[];
  topRightButtonText?: any;
  isDrawerOpen?: boolean;
  openDrawer?: () => void;
  closeDrawer?: () => void;
  appColor?: string;
  routeChangeCallback?: (path: string, routes?: any) => void;
  isHelpVisible?: boolean;
  helpURL?: string;
  currentPath?: string;
  activeViewUri?: string;
  isInspectorFormDirty?: boolean;
}

/**
 * Fixed top position Material UI App Bar with Hamburger Menu.
 *
 * Add ¬<Routing>`/`<Page>` as siblings.
 */
const Header: React.FunctionComponent<HeaderProps> = ({
  isLoggedIn,
  onLogin,
  topRightButtonText,
  topRightMenuItems,
  enabledApps = [],
  appMenuValue = '',
  appMenuLinks,
  routes,
  sideMenuRoutes,
  misc,
  extraIconButtons,
  onRouteChange,
  path,
  isDrawerOpen,
  openDrawer,
  closeDrawer,
  routeChangeCallback,
  isHelpVisible = false,
  helpURL,
  activeViewUri,
  currentPath,
  isInspectorFormDirty,
}) => {
  const [open, setOpen] = useState(false);

  const closeInspectorDialog = (_path: string) => {
    routeChangeCallback && routeChangeCallback(_path);
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const _onRouteChange = onRouteChange
    ? onRouteChange
    : (_path: string, routes?: any) => {
        if (isInspectorFormDirty && routes) {
          displayConfirmationDialog(_path);
        } else {
          closeInspectorDialog(_path);
        }
      };
  const isOpen = isDrawerOpen === null || isDrawerOpen === undefined ? open : isDrawerOpen;
  return (
    <>
      <QuitInspector />
      <StyledAppBar position="fixed" data-testid="header">
        <Toolbar>
          {isLoggedIn && (
            <MenuIconContainer data-testid="header-menu" aria-label="Menu" onClick={openDrawer ? openDrawer : onOpen}>
              <MenuIcon />
            </MenuIconContainer>
          )}
          <NavigationBar
            appMenuValue={appMenuValue}
            enabledApps={enabledApps}
            appMenuLinks={appMenuLinks}
            routes={routes}
            misc={misc}
            extraIconButtons={extraIconButtons}
            path={path}
            onRouteChange={_onRouteChange}
            topRightButtonText={topRightButtonText}
            topRightMenuItems={topRightMenuItems}
            isLoggedIn={isLoggedIn}
            onLogin={onLogin}
            isHelpVisible={isHelpVisible}
            helpURL={helpURL}
            activeViewUri={activeViewUri}
            currentPath={currentPath}
            isInspectorFormDirty={isInspectorFormDirty}
          />
        </Toolbar>
      </StyledAppBar>
      <Sidemenu
        isOpen={isOpen}
        onClose={closeDrawer ? closeDrawer : onClose}
        routes={sideMenuRoutes || routes}
        onRouteChange={_onRouteChange}
        currentPath={currentPath}
      />
    </>
  );
};

const noop = () => null;

Header.defaultProps = {
  isLoggedIn: false,
  open: false,
  onLogin: noop,
};

const StyledAppBar = styled(AppBar)`
  background-color: var(--background-app-bar);
  box-shadow: none;
  /** recreate Toolbar gutters, but Orange box hugs left **/
  padding-right: 15px;
  padding-left: 15px;
  height: 45px;
  z-index: 11;
`;

const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10.5px 10px 10.5px 0px;
  cursor: pointer;

  & .MuiSvgIcon-root {
    font-size: var(--app-bar-menu-icon-font-size);
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export default Header;
