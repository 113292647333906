export const searchExpr = ['username', 'displayname', 'comments'];

export const groupByGlideList = [
  { id: -1, text: 'Group By None' },
  { id: 1, text: 'Category', groupfield: 'category' },
  { id: 2, text: 'Username', groupfield: 'username' },
];

export const sortByGlideList = [
  { id: -1, text: 'Sort by' },
  { id: 1, text: 'Latest', orderfield: 'commentdate', orderby: 'desc' },
  { id: 2, text: 'Oldest', orderfield: 'commentdate', orderby: 'asc' },
  { id: 3, text: 'A-Z', orderfield: 'username', orderby: 'asc' },
  { id: 4, text: 'Z-A', orderfield: 'username', orderby: 'desc' },
];

export const placeholder = 'Add Comment';
