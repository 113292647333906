import { CustomCellRenderKeyProps, ILinkedColumn } from 'src/DxDataGrid/DxDataGrid';
import { mapSchemaGlide } from 'src/DxDataGrid/utils/mapSchemaGlide';
import { mapSchemaVirtus } from 'src/DxDataGrid/utils/mapSchemaVirtus';

export enum DataSourceType {
  GLIDE = 'GLIDE',
  VIRTUS = 'VIRTUS',
}

// Convert Glide Schema to DevExtreme Column
export const mapSchema = (
  data: any,
  dataSourceType: DataSourceType,
  customCellRenderKeyProps?: CustomCellRenderKeyProps,
  linkedColumns?: ILinkedColumn[],
  displayType?: string,
  customComponents?: any,
) => {
  if (dataSourceType === DataSourceType.GLIDE) {
    return mapSchemaGlide(data, customCellRenderKeyProps || {}, customComponents);
  }
  if (dataSourceType === DataSourceType.VIRTUS) {
    return mapSchemaVirtus(data, customCellRenderKeyProps || {}, linkedColumns, displayType);
  }
  throw Error('Error: Missing data source Type. Must be GLIDE or NEXUS.');
};
