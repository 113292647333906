import { Button } from '@mui/material';
import styled from 'styled-components';

export const IconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: var(--foreground-form-header);
  & + h3[class^='style__Title'] {
    padding-top: 4px;
  }
`;

export const Title = styled.h3`
  color: var(--foreground-header);
  font-weight: bold;
  text-transform: capitalize;
  line-height: 22px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: var(--foreground-text) 0% 0% no-repeat padding-box;
  border-bottom: var(--foreground-form-border);
  padding-left: 13px;
  padding-right: 16px;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
