import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import FormErrorWrapper from '../FormErrorWrapper';
import { FormElementInputComponent } from '../..';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()(() => ({
  paper: {
    display: 'flex',
    borderRadius: '12px;',
    height: '25px',
    flexWrap: 'wrap',
    width: 'fit-content;',
    backgroundColor: 'var(--background-toggler)',
  },
  selected: {
    backgroundColor: 'var(--background-toggler-selected)',
  },
}));

export const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, () => ({
  grouped: {
    border: 'none',
    margin: '3px',
    '&:not(:first-child)': {
      color: 'var(--foreground-toggler)',
      borderRadius: '9px',
      height: '18px',
      padding: '3.2px 4.7px',
      backgroundColor: 'transparent',
    },
    '&:first-child': {
      color: 'var(--foreground-toggler)',
      borderRadius: '9px',
      height: '18px',
      padding: '3.2px 4.7px',
      backgroundColor: 'transparent',
    },

    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'var(--foreground-toggler-selected)',
      backgroundColor: 'var(--background-toggler-selected)',
      fontWeight: 'bold',
    },
  },
}));

const TogglerInput = ({ field, onClick, value = '', error, isMultiSelect, onChange }: FormElementInputComponent) => {
  const [SelectedValue, setSelectedValue] = useState(value);
  const { classes } = useStyles();
  const Options: any = Object.values(field.options || []);

  useEffect(() => {
    let val = null;
    if (value && value.constructor === Array) {
      if (value.length) {
        val = Options.reduce((acc: string[], item: { name: string; value: string }) => {
          if (value.includes(item.value) || value.includes(item.name)) {
            acc = [...acc, item.value];
          }
          return acc;
        }, []);
      } else {
        val = [];
      }
    } else {
      for (const item of Options) {
        if (value === item.value || value === item.name) {
          val = item.value;
          break;
        }
      }
    }
    setSelectedValue(val);
  }, [value]);

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      disableErrorWarning={field.disableErrorWarning}
    >
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value={SelectedValue}
          exclusive={!isMultiSelect}
          onChange={(e, value) => {
            onChange({
              target: {
                value: value,
                addEventListener: () => null,
                removeEventListener: () => null,
                dispatchEvent: () => true,
              },
            });

            if (onClick) onClick(e);
          }}
          aria-label="text alignment"
        >
          {field.options &&
            Object.values(field.options)
              .sort((a, b) => {
                /* eslint-disable no-nested-ternary */
                return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
              })
              .map(option => {
                return (
                  <ToggleButton
                    value={option.value}
                    aria-label={option.value}
                    key={option.value}
                    disabled={field.disabled || field.readonly}
                  >
                    {option.name}
                  </ToggleButton>
                );
              })}
        </StyledToggleButtonGroup>
      </Paper>
    </FormErrorWrapper>
  );
};

export default TogglerInput;
