// eslint-disable-next-line react-hooks/rules-of-hooks
import React, { useEffect, useState } from 'react';
import * as S from './column-manager.style';
import { ColumnRow } from './components/column-row';
import { DxGridInternalStateColumn } from '@virtus/components/DxDataGrid/model/DxDataGrid.model';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Close } from '@mui/icons-material';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import { ComponentGridLayout } from 'src/reducers/components';
import { store } from 'src/app/store';
import { activeTabSelector } from 'src/reducers/tabs';
import { dispatchActions } from 'src/app/store';
import filter from 'lodash/filter';
import { FixTopIcon, FixBottomIcon, ArrowDownIcon, ArrowUpIcon } from '@virtus/components/icons';

interface IconProps {
  style: React.CSSProperties;
}

type DropDownOptions = {
  label: string;
  onClick: () => void;
};

export interface ColumnManagerProps {
  gridLayout: ComponentGridLayout;
  dropdownOptions?: DropDownOptions[];
  excludedColumns?: string[];
  clientUri?: string;
}
const filtered_groupedColumns = (_columns: any) =>
  filter(_columns, item => {
    return item.dataField && item.dataField !== 'Grouped Data';
  });

const reorder = (list: DxGridInternalStateColumn[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed]: any = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((column, index) => ({ ...column, visibleIndex: index }));
};

const sortByGridOrder = (columns: DxGridInternalStateColumn[]) => {
  const sortedColumns = [...columns];
  sortedColumns.sort((a, b) => a.visibleIndex - b.visibleIndex);
  return sortedColumns;
};

const sortByAZ = (columns: DxGridInternalStateColumn[]) => {
  const sortedColumns = [...columns];
  sortedColumns.sort((a, b) => {
    if (a.dataField < b.dataField) {
      return -1;
    }
    if (a.dataField > b.dataField) {
      return 1;
    }
    return 0;
  });
  return sortedColumns;
};

const fixColumn = (
  updatedColumn: DxGridInternalStateColumn,
  filteredColumns: DxGridInternalStateColumn[],
  position: 'left' | 'right',
) =>
  filteredColumns.map(column => {
    if (column.dataField === updatedColumn.dataField) {
      column.fixed = true;
      column.fixedPosition = position;
    }
    return column;
  });

const moveColumnToTheTop = (
  column: DxGridInternalStateColumn,
  columns: DxGridInternalStateColumn[],
  search: string,
) => {
  const reorderedColumns: any = reorder(columns, column.visibleIndex, 0);
  const filteredColumns = getFilteredColumns(reorderedColumns, search);
  return { reorderedColumns, filteredColumns };
};

const moveColumnToTheBottom = (
  column: DxGridInternalStateColumn,
  columns: DxGridInternalStateColumn[],
  search: string,
) => {
  const reorderedColumns: any = reorder(columns, column.visibleIndex, columns.length);
  const filteredColumns = getFilteredColumns(reorderedColumns, search);
  return { reorderedColumns, filteredColumns };
};

const getFilteredColumns = (columns: DxGridInternalStateColumn[], searchString: string) => {
  if (!searchString) return columns;
  return columns.filter(column => column.dataField?.toLowerCase().includes(searchString.toLowerCase()));
};

const getColumnVisibility = (updatedColumn: DxGridInternalStateColumn, currentColumn: DxGridInternalStateColumn) => {
  if (currentColumn.dataField === 'Order Date') {
    return true;
  }
  if (currentColumn.dataField === updatedColumn.dataField) {
    return !currentColumn.visible;
  }
  return currentColumn.visible;
};

export const ColumnManager = ({ dropdownOptions, excludedColumns, clientUri }: ColumnManagerProps) => {
  const cvcUri = clientUri ?? activeTabSelector(store.getState());
  // @ts-ignore
  if (!window['gridInstances'][cvcUri].current?.instance?.getDataSource()) {
    return null;
  }
  const [defaultColumns, setDefaultColumns] = useState(
    // @ts-ignore
    [...window['gridInstances'][cvcUri].current.instance.state().columns],
  );
  // @ts-ignore
  const _columns = window['gridInstances'][cvcUri].current.instance.state().columns;
  const [columns, setColumns] = useState<DxGridInternalStateColumn[]>(
    sortByGridOrder(filtered_groupedColumns(_columns)),
  );
  const [filteredColumns, setFilteredColumns] = useState<DxGridInternalStateColumn[]>(
    sortByGridOrder(filtered_groupedColumns(_columns)),
  );
  const [search, setSearch] = useState(() => '');
  const [tabIndex, setTabIndex] = useState(0);

  const handleOnToggleChecked = (updatedColumn: DxGridInternalStateColumn) => {
    const nextColumns = columns.map(column => {
      return {
        ...column,
        visible: getColumnVisibility(updatedColumn, column),
      };
    });
    setColumns(nextColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: nextColumns,
    });
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (destination && destination.index >= 0) {
      const reordered = reorder(columns, source.index, destination.index);
      setColumns(reordered);
      // @ts-ignore
      window['gridInstances'][cvcUri].current.instance.state({
        // @ts-ignore
        ...window['gridInstances'][cvcUri].current.instance.state(),
        columns: reordered,
      });
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const _filteredColumns = getFilteredColumns(columns, search);
    setFilteredColumns(_filteredColumns);
  }, [search, columns]);

  const updateColumns = (
    nextColumns: DxGridInternalStateColumn[],
    nextFilteredColumns: DxGridInternalStateColumn[],
  ) => {
    setColumns(nextColumns);
    setFilteredColumns(nextFilteredColumns);
  };

  /* Column Actions */
  const onMoveToTheTop = (column: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheTop(column, columns, search);
    updateColumns(reorderedColumns, _filteredColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: reorderedColumns,
    });
  };

  const onMoveToTheBottom = (column: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheBottom(column, columns, search);
    updateColumns(reorderedColumns, _filteredColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: reorderedColumns,
    });
  };

  const onFixToTheTop = (updatedColumn: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheTop(updatedColumn, columns, search);
    const nextFilteredColumns = fixColumn(updatedColumn, _filteredColumns, 'left');
    updateColumns(reorderedColumns, nextFilteredColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: reorderedColumns,
    });
  };

  const onFixToTheBottom = (updatedColumn: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheBottom(
      updatedColumn,
      columns,
      search,
    );
    const nextFilteredColumns = fixColumn(updatedColumn, _filteredColumns, 'right');
    updateColumns(reorderedColumns, nextFilteredColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: reorderedColumns,
    });
  };

  const onUnfix = (updatedColumn: DxGridInternalStateColumn) => {
    const nextColumns = columns.map(column => {
      if (column.dataField === updatedColumn.dataField) {
        column.fixed = false;
        column.fixedPosition = undefined;
      }
      return column;
    });
    setColumns(nextColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: nextColumns,
    });
  };

  /* Tab Actions */
  const onGridOrderTabClick = () => {
    const sortedColumns = sortByGridOrder(columns);
    const _filteredColumns = getFilteredColumns(sortedColumns, search);
    updateColumns(sortedColumns, _filteredColumns);
    setTabIndex(0);
  };

  const onAlphabeticalTabClick = () => {
    const sortedColumns = sortByAZ(columns);
    const _filteredColumns = getFilteredColumns(sortedColumns, search);
    updateColumns(sortedColumns, _filteredColumns);
    setTabIndex(1);
  };

  /* Column Manager dropdown actions */
  const onSelectAll = () => {
    const nextColumns = columns.map(column => {
      column.visible = true;
      return column;
    });
    setColumns(nextColumns);
    // props.onUpdateColumns(nextColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: nextColumns,
    });
  };

  const onDeselectAll = () => {
    const nextColumns = columns.map(column => {
      column.visible = false;
      return column;
    });
    setColumns(nextColumns);
    // props.onUpdateColumns(nextColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: nextColumns,
    });
  };

  const onDiscardChanges = () => {
    setColumns([...sortByGridOrder(filtered_groupedColumns(defaultColumns))]);
    const sortedColumns = sortByGridOrder([...filtered_groupedColumns(defaultColumns)]);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: sortedColumns,
    });
    // @ts-ignore
    const setCols = [...window['gridInstances'][cvcUri].current.instance.state().columns];
    setDefaultColumns([...setCols]);
  };

  const closeItem = () => {
    dispatchActions.components.toggleViewDisplay('columnManagerInspector', cvcUri, false);
  };

  const onUnLockAll = () => {
    const nextColumns = columns.map(column => {
      column.fixed = false;
      return column;
    });
    setColumns(nextColumns);
    // @ts-ignore
    window['gridInstances'][cvcUri].current.instance.state({
      // @ts-ignore
      ...window['gridInstances'][cvcUri].current.instance.state(),
      columns: nextColumns,
    });
  };

  const isSearching = search !== '';
  const isOnAlphabeticalTab = tabIndex === 1;

  const defaultDropdownOptions = [
    ...[
      {
        label: 'Select All',
        onClick: () => onSelectAll(),
      },
      {
        label: 'Deselect All',
        onClick: () => onDeselectAll(),
      },
    ],
    ...(!isOnAlphabeticalTab
      ? [
          {
            label: 'Discard Changes',
            onClick: () => onDiscardChanges(),
          },
        ]
      : []),
    ...(columns.some(column => column.fixed)
      ? [
          {
            label: 'Unlock All',
            onClick: () => onUnLockAll(),
          },
        ]
      : []),
  ];

  return (
    <S.Root>
      <S.LeftContainer></S.LeftContainer>
      <S.RightContainer>
        <S.Tabs>
          <S.Tab className={`${tabIndex === 0 ? 'selected' : ''}`} onClick={onGridOrderTabClick}>
            Grid Order
          </S.Tab>
          <S.Tab className={`${tabIndex === 1 ? 'selected' : ''}`} onClick={onAlphabeticalTabClick}>
            Alphabetical
          </S.Tab>
          <S.IconBtn onClick={closeItem} color="inherit" data-testid="column-manager-close-button">
            <Close
              style={{
                fontSize: 'var(--inspector-icon-font-size)',
                color: 'var(--foreground-column-manager-close-icon)',
              }}
            />
          </S.IconBtn>
        </S.Tabs>
        <S.StaticHeader>
          <S.Row>
            <S.Input
              id="search"
              label="Search..."
              datatestid="columnSearch"
              value={search}
              placeholder="Search..."
              onChange={onSearchChange}
            />
            <S.SearchIcon />
            <DropdownMenu light>
              {[...defaultDropdownOptions, ...(dropdownOptions ?? [])].map(action => (
                <DropdownMenuItem key={`column-management-action-button-${action.label}`} onClick={action.onClick}>
                  <DropdownMenuItemText>{action.label}</DropdownMenuItemText>
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          </S.Row>
        </S.StaticHeader>
        <DragDropContext onDragEnd={onDragEnd}>
          <S.ListContainer>
            <Droppable droppableId="columnList">
              {dropProvided => (
                <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                  {filteredColumns.map(column => {
                    if (excludedColumns && excludedColumns.includes(column.dataField)) {
                      return;
                    }
                    const onToggleChecked = () => handleOnToggleChecked(column);

                    const fixedActions = [
                      {
                        text: 'Unfix',
                        icon: (iconProps: IconProps) => <FixBottomIcon {...iconProps} />,
                        onClick: () => onUnfix(column),
                      },
                    ];
                    const unFixedActions = [
                      {
                        text: 'Fix to the top',
                        icon: (iconProps: IconProps) => <FixTopIcon {...iconProps} />,
                        onClick: () => onFixToTheTop(column),
                      },
                      {
                        text: 'Fix to the bottom',
                        icon: (iconProps: IconProps) => <FixBottomIcon {...iconProps} />,
                        onClick: () => onFixToTheBottom(column),
                      },
                    ];
                    const columnRowActions =
                      tabIndex === 0
                        ? [
                            ...[
                              {
                                text: 'Move to the top',
                                icon: (iconProps: IconProps) => <ArrowUpIcon {...iconProps} />,
                                onClick: () => onMoveToTheTop(column),
                              },
                              {
                                text: 'Move to the bottom',
                                icon: (iconProps: IconProps) => <ArrowDownIcon {...iconProps} />,
                                onClick: () => onMoveToTheBottom(column),
                              },
                            ],
                            ...(column.fixed ? fixedActions : unFixedActions),
                          ]
                        : [];

                    return (
                      <Draggable
                        key={column.dataField}
                        draggableId={column.dataField || new Date().valueOf().toString()}
                        isDragDisabled={isSearching || isOnAlphabeticalTab || column.fixed}
                        index={column.visibleIndex}
                      >
                        {(dragProvided, dragSnapshot) => (
                          // @ts-ignore
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <ColumnRow
                              fixed={column.fixed}
                              value={column.dataField}
                              checked={column.visible}
                              isDragging={dragSnapshot.isDragging}
                              toggleChecked={onToggleChecked}
                              actions={columnRowActions}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </S.ListContainer>
        </DragDropContext>
      </S.RightContainer>
    </S.Root>
  );
};
