import { TabPanel } from '@virtus/components/tab-panel/tab-panel';
import React from 'react';
import DxDataGrid from '@virtus/components/DxDataGrid';
import { DataSourceType } from '@virtus/components/DxDataGrid/utils/mapSchema';
import { GlideDataSource } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { Close } from '@mui/icons-material';
import * as S from './style';

interface TestResultsOverlayProps {
  dataSource: any[];
  onCloseModal: () => void;
  testResultName: string;
}

interface HeaderProps {
  onClose: () => void;
  title: string;
}

export interface HypoTestResult {
  display_name: string;
  datagrid: GlideDataSource;
}

interface ItemTitleRenderProps {
  display_name: string;
}

interface ItemComponentProps {
  data: HypoTestResult;
}

export const Header = ({ onClose, title }: HeaderProps) => (
  <S.Header>
    <S.Title>{title.replace(/_/g, ' ')}</S.Title>
    <S.IconButton data-testid="close-btn" onClick={onClose}>
      <Close />
    </S.IconButton>
  </S.Header>
);

export const itemTitleRender = ({ display_name }: ItemTitleRenderProps) => {
  return <span style={{ fontSize: 'capitalize' }}>{display_name} </span>;
};

// Render grid for each test results
const itemComponent = ({ data }: ItemComponentProps) => {
  return (
    <S.ItemComponent>
      <DxDataGrid
        borderTopColor="transparent"
        skipKeyId
        showPaging
        pageSize={30}
        dataSource={{ schema: data.datagrid?.schema, data: data.datagrid?.data }}
        dataSourceType={DataSourceType.GLIDE}
      />
    </S.ItemComponent>
  );
};

export const TestResultsOverlay = ({ dataSource, onCloseModal, testResultName }: TestResultsOverlayProps) => {
  return (
    <S.Dialog open={true} onClose={onCloseModal} disableEnforceFocus={true} disableEscapeKeyDown>
      <S.DXClassesOverride>
        <Header onClose={onCloseModal} title={testResultName} />
        <TabPanel dataSource={dataSource} itemComponent={itemComponent} itemTitleRender={itemTitleRender} />
      </S.DXClassesOverride>
    </S.Dialog>
  );
};
