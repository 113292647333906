import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { ComponentPivotGridLayout, selectViewComponent, updateComponentViewAction } from 'src/reducers/components';
import { selectCVC } from 'src/reducers/tabs';
import { PivotField, PivotLayout } from './model/dx-pivot-grid.model';

export enum PivotGridActions {
  PIVOT_GRID_SEARCH = 'PIVOT_GRID_SEARCH',
  PIVOT_GRID_STATE_CHANGE = 'PIVOT_GRID_STATE_CHANGE',
  PIVOT_GRID_UNMOUNTING = 'PIVOT_GRID_UNMOUNTING',
}

export interface PivotGridStateChangePayload {
  type: PivotGridActions.PIVOT_GRID_STATE_CHANGE;
  payload: {
    state: PivotLayout;
  };
}

export type searchFilterExpression = (string[] | 'or')[];

export function* handleSearch(): Generator<SelectEffect, void, ClientViewConfigurationData & ComponentPivotGridLayout> {
  const clientViewConfiguration = yield select(selectCVC);
  const viewGridLayout = yield select(selectViewComponent, 'gridLayout', clientViewConfiguration.uri);
  // @ts-ignore: FIXME
  const dataSource = window['gridInstances'][clientViewConfiguration.uri].current.instance.getDataSource();
  const searchText = viewGridLayout.searchText;
  const filterExpressionArr: searchFilterExpression = [];

  // 💡 Method 1 -> Apply filter on all fields to return relevant search

  // dataSource.fields().forEach((field: PivotField) => {
  //   const filterExpression = (field.dataType === 'string'
  //     ? [field.dataField, 'contains', searchText]
  //     : [field.dataField, '=', searchText]) as string[];
  //   filterExpressionArr.push(filterExpression, 'or');
  //   field.expanded = true;
  // });

  // 💡 Method 2 -> Apply filter only on fields visible in area

  const fieldsInDOM: PivotField[] = [
    ...dataSource.getAreaFields('row', false),
    ...dataSource.getAreaFields('column', false),
    ...dataSource.getAreaFields('data', false),
    ...dataSource.getAreaFields('filter', false),
  ];

  fieldsInDOM.forEach((field: PivotField) => {
    const filterExpression = (field.dataType === 'string' || field.dataType === 'date'
      ? [field.dataField, 'contains', searchText]
      : [field.dataField, '=', searchText]) as string[];
    filterExpressionArr.push(filterExpression, 'or');
    field.expanded = true;
  });

  // ds.filter([['Instrument', 'contains', searchText], 'or', ['Fund', 'contains', searchText]]);

  dataSource.filter(filterExpressionArr);
  dataSource.reload();
}

export function* handleStateChange(
  action: PivotGridStateChangePayload,
): Generator<SelectEffect | CallEffect | PutEffect, void, ClientViewConfigurationData & ComponentPivotGridLayout> {
  const clientViewConfiguration = yield select(selectCVC);
  const viewGridLayout = yield select(selectViewComponent, 'gridLayout', clientViewConfiguration.uri);
  if (!viewGridLayout) return;
  const _fieldCount = action.payload.state.fields.filter(item => item.areaIndex! >= 0).length;
  if (viewGridLayout.fieldCount !== 0 && viewGridLayout.fieldCount !== _fieldCount && viewGridLayout.searchText) {
    yield call(handleSearch);
    yield put(
      updateComponentViewAction('gridLayout', clientViewConfiguration.uri, {
        fieldCount: _fieldCount,
      }),
    );
  }
}

export function* handleUnmounting(): Generator<SelectEffect | PutEffect, void, ClientViewConfigurationData> {
  const clientViewConfiguration = yield select(selectCVC);
  yield put(
    updateComponentViewAction('gridLayout', clientViewConfiguration.uri, {
      fieldCount: 0,
      searchText: '',
    }),
  );
}

export function* watchPivotGridSearch() {
  yield takeLatest(PivotGridActions.PIVOT_GRID_SEARCH, handleSearch);
}

export function* watchPivotGridStateChange() {
  yield takeLatest(PivotGridActions.PIVOT_GRID_STATE_CHANGE, handleStateChange);
}

export function* watchPivotGridUnmounting() {
  yield takeLatest(PivotGridActions.PIVOT_GRID_UNMOUNTING, handleUnmounting);
}

export default [watchPivotGridSearch, watchPivotGridStateChange, watchPivotGridUnmounting];
