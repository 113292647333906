import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const FisIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="98" height="26" viewBox="0 0 55.2 28.34" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Layer_2-2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1-2">
          <path
            className="fis-icon-cls-3"
            d="m54.65,8.44c0-.67-.55-1.22-1.22-1.22h-14.11c-2.51,0-4.55,2.04-4.55,4.55s2.04,4.55,4.55,4.55h11.33c1.16,0,2.11.95,2.11,2.11,0,1.16-.94,2.11-2.11,2.11h-14.06c-.67,0-1.22.55-1.22,1.22s.55,1.22,1.22,1.22h14.06c2.51,0,4.55-2.04,4.55-4.55s-2.04-4.55-4.55-4.55h-11.33c-1.16,0-2.11-.94-2.11-2.11s.94-2.11,2.11-2.11h14.11c.67,0,1.22-.55,1.22-1.22"
          />
          <path
            className="fis-icon-cls-3"
            d="m29.78,1.76c0-.97-.79-1.76-1.76-1.76s-1.76.79-1.76,1.76.79,1.76,1.76,1.76,1.76-.79,1.76-1.76"
          />
          <path
            className="fis-icon-cls-2"
            d="m24.12,1.76c0-.97-.79-1.76-1.76-1.76s-1.76.79-1.76,1.76.79,1.76,1.76,1.76,1.76-.79,1.76-1.76"
          />
          <path
            className="fis-icon-cls-1"
            d="m18.47,1.76c0-.97-.79-1.76-1.76-1.76s-1.76.79-1.76,1.76.79,1.76,1.76,1.76,1.76-.79,1.76-1.76"
          />
          <path
            className="fis-icon-cls-4"
            d="m12.79,1.76c0-.97-.79-1.76-1.76-1.76s-1.76.79-1.76,1.76.79,1.76,1.76,1.76,1.76-.79,1.76-1.76"
          />
          <path
            className="fis-icon-cls-5"
            d="m7.12,1.76c0-.97-.79-1.76-1.76-1.76s-1.76.79-1.76,1.76.79,1.76,1.76,1.76,1.76-.79,1.76-1.76"
          />
          <path
            className="fis-icon-cls-3"
            d="m20.12,7.22h-12.11C3.24,7.22.02,10.34.02,15.12v6.66c-.12.73.37,1.41,1.1,1.53.73.12,1.41-.37,1.53-1.1.02-.14.02-.29,0-.43v-6.69c0-3.3,2.02-5.43,5.35-5.43h12.11c.67,0,1.22-.55,1.22-1.22s-.55-1.22-1.22-1.22h0"
          />
          <path
            className="fis-icon-cls-3"
            d="m4.02,15.1c0,.67.55,1.22,1.22,1.22h12.21c.67,0,1.22-.55,1.22-1.22,0-.67-.55-1.22-1.22-1.22H5.24c-.67,0-1.22.55-1.22,1.22h0"
          />
          <path
            className="fis-icon-cls-3"
            d="m26.66,8.44v13.32c-.12.73.37,1.41,1.1,1.53.73.12,1.41-.37,1.53-1.1.02-.14.02-.29,0-.43v-13.32c-.12-.73-.81-1.22-1.53-1.1-.57.09-1.01.54-1.1,1.1"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default FisIcon;
