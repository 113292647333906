import omit from 'lodash/omit';
import { Action } from 'redux';
import { actionTypes } from 'redux-query';
import { actions } from 'src/reducers/actions';
import { RootState } from 'src/reducers/rootReducer';

interface QueryAction extends Action {
  url: string;
  type: string;
  queryKey: string;
  time: number;
}

export interface ErrorState {
  [queryKey: string]: QueryAction;
}

const initialState: ErrorState = {};

export const errors = (state: ErrorState = initialState, action: QueryAction): ErrorState => {
  switch (action.type) {
    case actionTypes.RESET:
    case actions.errors.RESET: {
      return initialState;
    }
    case actionTypes.MUTATE_START:
    case actionTypes.REQUEST_START: {
      const { queryKey } = action;

      return omit(state, queryKey);
    }
    case actionTypes.MUTATE_FAILURE:
    case actionTypes.REQUEST_FAILURE: {
      const { queryKey } = action;

      return {
        ...state,
        [queryKey]: {
          ...action,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export const errorsSelector = (state: RootState): ErrorState => state.errors;
