import styled from 'styled-components';

export const Container = styled.div`
  cursor: move;
  position: relative;
  z-index: 999;
`;

export const Cell = styled.div<{ isBeingDragged: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => (props.isBeingDragged ? 'white' : '')};
`;
