import DropdownMenu from '@virtus/components/DropdownMenu';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import * as S from './portfolio-fund-selector-style';
import { RootState } from 'src/reducers/rootReducer';
import { PortfolioSagaAction } from 'src/sagas/portfolio/portfolio.saga';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Glide } from 'src/api/queries';
import { Search } from '@virtus/components/Search/Search';
// import SortIcon from '@mui/icons-material/Sort';
import { ListSelection } from '@virtus/components/list-selection';
import { selectedFundURIs } from 'src/reducers/portfolio';
import {
  fundSelectorReducer,
  initialState,
  listPortfoliosAction,
  selectedFundAction,
  setFilteredPortfolios,
  sorttype,
  toggleAction,
} from './portfolio-fund-selector.reducer';
import { ClientViewConfigurationData, ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import Tooltip from '@mui/material/Tooltip';
import { FundSelectorComponentProps, selectViewComponent } from 'src/reducers/components';
import { dispatchActions } from 'src/app/store';
import { GlideObject } from 'src/models/glide/glideObject';
import { formattedDate } from 'src/sagas/date-input/date-input.saga';
import { PortfolioManager, SortIcon } from '@virtus/components/icons';

interface ReduxProps {
  readonly portfolios: any;
  readonly fundURIs: string[];
  fundSelectorComponent: FundSelectorComponentProps;
  datePicker: any;
}

interface ReduxDispatch {
  getPortfolioHoldings: (data: PortfolioQueryParams) => void;
}

interface PortfolioQueryParams {
  portfolioNames: string[];
  fundURIs: string[];
  clientViewConfiguration: ClientViewConfigurationData;
  orderDate: string;
}

interface PortfolioManagerProps {
  clientViewConfiguration: ClientViewConfigurationData;
}

type Props = ReduxProps & ReduxDispatch & PortfolioManagerProps;

const PortfolioFundSelector: React.FunctionComponent<Props> = ({
  portfolios,
  fundURIs,
  getPortfolioHoldings,
  clientViewConfiguration,
  fundSelectorComponent,
  datePicker,
}) => {
  const [searchIconVisible, setSearchIconVisible] = useState(false);
  const [resetSerachText, setResetSerachText] = useState(false);
  const [state, dispatch] = useReducer(fundSelectorReducer, initialState);
  const [isFundSelectorOpen, setIsFundSelectorOpen] = useState(true);
  const [isFundSorted, setIsFundSorted] = useState(false);
  const isCustomType = clientViewConfiguration.client_view_type === ClientViewTypeLookUps.Custom;
  const dropDownRef = useRef(null);
  const isFundSelectorOpened = fundSelectorComponent?.visible;

  useEffect(() => {
    if (portfolios && Array.isArray(portfolios)) {
      dispatch(listPortfoliosAction(portfolios));
      if (fundURIs) {
        dispatch(selectedFundAction(portfolios, fundURIs));
      }
    }
  }, [portfolios, fundURIs]);

  const onFilterChange = (searchValue: string) => {
    // list of portfolios, compliance dashboard will filter out inactive funds
    const _portfolios = isCustomType
      ? portfolios.filter((fund: GlideObject) => !fund.data['fund_inactive'])
      : portfolios;

    if (!searchValue) {
      setSearchIconVisible(false);
      setResetSerachText(true);
      dispatch(setFilteredPortfolios(_portfolios, sorttype.asc));
    } else {
      setSearchIconVisible(true);
      setResetSerachText(false);
      const nextFilteredPortfolios = _portfolios.filter(
        (portfolio: GlideObject) =>
          portfolio.data.display_name != null &&
          portfolio.data.display_name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      dispatch(setFilteredPortfolios(nextFilteredPortfolios, sorttype.asc));
    }
  };

  const toggleSortingOrder = () => dispatch(toggleAction(portfolios));

  const onSearchClick = (e: React.SyntheticEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onSortFilterClick = (e: React.SyntheticEvent<Element>) => {
    setIsFundSorted(!isFundSorted);
    e.stopPropagation();
    e.preventDefault();
    toggleSortingOrder();
  };

  const onMenuClose = () => {
    dispatch(setFilteredPortfolios(portfolios, sorttype.asc));
    setIsFundSelectorOpen(true);
    dispatchActions.components.updateView('fundSelector', clientViewConfiguration.uri, { visible: false });
  };

  const onMenuOpen = () => {
    if (fundURIs) {
      dispatch(selectedFundAction(portfolios, fundURIs));
    }
  };

  const onSelectedItemsChange = (args: any) => {
    if (args.name === 'selectedItemKeys') {
      dispatch(selectedFundAction(portfolios, null, args.value));
    }
  };

  const getPortfolioPositions = () => {
    const filteredPortfolioNames: any[] = [];
    state.selectedItemKeys.map((uri: string) =>
      state.listDataSource.byKey(uri).then((dataItem: any) => {
        return filteredPortfolioNames.push(dataItem);
      }),
    );
    const datePicked = datePicker?.data?.singleDate;
    const data: PortfolioQueryParams = {
      fundURIs: filteredPortfolioNames,
      portfolioNames: filteredPortfolioNames.map((item: any) => item.data.display_name),
      clientViewConfiguration,
      orderDate: datePicked ? new Date(datePicked).toISOString() : formattedDate(new Date()).formattedDate,
    };
    getPortfolioHoldings(data);
    setIsFundSelectorOpen(false);
    dispatchActions.components.updateView('fundSelector', clientViewConfiguration.uri, { visible: false });
  };

  const clearPortfolioSelection = () => {
    dispatch(selectedFundAction(portfolios, null, []));
  };

  const renderListItem = (itemData: any) => (
    <span title={itemData.data.display_name}>{itemData.data.display_name}</span>
  );
  return (
    <S.Container ref={dropDownRef}>
      <DropdownMenu
        button={
          clientViewConfiguration.client_view_type === ClientViewTypeLookUps.Custom ? (
            <Tooltip title="Portfolios">
              <S.IconButton>
                <PortfolioManager />
              </S.IconButton>
            </Tooltip>
          ) : (
            <S.IconButton>
              <PortfolioManager />
            </S.IconButton>
          )
        }
        onClose={onMenuClose}
        onOpen={onMenuOpen}
        keepMenuOpen
        open={isFundSelectorOpen}
        customAnchorEl={isFundSelectorOpened ? dropDownRef?.current : null}
        menuMarginTop={38}
        id="portfolio-fund-selector"
      >
        <S.PortfolioDropdownContentWrapper>
          <S.SearchContainer>
            <Search
              data-testid="layouts-filter-input"
              style={{
                input: { marginRight: '5px', height: '25px', paddingLeft: '5px' },
                row: { height: '25px', marginTop: '7px', marginBottom: '7px' },
                icon: {
                  color: 'var(--foreground-dx-grid-filter-search)',
                  marginTop: '3px',
                  height: '18px',
                  width: '18px',
                },
              }}
              onChange={onFilterChange}
              onClick={onSearchClick}
              resetSerachText={resetSerachText}
              autoFocus={true}
            />
            {searchIconVisible && <S.CloseIcon data-testid="filter-clear-btn" onClick={() => onFilterChange('')} />}
            <SortIcon
              titleAccess="Sort Funds"
              style={{
                display: 'flex',
                color: 'var(--foreground-dx-grid-filter-clear)',
                marginRight: '-5px',
                cursor: 'pointer',
                marginBottom: '2px',
                fontSize: '20px',
                transform: isFundSorted ? 'rotate(360deg) scale(1)' : 'rotate(180deg) scale(1)',
              }}
              onClick={onSortFilterClick}
            />
          </S.SearchContainer>
          <S.MenuItem>
            <ListSelection
              dataSource={state.listDataSource}
              width={250}
              showSelectionControls
              selectionMode="all"
              selectAllMode="allPages"
              pageLoadMode="scrollBottom"
              focusStateEnabled={false}
              selectedItemKeys={state.selectedItemKeys}
              onOptionChanged={onSelectedItemsChange}
              itemRender={renderListItem}
              items={state.filteredPortfolios}
              useNativeScrolling
              showScrollbar="never"
            />
          </S.MenuItem>
          <S.PortfolioDropdownFooter>
            <S.StyledButton
              data-testid="portfolio-dropdown-clear-all-btn"
              disabled={Boolean(state.selectedItemKeys.length === 0)}
              onClick={clearPortfolioSelection}
              isPrimary={false}
              style={{ width: '90px' }}
            >
              Clear All
            </S.StyledButton>
            <S.StyledButton
              data-testid="portfolio-dropdown-apply-btn"
              disabled={Boolean(state.selectedItemKeys.length === 0)}
              onClick={getPortfolioPositions}
              isPrimary={true}
            >
              Apply
            </S.StyledButton>
          </S.PortfolioDropdownFooter>
        </S.PortfolioDropdownContentWrapper>
      </DropdownMenu>
    </S.Container>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { clientViewConfiguration } = ownProps;
  return {
    portfolios: Glide.selector(state, 'portfolios'),
    fundURIs: selectedFundURIs(state, clientViewConfiguration),
    fundSelectorComponent: selectViewComponent(state, 'fundSelector', clientViewConfiguration?.uri),
    datePicker: selectViewComponent(state, 'datePicker', clientViewConfiguration?.uri),
  };
};

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  getPortfolioHoldings: ({ portfolioNames, fundURIs, clientViewConfiguration, orderDate }: PortfolioQueryParams) =>
    dispatch({
      type: PortfolioSagaAction.FETCH_PORTFOLIO_HOLDINGS,
      payload: {
        fullPath: clientViewConfiguration?.uri.lastSplitValue() === 'portfolio' ? '/portfolio' : '/portfolio-analyzer',
        tabName: portfolioNames,
        fundURIs,
        clientViewConfiguration,
        orderDate,
      },
    }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PortfolioFundSelector);
