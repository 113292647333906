import DataGrid from 'devextreme-react/data-grid';
import sortBy from 'lodash/sortBy';
import { auditIcon, filterIcon, resetIcon } from 'src/DxDataGrid/icons/svgicons';
import { DxHeaderText, DxToolbarButton } from 'src/DxDataGrid/model/DxDataGrid.model';

interface DxToolbarOptions {
  /** An array of items displayed by the widget. */
  items?: Array<string | any>;
  /** Specifies a custom template for menu items. */
  menuItemTemplate?: any;
  /** Informs the widget about its location in a view HTML markup. */
  renderAs?: 'bottomToolbar' | 'topToolbar';
}

interface ToolbarPreparingEvent {
  component?: Partial<DataGrid['instance']>;
  element?: any;
  model?: any;
  toolbarOptions?: DxToolbarOptions;
}

export type DxGridHeaderActionsAlign = 'ALL_BEFORE' | 'ALL_AFTER' | 'DEFAULT';
// right now title is just declared but we can add more.
export type DxGridHeaderItems = 'Title';

/*
 * Helpers to work with the DataGrid instance
 */
const getShowFilterRow = (component: Pick<DataGrid['instance'], 'option'>): any =>
  component?.option('filterRow.visible');

const setFiltersVisibility = (
  component: Pick<DataGrid['instance'], 'option'>,
  show: boolean,
  hideHeaderFilter?: boolean,
  hideFilterPanel?: boolean,
) => {
  component.option({
    'filterRow.visible': show,
    'filterPanel.visible': show && !hideFilterPanel,
    'headerFilter.visible': show && !hideHeaderFilter,
  } as any);
};
const toggleShowFilterRow = (
  component: Pick<DataGrid['instance'], 'option'>,
  hideHeaderFilter?: boolean,
  hideFilterPanel?: boolean,
) => {
  const currentShowFilterRow = getShowFilterRow(component);
  setFiltersVisibility(component, !currentShowFilterRow, hideHeaderFilter, hideFilterPanel);
};

const getAlignment = (value: string, alignment: DxGridHeaderActionsAlign) => {
  switch (alignment) {
    case 'ALL_AFTER':
      return 'after';
    case 'ALL_BEFORE':
      return 'before';
    case 'DEFAULT':
      return value;
  }
};

/**
 * Returns the DataGrid onToolbarPreparing prop
 */

interface GetOnToolbarPreparingProps {
  toolbarButtons: { add: any[]; remove: any[]; addAfter: any[]; addBefore: any[] };
  headerButtons?: any;
  headerText?: DxHeaderText;
  showFilterButton?: boolean;
  hideHeaderFilter?: boolean;
  hideFilterPanel?: boolean;
  showRefresh?: boolean;
  onRefresh?: () => void;
  enableLayouts?: boolean;
  moreButtonActions?: any;
  alignment?: DxGridHeaderActionsAlign;
  excludeAlignment?: DxGridHeaderItems[];
  isFilterButtonLeftAligned?: boolean;
  isSearchPanelLeftAligned?: boolean;
  toolbarIconsOrder?: string[];
  repaintChangesOnly?: boolean;
  customDxToolbarButtonsActions?: { [key: string]: () => any };
  toolbarButtonsFromConfig?: DxToolbarButton[];
  columnGrouped?: any;
  onGroupColumnClicked?: (isExpanded: boolean) => void;
  isAuditEnabled?: boolean;
  onAudit?: () => void;
}
export const getOnToolbarPreparing = ({
  toolbarButtons,
  headerButtons,
  headerText,
  showFilterButton,
  hideHeaderFilter,
  hideFilterPanel,
  showRefresh,
  onRefresh,
  onAudit,
  enableLayouts,
  moreButtonActions,
  alignment = 'DEFAULT',
  excludeAlignment,
  isFilterButtonLeftAligned,
  isSearchPanelLeftAligned,
  toolbarIconsOrder,
  repaintChangesOnly,
  customDxToolbarButtonsActions = {},
  toolbarButtonsFromConfig = [],
  columnGrouped,
  onGroupColumnClicked,
  isAuditEnabled,
}: GetOnToolbarPreparingProps) => (e: ToolbarPreparingEvent) => {
  const { component, toolbarOptions } = e;

  let filteredButtons = (toolbarOptions && toolbarOptions.items
    ? toolbarButtons && toolbarButtons.remove
      ? toolbarOptions.items.filter((button: any) => !toolbarButtons.remove.includes(button.name))
      : toolbarOptions.items
    : []
  ).filter((button: any) => button.name !== 'searchPanel' && button.name !== 'groupPanel');

  const searchAndGrouping = toolbarOptions?.items
    ?.filter(fb => fb.name === 'searchPanel' || fb.name === 'groupPanel')
    .reverse();
  searchAndGrouping?.forEach(sg => {
    if (sg.name === 'groupPanel') {
      sg.location = 'after';
    }
    if (isSearchPanelLeftAligned && sg.name === 'searchPanel') {
      sg.location = 'before';
    }
  });

  let index = 0;
  if (headerButtons !== undefined) {
    headerButtons.map((btn: any) => {
      if (btn.location === 'before') {
        filteredButtons.splice(index, 0, {
          location: 'before',
          locateInMenu: 'never',
          widget: 'dxButton',
          options: {
            text: btn.label,
            icon: btn.icon,
            hint: btn.hint,
            onClick: () => btn.onClick(),
          },
        });
        index += 1;
      }
    });
  }

  if (headerText !== undefined && (headerText.title || headerText.description)) {
    filteredButtons.splice(index, 0, {
      location: 'before',
      name: 'Title',
      locateInMenu: 'never',
      template: () => {
        return `<div class='dxgrid-header'>
                  <span class='dxgrid-header-title'>${headerText.title || ''}</span>
                  <span class='dxgrid-header-description'>${headerText.description || ''}</span>
                  </div>`;
      },
    });
    index += 1;
  }

  if (headerButtons !== undefined) {
    headerButtons.map((btn: any) => {
      if (btn.location === 'after') {
        filteredButtons.splice(index, 0, {
          location: 'after',
          locateInMenu: 'never',
          widget: 'dxButton',
          options: {
            text: btn.label,
            icon: btn.icon,
            hint: btn.hint,
            onClick: () => btn.onClick(),
          },
        });
        index += 1;
      }
    });
  }

  const isFilterRowVisible = getShowFilterRow(component as DataGrid['instance']);
  if (columnGrouped && columnGrouped?.isGrouped) {
    filteredButtons.splice(index, 0, {
      name: 'expandCollapseGroupIconButton',
      location: 'before',
      widget: 'dxButton',
      options: {
        hint: columnGrouped?.isExpanded ? 'Collapse Grouped Icon' : 'Expand Grouped Icon',
        icon: columnGrouped?.isExpanded ? 'collapse' : 'expand',
        elementAttr: {
          ['data-testid']: 'expand-collapse-icon-btn',
        },
        onClick: () => {
          onGroupColumnClicked && onGroupColumnClicked(columnGrouped?.isExpanded);
        },
      },
    });
  }
  if (showFilterButton) {
    filteredButtons.splice(index, 0, {
      name: 'filterButton',
      location: isFilterButtonLeftAligned ? 'before' : 'after',
      locateInMenu: 'never',
      widget: 'dxButton',
      options: {
        text: '',
        hint: isFilterRowVisible ? 'Hide Filter' : 'Show Filter',
        icon: filterIcon,
        elementAttr: {
          ['data-testid']: isFilterRowVisible ? 'dxgrid_hide_filter' : 'dxgrid_show_filter',
        },
        onClick: () => {
          toggleShowFilterRow(component as DataGrid['instance'], hideHeaderFilter, hideFilterPanel);
          if (repaintChangesOnly) {
            (component as DataGrid['instance']).refresh();
          }
        },
      },
    });
    index += 1;
  } else {
    // This handles the case when the showFilterButton prop changes from true to false and the filterRow is visible
    if (isFilterRowVisible) setFiltersVisibility(component as DataGrid['instance'], false);
  }

  if (showRefresh) {
    filteredButtons.splice(index, 0, {
      name: 'refreshButton',
      location: 'after',
      locateInMenu: 'never',
      widget: 'dxButton',
      cssClass: 'vertical-separator-line',
      options: {
        icon: resetIcon,
        hint: 'Refresh',
        onClick: () => {
          if (onRefresh) {
            onRefresh();
          } else if (component && component.refresh) component.refresh();
        },
        elementAttr: { 'data-testid': 'toolbar-grid-refresh-btn', class: 'toolbar-grid-refresh-btn' },
      },
    });
  }
  if (isAuditEnabled && onAudit) {
    filteredButtons.splice(index, 0, {
      name: 'auditButton',
      locateInMenu: 'never',
      location: 'after',
      widget: 'dxButton',
      cssClass: 'vertical-separator-line toolbar-audit-trail-btn-disable',
      options: {
        icon: auditIcon,
        hint: 'Audit Trail',
        onClick: () => {
          onAudit();
        },
        elementAttr: { 'data-testid': 'toolbar-audit-trail-btn', class: 'toolbar-audit-trail-btn' },
      },
    });
  }

  if (toolbarButtons && toolbarButtons.addAfter) {
    toolbarButtons.addAfter.forEach((button: any) => filteredButtons.push(button));
  }
  if (toolbarButtons && toolbarButtons.addBefore) {
    toolbarButtons.addBefore.forEach((button: any) => filteredButtons.unshift(button));
  }
  if (toolbarButtons?.add) {
    toolbarButtons.add.forEach((button: any) => filteredButtons.unshift(button));
  }

  if (moreButtonActions) {
    moreButtonActions.map((btn: any) => {
      filteredButtons.push({
        location: 'after',
        widget: 'dxButton',
        name: 'moreBtnActions',
        locateInMenu: 'always',
        options: {
          icon: btn.icon,
          onClick: () => btn.onClick(),
          text: btn.label,
        },
      });
    });
  }

  // Layouts for Glide
  if (enableLayouts) {
    filteredButtons.unshift({
      name: 'layoutDropdown',
      location: 'after',
      template: 'layouts',
    });
  }

  searchAndGrouping?.forEach(sg => !toolbarButtons.remove?.includes(sg.name) && filteredButtons.unshift(sg));

  filteredButtons.forEach(fb => {
    if (alignment !== 'DEFAULT' && !excludeAlignment?.includes(fb.name)) {
      fb.location = getAlignment(fb.location, alignment);
    }
    if (fb?.name !== 'moreBtnActions') {
      fb.locateInMenu = 'never';
    }
  });

  if (toolbarIconsOrder && toolbarIconsOrder.length > 0) {
    filteredButtons = [...filteredButtons, ...toolbarButtonsFromConfig];
    filteredButtons = sortBy(filteredButtons, (btn: any) => toolbarIconsOrder.indexOf(btn.name));
    filteredButtons.forEach(btn => {
      if (btn.name === 'exportButton') {
        btn.cssClass = 'vertical-separator-line';
      }
    });
  }

  if (toolbarOptions && toolbarOptions.items) {
    toolbarOptions.items.map((item: any) => {
      if (customDxToolbarButtonsActions[item.name]) {
        item.options.onClick = customDxToolbarButtonsActions[item.name];
      }
    });
  }

  if (toolbarOptions) toolbarOptions.items = filteredButtons;
};
