import { SearchIcon } from '@virtus/components/Search/Search.style';
import { PivotGrid } from 'devextreme-react/pivot-grid';

import { Texts } from 'devextreme-react/pivot-grid-field-chooser';
import styled from 'styled-components';

/**
 * !!STYLES HERE AFFECT GRIDS IN ALL APPS!!
 * Do not change unless you want to set new styles for all apps
 * it requires approval from all leads (dev, product, design) from Nexus/Glide/Genesis.
 * Please discuss on #dx-datagrid first
 *
 * If you want to override grid styling for your app. You can include a css in your App.ts
 * ie: glide/src/app/App/App.css
 */

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  flex: 1;
`;

export const StyledPivotGridWrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
`;
export const StyledDxPivotGrid = styled(PivotGrid)<{ borderTopColor?: string }>`
  height: 100%;
  overflow: auto !important;
  /* CSS for pivot grid slider */
  overflow-x: hidden !important;
  table.dx-word-wrap {
    table-layout: fixed !important;
    /* CSS for pivot grid slider */
    width: 100%;
    /* CSS for pivot grid slider */
    display: inline-block;

    .dx-pivotgrid-fields-area.dx-area-fields.dx-pivotgrid-drag-action {
      max-width: unset !important;
      overflow: auto !important;
      background: var(--background-dx-pivotgrid-header);
    }

    td.dx-area-description-cell {
      overflow: auto !important;

      .dx-pivotgrid-fields-area.dx-area-fields.dx-pivotgrid-drag-action {
        width: auto !important;
      }
    }

    .dx-scrollable-container {
      overflow: auto !important;
    }

    .dx-pivotgrid-horizontal-headers.dx-pivotgrid-area.dx-scrollable {
      width: 100% !important;
      height: 100% !important;
    }

    .dx-pivotgrid-area.dx-pivotgrid-area-data.dx-scrollable {
      width: 100% !important;
      height: 100% !important;
    }

    .dx-pivotgrid-vertical-headers.dx-pivotgrid-area.dx-scrollable {
      height: 100% !important;
    }

    /* CSS for pivot grid resizing using slider */
    > tr:nth-child(1) {
      display: inline-flex;
      width: 100%;

      > td:nth-child(1) {
        display: inline-block;
        width: 100% !important;
      }
    }

    > tr.dx-bottom-row {
      display: inline-flex;
      width: 100%;

      > td.dx-area-row-cell {
        display: inline-block;
        width: 40%;

        table {
          width: 100% !important;
        }
      }

      > td.dx-area-data-cell {
        display: inline-block;
        width: 60%;

        table {
          width: 100% !important;
        }
      }
    }

    > tr:not(:nth-child(1)) {
      display: inline-flex;
      width: 100%;

      > td:nth-child(1) {
        display: inline-block;
        width: 40%;

        &.dx-area-description-cell table {
          width: 100% !important;
        }
      }

      > td:nth-child(2) {
        display: inline-block;
        width: 60%;

        &.dx-area-column-cell table {
          width: 100% !important;
        }
      }
    }
  }
`;

export const StyledPivotGridFieldChooser = styled.div`
  min-width: 30%;
  .side-field-chooser.dx-pivotgridfieldchooser {
    height: 100%;
  }

  .side-field-chooser.dx-pivotgridfieldchooser .dx-area-fields-header {
    display: none;
  }
  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:first-child {
    width: 100%;
  }

  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:last-child {
    display: none;
  }
  .side-field-chooser.dx-pivotgridfieldchooser .dx-area .dx-area-fields {
    margin-top: 0;
  }
`;

export const StyledTexts = styled(Texts)``;

export const PageStyledPivotGridWrapper = styled.div`
  flex: 2;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const PageStyledFiledChooser = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  .side-field-chooser.dx-pivotgridfieldchooser {
    height: 100%;
  }
  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:first-child {
    width: 100%;
  }

  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:last-child {
    display: none;
  }
`;

export const StyledRowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 0 0 0 15px;
`;

export const StyledPivotViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 1px solid var(--grey);
`;

export const StyledToolBarWrapper = styled.div`
  width: 100%;
`;

export const StyledTabWrapper = styled.div`
  height: 100%;
`;

export const StyledSettingsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 200px;

  label {
    font-size: 14px !important;
  }

  .MuiSwitch-root {
    position: absolute;
    right: 0;
  }
`;

export const StyledSettingsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: var(--foreground-dx-pivotgrid-search-icon);
  height: 18px;
  width: 18px;
`;
