import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export type FillProps = { fillColor?: string };

const InfoIcon: React.SFC<SvgIconProps & FillProps> = props => (
  <SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>Information</title>
    <path
      fill={props.fillColor || 'var(--background-snackbar-header-info)'}
      d="M16 31.218c-8.405 0-15.218-6.813-15.218-15.218s6.813-15.218 15.218-15.218c8.405 0 15.218 6.813 15.218 15.218v0c-0.010 8.4-6.817 15.208-15.217 15.218h-0.001zM16 2.773c-7.305 0-13.227 5.922-13.227 13.227s5.922 13.227 13.227 13.227c7.305 0 13.227-5.922 13.227-13.227v0c-0.010-7.301-5.926-13.217-13.226-13.227h-0.001zM14.311 12.604h3.378v11.502h-3.378v-11.502zM14.311 7.876h3.378v3.147h-3.378v-3.147z"
    ></path>
  </SvgIcon>
);

export default InfoIcon;
