import styled from 'styled-components';
import { Dialog as MUIDialog } from '@mui/material';

export const StyledRowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 5px 25px 25px 25px;
  height: 100%;
`;

export const CreditDetailWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--background-form);
  padding: 20px 40px 20px 20px;
  overflow: hidden;
`;

export const Grid = styled.div<{ groups: number }>`
  display: flex;
  flex-direction: column;
  width: 40%;
  @supports (-moz-appearance: none) {
    margin-bottom: 20px;
  }
`;

export const GridContainer = styled.div`
  overflow: auto;
  padding-right: 10px;
  height: 100%;
  & + div[data-testid='glide-inspector-footer'] {
    border-left: var(--foreground-form-border);
    border-right: var(--foreground-form-border);
  }
`;

export const TabPanel = styled.div`
  flex: 1;
  margin: 0 10px;
  background: rgba(0, 0, 0, 0.87);
  @supports (-moz-appearance: none) {
    margin-bottom: 20px;
  }
`;

export const Cell = styled.div<{ $takeAllHeightAvailable?: boolean; background?: boolean }>`
  padding: 16px;
  height: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex: ${props => (props.$takeAllHeightAvailable ? 1 : 'none')};
  border: 1px solid #a7a7a7;
  background: ${props => (props.background ? 'var(--background-form)' : 'var(--bgInspectorFullscreenContent)')};
  margin: 10px 0;
`;

export const CellHeader = styled.div`
  font-size: var(--headerFontSize);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--foreground-form-header);
  margin-bottom: 10px;
`;

export const Dialog = styled(MUIDialog)<{ display_type?: string; groupsLength: number }>`
  height: ${props => (props.groupsLength > 1 ? 100 : 70)}vh;
  margin: auto;
  width: ${props => (props.groupsLength > 1 ? 100 : 50)}vw;
  padding: 45px 50px 20px;
  & > div {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    & > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;

export const DXTabsClassesOverride = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .dx-tabpanel.dx-multiview {
    height: 100%;
    display: flex;
    flex-direction: column;
    .dx-tabs {
      background-color: transparent;
    }
  }
  .dx-tabs-wrapper {
    background-color: var(--background-tab-group);
    .dx-tab-content,
    .dx-tab-text {
      vertical-align: middle;
      text-transform: capitalize;
      line-height: 24px;
      font-weight: 500;
    }
    .dx-tab {
      color: var(--foreground-tab);
      position: relative;
      display: table-cell;
      vertical-align: middle;
      cursor: pointer;
      white-space: nowrap;
      float: left;
      padding: 5px 16px !important;
      background-color: var(--background-tab-group);
      border-right: var(--foreground-tab-vertical-separator-border-right);
      .dx-tab-content {
        text-transform: capitalize;
        font-size: var(--tab-font-size);
        font-weight: 400;
      }
      &:hover {
        color: var(--foreground-tab);
        background-color: var(--bgLight);
      }
      .dx-tabs.dx-state-focused .dx-tab.dx-state-focused {
        background-color: var(--bgLight) !important;
        color: var(--foreground-tab);
      }
    }
    .dx-tab-selected {
      background-color: var(--bgLight) !important;
      color: var(--foreground-tab) !important;
      border-right: var(--foreground-tab-vertical-separator-border-right);
      border-top: var(--foreground-tab-vertical-separator-border-right);
      border-left: var(--foreground-tab-vertical-separator-border-right);
    }
  }
  .dx-tabs-scrollable .dx-tabs-wrapper {
    display: inline-flex;
    white-space: nowrap;
    height: 100%;
  }
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .dx-datagrid .dx-row > td {
    padding: 5px;
  }
`;
