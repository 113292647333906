import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const FixTopIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 19 19" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <rect fill="#fff" style={{ opacity: 0, strokeWidth: '0px' }} width="19" height="19" />
      <g>
        <rect fill="#012834" style={{ strokeWidth: '0px' }} x="2.3" y="2.43" width="14.4" height="1.14" />
        <polygon
          fill="#012834"
          style={{ strokeWidth: '0px' }}
          points="8.93 7.23 6.03 10.15 5.21 9.35 9.5 5.02 13.79 9.35 12.97 10.15 10.07 7.23 10.07 16.57 8.93 16.57 8.93 7.23"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FixTopIcon;
