import styled from 'styled-components';

export const Label = styled.label`
  min-width: 100px;
  margin-bottom: 10px;
`;

export const FormControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  padding-bottom: 5px;
`;

export const ErrorBlock = styled.div`
  margin-left: 120px;
  line-height: 1;
`;

export const ErrorMsg = styled.span`
  color: var(--red);
`;
