import styled from 'styled-components';
import { InputBase } from '@mui/material';
export const TagStyle = styled.div`
  background: var(--background-form);
  color: var(--foreground-form);
  order: 1;
  width: 100%;
  overflow-wrap: break-word;
  text-align: center;
  font-size: var(--form-input-font-size);
  letter-spacing: 0px;
  border-radius: 12px;
  padding: 0px;
  border: var(--foreground-form-btn-border);
  font-weight: 700;
`;
export const DateStyle = styled.div`
  margin-left: 8px;
  color: var(--foreground-form);
  font-size: var(--form-input-font-size);
  letter-spacing: 0px;
  order: 2;
`;
export const BlogOuterWrapper = styled.div`
  flex: 2.4;
  background-color: --var(bgDark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* padding-top: 10px; */
`;
export const CommentButton = styled.div`
  align-self: center;
`;
export const BlogInnerWrapper = styled.div<{ fromModal?: boolean }>`
  padding: ${props => (props.fromModal ? '0 10px' : '0px 8px 0px 13px')};
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: var(--foreground-glide-comentary-ddl-border);
  height: 45px;
  background: var(--background-dx-pivotgrid-header);
`;
export const BlogTitleWrapper = styled.h4<{ sortOrder?: number }>`
  color: var(--foreground-form);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: var(--inspector-font-size);
  font-weight: 700;
  height: 22px;
  letter-spacing: 0px;
`;
export const ListItem = styled.div`
  overflow-y: scroll;
  flex: 1;
  /* background: var(--background-glide-commentary-list-item); */
  height: 100%;
  display: block;
  overflow-x: hidden;
  padding-top: 13px;
  padding-right: 12px;
  padding-left: 13px;
`;
export const BlogAuthorWrapper = styled.p<{ sortOrder?: number }>`
  color: var(--foreground-form);
  font-weight: 700;
  height: 20px;
  letter-spacing: 0px;
  font-size: var(--form-input-font-size);
  margin-top: 6px;
  margin-bottom: 9px;
`;
export const BlogCommentsWrapper = styled.h5<{ sortOrder?: number }>`
  color: var(--foreground-form);
  letter-spacing: 0px;
  font-size: var(--form-input-font-size);
`;
export const BlogLeftSection = styled.div`
  flex: 3;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  line-break: strict;
  hyphens: none;
  color: var(--foreground-form);
  padding-top: 7px;
`;
export const BlogRightSection = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 10px;
  padding-top: 7px;
  padding-right: 15px;
`;
export const BlogSection = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  border: var(--foreground-glide-comentary-ddl-border);
  background: var(--background-form);
  margin-bottom: 12px;
  height: 117px;
  overflow-y: auto;
`;
export const StyledSearchInput = styled.div`
  display: flex;
  align-items: center;
  border: 1px;
  padding: 10px 10px 5px 10px;
  svg:nth-of-type(1) {
    height: 31px;
    background-color: var(--label);
  }

  svg:nth-of-type(2) {
    height: 31px;
    right: 0;
    background-color: var(--label);
  }
`;
export const SelectedItemStyle = styled.div<{ isActive?: boolean }>`
  margin-right: 10px;
  width: 4px;
  background: ${props =>
    props.isActive
      ? 'var(--background-collapser-toggle-container)'
      : 'var(--background-glide-commentary-list-item-verticle-line)'};
`;
export const StyledInput = styled(InputBase)<{ margin: string; label: string; flex: string } & any>`
  color: var(--foreground-form);
  width: auto;
  height: 23px;
  border: var(--foreground-form-dropdown-boder);
  border-radius: 4px;
  background: var(--background-form);
  padding: 0 8px;
  ${props => (props.flex ? `flex: ${props.flex}` : '')};

  &.Mui-focused {
    border: var(--foreground-form-input-focus-border);
  }

  & > input {
    font-size: var(--form-input-font-size);
  }

  & > input:-webkit-autofill,
  & > input:-internal-autofill-selected,
  & > input:-internal-autofill-selected:hover,
  & > input:-internal-autofill-selected:focus,
  & > input:-internal-autofill-selected:active,
  & > input:-webkit-autofill:hover,
  & > input:-webkit-autofill:focus,
  & > input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    font-size: var(--form-input-font-size);
    height: 10px;
    font-weight: normal;
  }
`;
