import styled from 'styled-components';
import { SecondaryButton } from '@virtus/components/Buttons';

export const Button = styled(SecondaryButton)`
  margin: auto;
  :hover {
    background-color: var(--bg);
    border-bottom: 1px solid var(--blue);
  }
`;

export const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const GlideCopyRight = styled.span`
  font-size: var(--footer-font-size);
  letter-spacing: 0px;
`;
export const GlideVersion = styled.span`
  letter-spacing: 0px;
  font-size: var(--footer-font-size);
`;

export const GlideFooter = styled.div`
  width: 100%;
  height: 21px;
  background: var(--background-footer);
  color: var(--foreground-footer);
  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  position: fixed;
  bottom: 0px;
`;
