import React from 'react';
import { ObjectCollectionCellRender } from '../components/cell-renders/objectcollection/objectcollection';
import { GlideSchema, GlideDataType } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { CustomCellRenderKeyProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';

const filterObjectCollectionColumns = (column: GlideSchema) => {
  return (
    !['Blog', 'Covenants', 'KPIs'].includes(column.display_name) && column.data_type === GlideDataType.ObjectCollection
  );
};

const getCellRenderForObjectCollectionColumns = (
  acc: CustomCellRenderKeyProps,
  column: GlideSchema,
  onClick: (cellData: CellData) => void,
) => {
  const key = (column.display_name || column.caption) as string;
  const isLinkEnabled = column.enable_link;
  return {
    ...acc,
    [key]: {
      cellRender: (cellData: CellData) => (
        <ObjectCollectionCellRender cellData={cellData} onClick={onClick} isLinkEnabled={isLinkEnabled} />
      ),
    },
  };
};

export const getObjectCollectionColumns = (
  columns: Array<GlideSchema> = [],
  onObjectCollectionColumnCellClick: (cellData: CellData) => void,
) =>
  columns
    .filter(filterObjectCollectionColumns)
    .reduce(
      (acc: CustomCellRenderKeyProps, column: GlideSchema) =>
        getCellRenderForObjectCollectionColumns(acc, column, onObjectCollectionColumnCellClick),
      {},
    );
