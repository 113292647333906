/*
⚠️⚠ TECH DEBT: DO NOT USE OR MAINTAIN ️
SHARED STATES NEED TO BE HANDLED in STORE!!
Migrate to reducer if further maintenance is needed to save time and avoid
bugs!
*/
import { useCallback, useEffect, useState } from 'react';
import {
  getErrorsPerFormGroup,
  populateFormGroupsWithErrors,
  validateForm,
} from 'src/components/forms/common/validation';
import { FormGroupsType, FormValuesType } from 'src/components/forms/form-elements/FormElement.model';
import { getInitialFormState } from 'src/components/forms/common/initialization';
import { useStateRef } from 'src/hooks/useStateRef';

export const getNextFormGroupState = ({
  formGroupsState,
  enableEdit,
}: {
  formGroupsState: { [key: string]: FormValuesType };
  enableEdit: boolean;
}) => {
  const nextFormGroupsState = Object.entries(formGroupsState).reduce(
    (formGroupsAcc, [formGroup, values]) => ({
      ...formGroupsAcc,
      [formGroup]: {
        ...formGroupsState[formGroup],
        formErrors: {},
        formFields: Object.entries(values.formFields).reduce(
          (formFieldsAcc: any, field: any) => ({
            ...formFieldsAcc,
            [field[0]]: {
              ...field[1],
              disabled: !enableEdit,
            },
          }),
          {},
        ),
        hasChanged: false,
      },
    }),
    {},
  );

  return nextFormGroupsState;
};

interface UseFormGroupProps {
  onSubmit: (data: { [key: string]: { [key: string]: string } }) => void;
  onValidationError?: (errors: any) => void;
  objectKey?: string;
  initialEditing?: boolean;
}

export const getFormGroupWithErrors = (nextFormGroupsState: any) => {
  const formValidationErrors = getErrorsPerFormGroup(nextFormGroupsState);
  return populateFormGroupsWithErrors(formValidationErrors, nextFormGroupsState);
};

export const useFormGroup = ({
  onSubmit,
  onValidationError,
  objectKey = 'order',
  initialEditing = false,
}: UseFormGroupProps) => {
  const [formGroups, setFormGroups] = useState<FormGroupsType | undefined>(undefined);
  const [formGroupsState, setFormGroupsState, formGroupsStateRef] = useStateRef({});
  const [isEditing, setIsEditing] = useState(initialEditing);
  const [, setFormChanges, formChangesRef] = useStateRef({});

  useEffect(() => {
    if (formGroups) {
      const nextFormGroupsState = getInitialFormState({ formGroups, isEditing: initialEditing });
      if (isEditing !== initialEditing) {
        setIsEditing(initialEditing);
      }
      setFormGroupsState(getFormGroupWithErrors(nextFormGroupsState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formGroups, initialEditing]);

  const enableEditMode = () => {
    const nextFormGroupsState = getNextFormGroupState({
      formGroupsState: formGroupsStateRef.current,
      enableEdit: true,
    });
    setFormGroupsState(getFormGroupWithErrors(nextFormGroupsState));
    setIsEditing(true);
  };

  const disableEditMode = useCallback(() => {
    const nextFormGroupsState = getNextFormGroupState({
      formGroupsState: formGroupsStateRef.current,
      enableEdit: false,
    });
    setFormGroupsState(nextFormGroupsState);
    setIsEditing(false);
  }, [formGroupsState]);

  const onChangeField = (change: { [key: string]: string }, formGroupName: string) => {
    const formGroup = formGroupsState[formGroupName];
    const [key, value] = Object.entries(change)[0];
    if (formGroup.formValues[key] === value) return;

    setFormChanges((prevFormChanges: any) => {
      return {
        ...prevFormChanges,
        ...change,
      };
    });

    setFormGroupsState((prevFormGroupsState: any) => {
      const nextFormValues = {
        ...prevFormGroupsState[formGroupName].formValues,
        ...change,
      };
      const nextFormErrors = validateForm(nextFormValues, prevFormGroupsState[formGroupName].formFields);
      return {
        ...prevFormGroupsState,
        [formGroupName]: {
          ...formGroup,
          formErrors: nextFormErrors,
          formValues: nextFormValues,
          hasChanged: true,
        },
      };
    });
  };

  const onSave = () => {
    const formValidationErrors = getErrorsPerFormGroup(formGroupsStateRef.current);
    const thereAreErrors = Object.keys(formValidationErrors).length;

    if (thereAreErrors) {
      const formGroupsWithErrors = populateFormGroupsWithErrors(formValidationErrors, formGroupsStateRef.current);
      let errorMessage = '';
      Object.values(formValidationErrors).forEach((errorObjects: any) => {
        Object.values(errorObjects).forEach(
          message =>
            (errorMessage = `${errorMessage}${message}.
        `), //Adding a new Line after each error message in case of multiple message
        );
      });
      onValidationError && onValidationError(errorMessage);
      setFormGroupsState(formGroupsWithErrors);
      return errorMessage;
    }

    onSubmit({ [`${objectKey}`]: formChangesRef.current });
    return disableEditMode();
  };

  return {
    onSave,
    onChangeField,
    enableEditMode,
    disableEditMode,
    isEditing,
    setFormGroups,
    formGroupsState,
    formGroupsStateRef,
  };
};
