import React, { CSSProperties, FC, ReactNode } from 'react';
import { PrimaryButton as PB } from 'src/Buttons';
import { Footer } from './InspectorFooter.style';
import { CancelButton as SB } from 'src/Buttons/CancelButton/CancelButton';
import styled from 'styled-components';

export type InspectorFooterProps = {
  onApply?: (e: any) => void;
  onCancel?: () => void;
  disabled?: boolean;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  confirmButtonText?: string;
  actions?: ReactNode;
  hideActions?: boolean;
  children?: ReactNode;
  buttonsContainerStyle?: CSSProperties;
  isLight?: boolean;
};

export const DefaultButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

export const PrimaryButton = styled(PB)`
  margin-left: 25px;
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-primary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 18px;

  &:hover {
    background: var(--background-form-primary-btn);
  }
  &:disabled {
    color: var(--foreground-form);
    opacity: 0.5;
    background: var(--background-form-primary-btn);
  }
`;

export const CancelButton = styled(SB)<{ backgroundcolor?: string }>`
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-secondary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 23px;

  &:hover {
    background: var(--background-form-secondary-btn);
  }
`;

const InspectorFooter: FC<InspectorFooterProps> = ({
  onCancel,
  onApply,
  disabled,
  hideCancel,
  hideConfirm,
  confirmButtonText = 'Apply',
  actions,
  hideActions,
  buttonsContainerStyle,
  isLight,
}) => {
  return (
    <Footer isLight={isLight} data-testid="inspector-footer" style={buttonsContainerStyle}>
      {!hideActions && actions && <section data-testid="other-btn">{actions}</section>}
      {!hideCancel && (
        <CancelButton data-testid="secondary-btn" onClick={onCancel} color="inherit">
          Cancel
        </CancelButton>
      )}
      {!hideConfirm && (
        <PrimaryButton
          data-testid="primary-btn"
          onClick={onApply}
          disabled={disabled}
          variant="contained"
          color="secondary"
          type="submit"
        >
          {confirmButtonText}
        </PrimaryButton>
      )}
    </Footer>
  );
};

export default InspectorFooter;
