import styled from 'styled-components';
import BasicButton from '../Basic/BasicButton';

export const CancelButton = styled(BasicButton)<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor || 'var(--error)'};
  &:hover {
    background-color: ${props => props.backgroundColor || 'var(--errorLight)'};
  }
`;

export default CancelButton;
