import ArrayStore from 'devextreme/data/array_store';

export enum sorttype {
  asc = 'asc',
  desc = 'desc',
}

export interface FundSelectorState {
  sortingOrder: sorttype;
  listDataSource: any;
  selectedItemKeys: any;
  filteredPortfolios: any[];
}

interface FundSelectorActionPayload {
  portfolios: any;
  fundURIs?: any;
  sort?: string;
  value?: any;
}

export enum FundSelectorActions {
  SET_LIST_DATASOURCE = 'SET_LIST_DATASOURCE',
  SET_SELECTED_ITEM_KEYS = 'SET_SELECTED_ITEM_KEYS',
  SET_FILTERED_PORTFOLIOS = 'SET_FILTERED_PORTFOLIOS',
  TOGGLE_SORTING = 'TOGGLE_SORTING',
  RESET = 'RESET',
}

export const initialState = {
  sortingOrder: sorttype.asc,
  listDataSource: {},
  selectedItemKeys: [],
  filteredPortfolios: [],
};

const getSortedPortfolios = (portfolios: any, sortingOrder: sorttype.asc | sorttype.desc) => {
  if (!portfolios || !portfolios.length) return [];
  const portfolioNames = portfolios.filter((portfolio: any) => portfolio['data'].display_name);
  sortingOrder === sorttype.asc
    ? portfolioNames.sort((a: any, b: any) => a.data.display_name.localeCompare(b.data.display_name))
    : portfolioNames.sort((a: any, b: any) => b.data.display_name.localeCompare(a.data.display_name));
  return [...portfolioNames];
};

export const fundSelectorReducer = (
  state: FundSelectorState,
  action: {
    type: FundSelectorActions;
    payload?: FundSelectorActionPayload;
  },
) => {
  switch (action.type) {
    case FundSelectorActions.SET_LIST_DATASOURCE: {
      const sortingOrder: any = action.payload?.sort ?? state.sortingOrder;
      const data = new ArrayStore({
        key: 'uri',
        data: getSortedPortfolios(action.payload?.portfolios, sortingOrder),
      });
      return {
        ...state,
        listDataSource: data,
      };
    }
    case FundSelectorActions.SET_FILTERED_PORTFOLIOS: {
      const sortingOrder: any = action.payload?.sort ?? state.sortingOrder;
      return {
        ...state,
        filteredPortfolios: getSortedPortfolios(action.payload?.portfolios, sortingOrder),
      };
    }
    case FundSelectorActions.SET_SELECTED_ITEM_KEYS: {
      const filteredItemKeys =
        action.payload?.value ??
        action.payload?.fundURIs.filter((fundURI: any) =>
          action.payload?.portfolios?.some((portfolio: any) => fundURI === portfolio.uri),
        );
      return {
        ...state,
        selectedItemKeys: filteredItemKeys,
      };
    }
    case FundSelectorActions.TOGGLE_SORTING: {
      const dataToSort: any = action.payload?.portfolios;
      if (state.sortingOrder === sorttype.asc) {
        return {
          ...state,
          sortingOrder: sorttype.desc,
          filteredPortfolios: getSortedPortfolios(dataToSort, sorttype.desc),
        };
      } else {
        return {
          ...state,
          sortingOrder: sorttype.asc,
          filteredPortfolios: getSortedPortfolios(dataToSort, sorttype.asc),
        };
      }
    }
    case FundSelectorActions.RESET:
      return initialState;
    default:
      return state;
  }
};

export const listPortfoliosAction = (portfolios: any, sort?: any) => ({
  type: FundSelectorActions.SET_LIST_DATASOURCE,
  payload: { portfolios, sort },
});

export const setFilteredPortfolios = (portfolios: any, sort?: any) => ({
  type: FundSelectorActions.SET_FILTERED_PORTFOLIOS,
  payload: { portfolios, sort },
});

export const selectedFundAction = (portfolios: any, fundURIs?: any, value?: any) => ({
  type: FundSelectorActions.SET_SELECTED_ITEM_KEYS,
  payload: { portfolios, fundURIs, value },
});

export const toggleAction = (portfolios: any) => ({
  type: FundSelectorActions.TOGGLE_SORTING,
  payload: { portfolios },
});
