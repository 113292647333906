import DxDataGrid, {
  DxDataGridProps as _DxDataGridProps,
  CustomCellRenderKeyProps as _CustomCellRenderKeyProps,
  DxColumn as _DxColumn,
  DxDataType as _DxDataType,
  DxSummary as _DxSummary,
  DxSummaryType as _DxSummaryType,
  ILinkedColumn as _ILinkedColumn,
} from './DxDataGrid';

export * from './DxDataGrid.model';
export type DxDataGridProps = _DxDataGridProps;
export type CustomCellRenderKeyProps = _CustomCellRenderKeyProps;
export type DxColumn = _DxColumn;
export type DxDataType = _DxDataType;
export type DxSummary = _DxSummary;
export type DxSummaryType = _DxSummaryType;
export type ILinkedColumn = _ILinkedColumn;

export enum PositionDetailsRatingColors {
  B1 = '#21E500',
  B2 = '#3DE600',
  B3 = '#5AE700',
  Ba1 = '#77E800',
  Ba2 = '#95E900',
  Ba3 = '#B2EA00',
  Baa1 = '#D0EB00',
  Baa2 = '#ECEA00',
  Baa3 = '#EDCD00',
  Caa1 = '#EEB100',
  Caa2 = '#EF9400',
  Caa3 = '#F07700',
  NR = '#F15900',
  WR = '#F23B00',
}

export default DxDataGrid;
