import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const CalendarIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#FFF" strokeWidth={0} opacity={0} width="16" height="16" />
      <path
        fill="currentColor"
        strokeWidth={0}
        width="16"
        height="16"
        d="M17.83,2.76v14.99H1.17V2.76h4.17v-1.51h1.16v1.51h6.06v-1.51h1.11v1.51h4.17ZM2.29,4.87h14.43v-.98h-3.01v.53h-1.12v-.53h-6.1v.53h-1.21v-.53h-2.99v.98ZM16.71,5.99H2.29v10.67h14.41V5.99ZM3.59,10.86h1v-1.42h-1v1.42ZM3.59,13.22h1v-1.41h-1v1.41ZM3.59,15.57h1v-1.41h-1v1.41ZM5.4,7.08v1.41h1v-1.41h-1ZM5.4,10.86h1v-1.42h-1v1.42ZM5.4,13.22h1v-1.41h-1v1.41ZM5.4,15.57h1v-1.41h-1v1.41ZM7.2,7.08v1.41h1v-1.41h-1ZM7.2,10.86h1v-1.42h-1v1.42ZM7.2,13.22h1v-1.41h-1v1.41ZM7.2,15.57h1v-1.41h-1v1.41ZM9.01,7.08v1.41h.98v-1.41h-.98ZM9.01,9.44v1.42h.98v-1.42h-.98ZM9.01,11.81v1.41h.98v-1.41h-.98ZM9.01,14.17v1.41h.98v-1.41h-.98ZM10.8,7.08v1.41h1v-1.41h-1ZM10.8,10.86h1v-1.42h-1v1.42ZM10.8,13.22h1v-1.41h-1v1.41ZM10.8,15.57h1v-1.41h-1v1.41ZM12.59,7.08v1.41h1v-1.41h-1ZM12.59,10.86h1v-1.42h-1v1.42ZM12.59,13.22h1v-1.41h-1v1.41ZM14.4,7.08v1.41h1v-1.41h-1ZM14.4,10.86h1v-1.42h-1v1.42ZM14.4,13.22h1v-1.41h-1v1.41Z"
      />
    </g>
  </SvgIcon>
);

export default CalendarIcon;
