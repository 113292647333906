/*
⚠️⚠ TECH DEBT: DO NOT USE OR MAINTAIN ️
SHARED STATES NEED TO BE HANDLED in STORE!!
Migrate to reducer if further maintenance is needed to save time and avoid
bugs!
*/
import { useEffect, useRef, useState } from 'react';

// A hook that allows to use the ref as a reference to the state value

export const useStateRef = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
};
