import { Button, Menu, MenuItem, PopoverProps } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { ComponentType, useState } from 'react';
import styled from 'styled-components';
import { ArrowForwardIos, PermIdentity } from '@mui/icons-material';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';
import { useConfirmationDialog } from 'src/withConfirmationDialogOnClick/withConfirmationDialogOnClick';

const styles = {
  menuStyle: {
    marginTop: 26,
    marginLeft: 6,
    borderRadius: '2px',
    background: 'var(--app-bar-profile-items-background)',
    width: '179px',
    minHeight: '68px',
    '&::before': {
      content: `''`,
      position: 'fixed',
      top: '36px',
      right: '23px',
      width: '0',
      height: '0',
      border: 'var(--app-bar-profile-menu-icon-border)',
      borderTopColor: 'var(--app-bar-profile-menu-icon-border-top)',
      transform: 'rotate(135deg)',
      transition: 'border 0.3s ease-in-out',
    },
  },
};

export interface TopRightMenuItemProps {
  routePath?: string;
  text: string;
  icon?: React.SFC<SvgIconProps>;
  onClick: () => void;
  component?: ComponentType;
  isIdleOnClick?: boolean;
}

interface NavigationBarMenuProps {
  topRightMenuItems?: TopRightMenuItemProps[];
  buttonText: string | number;
  buttonIcon: React.ReactType<any>;
  classes?: any;
  isInspectorFormDirty?: boolean;
}

const NavigationBarMenu = (props: NavigationBarMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | ((el: Element) => Element) | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const getItemWidth = () => {
    return document.getElementById('top-right-button')
      ? window.getComputedStyle(document.getElementById('top-right-button')!).getPropertyValue('width')
      : 'auto';
  };

  const closeInspectorDialog = (menuItem: any) => {
    menuItem.onClick();
    if (!menuItem.isIdleOnClick) handleClose();
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const onClickhandler = (menuItem: any) => {
    if (props.isInspectorFormDirty) {
      displayConfirmationDialog(menuItem);
    } else {
      menuItem.onClick();
    }
    if (!menuItem.isIdleOnClick) handleClose();
  };

  const renderTopRightMenuItems = (_topRightMenuItems: TopRightMenuItemProps[] | undefined) => {
    if (!_topRightMenuItems) return null;
    return _topRightMenuItems.map(menuItem => (
      <MenuItemStyledProfile
        key={`menu-item-${menuItem.text}`}
        width={getItemWidth()}
        onClick={() => onClickhandler(menuItem)}
      >
        <MenuItemText>
          {React.createElement(ArrowForwardIos, {
            style: {
              fontSize: 'var(--forwardArrowFontSize)',
              position: 'relative',
              top: '1px',
              left: '-2px',
            },
          })}
          {menuItem.text}
          {menuItem.component as any}
        </MenuItemText>
      </MenuItemStyledProfile>
    ));
  };

  const { topRightMenuItems, buttonText, buttonIcon, classes } = props;

  return (
    <div>
      <QuitInspector />
      <ButtonStyled
        id="top-right-button"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <ProfileIconContainer></ProfileIconContainer>
        {React.createElement(buttonIcon, {
          style: { fontSize: 'var(--app-bar-profile-dropdown-icon-font-size)', position: 'relative', top: '-1px' },
        })}
      </ButtonStyled>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl as PopoverProps['anchorEl']}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuStyle }}
      >
        <MenuItem>
          <ItemText title={buttonText as string}>{buttonText}</ItemText>
        </MenuItem>
        {renderTopRightMenuItems(topRightMenuItems)}
      </Menu>
    </div>
  );
};

const ButtonStyled = styled(Button)`
  border-radius: 2px;
  padding: 0px;
  padding-left: 20px;
  background-color: var(--background-app-bar-profile);
  color: var(--foreground-text);
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: none;
  &:hover {
    background-color: var(--background-app-bar-profile);
  }
  & > span.MuiButton-label {
    display: flex;
  }
`;
const MenuItemStyled = styled(MenuItem)<{ width: string }>`
  font-size: var(--menuItemFontSize);
  font-weight: bold;
  min-width: ${props => props.width};
  height: 30px;
  padding: 0px;
  margin: 0px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MenuItemStyledRoutes = styled(MenuItemStyled)`
  padding-left: 10px;
  color: var(--foreground-text);
  background-color: var(--background-app-bar-dropdown-items);
  &:focus {
    background-color: var(--background-app-bar-dropdown-items);
  }
  &:hover {
    background: var(--background-app-bar-dropdown-items-hover);
  }
`;

const MenuItemStyledProfile = styled(MenuItemStyled)`
  background-color: var(--background-app-bar-profile-panel);
  color: var(--foreground-app-bar-profile-items);
  width: 162px;
  height: 26px;
  left: 8px;
  font-size: var(--app-bar-profile-menu-item-font-size);
  font-weight: 500;
  &:hover {
    background: var(--background-app-bar-profile-items-hover);
    color: var(--foreground-app-bar-profile-items-hover);
    border: 1px solid var(--foreground-app-bar-profile-panel-border);
  }
`;

const ProfileIconContainer = styled(PermIdentity)`
  position: relative;
  top: -1px;
  font-size: var(--app-bar-profile-icon-font-size);
`;

export const MenuItemText = styled.div`
  display: flex;
  padding-left: 11px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.28px;
`;

const ItemText = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: var(--foreground-app-bar-profile-items);
  font-size: var(--app-bar-profile-menu-item-font-size);
  font-weight: bold;
  margin: 0px 0px 0px -8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 141px;
  height: 20px;
`;

export default withStyles(NavigationBarMenu, styles);
