import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const FixBottomIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 19 19" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <rect
        fill="#fff"
        style={{ opacity: 0, strokeWidth: '0px' }}
        width="19"
        height="19"
        transform="translate(19 19) rotate(180)"
      />
      <g>
        <rect fill="#012834" style={{ strokeWidth: '0px' }} x="2.3" y="15.43" width="14.4" height="1.14" />
        <polygon
          fill="#012834"
          style={{ strokeWidth: '0px' }}
          points="10.07 11.77 12.97 8.85 13.79 9.65 9.5 13.98 5.21 9.65 6.03 8.85 8.93 11.77 8.93 2.43 10.07 2.43 10.07 11.77"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FixBottomIcon;
