import { getVirtusDate, getVirtusDateTime } from '@virtus/common/utils/dateFormater';
import { formatNumber } from '@virtus/glide/src/utils/formatters';
import percentFormat from '@virtus/common/utils/percentFormat';
import { DataType, dataTypeMap, NexusDataType } from 'src/DxDataGrid/utils/mapSchemaVirtus';

/**
 * Checks if a numericPrecision is valid
 */
const _isNumericPrecisionValid = (numericPrecision: number) => !isNaN(numericPrecision) && numericPrecision <= 9;

/**
 * Returns the numericPrecision if it's valid or undefined
 */
const _sanitizeNumericPrecision = (numericPrecision?: number) => {
  const numericPrecisionNumber = Number(numericPrecision);
  return _isNumericPrecisionValid(numericPrecisionNumber) ? numericPrecisionNumber : undefined;
};

/**
 * Returns the default numericPrecision for the different dataTypes
 */
const _getDefaultNumericPrecision = (dataType: NexusDataType, fieldName = '') => {
  const isPrice = isPriceField(fieldName, dataType); // @TODO confirm where price rule should be applied
  const isPercent = isPercentField(fieldName, dataType);

  switch (true) {
    case isPrice:
      return 5;
    case isPercent:
      return 3;
  }
  return 2;
};

/**
 * checks if a dataType is a NexusDataType
 */
const _isNexusDataTypeValid = (dataType: string) => Object.keys(dataTypeMap).includes(dataType);

/**
 * checks if a dataType is a valid DataType
 */
const _isDataTypeValid = (dataType: string) => Object.values(dataTypeMap).includes(dataType as DataType);

/**
 * get a valid DataType from NexusDataType or a DataType itself
 */
const _getDataType = (dataType: NexusDataType | DataType, defaultDataType: DataType = 'string'): DataType => {
  const isNexusDataType = _isNexusDataTypeValid(dataType);
  const isDataType = _isDataTypeValid(dataType);
  return isNexusDataType
    ? dataTypeMap[dataType as NexusDataType]
    : isDataType
    ? (dataType as DataType)
    : defaultDataType;
};

/**
 * format boolean values to Yes or No
 */
export const formatBoolean = (value: boolean | (0 | 1)) => {
  return value === 1 || value === true ? 'Yes' : value === 0 || !value ? 'No' : value;
};

/**
 * this is a kludgy override to use the column name as a hint
 */
export const isIdField = (fieldName = '', dataType: NexusDataType | DataType = 'number') =>
  _getDataType(dataType, 'number') === 'number' && fieldName?.toLowerCase().endsWith(' id');

/**
 * this is a kludgy override to use the column name as a hint
 */
export const isPriceField = (fieldName = '', dataType: NexusDataType | DataType = 'number') =>
  _getDataType(dataType, 'number') === 'number' && fieldName?.toLowerCase().endsWith(' price');

/**
 * not all percentage fields seem to have datatype set to decimal so,
 * this is a kludgy override to use the column name as a hint
 */
export const isPercentField = (fieldName = '', dataType: NexusDataType | DataType = 'number') =>
  _getDataType(dataType, 'number') === 'number' && fieldName?.endsWith(' %');

/**
 * this is a kludgy override to use the column name as a hint
 * There are some columns with PIK like "Is PIK Amount", but their datatype is bit or boolean
 */
export const isPIKField = (fieldName = '', dataType: NexusDataType | DataType = 'number') =>
  _getDataType(dataType, 'number') === 'number' && fieldName?.toLowerCase().includes('pik');

/**
 * returns the NexusDataType depending on the fieldName rules like ID and %
 */
export const getNexusDataType = (fieldName = '', dataType: NexusDataType): NexusDataType => {
  const _dataType = _getDataType(dataType);
  return isIdField(fieldName, _dataType) ? 'id' : isPercentField(fieldName, _dataType) ? 'percent' : dataType;
};

/**
 * Place to catch inconsistent values
 */
const _getSafeValue = (cellDataType: string, cellValue: any) => {
  // date and datetime are the only dataTypes that can be an object. Add other rules here if needed
  const hasInconsistentDateValue =
    ['date', 'datetime'].includes(dataTypeMap[cellDataType as NexusDataType]) || typeof cellValue !== 'object';

  // VERACODE restricted output
  /*
  if (!hasInconsistentDateValue) {
    const constructorMsg = `with constructor: ${cellValue.constructor}`;
    const typeMsg = `does not correspond with the type: ${cellDataType}`;
    console.warn(`WARNING! the value: ${cellValue}, ${constructorMsg}, ${typeMsg}.
    Applying the toLocalString fallback to the value to avoid execution errors...
    Please, check your data types.
  `);
  }
*/
  // catch the case when cellValue is a date but dataType is not a date type
  // and apply a fallback to the toLocalString of the value
  return hasInconsistentDateValue ? cellValue : cellValue.toLocaleString();
};

/**
 * get a safe numericPrecision with fallback to the default value
 */
export const getNumericPrecision = (dataType: NexusDataType, fieldName = '', numericPrecision?: number): number => {
  const sanitizedNumericPrecision = _sanitizeNumericPrecision(numericPrecision);

  return sanitizedNumericPrecision == null
    ? _getDefaultNumericPrecision(dataType, fieldName)
    : (numericPrecision as number);
};

/**
 * Virtus standard data parser
 */
export const parseDataTypeValue = (
  cellDataType: string = 'varchar',
  cellValueOrigin: any,
  numericPrecisionArg?: any,
  fieldName = '',
  nullValueRepresentation: any = null,
) => {
  if (cellValueOrigin == null) return nullValueRepresentation;

  const cellValue = _getSafeValue(cellDataType, cellValueOrigin);

  // apply the custom rules like ID and % detection
  const dataType = getNexusDataType(fieldName, cellDataType.toLowerCase() as NexusDataType);

  // The default schema numeric precision is actually not referring to decimal precision as we are using it here
  // so filtering out high numbers because we probably aren't trying to display that many decimal places
  const numericPrecision = getNumericPrecision(dataType, fieldName, numericPrecisionArg);

  let formattedValue = cellValue;
  switch (dataType) {
    case 'tinyint':
    case 'boolean':
    case 'bit':
      formattedValue = formatBoolean(cellValue);
      break;
    case 'datetime':
    case 'smalldatetime':
    case 'date':
      if (!isNaN(Date.parse(cellValue))) formattedValue = getVirtusDate(new Date(cellValue));
      break;
    case 'dateandtime':
      if (!isNaN(Date.parse(cellValue))) formattedValue = getVirtusDateTime(new Date(cellValue));
      break;
    case 'currency':
    case 'money':
    case 'float': {
      // Add expected Virtus Metadata format: https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/28987
      // Virtus does not currently provide it
      const valueNumber = Number(cellValue);
      if (!isNaN(valueNumber))
        formattedValue = formatNumber({ value: valueNumber, formatString: `d${numericPrecision}` });
      break;
    }
    case 'decimal':
    case 'percent':
      formattedValue = percentFormat(cellValue, numericPrecision);
      break;
  }

  return formattedValue;
};
