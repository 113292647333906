import React from 'react';
import { Dispatch } from 'redux';
import { FormControl, InputLabel, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { StyledMenuItem, StyledMenuItemText } from './glide-inspector-actions.style';
import { mapGlideObjectBgColor } from 'src/utils/constants';
import { dispatchActions } from 'src/app/store';
import { store } from 'src/app/store';
import { isArray } from 'lodash';
import { GlideAction, GlideWorkflowAction } from 'src/reducers/actions';
import { NotificationsAction } from 'src/reducers/notifications';
import { LayoutRegion } from 'src/components/glide-object-manager/components/glide-object-manager.model';
import { inspectorFormState, selectInspectorForm } from 'src/reducers/inspectorForm.reducer';
import { selectModalForm } from 'src/reducers/modalForm.reducer';
import { activeTabSelector } from 'src/reducers/tabs';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import {
  updateWorkFlowAction,
  updateModalWorkFlowAction,
  WorkflowUpdatePayload,
  WorkflowUpdate,
  updateCreditDetailWorkFlowAction,
} from 'src/sagas/glide-actions/glide-wflow-actions-saga';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { StyledDoneIcon } from 'src/components/forms/form-elements/FormElements.style';
import { creditDetailForm, selectCreditDetailForm } from 'src/reducers/creditDetailForm.reducer';

const styles = makeStyles()({
  paper: {
    marginTop: -31,
    borderRadius: '2px',
    backgroundColor: 'var(--background-form-dropdown-items)',
    border: 'var(--foreground-form-border)',
    width: '180px',
    '& ul li:last-child': {
      paddingBottom: '10px',
      height: '16px',
      boxSizing: 'content-box',
      width: '170px',
    },
  },
  select: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  formControl: {
    border: 'var(--foreground-form-dropdown-boder)',
    minWidth: 117,
    marginRight: 'auto',
    bottom: '0px',
    borderRadius: '3px',
    maxHeight: '34px',
    background: 'var(--background-form)',
    '& .MuiInput-root.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-root.Mui-focused:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-root.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& > .MuiInputBase-root.MuiInput-root': {
      marginTop: '14px',
    },
  },
  label: {
    paddingLeft: '7px',
    top: '-6px',
    color: 'var(--foreground-form)',
    fontSize: 'var(--form-label-font-size) !important',
    transform: 'translate(0, 13px) scale(1)',
    '&.MuiInputLabel-shrink.Mui-focused': {
      transform: 'translate(0, 5.5px) scale(0.75)',
    },
    '&.MuiInputLabel-shrink.MuiFormLabel-filled': {
      transform: 'translate(0, 5.5px) scale(0.75)',
    },
    '&.MuiFormLabel-root.Mui-focused.Mui-focused': {
      color: 'var(--foreground-form)',
    },
  },
  icon: {
    height: '34px',
    width: '34px',
    background: 'var(--background-form-dropdown-icon)',
    top: 'calc(50% - 24px)',
    fill: 'var(--foreground-form)',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    right: '-1px',
  },
});

export interface ReduxProps {
  inspectorForm: inspectorFormState;
  modalForm: any;
  creditDetailForm: creditDetailForm;
  clientViewUri: string;
}

interface GlideInspectorActionsProps {
  actions: GlideAction[];
  workflowActions?: GlideWorkflowAction[];
  actionButtonText: string;
  layout?: LayoutRegion;
  isModalInspector?: boolean;
  isCreditDetailInspector?: boolean;
  isGlobalGlideInspectorActions?: boolean;
  targetInstance?: string;
}

export interface GlideInspectorActionsReduxDispatch {
  updateWorkFlowAction: ({ uri, transition_uri, comment, storeViewName, storeViewProp }: WorkflowUpdatePayload) => void;
  updateModalWorkFlowAction: ({ uri, transition_uri, comment }: WorkflowUpdate) => void;
  updateCreditDetailWorkFlowAction: ({ uri, transition_uri, comment }: WorkflowUpdate) => void;
}

export const GlideInspectorActions: React.FunctionComponent<
  GlideInspectorActionsProps & ReduxProps & GlideInspectorActionsReduxDispatch
> = ({
  actions = [],
  workflowActions,
  actionButtonText = 'Actions',
  layout,
  inspectorForm,
  modalForm,
  creditDetailForm,
  clientViewUri,
  isModalInspector,
  isCreditDetailInspector,
  isGlobalGlideInspectorActions,
  updateWorkFlowAction,
  updateModalWorkFlowAction,
  updateCreditDetailWorkFlowAction,
  targetInstance,
}) => {
  const [isDropdownExpanded, setDropdownExpanded] = React.useState(false);
  const { classes } = styles();
  // Return empty element to keep footer display consistent
  if ((!isArray(actions) || actions.length === 0) && !workflowActions) return null;

  const hasFormErrors = () => {
    let hasErrors = false;
    if (isModalInspector) {
      if (
        targetInstance &&
        (Object.keys(modalForm.uri[targetInstance].initErrors.errors)?.length > 0 ||
          Object.keys(modalForm.uri[targetInstance].formErrors.errors)?.length > 0)
      ) {
        hasErrors = true;
        store.dispatch({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage:
              Object.keys(modalForm.uri[targetInstance].initErrors)
                // @ts-ignore: FIXME
                .map(key => Object.values(modalForm.uri[targetInstance].initErrors[key]))
                .join('\n') ||
              Object.keys(modalForm.uri[targetInstance].formErrors)
                // @ts-ignore: FIXME
                .map(key => Object.values(modalForm.uri[targetInstance].formErrors[key]))
                .join('\n'),
          },
        });
      }
    } else if (isCreditDetailInspector) {
      if (Object.keys(creditDetailForm?.formErrors.errors)?.length > 0) {
        hasErrors = true;
        store.dispatch({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage: Object.keys(creditDetailForm.formErrors)
              // @ts-ignore: FIXME
              .map(key => Object.values(creditDetailForm.formErrors[key]))
              .join('\n'),
          },
        });
      }
    } else {
      const _clientViewUri = isGlobalGlideInspectorActions ? 'global' : clientViewUri;
      if (
        Object.keys(inspectorForm?.uri[_clientViewUri].initErrors.errors)?.length > 0 ||
        Object.keys(inspectorForm?.uri[_clientViewUri].formErrors.errors)?.length > 0
      ) {
        hasErrors = true;
        store.dispatch({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage:
              Object.keys(inspectorForm.uri[_clientViewUri].initErrors)
                // @ts-ignore: FIXME
                .map(key => Object.values(inspectorForm.uri[_clientViewUri].initErrors[key]))
                .join('\n') ||
              Object.keys(inspectorForm.uri[_clientViewUri].formErrors)
                // @ts-ignore: FIXME
                .map(key => Object.values(inspectorForm.uri[_clientViewUri].formErrors[key]))
                .join('\n'),
          },
        });
      }
    }
    return hasErrors;
  };

  const hasFormChanges = () => {
    let hasFormChanges = false;
    if (isModalInspector) {
      if (targetInstance && modalForm.uri[targetInstance].hasChanged) {
        hasFormChanges = true;
        store.dispatch({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage: 'Please save the form changes !!!',
          },
        });
      }
    } else if (isCreditDetailInspector) {
      if (creditDetailForm.hasChanged) {
        hasFormChanges = true;
        store.dispatch({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage: 'Please save the form changes !!!',
          },
        });
      }
    } else {
      const _clientViewUri = isGlobalGlideInspectorActions ? 'global' : clientViewUri;
      if (inspectorForm?.uri[_clientViewUri].isFormDirty) {
        hasFormChanges = true;
        store.dispatch({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage: 'Please save the form changes !!!',
          },
        });
      }
    }
    return hasFormChanges;
  };

  const isCancelAction = (action: GlideAction) => {
    return action.data?.transition_end_value === 'Cancelled' || action.data?.display_name === 'Cancel Order';
  };

  const workflowActionClick = (transitionEndValue: string, transitionUri: string) => {
    if (transitionEndValue.toLowerCase() !== 'cancelled' && hasFormErrors()) return;
    if (isModalInspector) {
      updateModalWorkFlowAction({
        uri: targetInstance,
        transition_uri: transitionUri,
      });
    } else if (isCreditDetailInspector) {
      updateCreditDetailWorkFlowAction({
        uri: targetInstance,
        transition_uri: transitionUri,
      });
    } else {
      updateWorkFlowAction({
        uri: targetInstance,
        transition_uri: transitionUri,
        storeViewName: isGlobalGlideInspectorActions ? 'global' : clientViewUri,
        storeViewProp: VIEW_INSPECTOR,
      });
    }
  };

  const onCloseHandler = () => setDropdownExpanded(false);

  const onOpenHandler = () => setDropdownExpanded(true);

  return (
    <FormControl color="primary" className={classes.formControl}>
      <InputLabel className={classes.label}>{actionButtonText}</InputLabel>
      <Select
        id="actions-menu"
        label="actions"
        MenuProps={{ classes: { paper: classes.paper } }}
        classes={{
          icon: classes.icon,
          select: classes.select,
        }}
        onClose={onCloseHandler}
        onOpen={onOpenHandler}
        variant="standard"
      >
        {actions.map((action: any, index: any) => (
          <StyledMenuItem
            {...action.props}
            backgroundcolor={mapGlideObjectBgColor(action?.data?.editor_button_style)}
            key={index}
            onClick={() => {
              if (hasFormErrors() || hasFormChanges()) {
                return;
              }
              dispatchActions.execute({
                glideAction: action,
                clientViewUri: isGlobalGlideInspectorActions ? 'global' : clientViewUri,
              });
            }}
            layout={layout}
            value={action.uri}
          >
            {action.icon && React.createElement(action.icon)}
            <StyledMenuItemText>{action.data?.display_name}</StyledMenuItemText>
            {isDropdownExpanded && <StyledDoneIcon style={{ display: 'none' }}></StyledDoneIcon>}
          </StyledMenuItem>
        ))}
        {workflowActions?.map((action: any, index: any) => (
          <StyledMenuItem
            {...action.props}
            backgroundcolor={mapGlideObjectBgColor(action?.data?.editor_button_style)}
            key={actions.length + index}
            onClick={() => {
              if ((hasFormErrors() || hasFormChanges()) && !isCancelAction(action)) {
                return;
              }
              workflowActionClick(action?.data?.transition_end_value, action.uri);
            }}
            layout={layout}
            value={action.uri}
          >
            {action.icon && React.createElement(action.icon)}
            <StyledMenuItemText>{action.data?.display_name}</StyledMenuItemText>
            {isDropdownExpanded && <StyledDoneIcon style={{ display: 'none' }}></StyledDoneIcon>}
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  inspectorForm: selectInspectorForm(state),
  modalForm: selectModalForm(state),
  creditDetailForm: selectCreditDetailForm(state),
  clientViewUri: activeTabSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): GlideInspectorActionsReduxDispatch => ({
  updateWorkFlowAction: ({ uri, transition_uri, comment, storeViewName, storeViewProp }: WorkflowUpdatePayload) =>
    dispatch(
      updateWorkFlowAction({
        uri,
        transition_uri,
        comment,
        storeViewName,
        storeViewProp,
      }),
    ),
  updateModalWorkFlowAction: ({ uri, transition_uri, comment }: WorkflowUpdate) =>
    dispatch(
      updateModalWorkFlowAction({
        uri,
        transition_uri,
        comment,
      }),
    ),

  updateCreditDetailWorkFlowAction: ({ uri, transition_uri, comment }: WorkflowUpdate) =>
    dispatch(
      updateCreditDetailWorkFlowAction({
        uri,
        transition_uri,
        comment,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlideInspectorActions);
