import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from 'styled-components';

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  padding-right: 1em;
`;

export const StyledRadio = styled(Radio)`
  & svg {
    fill: var(--light-blue);
  }

  &:disabled + label {
    color: var(--grey);
  }
`;

export const StyledLabel = styled.label`
  font-size: var(--labelFontSize);
`;

export const StyledRadioWrapper = styled.div`
  margin-right: 12px;
  &.radio-button-disabled {
    color: var(--grey);
  }

  &.radio-button-disabled svg {
    opacity: 0.5;
  }

  & > span {
    padding: 0;
    margin-right: 12px;
  }
`;

export const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em;
  & > div {
    margin: 10px 0;
  }
`;

export const FormWrapper = styled.div`
  padding-bottom: 5px;
`;

export const Error = styled.div`
  width: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;
