import React, { useMemo, useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import Select from './components/Select';
import { FormFieldOptions } from '../../FormElement.model';
export const MultipleSelectInput = ({
  field,
  onChange,
  error,
  //formValues,
  customWidth,
}: Omit<FormElementInputComponent, 'value'>) => {
  if (field?.hidden) return null;
  const [optionName, setOptionName] = useState<any>([]);

  const onHandleChange = (e: any) => {
    if (e.target.value.includes('')) return;
    const fieldValues = e.target.value;
    const values: string[] = [];
    fieldValues?.map((d: any) => {
      const filteredOptions = Object.values(field?.options as any).find((x: any) => x.value === d || x.name === d);
      if (filteredOptions) {
        values.push((filteredOptions as any)?.value);
      }
    });
    setOptionName(typeof fieldValues === 'string' ? fieldValues.split(',') : fieldValues);
    e.target.value = values;
    onChange && onChange(e);
  };
  const renderValues = (data: any) => {
    return Array.isArray(data) ? data.join(',') : data;
  };

  const sortedMenuItems = useMemo(
    () => Object.values(field.options as FormFieldOptions).sort((a, b) => a.name.localeCompare(b.name)),
    [field.options],
  );

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      <Select
        dataTestId={`${field.name}-multi-select`}
        disabled={field.disabled || field.readonly}
        value={!optionName ? [] : optionName}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={onHandleChange}
        menuItems={field.options ? sortedMenuItems : []}
        multiple={true}
        renderValues={renderValues}
        is_search_enable={field.is_search_enable}
      ></Select>
    </FormErrorWrapper>
  );
};
