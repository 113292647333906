import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import {
  Error,
  FormGroup,
  StyledLabel,
  StyledRequiredStar,
  StyledWarningIcon,
} from 'src/components/forms/form-elements/FormElements.style';

const styles = () => ({
  htmlTooltip: {
    backgroundColor: 'var(--background-error-message)',
    color: 'var(--foreground-error)',
    fontSize: 'var(--error-message-font-size)',
    marginTop: '12px',
    border: 'var(--foreground-form-border)',
    fontFamily: 'var(--font-family)',
  },
});

export const FormErrorWrapper = withStyles(
  ({
    name,
    displayName,
    error,
    children,
    disabled,
    classes,
    required,
    disableErrorWarning,
    hideLabel,
    customWidth,
    formGroupChildrenStyle = {},
    formGroupStyle,
  }: {
    name: string;
    displayName: string;
    error?: string;
    children: React.ReactNode;
    disabled?: boolean;
    classes?: any;
    required?: boolean;
    readonly?: boolean;
    disableErrorWarning?: boolean;
    hideLabel?: boolean;
    customWidth?: string;
    formGroupChildrenStyle?: React.CSSProperties;
    formGroupStyle?: any;
  }) => (
    <FormGroup key={name} data-testid={`formgroup-${name}`} style={formGroupStyle}>
      {!hideLabel && (
        <Tooltip title={displayName}>
          <StyledLabel htmlFor={name} width={customWidth}>
            {displayName}
            {required && <StyledRequiredStar data-testid={`${name}-required-star`}>*</StyledRequiredStar>}
          </StyledLabel>
        </Tooltip>
      )}
      {!disableErrorWarning && (
        <Error>
          {error && !disabled && (
            <Tooltip title={error} classes={{ tooltip: classes.htmlTooltip }}>
              <StyledWarningIcon data-testid="alert-icon" />
            </Tooltip>
          )}
        </Error>
      )}
      {/* 
      This width:1px is a workaround to make text-ellipsis work on elements with dynamic width (%). 
      We have select elements in default inspector and in full inspector, and the text should be adapted 
      to be shown on the available space and truncated if there is not enough space.
      Ivan. Task: 36671 (Glide)
    */}
      <div style={{ flex: 1, width: '1px', ...formGroupChildrenStyle }}>{children}</div>
    </FormGroup>
  ),
  styles,
);
export default FormErrorWrapper;
