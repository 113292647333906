import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const Layout: React.SFC<SvgIconProps> = props => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    width="20"
    height="15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 19 19"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <rect fill="#fff" opacity={0} strokeWidth={0} width="15" height="15" />
        <path
          fill="#285bc5"
          strokeWidth={0}
          d="M17.8,1.2v16.61H1.2V1.2h16.61ZM2.42,16.67h4.04V4.54H2.32l.11,12.13ZM2.33,3.44h14.33v-1.1H2.33v1.1ZM7.56,4.54v12.13h3.97V4.54h-3.97ZM16.67,4.54h-3.98v12.13h3.97l.02-12.13Z"
        />
      </g>
    </g>
  </svg>
);

export default Layout;
