// Those are Glide models for now but are meant to be extended

// Glide search types for OMS
enum EnumSearchTypesBase {
  All = 'All',
}

export enum EnumSearchTypesGlides {
  Instruments = 'Instruments',
  Orders = 'Orders',
  Trades = 'Trades',
  Hypo_Orders = 'Hypo Orders',
  Fund_Allocations = 'Fund Allocations',
}

type GlideSearchResult = {
  // search UID used for linking and indexing
  uri: string;
  name: string;
  display_name: string;
};

// You can augment the types here to fit your requirements
export type SearchResult = GlideSearchResult;

export const SearchTypes = { ...EnumSearchTypesBase, ...EnumSearchTypesGlides };
