import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { Portfolios } from 'src/api/queries';
import { QueryConfig, requestAsync } from 'redux-query';
import { selectCVC, TabsAction } from 'src/reducers/tabs';
import { GlideNotificationTypes, NotificationsAction } from 'src/reducers/notifications';
import { getClientView } from 'src/api/views/client-view/ClientView';
import { PortfolioActionTypes } from 'src/reducers/portfolio';
import { ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import { PayloadAction } from 'typesafe-actions/dist/types';
import { executeLoadTests } from '../time-series/time-series.saga';

export enum PortfolioSagaAction {
  FETCH_PORTFOLIO_NAMES = 'FETCH_PORTFOLIO_NAMES',
  FETCH_PORTFOLIO_HOLDINGS = 'FETCH_PORTFOLIO_HOLDINGS',
  CREATE_NEW_ORDER_INSTRUMENT = 'CREATE_NEW_ORDER_INSTRUMENT',
}

export function* fetchPortfolioNames(action: PayloadAction<string, any>): any {
  if (action.payload.clientViewType == ClientViewTypeLookUps.Custom) {
    const clientViewConfiguration = yield select(selectCVC);
    if (JSON.parse(localStorage.getItem(`selectedFundURIs_${clientViewConfiguration?.uri?.lastSplitValue()}`)!)) {
      yield put({
        type: PortfolioActionTypes.STORE_SELECTED_PORTFOLIOS,
        payload: {
          selectedFundURIs: JSON.parse(
            localStorage.getItem(`selectedFundURIs_${clientViewConfiguration?.uri?.lastSplitValue()}`)!,
          ),
          portfolio_type: clientViewConfiguration?.uri,
        },
      });
    }
  }
}

export function* fetchPortfolioHoldings(action: any) {
  const configUri = action.payload.clientViewConfiguration?.uri;
  // tab name change
  yield put({
    type: TabsAction.MODIFY_TAB_NAME,
    data: { fullPath: action.payload.fullPath, tabName: action.payload.tabName },
  });
  // store fund URI to enable refresh
  yield put({
    type: PortfolioActionTypes.STORE_SELECTED_PORTFOLIOS,
    payload: {
      selectedFundURIs: action.payload.fundURIs,
      portfolio_type: configUri,
    },
  });

  if (configUri.includes('compliance_dashboard')) {
    yield put(executeLoadTests());
  } else {
    // get portfolio holdings
    yield putResolve(
      requestAsync(
        getClientView({
          configUri,
          fund: action.payload.fundURIs.map((item: { uri: string; data: any }) => item.uri).join(','),
          order_date: action.payload?.orderDate,
        }) as QueryConfig,
      ),
    );
  }
}

export function* resetStoredPortfolios(action: any) {
  // reset fund URIs on tab close
  if (action.data.fullPath.includes('portfolio')) {
    yield put({
      type: PortfolioActionTypes.RESET_STORED_PORTFOLIO,
    });
  }
}

export function* createNewOrderInstrument(action: any): any {
  yield put({
    type: NotificationsAction.PENDING_NOTIFICATION,
  });
  const orderData = yield putResolve(
    requestAsync(Portfolios.createOrderInstrument({ ...action?.payload }) as QueryConfig),
  );

  if (orderData && orderData?.body) {
    yield put({
      type: NotificationsAction.ACTION_RESOLVED_NOTIFICATION,
      payload: {
        resolved_entity_type: GlideNotificationTypes.create_new_order_with_instrument_behaviour,
        resolved_entity_uri: orderData?.body?.uri[0],
      },
    });
  }
}

export function* watchFetchPortfolio() {
  yield takeLatest(PortfolioSagaAction.FETCH_PORTFOLIO_NAMES, fetchPortfolioNames);
}

export function* watchFetchPortfolioHoldings() {
  yield takeLatest(PortfolioSagaAction.FETCH_PORTFOLIO_HOLDINGS, fetchPortfolioHoldings);
}

export function* watchClosePortfolioTabs() {
  yield takeLatest(TabsAction.CLOSE_TAB, resetStoredPortfolios);
}

export function* watchCreateNewOrderPortfolio() {
  yield takeLatest(PortfolioSagaAction.CREATE_NEW_ORDER_INSTRUMENT, createNewOrderInstrument);
}
export default [
  watchFetchPortfolio,
  watchFetchPortfolioHoldings,
  watchClosePortfolioTabs,
  watchCreateNewOrderPortfolio,
];
