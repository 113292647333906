import { FormFieldOptions, FormFieldsType, OptionElement } from 'src/components/forms/form-elements/FormElement.model';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActionArguments } from 'src/api/queries';
import { mapActionArgumentsToActionForm } from 'src/mappers/action-arguments/action-entity';
import { RootState } from 'src/reducers';
import { ComponentProps, closeActionForm, discardActionFormChanges, selectComponents } from 'src/reducers/components';
import Modal from '@virtus/components/Modal';
import { FormWrapper } from './action-form.style';
import { omit } from 'lodash';
import { actionFormOverrideSelector, actions } from 'src/reducers/actions';
import { dispatchActions } from 'src/app/store';
import { selectClientViewData } from 'src/api/views/client-view';
import { View } from 'src/api/queries/display-view/display-view';
import { CONFIRMATION_DIALOG, GLIDE_DATA_FORM_NAME, backDropClick } from 'src/utils/constants';
import { store } from 'src/app/store';
import { NotificationsAction } from 'src/reducers/notifications';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import GlideDataContent from '../glide-data-content/glide-data-content';

interface ReduxProps {
  actionArguments: any;
  // Obsolete, if view exists then it should have the expected fields
  actionOverrideObject: any;
  clientViewData: View;
}

interface ReduxDispatch {
  executeAction: ({
    targetUri,
    actionArguments,
    actionUri,
  }: {
    targetUri: any;
    actionArguments?: any;
    actionUri: string;
  }) => void;
  discardActionFormChanges: (displayConfirmationDialog: Function) => void;
  closeActionForm: () => void;
}

export interface ActionFormProps {
  onChangeField?: (change: { [key: string]: string }) => void;
  targetUris?: string | string[];
  showAsModal?: boolean;
}

interface ActionFormContentType {
  formFields: any;
  formValues: any;
  formErrors: { errors: { [key: string]: any }; valid: boolean };
  actionUri: string;
  isEdit?: boolean;
}

type ActionFormType = ActionFormProps & ReduxProps & ReduxDispatch & ComponentProps;

const getSelectedOption = (options: FormFieldOptions, defaultValue: any) => {
  let result: any = '';
  if (typeof defaultValue === 'string') {
    const optionValues: OptionElement[] = Object.values(options) || [];
    const foundOption = optionValues.find((option: OptionElement) => option.value === defaultValue);
    result = foundOption ? foundOption.value : '';
  } else if (typeof defaultValue === 'object') {
    const optionKeys = Object.keys(defaultValue);
    const foundOption = optionKeys.find(key => options?.hasOwnProperty(key)) || '';
    result = foundOption ? options[foundOption].value : '';
  }
  return result;
};

const getInitialCreateActionFormValues = (formFields: FormFieldsType) =>
  Object.keys(formFields).reduce((acc, key) => {
    const options = formFields[key].options || {};
    return {
      ...acc,
      [key]:
        formFields[key].formElementType === 'select'
          ? getSelectedOption(options, formFields[key]?.defaultValue ?? {})
          : formFields[key].defaultValue,
    };
  }, {});

export const ActionForm: FC<ActionFormType> = ({
  actionArguments,
  components,
  targetUris,
  onChangeField,
  executeAction,
  actionOverrideObject,
  showAsModal = true,
  clientViewData,
  discardActionFormChanges,
  closeActionForm,
}) => {
  const [argsNotAvailable, setArgsNotAvailable] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<ActionFormContentType>({
    formFields: {},
    formValues: {},
    formErrors: { errors: {}, valid: true },
    actionUri: '',
  });

  const closeActionFormDialog = () => {
    closeActionForm();
  };

  const { DialogComponent: QuitActionForm, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeActionFormDialog,
    ...CONFIRMATION_DIALOG,
  });

  useEffect(() => {
    //reset form visibility on unmount
    return () => dispatchActions.components.update('actionForm', { visible: false, actionUri: '', hasChanged: false });
  }, []);

  const actionUri = components?.actionForm?.actionUri;

  useEffect(() => {
    if (actionArguments) {
      if (actionArguments?.[actionUri]?.arguments) {
        const formData = mapActionArgumentsToActionForm(
          actionArguments?.[actionUri],
          actionOverrideObject?.[actionUri] || {},
          {},
          clientViewData?.modalInspector ? clientViewData?.modalInspector?.data : clientViewData?.viewInspector?.data,
        );
        const initialFormValues = getInitialCreateActionFormValues(formData?.formFields);
        const filteredFormFieldsKeys = Object.keys(formData.formFields).filter(key => formData.formFields[key]?.hidden);
        setFormContent({
          formFields: omit(formData.formFields, filteredFormFieldsKeys),
          formValues: initialFormValues,
          formErrors: { errors: {}, valid: true },
          actionUri,
        });
      } else {
        setArgsNotAvailable(true);
      }
    }
  }, [actionArguments, actionUri, clientViewData]);

  if (argsNotAvailable) {
    executeAction({ actionUri, targetUri: targetUris });
    dispatchActions.components.toggleDisplay('actionForm', false);
    return null;
  }

  const onChangeFormField = (change: { [key: string]: string }) => {
    setFormContent({ ...formContent, formValues: { ...formContent.formValues, ...change } });
    if (onChangeField) {
      onChangeField(change);
    }
    dispatchActions.components.update('actionForm', { hasChanged: true });
  };

  const isFormValid = (formValues: object) => {
    const args = actionArguments?.[actionUri]?.arguments;
    const errors: string[] = [];
    Object.keys(args).map((key: string) => {
      if (args[key].argument_required) {
        const formVal = formValues[key as keyof typeof formValues];
        if (formVal === undefined || formVal === null || formVal === '') {
          errors.push(`${args[key].display_name} is required`);
        } else if (args[key].display_multiplier) {
          (formValues[key as keyof typeof formValues] as number) /= args[key].display_multiplier;
        }
      }
    });
    if (errors.length > 0) {
      store.dispatch({
        type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
        payload: {
          errorMessage: errors.join(', '),
        },
      });
      return false;
    }
    return true;
  };

  const handleOnSubmitAction = (formValues: object) => {
    if (isFormValid(formValues)) {
      executeAction({ actionUri, actionArguments: formValues, targetUri: components?.actionForm?.target_uri });
      dispatchActions.components.toggleDisplay('actionForm', false);
    }
  };

  const onCancel = (_event: any, reason?: any) => {
    if (reason === backDropClick) return;
    discardActionFormChanges(displayConfirmationDialog);
  };

  const GlideDataContentForm = (
    <FormWrapper>
      <GlideDataContent
        isEditing
        formName={GLIDE_DATA_FORM_NAME}
        dataTestId={GLIDE_DATA_FORM_NAME}
        onCancel={onCancel}
        onSubmitForm={handleOnSubmitAction}
        form={formContent}
        //formValues={formContent?.formValues}
        onChangeField={onChangeFormField}
        primaryButtonLabel={actionOverrideObject[actionUri]?.primaryActionButton?.label}
      />
    </FormWrapper>
  );

  return (
    <>
      <QuitActionForm />
      <Modal
        onClose={onCancel}
        show={showAsModal}
        isDraggable
        hideFooter
        title={actionArguments?.[actionUri]?.description ?? actionArguments?.[actionUri]?.display_name}
        fullWidth
      >
        {GlideDataContentForm}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps & ComponentProps => ({
  actionArguments: ActionArguments.selectActionArguments(state),
  components: selectComponents(state),
  actionOverrideObject: actionFormOverrideSelector(state),
  clientViewData: selectClientViewData(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  executeAction: ({ targetUri, actionArguments, actionUri }) =>
    dispatch({
      type: actions.action.EXECUTE_ACTION,
      payload: { targetUri, actionArguments, actionUri },
    }),
  discardActionFormChanges: (displayConfirmationDialog: Function) =>
    dispatch(discardActionFormChanges(displayConfirmationDialog)),
  closeActionForm: () => dispatch(closeActionForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionForm);
