import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--background-inspector);
  padding-left: 5px;
  min-height: 35px;
  height: 35px;
  width: 100%;
  border: var(--foreground-inspector-border);
`;

export const Title = styled(Typography)`
  color: var(--foreground-form);
  text-transform: capitalize;
  font-size: var(--inspector-font-size);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  letter-spacing: 0.8px;
  opacity: 1;
`;

export const Row = styled.div<{ canWidthExpand: boolean | undefined }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${props => (props.canWidthExpand ? '70%' : '80%')};
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 34px;
  & :last-child {
    margin-right: 5px;
  }
  & :hover {
    background: var(--background-sub-tab-active-hover);
    border-radius: 50%;
  }
`;
