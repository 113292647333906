import React, { ComponentClass, ComponentType, FunctionComponent, useCallback, useState } from 'react';
import DefaultConfirmDialog from './DefaultConfirmDialog';

export interface ConfirmDialogProps {
  onClose?: () => void;
  onHelp?: () => void;
  show: boolean;
  headerText?: string;
  bodyTitle?: string;
  bodyTextContent?: string;
  cancelButton?: any;
  acceptButton?: any;
  onAccept?: (e?: any) => void;
  onCancel?: (e?: any) => void;
  showCloseButton: boolean;
  bodyContentAlign?: string;
}

export interface ClickConfirmationProps extends Partial<ConfirmDialogProps> {
  onClick: (e?: any) => void;
  children?: React.ReactNode;
  DialogComponent?: ComponentType<ConfirmDialogProps>;
  onCancel?: () => void;
  onConfirm?: () => void;
  bodyContentAlign?: string;
}

export const useConfirmationDialog = ({
  onClick: originalOnClickHandler,
  headerText = 'Are you sure?',
  bodyTitle,
  bodyTextContent,
  cancelButton,
  acceptButton,
  onConfirm,
  onCancel,
  onClose,
  onHelp,
  showCloseButton = true,
  bodyContentAlign,
  DialogComponent = DefaultConfirmDialog,
}: ClickConfirmationProps) => {
  const [asking, setAsking] = useState(false);
  const [incomingEvent, setIncomingEvent] = useState(undefined);

  const onAcceptHandler = useCallback(() => {
    setAsking(false);
    originalOnClickHandler(incomingEvent);
    if (onConfirm) onConfirm();
  }, [incomingEvent, onConfirm, originalOnClickHandler]);

  const onCancelHandler = useCallback(() => {
    setAsking(false);
    if (onCancel) onCancel();
  }, [onCancel]);

  const onDispatcherClick = useCallback((e?: any) => {
    setIncomingEvent(e);
    setAsking(true);
  }, []);

  const dialogComponent = useCallback(
    () => (
      <DialogComponent
        show={asking}
        headerText={headerText}
        bodyTitle={bodyTitle}
        bodyTextContent={bodyTextContent}
        cancelButton={cancelButton}
        acceptButton={acceptButton}
        onAccept={onAcceptHandler}
        onCancel={onCancelHandler}
        onClose={onClose}
        onHelp={onHelp}
        showCloseButton={showCloseButton}
        bodyContentAlign={bodyContentAlign}
      />
    ),
    [
      acceptButton,
      asking,
      bodyContentAlign,
      showCloseButton,
      bodyTextContent,
      bodyTitle,
      cancelButton,
      headerText,
      onAcceptHandler,
      onCancelHandler,
      onClose,
      onHelp,
    ],
  );

  return {
    onDispatcherClick,
    DialogComponent: dialogComponent,
  };
};

const withConfirmationDialogOnClick = (DialogDispatcherComponent: FunctionComponent<any> | ComponentClass<any>) => {
  return (props: ClickConfirmationProps) => {
    const [asking, setAsking] = useState(false);
    const {
      onClick: originalOnClickHandler,
      headerText,
      bodyTitle,
      bodyTextContent,
      cancelButton,
      acceptButton,
      onConfirm,
      onCancel,
      onClose,
      onHelp,
      children,
      showCloseButton = true,
      DialogComponent = DefaultConfirmDialog,
      ...componentProps
    } = props;
    const onAcceptHandler = (e?: any) => {
      setAsking(false);
      originalOnClickHandler(e);
      if (onConfirm) onConfirm();
    };

    const onCancelHandler = () => {
      setAsking(false);
      if (onCancel) onCancel();
    };

    const onDispatcherClick = () => setAsking(true);

    return (
      <>
        <DialogComponent
          show={asking}
          headerText={headerText}
          bodyTitle={bodyTitle}
          bodyTextContent={bodyTextContent}
          cancelButton={cancelButton}
          acceptButton={acceptButton}
          onAccept={onAcceptHandler}
          onCancel={onCancelHandler}
          onClose={onClose}
          onHelp={onHelp}
          showCloseButton={showCloseButton}
        />
        <DialogDispatcherComponent data-testid="dialog-dispatcher" {...componentProps} onClick={onDispatcherClick}>
          {children}
        </DialogDispatcherComponent>
      </>
    );
  };
};

export default withConfirmationDialogOnClick;
