import React, { useEffect, useState } from 'react';
import { TimeSeriesDatePicker } from 'src/components/time-series-date-picker/time-series-date-picker';
import { computeDayCount } from 'src/components/glide-time-series/glide-time-series';
import { dispatchActions } from 'src/app/store';
import { activeTabSelector, selectCVC } from 'src/reducers/tabs';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/reducers';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { GlideViewActions } from 'src/sagas/glide-view.saga';
import { cancelQuery } from 'redux-query';
import { DateStoreKey, executeLoadTests, TimeSeries, validateDate } from 'src/sagas/time-series/time-series.saga';
import { Dispatch } from 'redux';

export interface DateConfig {
  dateType: string;
  singleDate: string;
  prevSingleDate?: string;
  toDate: string;
  fromDate: string;
  frequency: string;
}

export interface MultiDateObjectType {
  visible: boolean;
  count: number;
  data: DateConfig;
}

interface ReduxProps {
  clientViewUri: string;
  clientViewConfiguration: ClientViewConfigurationData;
}

interface ReduxDispatch {
  validateTimeSeriesDate: (timeSeries: TimeSeries) => void;
  executeLoadTests: (timeSeries?: TimeSeries) => void;
}

interface DatePickerProps {
  singleDateSelection?: boolean;
  dateFieldLabel?: string;
  defaultDate?: string;
  dateStoreKey?: DateStoreKey;
  validationError?: string;
  isDateNetworkRequest?: boolean;
  validationErrorCallback?: (_errorMsg: string) => void;
}

const isPendingDateRequest = (
  datePickerConfig: MultiDateObjectType,
  clientViewConfiguration: ClientViewConfigurationData,
) =>
  datePickerConfig.visible &&
  datePickerConfig.data.singleDate &&
  datePickerConfig.data.singleDate !== datePickerConfig.data.prevSingleDate &&
  clientViewConfiguration.is_time_series;

const DatePicker: React.FunctionComponent<ReduxProps & ReduxDispatch & DatePickerProps> = ({
  clientViewUri,
  clientViewConfiguration,
  singleDateSelection = true,
  dateFieldLabel,
  defaultDate = '',
  dateStoreKey = 'datePicker',
  validationError,
  isDateNetworkRequest = false,
  validateTimeSeriesDate,
  validationErrorCallback,
  executeLoadTests,
}) => {
  const dispatch = useDispatch();
  const initialState = {
    visible: true,
    count: 0,
    data: {
      singleDate: defaultDate,
      prevSingleDate: '',
      toDate: '',
      fromDate: '',
      frequency: '',
      dateType: 'single',
    },
  };
  const [datePickerConfig, setDatePickerConfig] = useState<MultiDateObjectType>({ ...initialState });

  useEffect(() => {
    setDatePickerConfig((prevState: MultiDateObjectType) => ({
      ...prevState,
      data: { ...prevState.data, singleDate: defaultDate },
    }));
  }, [defaultDate]);

  const updateDateConfig = (dateConfig: any) => {
    if (singleDateSelection && dateConfig?.data?.singleDate.length && dateConfig.visible) {
      const latestDate = dateConfig.data.singleDate[dateConfig.data.singleDate.length - 1];
      const updateDates = {
        singleDate: latestDate,
        prevSingleDate: datePickerConfig.data.singleDate ?? latestDate,
      };
      setDatePickerConfig(prevState => ({ ...prevState, data: { ...prevState.data, ...updateDates } }));
      dispatchActions.components.updateView(dateStoreKey, clientViewUri, {
        ...datePickerConfig,
        data: { ...datePickerConfig.data, ...updateDates },
      });
      executeLoadTests();
    } else {
      setDatePickerConfig(prevState => ({ ...prevState, ...dateConfig }));
    }
  };

  const onDatePickerCancel = () => {
    updateDateConfig({
      visible: false,
      data: { ...datePickerConfig.data, prevSingleDate: datePickerConfig.data.singleDate },
    });
  };

  useEffect(() => {
    dispatchActions.components.updateView(dateStoreKey, clientViewUri, datePickerConfig);
    validateTimeSeriesDate({
      uri: clientViewUri,
      validationCallback: validationErrorCallback,
    });
    if (isDateNetworkRequest && isPendingDateRequest(datePickerConfig, clientViewConfiguration)) {
      dispatch(cancelQuery(clientViewUri));
      dispatch({
        type: GlideViewActions.FETCH_CLIENT_VIEW,
      });
    }
  }, [datePickerConfig]);

  const onDatePickerApply = (data: any) => {
    const { count } = computeDayCount(data);
    updateDateConfig({ visible: true, count, data });
  };

  return (
    <>
      {datePickerConfig.visible && (
        <TimeSeriesDatePicker
          // Disable multiple date selection until it is supported by gsearch
          singleDateSelection
          onChange={onDatePickerApply}
          dateObj={{ ...datePickerConfig.data }}
          inspectorOnApply={onDatePickerApply}
          inspectorOnCancel={onDatePickerCancel}
          calendarAlign="left"
          dateFieldLabel={dateFieldLabel}
          error={validationError}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  clientViewUri: activeTabSelector(state),
  clientViewConfiguration: selectCVC(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  validateTimeSeriesDate: (timeSeries: TimeSeries) => dispatch(validateDate(timeSeries)),
  executeLoadTests: () => dispatch(executeLoadTests()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
