import { ButtonProps } from '@mui/material/Button';
import React, { ComponentType } from 'react';

export interface DropdownMenuProps extends React.ComponentPropsWithRef<ComponentType<any>> {
  /** custom IconButton or Button */
  button?: React.ReactElement<any> | React.ReactElement<ButtonProps>;
  styles?: { button?: any; menu?: any };
  /** pass in list of `<DropdownMenuItem>` and `<Divider>` as children */
  // children: React.ReactNode;
  light?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  hideButton?: boolean; // used if we want to open the dropdown using our custom button
  fixAnchor?: boolean;
  keepMenuOpen?: boolean;
  customAnchorEl?: Element | ((el: Element) => Element) | null;
  open?: boolean;
  id?: string;
}

export interface DropdownMenuItemProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  sublabel?: string;
  icon?: any;
}

export const OFFSET_EL_TOP = 15;
export const OFFSET_EL_LEFT = 125;
