import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const SubMenuIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" opacity=".4">
      <path d="M0 0H24V24H0z" transform="rotate(-90 12 12)" />
      <path fill="#FFF" d="M7 10L17 10 12 15z" transform="rotate(-90 12 12)" />
    </g>
  </SvgIcon>
);
