import { GlideAPIFieldRules, GlideAPIFieldRule, FieldRules } from './field-rules.model';
import { FieldRuleType, FieldRule } from 'src/components/forms/form-elements/FormElement.model';

export const mapFieldRules = (apiFieldRules: GlideAPIFieldRules): FieldRules => {
  return apiFieldRules?.reduce((accumulator: { [key: string]: FieldRule[] }, value: GlideAPIFieldRule) => {
    try {
      const validatorFn = eval(value.field_rule_script);
      const rule = {
        ruleFn: validatorFn,
        ruleType: value.field_rule_type as FieldRuleType,
        ...value,
      };

      // To handle multiple target fields at a time.
      if (value?.field_rule_targets) {
        value.field_rule_targets.map((field_rule_target: string) => {
          const otherFieldRules = accumulator[field_rule_target] || [];
          accumulator[field_rule_target] = [...otherFieldRules, ...[rule]];
        });
        return accumulator;
      }

      //To handle single target field at a time.
      if (value?.field_rule_target) {
        const otherFieldRules = (value.field_rule_target && accumulator[value.field_rule_target]) || [];
        return {
          ...accumulator,
          [value.field_rule_target]: [...otherFieldRules, ...[rule]],
        };
      }
      return accumulator;
    } catch (err) {
      console.error(`Rule not evaluated. Typo in ${value.display_name} rule: `, value.field_rule_script);
      return accumulator;
    }
  }, {});
};
