import moment from 'moment';
import { put, takeEvery } from 'redux-saga/effects';
import { updateComponentAction } from 'src/reducers/components';
import { CalendarErrors } from '@virtus/components/Calendar';
import { EndMonth, NumberOfYears, StartMonth } from '@virtus/components/Calendar/constants';

export enum DateInputSagaAction {
  SETINITIAL = 'SETINITIAL',
}

const checkValidDate = (date: Date | string) => {
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - NumberOfYears, StartMonth);
  const toMonth = new Date(currentYear + NumberOfYears, EndMonth);
  const newDate = new Date(date);
  const isNotValid = isNaN(newDate.getTime());
  if (isNotValid || newDate < fromMonth || newDate > toMonth) {
    return false;
  }
  return true;
};

export const formattedDate = (date: Date | string): { date: string; formattedDate: string } => {
  if (date === '' || date === undefined) return { date: '', formattedDate: '' };
  const isNotValid = isNaN(new Date(date).getTime());
  if (isNotValid) return { date: CalendarErrors.INVALID_DATE, formattedDate: '' };
  const _date = new Date(date);
  const month = `0${_date.getMonth() + 1}`;
  const day = `0${_date.getDate()}`;
  const year = `${_date.getFullYear()}`;
  const _glideDate = `${month.substring(month.length - 2)}/${day.substring(day.length - 2)}/${year}`;
  const _formattedDate = new Date(date).toLocaleDateString(navigator.language);

  return {
    date: _glideDate,
    formattedDate: _formattedDate,
  };
};

export const getFieldValueToSet = (date: string, field: any, format: any) => {
  const d = date;
  const m1 = moment(d, format);
  let fieldValue;
  let error;
  if (m1.isValid() && checkValidDate(m1.toDate())) {
    const formatted_date = formattedDate(m1.toDate());
    fieldValue =
      field.readonly || field.disabled
        ? formatted_date
        : { ...formatted_date, formattedDate: formatted_date.formattedDate.replace(/ /g, '') };
    error = '';
  } else {
    const localeData = moment.localeData();
    error = `please enter date in ${localeData.longDateFormat('L')} format`;
    // TO DO : in some cases field.defaultValue is not present even there is date is there 'value' prop.
    // In that case this logic set value to empty.
    if (field.defaultValue) {
      const formatted_date = formattedDate(new Date(field.defaultValue));
      fieldValue =
        field.readonly || field.disabled
          ? formatted_date
          : { ...formatted_date, formattedDate: formatted_date.formattedDate.replace(/ /g, '') };
    } else {
      fieldValue = { date: '', formattedDate: '' };
    }
  }
  return { fieldValue, error };
};

function* setInitialDateInputState() {
  // Get user locale
  // @ts-ignore
  const locale = navigator.language || navigator.userLanguage;
  // Set locale to moment
  moment.locale(locale);
  // Get locale data
  const localeData = moment.localeData();
  const DateToIdentifySaperator = formattedDate(new Date()).formattedDate;

  let LocalSeparator;
  if (DateToIdentifySaperator.indexOf('/') > -1) LocalSeparator = '/';
  else if (DateToIdentifySaperator.indexOf('-') > -1) LocalSeparator = '-';
  else LocalSeparator = '.';

  const searchRegExp = new RegExp(LocalSeparator, 'g');
  // calculating DateStringLength
  // ex. lenght = mmddyyyy + number of separator (in some case there is 2 and in some cases there is 3)
  const length =
    localeData.longDateFormat('L').replace(searchRegExp, '').length +
    DateToIdentifySaperator.split(LocalSeparator).length -
    1;
  yield put(
    updateComponentAction('dateInput', {
      placeholder: localeData.longDateFormat('L'),
      separator: LocalSeparator,
      dateStringLength: length,
    }),
  );
}

export function* watchValidateDate() {
  yield takeEvery<any>(DateInputSagaAction.SETINITIAL, setInitialDateInputState);
}

export default [watchValidateDate];
