import React, { useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import { CheckboxFilledIcon, CheckboxUncheckedIcon } from '@virtus/components/icons/CheckboxIcons';
import { StyledCheckbox } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { CheckboxWrapper, StyledDiv } from './CheckboxInput.style';

// This deals with Glide that expects all value to be string
const getBooleanValue = (value: string | boolean) => {
  if (typeof value !== 'boolean') {
    if (value === 'true') return true;
    return false;
  }
  return value;
};

export const CheckboxInput = ({ field, value = false, onChange, error }: FormElementInputComponent) => {
  const [checkBoxFocus, setcheckBoxFocus] = useState(false);
  const [checkBoxChecked, setCheckBoxChecked] = useState(value);

  if (field.hidden) return null;
  const _value: boolean = getBooleanValue(value);

  const onClick = (e: React.SyntheticEvent) => {
    (e.target as HTMLFormElement).checked = !_value;
    onChange(e);
    setCheckBoxChecked(!_value);
  };

  const isDisabled = field.disabled;
  const isRequired = field.required;
  const isReadonly = field.readonly;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={isDisabled}
      required={isRequired}
      readonly={isReadonly}
      disableErrorWarning={field.disableErrorWarning}
    >
      <CheckboxWrapper>
        <StyledDiv
          disabled={isDisabled || isReadonly}
          onFocus={() => setcheckBoxFocus(true)}
          onBlur={() => setcheckBoxFocus(false)}
        >
          <StyledCheckbox
            data-testid={field.name}
            name={field.name}
            disabled={isDisabled || isReadonly}
            checked={_value}
            onClick={onClick}
            $checkBoxChecked={checkBoxChecked}
            checkedIcon={<CheckboxFilledIcon hasFocus={checkBoxFocus} disabled={isDisabled || isReadonly} />}
            icon={<CheckboxUncheckedIcon hasFocus={checkBoxFocus} disabled={isDisabled || isReadonly} />}
          />
        </StyledDiv>
      </CheckboxWrapper>
    </FormErrorWrapper>
  );
};
