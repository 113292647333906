import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withStyles } from 'tss-react/mui';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

export interface SelectMenuProps {
  options?: any[];
  value?: string | number;
  valueKey?: string;
  displayKey?: string;
  name?: string;
  classes?: any;
  width?: string;
  onChange?: any;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  customSelectStyles?: any;
  customMenuItemStyles?: any;
  placeholder?: string;
  filterType?: string;
  labelFontSize?: string;
  testId?: string;
  styledinputheight?: string;
  styledinputborder?: string;
  styledinputcolor?: string;
}

const SelectMenu: React.FunctionComponent<SelectMenuProps> = ({
  value = '',
  options = [],
  onChange,
  name,
  classes,
  width,
  valueKey,
  displayKey,
  disabled = false,
  loading = false,
  style,
  customSelectStyles = {},
  customMenuItemStyles = {},
  filterType,
  labelFontSize,
  testId,
  styledinputheight,
  styledinputborder,
  styledinputcolor,
}) => {
  const SelectStyled = withStyles(Select, {
    ...{
      root: {
        height: 28,
        display: 'flex',
        alignItems: 'center',
        '&:focus': {
          backgroundColor: 'unset',
        },
      },
      icon: { color: 'var(--text)' },
      select: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        '&:focus': {
          backgroundColor: 'var(--background-form-dropdown-items)',
        },
      },
      disabled: {
        opacity: 0.6,
      },
    },
    ...customSelectStyles,
  });

  const MenuItemStyled = withStyles(MenuItem, {
    root: {
      ...{
        height: 26,
        margin: 0,
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.86,
        letterSpacing: -0.2,
        color: 'var(--foreground-dropdown)',
        backgroundColor: 'var(--background-form-dropdown-items)',
        display: 'flex',
        justifyContent: 'space-between',
        '&:hover': {
          backgroundColor: 'var(--background-form-dropdown-items-hover)',
        },
        '&:focus': {
          backgroundColor: 'var(--background-form-dropdown-items)',
        },
      },
      ...customMenuItemStyles,
    },
    selected: {
      backgroundColor: 'var(--background-form-dropdown-items) !important',
    },
  });

  const setLabelForDropdown = (filterType: string, value: any) => {
    let evaluateLabel: string = '';
    const filterOptions = options.filter(opt => opt.id == value);
    if (!isNaN(value) || filterOptions.length) {
      if (filterType) {
        evaluateLabel = value !== -1 ? filterType + ' ' + filterOptions[0].text : filterType + ' None';
        return evaluateLabel;
      } else {
        return filterOptions && filterOptions.length ? filterOptions[0].text : '';
      }
    } else return value;
  };

  return (
    <SelectStyled
      data-testid={testId || 'select-menu-dropdown'}
      value={loading ? 0 : value}
      classes={classes}
      onChange={onChange}
      disabled={loading || disabled}
      input={
        <StyledInput
          name={name}
          disabled={disabled}
          width={width}
          fontSize={labelFontSize}
          styledinputheight={styledinputheight}
          styledinputborder={styledinputborder}
          styledinputcolor={styledinputcolor}
        />
      }
      style={style}
      IconComponent={ExpandMore}
      renderValue={val => setLabelForDropdown(filterType ? filterType : '', val)}
      MenuProps={{
        PaperProps: {
          style: {
            border: 'var(--foreground-form-border)',
            width: '145px',
          },
        },
        classes: { paper: classes?.paperStyle },
      }}
    >
      {loading && (
        <MenuItemStyled value={0}>
          <MenuItemTextStyled>Loading...</MenuItemTextStyled>
        </MenuItemStyled>
      )}
      {!loading &&
        options.map((opt, index) => {
          const val = valueKey ? opt[valueKey] : opt;
          return (
            <MenuItemStyled key={index} value={val}>
              <MenuItemTextStyled>{displayKey ? opt[displayKey] : opt}</MenuItemTextStyled>
              {value === opt && <StyledDoneIcon></StyledDoneIcon>}
            </MenuItemStyled>
          );
        })}
    </SelectStyled>
  );
};

const StyledInput = styled(InputBase)<{
  width?: string;
  disabled?: boolean;
  loading?: boolean;
  fontSize?: string;
  styledinputborder?: string;
  styledinputheight?: string;
  styledinputcolor?: string;
}>`
  width: ${props => props.width};
  height: ${props => (props.styledinputheight ? props.styledinputheight : '28px')};
  border-radius: 2px;
  border: ${props => (props.styledinputborder ? props.styledinputborder : 'none')};
  color: ${props => (props.styledinputcolor ? props.styledinputcolor : 'var(--foreground-form)')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  align-items: center;
  overflow: inherit;
  background-color: var(--grey);
  padding: 6px 0 6px 10px;
  padding-left: 0;
  opacity: ${({ disabled, loading }) => (disabled || loading ? 0.6 : 1.0)};
`;

export const MenuItemTextStyled = styled.div`
  display: flex;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  font-size: var(--menuItemFontSize);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: var(--foreground-select-menu-item);
`;

export const StyledDoneIcon = styled(CheckIcon)`
  color: var(--forground-form-done-icon);
  font-size: var(--done-icon-font-size);
  margin-right: 2px;
  position: relative;
  bottom: 1px;
  height: 18px;
`;

export default SelectMenu;
