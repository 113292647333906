import React from 'react';
import { Switch } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const AntSwitch = withStyles(
  ({ classes, color, ...other }: any) => (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      style={{ color: 'var(--switchbaseColor)' }}
      {...other}
    />
  ),
  (_theme, _params, _classes) => ({
    root: {
      alignItems: 'center',
      width: 60,
      padding: '12px 12px 12px 15px',
    },
    switchBase: {
      padding: '9px 9px 9px 12px',
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#758A91 !important',
        },
      },
      // color: 'var(--switchbase-color)',
      // [`&.${classes.checked}`]: {
      //   transform: 'translateX(12px)',
      //   color: 'var(--text)',
      //   [`& + .${classes.track}`]: {
      //     opacity: 1,
      //     backgroundColor: 'var(--accent)',
      //     borderColor: 'var(--accent)',
      //   },
      // },
    },
    thumb: {
      width: 19,
      height: 19,
      backgroundColor: '#012834',
      padding: '2px 9px 0 9px',
    },
    track: {
      backgroundColor: '#758A91',
      width: 32,
      height: 14,
      padding: '4px 12px 0 12px',
    },
    checked: {},
  }),
);
