import { InputBase } from '@mui/material';
import { Warning } from '@mui/icons-material';
import BasicButton from 'src/Buttons/Basic/BasicButton';
import SelectMenu from 'src/SelectMenu';
import styled from 'styled-components';
import { Search as SearchIcon } from '@mui/icons-material';

export const StyledPanelSearch = styled.div`
  position: relative;
  left: 8px;
  display: flex;
  flex-direction: column;
  font-size: var(--search-panel-font-size);
  align-items: center;
  justify-content: center;
  color: var(--foreground-search-panel);
  background: var(--background-search-panel-dark);
  align-self: flex-end;
  width: var(--width-inspector);
  height: 100%;
  width: 429px;
  background: var(--background-search-panel);
  border: var(--foreground-form-border);
  [data-testid='inspector-group-container'] {
    border-bottom: 0;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  flex: 1;
`;

export const SearchResultsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: var(--background-search-result);
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const StyledResult = styled.li`
  list-style-type: none;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  letter-spacing: -0.18px;
  cursor: pointer;
`;

export const StyledInput = styled(InputBase)<{ margin: string; label: string; flex: string } & any>`
  color: var(--foreground-search-panel);
  width: auto;
  height: auto;
  border: 'var(--foreground-form-border)';
  right: '10px';
  ${props => (props.flex ? `flex: ${props.flex}` : '')}
`;

export const WarningIcon = styled(Warning)`
  font-size: var(--warning-icon-font-size);
`;

export const StyledForm = styled.form`
  display: flex;
  flex-flow: row;
  padding: 25px;
  border-bottom: var(--foreground-form-border);
  && {
    input {
      color: var(--foreground-search-panel);
      font-size: var(--form-input-font-size);
      height: auto;
      padding: 5px;
      ::placeholder {
        color: var(--foreground-search-panel);
        font-size: var(--placeholder-font-size);
        text-align: left;
        letter-spacing: 0px;
      }
      &:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s;
      }
      &:-webkit-autofill {
        transition: background-color 0s 600000s, color 0s 600000s;
      }
    }
  }
`;

export const StyledSearchForm = styled.div`
  border: var(--foreground-form-border);
  width: 100%;
  height: 28px;
`;

export const StyledSearchInput = styled.div`
  position: relative;
  margin: 0 1px;
  display: flex;
  align-items: center;
  flex: 1;
  border: var(--foreground-form-border);
  height: 24px;
  width: 279px;
  right: 2px;
  left: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  svg:nth-of-type(1) {
    height: 29px;
  }

  svg:nth-of-type(2) {
    height: 29px;
    right: 0;
    background-color: var(--background-search-icon);
  }
`;

export const RowsWrapper = styled.div`
  width: 420px;
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  overflow-x: hidden;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  border: var(--foreground-form-border);
`;

export const StyledSearchContainer = styled.div`
  border: var(--foreground-form-border);
  background: #eeeeee 0% 0% no-repeat padding-box;
`;

export const StyledSearchSubmitButton = styled(BasicButton)`
  height: 20px;
  border: none;
  background: white;
  color: black;
  width: 25px;
  min-width: 10px;
  &:hover {
    background: white;
  }
`;

export const SearchResultInfo = styled.div`
  height: 48px;
  border-bottom: var(--foreground-form-border);
`;

export const SearchResultText = styled.p`
  color: var(--foreground-search-noresult-text);
  text-align: center;
  font-size: 14px;
`;

export const SearchResultContainer = styled.div`
  display: flex;
  border-bottom: var(--foreground-form-border);
  height: 30px;
  padding: 5px 10px 0px 0px;
  font-size: var(--search-container-font-size);
  font-weight: bold;
  position: relative;
  left: 5px;
  right: 5px;
  top: 0px;
  bottom: 0px;
  white-space: nowrap;
`;

export const InspectorGroupStyled = styled.div`
  position: relative;
  top: -5px;
`;

export const StyledGroup = styled.div`
  margin: 0px 0px 2px 0px;
`;

export const StyledSearchIcon = styled(SearchIcon)<{ disabled: boolean }>`
  position: relative;
  padding-left: 4px;
  color: ${props => {
    return props.disabled ? 'var(--foreground-global-search-icon-dsiabled)' : 'var(--foreground-search-panel)';
  }};
`;

export const StyledSearchSelectMenu = {
  width: '127px',
  borderRadius: '5px 0px 0px 5px',
  fontWeight: '500',
  paddingLeft: '8px',
};
