import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import styled from 'styled-components';

const StyledSvgIcon = styled(SvgIcon)`
  font-size: var(--inspector-icon-font-size) !important;
`;

export type FillProps = { fillColor?: string };

const ExpandIcon: React.SFC<SvgIconProps & FillProps> = props => (
  <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.fillColor || 'var(--text)'}
          d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
        />
      </g>
    </svg>
  </StyledSvgIcon>
);

export default ExpandIcon;
