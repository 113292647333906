import React from 'react';
import { CellData, UriObject } from '@virtus/glide/src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
import * as S from './object-type-cell-render.style';
import DOMPurify from 'dompurify';
import { GetSelection } from '../search-selection';

interface ObjectTypeUriCellProps {
  cellData: CellData;
}

export const ObjectTypeUriCellRender: React.FC<ObjectTypeUriCellProps> = ({ cellData }) => {
  const uriObj = cellData.row?.data[cellData.column.dataField] as UriObject;
  const cellText = uriObj.display_name,
    search = cellData.component.option('searchPanel.text');
  let selectedText;
  if (search && search.length) {
    selectedText = GetSelection(cellText, search);
  }
  const textToDisplay = selectedText ? DOMPurify.sanitize(selectedText) : DOMPurify.sanitize(cellText);
  return (
    <React.Fragment>
      <S.ObjectTypeUriCell dangerouslySetInnerHTML={{ __html: textToDisplay }}></S.ObjectTypeUriCell>
    </React.Fragment>
  );
};
