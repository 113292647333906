// Those actions are generic for all editable objects therefore hardcoded here for convenience
// However, they could be fetched from config as done with commit (reverted): 5fc2e8807ebe14ed775ffd71dda553f4a757f463

import { GlideAction } from 'src/reducers/actions';

interface EditActions {
  cancel: GlideAction;
  save: GlideAction;
}

// TODO: Load those on app init so they're not hardcoded anymore
// https://dev.azure.com/virtusllc/AlphaKinetic/_workitems/edit/99795
export const editActions: EditActions = {
  cancel: {
    data: {
      display_name: 'Cancel',
      editor_button_style: 'Red',
      enabled: true,
      is_cancel_action: true,
      uri: 'locks/cancel',
    },
    uri: 'instance/actions/canceledit',
    date: '2022-06-07T00:00:00',
  },
  save: {
    data: {
      check_required_fields: true,
      display_name: 'Save',
      editor_button_style: 'Blue',
      enabled: true,
      uri: 'locks/commit',
    },
    uri: 'instance/actions/saveobject',
    date: '2022-06-07T00:00:00',
  },
};
