import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)<{ isPrimary: boolean }>`
  height: 34px;
  font-weight: bold;
  border-radius: 17px;
  color: var(--foreground-form);
  text-transform: capitalize;
  margin-right: 10px;
  background: ${props => {
    return props.isPrimary ? 'var(--background-form-primary-btn)' : 'var(--background-form-secondary-btn)';
  }};
  border: ${props => {
    return props.isPrimary ? 'var(--foreground-form-btn-border)' : 'var(--foreground-form-btn-border)';
  }};
  &:disabled {
    background-color: var(--background-search-inspector);
    color: var(--foreground-form-disabled);
    border: 1px solid var(--foreground-form-disabled);
  }
  &:hover {
    background: ${props => {
      return props.isPrimary ? 'var(--background-form-primary-btn)' : 'var(--background-form-secondary-btn)';
    }};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 15px;
  position: relative;
  left: -5px;
  width: 15px;
  line-height: 15px;
  padding-left: 5px;
`;

export const IconButton = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  padding: 0;
`;

export const MenuItem = styled.div`
  min-width: 150px;
  max-height: 204px;
  overflow-y: auto;
  border-bottom: var(--foreground-dx-grid-filter-border);
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  line-height: 14px;
`;

export const PortfolioDropdownContentWrapper = styled.div`
  overflow: hidden;
  min-height: 300px;
`;

export const PortfolioDropdownFooter = styled.div`
  height: 57px;
  background: var(--background-dx-grid-filter-bottom);
  display: flex;
  align-items: center;
  justify-content: end;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const CloseIcon = styled(Close)`
  color: var(--foreground-dx-grid-filter-clear);
  position: absolute;
  cursor: pointer;
  right: 20px;
  margin-top: -1px;
  font-size: var(--inspector-content-font-size);
`;
