import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const PortfolioManager: React.SFC<SvgIconProps> = props => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 19 19"
    {...props}
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <rect fill="#fff" opacity={0} strokeWidth={0} />
        <path
          strokeWidth={0}
          fill="#285bc5"
          d="M17.68,3.43v9h-1.01v5.05H2.33v-5.05h-1.01V3.43h5.32v-1.9h5.73v1.9h5.32ZM2.42,11.3h5.3v-1.17h3.56v1.17h5.3v-6.72H2.42v6.72ZM15.56,12.44h-4.29v1.17h-3.56v-1.17H3.44v3.89h12.13v-3.89ZM7.78,3.43h3.45v-.78h-3.45v.78ZM10.26,11.3h-1.53v1.14h1.49l.04-1.14Z"
        />
      </g>
    </g>
  </svg>
);

export default PortfolioManager;
