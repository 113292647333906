const indexOfSubstrings = function* (str: string, searchValue: string) {
  let i = 0;
  while (true) {
    const r = str.indexOf(searchValue, i);
    if (r !== -1) {
      yield r;
      i = r + 1;
    } else return;
  }
};

export const GetSelection = (cellText: string, search_text: string) => {
  const subStrIndex = [...indexOfSubstrings(cellText.toLowerCase(), search_text.toLowerCase())];
  const searchStrings = subStrIndex.map(index => [index, search_text.length]);
  let selectedText;
  if (searchStrings.length) {
    const span = "<span class='dx-datagrid-search-text'>";
    const highlights: string[] = [];
    searchStrings.forEach((str, index) => {
      if (index === 0) {
        highlights.push(cellText.slice(0, str[0]));
      }
      highlights.push(span);
      highlights.push(cellText.slice(str[0], str[0] + str[1]));
      highlights.push('</span>');
      if (index === searchStrings.length - 1) {
        highlights.push(cellText.slice(str[0] + str[1]));
      }
    });
    selectedText = highlights.join('');
  }
  return selectedText;
};
