import { actionTypes, mutateAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { parseDisplayViewData } from 'src/api/queries/display-view/display-view';
import { glideQuery } from 'src/api/query';
import {
  CREDIT_DETAIL_INSPECTOR,
  selectCreditDetailInspectorData,
} from 'src/components/inspectors/glide-object-inspector/credit-detail-inspector';
import {
  MODAL_INSPECTOR,
  selectModalInspectorData,
} from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import { setCreditDetailForm, setCreditDetailFormEdit } from 'src/reducers/creditDetailForm.reducer';
import { setModalForm, setModalFormEdit } from 'src/reducers/modalForm.reducer';

export interface WorkflowUpdate {
  uri?: string;
  transition_uri: string;
  comment?: string;
}

export interface WorkflowUpdatePayload extends WorkflowUpdate {
  storeViewName: string;
  storeViewProp: string;
}

export const updateWorkFlowAction = ({
  uri,
  transition_uri,
  comment,
  storeViewName,
  storeViewProp,
}: WorkflowUpdatePayload) => ({
  type: 'UPDATE_WORKFLOW_ACTION',
  payload: { uri, transition_uri, comment, storeViewName, storeViewProp },
});

export const updateModalWorkFlowAction = ({ uri, transition_uri, comment }: WorkflowUpdate) => ({
  type: 'UPDATE_MODAL_WORKFLOW_ACTION',
  payload: { uri, transition_uri, comment },
});

export const updateCreditDetailWorkFlowAction = ({ uri, transition_uri, comment }: WorkflowUpdate) => ({
  type: 'UPDATE_CREDIT_DETAIL_WORKFLOW_ACTION',
  payload: { uri, transition_uri, comment },
});

export function* updateWorkflowSaga(action: { payload: WorkflowUpdatePayload }) {
  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/order/workflow-transition`,
        body: {
          uri: action?.payload?.uri,
          transition_uri: action?.payload?.transition_uri,
          comment: action?.payload?.comment,
        },
        options: {
          method: 'POST',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          headers: { 'X-Http-Method-Override': 'PUT' },
        },
        update: {
          views: (prev: any, next: any) => ({
            ...prev,
            [action?.payload?.storeViewName]: {
              ...prev[action?.payload?.storeViewName],
              [action?.payload?.storeViewProp]: next[action?.payload?.storeViewName][action?.payload?.storeViewProp],
            },
          }),
        },
        transform: (body: any) => ({
          views: {
            [action?.payload?.storeViewName]: {
              [action?.payload?.storeViewProp]: parseDisplayViewData(body),
            },
          },
        }),
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: 'Updating Workflow Action...',
            [actionTypes.MUTATE_SUCCESS]: 'The Workflow Action Completed.',
          },
        },
      }),
    ),
  );
}

export function* updateModalWorkflowSaga(action: { payload: WorkflowUpdate }): any {
  const instanceUri: any = action?.payload?.uri;
  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/order/workflow-transition`,
        body: {
          uri: instanceUri,
          transition_uri: action?.payload?.transition_uri,
          comment: action?.payload?.comment,
        },
        options: {
          method: 'POST',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          headers: { 'X-Http-Method-Override': 'PUT' },
        },
        update: {
          [MODAL_INSPECTOR]: (prev: any, next: any) => ({
            ...prev,
            [instanceUri]: {
              ...prev[instanceUri],
              ...next,
            },
          }),
        },
        transform: (body: any) => ({ [MODAL_INSPECTOR]: parseDisplayViewData(body) }),
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: 'Updating Workflow Action...',
            [actionTypes.MUTATE_SUCCESS]: 'The Workflow Action Completed.',
          },
        },
      }),
    ),
  );

  const modalInspectorData = yield select(selectModalInspectorData);
  yield put(setModalForm(modalInspectorData[instanceUri]));
  yield put(setModalFormEdit(false, modalInspectorData[instanceUri]));
}

export function* updateCreditDetailWorkflowSaga(action: { payload: WorkflowUpdate }): any {
  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/order/workflow-transition`,
        body: {
          uri: action?.payload?.uri,
          transition_uri: action?.payload?.transition_uri,
          comment: action?.payload?.comment,
        },
        options: {
          method: 'POST',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          headers: { 'X-Http-Method-Override': 'PUT' },
        },
        update: {
          [CREDIT_DETAIL_INSPECTOR]: (_: any, next: any) => next,
        },
        transform: (body: any) => ({ [CREDIT_DETAIL_INSPECTOR]: parseDisplayViewData(body) }),
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: 'Updating Workflow Action...',
            [actionTypes.MUTATE_SUCCESS]: 'The Workflow Action Completed.',
          },
        },
      }),
    ),
  );

  const creditDetailInspectorData = yield select(selectCreditDetailInspectorData);
  yield put(setCreditDetailForm(creditDetailInspectorData));
  yield put(setCreditDetailFormEdit(false));
}

export function* watchUpdateActions() {
  yield takeLatest<any>('UPDATE_WORKFLOW_ACTION', updateWorkflowSaga);
}

export function* watchModalUpdateActions() {
  yield takeLatest<any>('UPDATE_MODAL_WORKFLOW_ACTION', updateModalWorkflowSaga);
}

export function* watchCreditDetailUpdateActions() {
  yield takeLatest<any>('UPDATE_CREDIT_DETAIL_WORKFLOW_ACTION', updateCreditDetailWorkflowSaga);
}

export default [watchUpdateActions, watchModalUpdateActions, watchCreditDetailUpdateActions];
