import React, { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { PrimaryButton } from 'src/Buttons';
import * as S from './page-error.style';

export interface PageErrorProps {
  statusCode: number;
  statusMessage?: string;
  footerMessage?: string;
  showBackButton?: boolean;
  setClientEnv?: Dispatch<SetStateAction<boolean>>;
}

interface ErrorStatus {
  [key: string]: { statusCode: number; footerMessage: string; statusMessage: string };
}

enum ErrorCodes {
  UNAUTHORIZED = 401,
  NOTFOUND = 404,
}

export const virtusErrorStatus: ErrorStatus = {
  [ErrorCodes.UNAUTHORIZED]: {
    statusCode: ErrorCodes.UNAUTHORIZED,
    statusMessage: 'You are not authorized to view this page.',
    footerMessage: 'Please contact your client services representative for more information.',
  },
  [ErrorCodes.NOTFOUND]: {
    statusCode: ErrorCodes.NOTFOUND,
    statusMessage: 'We couldn’t find this page.',
    footerMessage: 'The link you followed may be broken, or the page may have been removed.',
  },
};

export const PageError: FunctionComponent<PageErrorProps> = ({
  statusCode,
  statusMessage,
  footerMessage,
  showBackButton = false,
  setClientEnv,
}) => {
  const errorStatus = virtusErrorStatus[statusCode];
  return (
    <S.Container>
      <S.Header>
        <S.StatusCode>{statusCode || errorStatus.statusCode}</S.StatusCode>
        <S.StatusMessage>{statusMessage || errorStatus.statusMessage}</S.StatusMessage>
      </S.Header>
      <S.FooterMessage>{footerMessage || errorStatus.footerMessage}</S.FooterMessage>
      {showBackButton && <PrimaryButton onClick={() => history.back()}>Go Back</PrimaryButton>}
      {(statusCode === 401 || statusCode === 501) && setClientEnv && (
        <PrimaryButton onClick={() => setClientEnv(true)}>Change Client Env</PrimaryButton>
      )}
    </S.Container>
  );
};

export default PageError;
