import styled from 'styled-components';
import { Radio } from '@mui/material';

import { withStyles } from 'tss-react/mui';

export const Column = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 284px;
  height: auto;
`;

export const FormWrapper = styled(Column)`
  padding: 20px;
  height: auto;
  background-color: var(--text);
`;

export const FormLabel = styled.label`
  margin: 0;
  padding: 0;
  width: 150px;
`;

export const StyledRadio = withStyles(Radio, {
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export const CrossIcon = styled.span`
  &:after {
    content: '\\00d7';
    cursor: pointer;
    color: var(--text);
    padding: 1px 5px;
    font-size: var(--crossIconFontSize);
    position: absolute;
    right: 0;
    top: 0;
  }
`;
