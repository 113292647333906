// Extending the String prototype

interface String {
  lastSplitValue(separator?: string): string;
  isEmptyString(): boolean;
  capitalizeWords(separator?: string): string;
}

String.prototype.lastSplitValue = function (separator: string = '/'): string {
  if (this && this.length) return this.split(separator).reverse()[0];
  return '';
};

String.prototype.isEmptyString = function (): boolean {
  return typeof this === 'string' && this.trim() === '';
};

String.prototype.capitalizeWords = function (separator: string = ' '): string {
  return !this.isEmptyString()
    ? this.trim()
        .split(separator)
        .map(word => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ')
    : '';
};
