import styled from 'styled-components';
import { ListItemIcon as MUIListItemIcon, ListItem as MUIListItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const TextContainer = styled(Row)`
  flex: 1;
  color: var(--text);
  font-size: var(--textContainerFontSize);
`;

export const ListItemIcon = styled(MUIListItemIcon)`
  color: var(--text);
`;

export const ListItem = withStyles(MUIListItem, (_theme, _params, classes) => ({
  root: {
    [`&.${classes.selected}`]: {
      backgroundColor: 'var(--green)',
      '&:hover': {
        backgroundColor: 'var(--fis-green)',
      },
    },
    '&:hover': {
      backgroundColor: 'var(--fis-green)',
    },
  },
  selected: {},
}));
