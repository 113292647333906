import { AppConfig, GlideConfig, IDPConfig } from '@virtus/common/auth';

export interface GlideAppConfig extends AppConfig {
  glide: GlideConfig;
  idp: IDPConfig;
  marketmapURLS?: string[];
  routePathsDisabled?: string[]; // Route toggling
  disableLivePrice?: boolean; // Feature toggling
  nexusLinks?: any;
}

const MARKET_MAP_URLS = ['https://www.marketmapmuse.com', 'https://www2.marketmapmuse.com'];

// TODO: Store on .env but a pipeline will need to exist to inject env values

/**
 * returns correct GlideConfig object depending on __ENVIRONMENT__
 *
 * Using "if" statements other environments should be tree-shaked by webpack and not appear in bundled js
 */

const getConfig = (): GlideAppConfig => {
  if (__ENVIRONMENT__ === 'dev' || location.hostname.includes('glide-web-dev')) {
    const GLIDE_ENV_BASE_URL = 'glide-dev.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDev',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-dev',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local-web',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-dev') {
    const GLIDE_ENV_BASE_URL = 'glide-dev.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:5000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-local',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-qa') {
    const GLIDE_ENV_BASE_URL = 'glide.qa.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local-web',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  // TODO: The config should be populated via environment variable in
  // CI: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72433
  if (__ENVIRONMENT__ === 'BSP' || location.hostname.includes('glide-web-bsp')) {
    const GLIDE_ENV_BASE_URL = 'bsp.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },

      // This is a quick fix to disable some feature for clients.
      // This should be addressed properly with: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72173
      routePathsDisabled: ['dashboard', 'deals', 'credit'],
      disableLivePrice: true,
      nexusLinks: {
        compliance: 'https://nexus.virtusconnect.com/compliance',
        reporting: 'https://nexus.virtusconnect.com/reporting',
      },

      // TBC: Taufique
      idp: {
        baseURL: 'https://login.fisglobal.com/idp',
        firm: 'ben160551',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-bsp',
        serviceProviderId: 'glide-bsp',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      // Need whitelist for EC2 BSP
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'qa' || location.hostname.includes('glide-web-qa')) {
    const GLIDE_ENV_BASE_URL = 'glide.qa.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebQA',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-qa-web',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'demo-release' || location.hostname.includes('glide-web-demo-release')) {
    const GLIDE_ENV_BASE_URL = 'demo-release.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDemoRelease',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'demo-release',
        serviceProviderId: 'glide-demo-release',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'demo' || location.hostname.includes('glide-web-demo')) {
    const GLIDE_ENV_BASE_URL = 'demo.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://infinity.dev.fiscloudservices.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebDemo',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'demo',
        serviceProviderId: 'glide-demo',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'commerzbank') {
    const GLIDE_ENV_BASE_URL = 'commerzbank.alphakinetic.com';
    return {
      // Temporary config for IDP testing integration
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login.fisglobal.com/idp',
        firm: 'Commerzbankprod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-commerzbank',
        serviceProviderId: 'glide-commerzbank',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },

      // This is a quick fix to disable some feature for clients.
      // This should be addressed properly with: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/72173
      routePathsDisabled: ['dashboard', 'deals', 'credit'],

      disableLivePrice: true,
      nexusLinks: {
        compliance: 'https://nexus.virtusconnect.com/compliance',
        reporting: 'https://nexus.virtusconnect.com/reporting',
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'albacore-test' || location.hostname.includes('glide-web-albacore-test')) {
    const GLIDE_ENV_BASE_URL = 'albacore-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'fid185768uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore-test',
        serviceProviderId: 'glide-albacore-test',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-albacore') {
    return {
      glide: {
        baseURL: 'http://localhost:8080', // albacore-test.alphakinetic.com
        API_WEB_SOCKET_URL: 'ws://localhost:8082',
        environment: 'albacore-test',
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'fid185768uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore-test',
        serviceProviderId: 'glide-albacore-local',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: ['https://www.marketmapmuse.com', 'https://www2.marketmapmuse.com'],
    };
  }

  if (__ENVIRONMENT__ === 'local-api-qa') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-qa',
        serviceProviderId: 'glide-local-web',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'local-api-ksc-test') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'king62984uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ksc-test',
        serviceProviderId: 'glide-ksc-local',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'ksc-test' || location.hostname.includes('glide-web-ksc-test')) {
    const GLIDE_ENV_BASE_URL = 'ksc-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'king62984uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ksc-test',
        serviceProviderId: 'glide-ksc-test',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-metlife-test') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'met171186uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'metlife-test',
        serviceProviderId: 'glide-metlife-local',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'metlife-test' || location.hostname.includes('glide-web-metlife-test')) {
    const GLIDE_ENV_BASE_URL = 'metlife-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'met171186uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'metlife-test',
        serviceProviderId: 'glide-metlife-test',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-ft-test' || location.hostname.includes('local-api-ft-test')) {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'franklin133608uat',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ft-test',
        serviceProviderId: 'glide-ft-local',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'ft-test' || location.hostname.includes('glide-web-ft-test')) {
    const GLIDE_ENV_BASE_URL = 'ft-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'franklin133608uat',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ft-test',
        serviceProviderId: 'glide-ft-test',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'nw-test' || location.hostname.includes('glide-web-nw-test')) {
    const GLIDE_ENV_BASE_URL = 'nw-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'NW228127',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'nw-test',
        serviceProviderId: 'glide-nw-test',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'local-api-dev') {
    const GLIDE_ENV_BASE_URL = 'localhost';
    return {
      glide: {
        baseURL: `http://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `ws://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebLocal',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'glide-dev',
        serviceProviderId: 'glide-local',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'regression' || location.hostname.includes('glide-web-regression')) {
    const GLIDE_ENV_BASE_URL = 'glide.regression.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'GlideQA',
        clientId: 'GlideWebRegression',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'regression',
        serviceProviderId: 'glide-regression-web',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
      marketmapURLS: MARKET_MAP_URLS,
    };
  }

  if (__ENVIRONMENT__ === 'ft' || location.hostname.includes('glide-web-ft.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'ft.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'franklin133608prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ft',
        serviceProviderId: 'glide-ft',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'ksc' || location.hostname.includes('glide-web-ksc.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'ksc.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login10.fisglobal.com/idp',
        firm: 'king62984prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'ksc',
        serviceProviderId: 'glide-ksc',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'albacore' || location.hostname.includes('glide-web-albacore.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'albacore.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login4.fisglobal.com/idp',
        firm: 'alba185768prod',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'albacore',
        serviceProviderId: 'glide-albacore',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'axa-test' || location.hostname.includes('glide-web-axa-test')) {
    const GLIDE_ENV_BASE_URL = 'axa-test.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'axa165712',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'axa-test',
        serviceProviderId: 'glide-axa-test',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  if (__ENVIRONMENT__ === 'axa' || location.hostname.includes('glide-web-axa.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'axa.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login4.fisglobal.com/idp',
        firm: 'axa165712',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'axa',
        serviceProviderId: 'glide-axa',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }
  if (__ENVIRONMENT__ === 'bootb' || location.hostname.includes('glide-web-bootb.alphakinetic.com')) {
    const GLIDE_ENV_BASE_URL = 'bootb.alphakinetic.com';
    return {
      glide: {
        baseURL: `https://${GLIDE_ENV_BASE_URL}:8080`,
        API_WEB_SOCKET_URL: `wss://${GLIDE_ENV_BASE_URL}:8082`,
        DASHBOARD_URL: `https://${GLIDE_ENV_BASE_URL}:31000/dashboard/api`,
      },
      idp: {
        baseURL: 'https://login-uat.fisglobal.com/idp',
        firm: 'BOOTB_UAT',
        clientId: 'GlideWeb',
        responseType: 'code',
        scope: 'openid',
        clientEnvironment: 'bootb',
        serviceProviderId: 'glide-bootb',
        idleTimeout: 20,
        idleNotificationTimeout: 2,
        refreshTokenInterval: 2,
      },
    };
  }

  throw new Error(`No config for __ENVIRONMENT__:${__ENVIRONMENT__} or hostname:${location?.hostname}`);
};

console.info('Active config name:', __ENVIRONMENT__ || location.hostname);
// console.info('Active config details:', getConfig());

export default getConfig();
