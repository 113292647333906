import axios, { AxiosResponse } from 'axios';
import { CoreServicesClient, CoreServicesClientConfig, GET } from 'src/core-services/CoreServicesClient';

export type ExecuteCallback = (err: any, resStatus: number, resBody: any, resText: string, resHeaders: object) => void;

export type NetworkHandler = {
  execute: (callback: ExecuteCallback) => void;
  abort: () => void;
};

export type NetworkInterface = (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  config?: { body?: string | object; headers?: object; credentials?: 'omit' | 'include' },
) => NetworkHandler;

/**
 * Creates a network interface to use with redux-query
 * @param baseUrl
 */
export default function createCoreServicesNetworkInterface({
  baseURL,
  signalrUrl,
  signalrApiUrl,
  onUnauthorized,
  mockRoutes,
  dataEnvironment: environment,
  sessionTimeOut,
}: CoreServicesClientConfig): NetworkInterface {
  const csClient = new CoreServicesClient({
    baseURL,
    signalrUrl,
    signalrApiUrl,
    mockRoutes,
    onUnauthorized,
    dataEnvironment: environment,
    sessionTimeOut,
  });

  return (url: string, method: string, { body, headers }: { body?: any; headers?: any } = {}): NetworkHandler => {
    const cancelSource = axios.CancelToken.source();
    const requestConfig: any = { url, method, data: body, headers, cancelToken: cancelSource.token };
    if (method === GET && body) {
      requestConfig.params = body;
      delete requestConfig.data;
    }

    // Check if SignalR is true or False
    let useSignalR: boolean = false;
    if (headers) {
      if (headers[`usesignalr`]) {
        useSignalR = true;
        delete headers[`usesignalr`];
      }
    }

    const request = csClient.request(requestConfig, useSignalR);
    const execute = async (cb: ExecuteCallback) => {
      try {
        const response: AxiosResponse = await request;
        const { status, headers: responseHeaders } = response;
        let data = response.data;

        // API is responding with stringified JSON, adding a workaround until this is fixed
        const contentType = responseHeaders['content-type'];
        if (
          typeof data === 'string' &&
          (contentType.indexOf('application/json') !== -1 || contentType === 'application/octet-stream')
        ) {
          data = JSON.parse(data);
        }

        cb(null, status, data, '', responseHeaders);
      } catch (err) {
        const { response = {} } = err;
        const { data, status, headers: responseHeaders } = response;

        if (status === 401 && onUnauthorized) onUnauthorized();

        cb(err, status, data, '', responseHeaders);
      }
    };

    const abort = () => cancelSource.cancel();

    return {
      abort,
      execute,
    };
  };
}
