import styled from 'styled-components';
import List from 'devextreme-react/list';

export const ListSelection = styled(List)`
  .dx-checkbox-icon {
    border: var(--foreground-dx-grid-filter-border);
  }
  .dx-list-select-all:after {
    background-color: var(--foreground-dx-grid-filter-search);
    opacity: 1;
  }
  .dx-list-select-all-label {
    visibility: hidden;
    position: relative;
  }
  .dx-list-select-all-label:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 15px;
    content: 'All';
  }

  .dx-list-item-before-bag.dx-list-select-checkbox-container {
    width: 30px;
  }
`;
