import { useEffect, useState } from 'react';

export const useSearchWarning = (searchTerm: any, minCharacters: number = 3, error?: string | undefined) => {
  const [searchInputError, setSearchInputError] = useState<string | undefined>(error);

  useEffect(() => {
    if (searchTerm.length < minCharacters) {
      return setSearchInputError('Please type minimum 3 characters to search');
    }

    if (error) {
      return setSearchInputError(error);
    }
    return setSearchInputError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, searchTerm]);

  return searchInputError;
};
