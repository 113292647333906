import styled from 'styled-components';

export const Divider = styled.div`
  height: 5px;
  width: 100%;
`;

export const ViewContentWrapper = styled.div`
  flex: 2.5;
  height: 100%;
  max-width: 100%;
`;

export const BottomPanelWrapper = styled.div<{ isBottomPanelExpanded: boolean }>`
  flex: ${props => (props.isBottomPanelExpanded ? 2 : 0.4)};

  .dx-toolbar-items-container .dx-toolbar-center {
    margin: 0 auto !important;
  }
`;
