import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import App from 'src/app/App';
import { initStore, getQueries } from 'src/app/store';
import config from 'src/config';
import { createRoot } from 'react-dom/client';
// Global init
const store = initStore();

// Load React
const rootDOMElement = document.getElementById('app') as HTMLElement;
const root = createRoot(rootDOMElement);

// const render = (AppComponent = App) =>
//   ReactDOM.render(
//     <Provider store={store}>
//       <ReduxQueryProvider queriesSelector={getQueries}>
//         <AppComponent config={config} />
//       </ReduxQueryProvider>
//     </Provider>,
//     rootDOMElement,
//   );

// render(App);
root.render(
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={getQueries}>
      <App config={config} />
    </ReduxQueryProvider>
  </Provider>,
);
