/* eslint-disable max-len */
import styled from 'styled-components';
import { DataGrid as DxDataGrid } from 'devextreme-react/data-grid';
import { LayoutRegion } from '../glide-object-manager.model';

export const DataGrid = styled(DxDataGrid)`
  width: 100%;
  height: inherit;
`;

export const GridWrapper = styled.div<{
  edit?: boolean;
  layout?: LayoutRegion;
}>`
  width: 100%;
  height: 100%;
  padding: '0px';

  & .dx-datagrid-search-panel {
    height: 28px;
    display: flex;
    align-items: center;
  }
  & .dx-toolbar .dx-toolbar-items-container {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  & .dx-toolbar-before,
  .dx-toolbar-after {
    position: static;
  }
`;
