import { PivotFieldSchema, PivotGridDataSource as PivotDataSource } from './mapSchemaToFields';
import PivotGridDataSource, { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source';

interface GetTreeViewProps {
  data: PivotDataSource;
  dataSource: PivotGridDataSource;
}

const generateTreeViewData = ({ data, dataSource }: GetTreeViewProps) => {
  const { schema } = data;
  const categoryMap: any = {};

  schema
    .filter((item: PivotFieldSchema) => item.category)
    .forEach((item: PivotFieldSchema) => {
      const displayName: string | undefined = item.display_name || item.dataField;
      if (displayName) {
        categoryMap[displayName] = { displayName: displayName, displayFolder: item.category };
      }
    });

  const fields = dataSource.fields().map((item: PivotGridDataSourceField) => {
    if (categoryMap[item.dataField as any]) {
      return { ...item, ...categoryMap[item.dataField as any] };
    } else {
      return { ...item };
    }
  });

  dataSource.fields([...fields]);
};

export default generateTreeViewData;
