import React, { useState } from 'react';
import * as S from '../../glide-object-manager/style';
import GlideObjectCommentaryContainer from './glide-object-commentary-container';
import { useDispatch } from 'react-redux';
import { resetCommentary } from 'src/sagas/commentary/Commentary.saga';
import { backDropClick, CONFIRMATION_DIALOG } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';

interface ReduxProps {
  commentaryData: any;
  isCommentaryDirty?: any;
}

const CommentaryDialog = ({ commentaryData, isCommentaryDirty }: ReduxProps) => {
  if (!Object.keys(commentaryData).length) {
    return null;
  }
  const dispatch = useDispatch();
  const [displayType, setDisplayType] = useState<string>('preview');
  const closeCommentaryDialog = () => {
    setDisplayType('preview');
    dispatch(resetCommentary());
  };
  const { DialogComponent: QuitCommentaryDialog, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog(
    {
      onClick: closeCommentaryDialog,
      ...CONFIRMATION_DIALOG,
    },
  );
  const handleCloseModal = (_event: any, reason?: any) => {
    if (reason === backDropClick) return;
    if (isCommentaryDirty) {
      displayConfirmationDialog();
    } else {
      setDisplayType('preview');
      dispatch(resetCommentary());
    }
  };

  const handleExpansion = () => {
    if (displayType === 'fullscreen') {
      setDisplayType('preview');
    } else {
      setDisplayType('fullscreen');
    }
  };
  return (
    <>
      <QuitCommentaryDialog />
      <S.Dialog
        open={commentaryData.showModal || false}
        onClose={handleCloseModal}
        display_type={displayType}
        disableEscapeKeyDown
        disableEnforceFocus={true}
      >
        <GlideObjectCommentaryContainer
          isOpenFromGrid={true}
          fromColumn={true}
          onBack={handleCloseModal}
          handleExpansion={handleExpansion}
        />
      </S.Dialog>
    </>
  );
};

export default CommentaryDialog;
