import React, { useMemo, useState } from 'react';
import Toolbar from 'devextreme-react/toolbar';
import { resetIcon } from '@virtus/components/DxDataGrid/icons/svgicons';
import { StyledToolBarWrapper } from './pivot-toolbar.styles';
import sortBy from 'lodash/sortBy';
import { DxPivotCell } from '../dx-pivot-grid';

export interface PivotTollBarProps {
  getCustomToolBarItems?: any[];
  getGridTemplates?: any[];
  useAllFieldsPane?: boolean;
  refreshHandler: () => void;
  toggleAllFieldsPane?: () => void;
  toggleFieldChooser?: () => void;
  toolbarIconsOrder?: string[];
  toolbarItemsFromConfig?: any[];
  exportPivotData?: (e: DxPivotCell) => void;
  enableExport?: boolean;
}

const DEFAULT_TOOLBAR_ICONS_ORDER = [
  'portfolioManagerButton',
  'toggleAllFieldsPane',
  'fieldChooser',
  'searchButton',
  'searchPanel',
  'switchComponent',
  'refreshButton',
  'modellingButton',
  'portfolioOrderDropdownBtn',
  'portfolioAddDropdownBtn',
  'portfolioProposedOrderButton',
  'exportButton',
  'layout',
  'settingsButton',
];

export const PivotToolBar = React.memo(
  ({
    getGridTemplates,
    getCustomToolBarItems,
    refreshHandler,
    toggleAllFieldsPane,
    toggleFieldChooser,
    exportPivotData,
    useAllFieldsPane = false,
    toolbarIconsOrder = DEFAULT_TOOLBAR_ICONS_ORDER,
    toolbarItemsFromConfig,
    enableExport,
  }: PivotTollBarProps) => {
    const [displayInputSearch, setDisplayInputSearch] = useState(false);

    const searchHandler = () => setDisplayInputSearch(true);

    const defaultItems: any[] = useMemo(
      () => [
        {
          name: 'refreshButton',
          location: 'after',
          locateInMenu: 'never',
          widget: 'dxButton',
          cssClass: 'vertical-separator-line',
          options: {
            icon: resetIcon,
            hint: 'Refresh',
            onClick: refreshHandler,
            elementAttr: { 'data-testid': 'toolbar-grid-refresh-btn', class: 'toolbar-grid-refresh-btn' },
          },
        },
      ],
      [refreshHandler, displayInputSearch],
    );

    if (useAllFieldsPane && toggleAllFieldsPane) {
      defaultItems.push({
        name: 'toggleAllFieldsPane',
        location: 'before',
        locateInMenu: 'never',
        widget: 'dxButton',
        options: {
          icon: 'smalliconslayout',
          hint: 'Show All Fields',
          onClick: toggleAllFieldsPane,
          elementAttr: { 'data-testid': 'toolbar-show-all-fields-btn' },
        },
      });
    }

    defaultItems.push({
      name: 'fieldChooser',
      location: 'before',
      locateInMenu: 'never',
      widget: 'dxButton',
      options: {
        icon: 'columnchooser',
        hint: 'Fields Chooser',
        onClick: toggleFieldChooser,
        elementAttr: { 'data-testid': 'toolbar-field-chooser-btn' },
      },
    });

    if (!displayInputSearch) {
      defaultItems.push({
        name: 'searchButton',
        location: 'after',
        locateInMenu: 'never',
        widget: 'dxButton',
        options: {
          icon: 'search',
          hint: 'Search',
          onClick: searchHandler,
          elementAttr: { 'data-testid': 'pivot-grid-toolbar-search-btn' },
        },
      });
    }

    if (!enableExport) {
      defaultItems.push({
        name: 'exportButton',
        location: 'after',
        locateInMenu: 'never',
        widget: 'dxButton',
        cssClass: 'vertical-separator-line',
        options: {
          icon: 'export',
          hint: 'Export to excel',
          onClick: exportPivotData,
          elementAttr: { 'data-testid': 'pivot-grid-toolbar-export-btn' },
        },
      });
    }

    if (displayInputSearch) {
      defaultItems.push({
        name: 'searchPanel',
        location: 'after',
        template: 'pivotGridSearchPanel',
        options: {
          elementAttr: { 'data-testid': 'pivot-grid-toolbar-search-panel' },
        },
      });
    }

    defaultItems.push({
      name: 'pivotGridResizeSlider',
      location: 'after',
      template: 'pivotGridResizeSliderTemplate',
      cssClass: 'pivot-grid-slider',
      options: {
        elementAttr: { 'data-testid': 'pivot-grid-resize-slider' },
        hint: 'Slide left or right to see more',
      },
    });

    defaultItems.push({
      name: 'settingsButton',
      location: 'after',
      locateInMenu: 'never',
      widget: 'dxButton',
      template: 'settingsTemplate',
      options: {
        elementAttr: { 'data-testid': 'pivot-grid-toolbar-settings-btn' },
      },
    });

    const items = useMemo(() => {
      let toolbarItem = getCustomToolBarItems ? [...getCustomToolBarItems, ...defaultItems] : [...defaultItems];
      toolbarItem = toolbarItemsFromConfig ? [...toolbarItem, ...(toolbarItemsFromConfig as any[])] : toolbarItem;
      toolbarItem = sortBy(toolbarItem, (btn: any) => toolbarIconsOrder.indexOf(btn.name));
      return toolbarItem;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCustomToolBarItems, defaultItems]);

    return (
      <StyledToolBarWrapper>
        <Toolbar items={items}>{getGridTemplates && getGridTemplates.map((template: any) => template)}</Toolbar>
      </StyledToolBarWrapper>
    );
  },
);
