import React, { useState } from 'react';
import * as S from './summary-panel.style';
import SummaryButtons from 'src/components/screen-wrapper/components/summary-buttons/summary-buttons';
import { RootState } from 'src/reducers';
import { selectCVC } from 'src/reducers/tabs';
import { connect } from 'react-redux';
import { ClientViewConfigurationData, ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import DatePicker from 'src/components/date-picker/date-picker';
import 'src/components/portfolio-fund-selector/portfolio-fund-selector';
import { ComponentProps, selectComponents } from 'src/reducers/components';
import { getBusinessDay, getDefaultDateFromConfig } from 'src/utils/date-expression';
import PortfolioFundSelector from 'src/components/portfolio-fund-selector/portfolio-fund-selector';
import DateFilterSelection from 'src/components/summary-panel/date-filter-selection/date-filter-selection';

export interface SummaryPanelProps {
  children?: React.ReactElement<any>;
  panelContentLeft?: any;
  panelContentRight?: any;
}

interface ReduxProps extends ComponentProps {
  clientViewConfiguration: ClientViewConfigurationData;
}

export const parseDefaultDateFilter = (defaultDate: string) => {
  const defaultDateObj = JSON.parse(defaultDate);
  const type = defaultDateObj.type as string;
  const value = defaultDateObj.value;
  let start_date: number = 0;
  let end_date: number = 0;
  if (value.includes('-')) {
    start_date = new Date().setDate(new Date().getDate() - value.split('-')[1]);
    end_date = new Date().getTime();
  } else if (value.includes('+')) {
    start_date = new Date().getTime();
    end_date = new Date().setDate(new Date().getDate() + value.split('+')[1]);
  }
  return {
    type,
    start_date,
    end_date,
  };
};

export const SummaryPanel = ({
  clientViewConfiguration,
  panelContentLeft,
  panelContentRight,
  children,
  components,
}: ReduxProps & SummaryPanelProps) => {
  const [complianceDashboardError, setComplianceDahsboardError] = useState<string>('');
  const hasComplianceButtons = clientViewConfiguration?.web_summary_panel?.panel_left?.action_buttons.length > 0;
  const baseLineDate = components?.viewComponents?.[clientViewConfiguration?.uri]?.['baseLineDate'];
  const currentDate = components?.viewComponents?.[clientViewConfiguration?.uri]?.['currentDate'];
  const datePicker = components?.viewComponents?.[clientViewConfiguration?.uri]?.['datePicker'];

  const complianceButtons = () =>
    clientViewConfiguration.web_summary_panel.panel_left.action_buttons.map(
      (
        item: { id: string; text: string | number; label: string; backgroundColor: string | undefined },
        index: string | number | undefined,
      ) => (
        <SummaryButtons
          id={item.id}
          key={index}
          buttonText={item.text}
          buttonLabel={item.label}
          backgroundcolor={item.backgroundColor}
          onButtonClick={() => {}}
        />
      ),
    );

  if (clientViewConfiguration.default_date_filter) {
    const { type, start_date, end_date } = parseDefaultDateFilter(clientViewConfiguration.default_date_filter);
    return <DateFilterSelection type={type} start_date={start_date} end_date={end_date} />;
  }

  if (!clientViewConfiguration.is_time_series && !panelContentLeft && !panelContentRight && !hasComplianceButtons) {
    return null;
  }

  return (
    <S.SummaryPanelComponent>
      <div>{panelContentLeft}</div>
      {hasComplianceButtons && complianceButtons()}
      {panelContentRight && <div>{panelContentRight}</div>}
      {children}
      {clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Custom && (
        <>
          <S.FundSelectorWrapper>
            <PortfolioFundSelector clientViewConfiguration={clientViewConfiguration} />
          </S.FundSelectorWrapper>
          <S.DatePickerWrapper>
            {clientViewConfiguration.has_baseline_date && (
              <DatePicker
                singleDateSelection
                dateFieldLabel="Baseline Date:"
                defaultDate={baseLineDate?.data?.singleDate ?? getBusinessDay(-2)}
                dateStoreKey="baseLineDate"
                validationError={complianceDashboardError}
                validationErrorCallback={setComplianceDahsboardError}
              />
            )}
            <DatePicker
              singleDateSelection
              dateFieldLabel="Current Date:"
              defaultDate={currentDate?.data?.singleDate ?? getBusinessDay(-1)}
              dateStoreKey="currentDate"
              validationError={complianceDashboardError}
              validationErrorCallback={setComplianceDahsboardError}
            />
          </S.DatePickerWrapper>
        </>
      )}
      {clientViewConfiguration?.client_view_type !== ClientViewTypeLookUps.Custom &&
        clientViewConfiguration.is_time_series && (
          <S.DatePickerWrapper>
            <DatePicker
              singleDateSelection
              isDateNetworkRequest
              defaultDate={
                datePicker?.data?.singleDate ??
                getDefaultDateFromConfig(clientViewConfiguration.default_dates as string)
              }
            />
          </S.DatePickerWrapper>
        )}
    </S.SummaryPanelComponent>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  clientViewConfiguration: selectCVC(state),
  components: selectComponents(state),
});

export default connect(mapStateToProps)(SummaryPanel);
