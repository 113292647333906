import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const ProposedOrder: React.SFC<SvgIconProps> = props => (
  <svg
    id="ProposedOrder"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 19 19"
    {...props}
  >
    <g id="ProposedOrder_1-2" data-name="Layer 1">
      <g>
        <rect fill="#fff" opacity={0} strokeWidth={0} width="19" height="19" />
        <path
          strokeWidth={0}
          fill="#285bc5"
          d="M.96,17.96V1.04h13.05v2.61h-1.14v-1.48H2.12v14.65h10.76v-2.01h1.14v3.15H.96ZM3.79,4.91h5.48v1.01H3.79v-1.01ZM3.79,7.63h4.34v1.01H3.79v-1.01ZM3.79,10.35h4.34v1.01H3.79v-1.01ZM3.79,13.07h5.48v1.01H3.79v-1.01ZM11.9,13.36c-.53-.24-1-.55-1.4-.95-.4-.4-.72-.86-.94-1.4-.23-.53-.34-1.1-.34-1.71s.11-1.19.34-1.72c.23-.53.54-1,.94-1.4.4-.4.87-.71,1.4-.95.53-.23,1.11-.34,1.72-.34s1.17.11,1.71.34c.53.24,1,.55,1.4.95.4.4.72.86.94,1.4.24.53.36,1.11.36,1.72s-.12,1.17-.36,1.71c-.23.53-.54,1-.94,1.4-.4.4-.87.72-1.4.94-.53.24-1.1.36-1.71.36h-.02c-.6,0-1.17-.12-1.71-.36ZM11.3,6.97c-.64.64-.96,1.42-.96,2.33s.32,1.67.96,2.31c.64.64,1.41.96,2.31.96s1.67-.32,2.31-.96c.64-.64.96-1.41.96-2.31s-.32-1.69-.96-2.32c-.64-.63-1.41-.96-2.31-.97-.9,0-1.67.32-2.31.96ZM13.34,11.14l-1.76-1.53.66-.76.96.84,1.71-2.21.8.6-2.37,3.06Z"
        />
      </g>
    </g>
  </svg>
);

export default ProposedOrder;
