import React, { useState } from 'react';
import DXTabPanel from 'devextreme-react/tab-panel';

interface TabPanelProps {
  dataSource: any[];
  itemTitleRender: React.FC<any>;
  itemComponent: React.FC<{ data: any }>;
}

interface OnSelectionChangedProps {
  name?: string;
  value?: number;
}
export const TabPanel = (props: TabPanelProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const onSelectionChanged = (e: OnSelectionChangedProps) => {
    if (e.name === 'selectedIndex') {
      setSelectedIndex(e.value || 0);
    }
  };

  return (
    <div>
      <DXTabPanel
        height="100%"
        selectedIndex={selectedIndex}
        onOptionChanged={onSelectionChanged}
        animationEnabled
        {...props}
      />
    </div>
  );
};
