import React, { useRef } from 'react';
import { IPortalLink, PortalLink } from 'src/Buttons';
import Tooltip from '@mui/material/Tooltip';
import { ExtraIconButtonProps, ILabel } from './tool-bar.model';
import { IconButton, StyledButtonContainer, StyledContainer, StyledLabelContainer } from './tool-bar.style';
import ViewLabels from 'src/ViewToolbar/components/ViewLabels';

export interface ToolBarProps {
  labels: ILabel[];
  active?: any;
  // active?: number;
  moreButtonItems?: () => React.ReactNode;
  onExport?: () => void;
  onClickTab: (key: string | any) => void;
  extraIconButtons?: ExtraIconButtonProps[];
  links?: IPortalLink[];
  customParseViews?: (data: any[]) => any[];
  viewContainerStyles?: any;
  isInspectorCollapsed?: boolean;
  isColumnManagerVisible?: boolean;
  isSearchInspectorVisible?: boolean;
  isLayoutManagerVisible?: boolean;
}

const onClickToolBarTab = () => console.info('Action not defined');

const setParentWidth = (width: number) => (width < 900 ? '30vw' : '65vw');
export const ToolBar = ({
  labels,
  active,
  onClickTab = onClickToolBarTab,
  extraIconButtons,
  customParseViews,
  viewContainerStyles = {},
  links = [],
  isInspectorCollapsed,
  isColumnManagerVisible,
  isSearchInspectorVisible,
  isLayoutManagerVisible,
}: ToolBarProps) => {
  const containerRef = useRef(null);

  const onLabelClickHandler = (id: number) => () => onClickTab(id);
  return (
    <StyledContainer>
      <StyledLabelContainer ref={containerRef} style={viewContainerStyles}>
        <ViewLabels
          views={labels}
          onClick={onLabelClickHandler}
          parentRef={containerRef}
          labelStyle={{
            minWidth: 'fit-content',
          }}
          active={active}
          customParseViews={customParseViews}
          setParentWidth={setParentWidth}
          isInspectorCollapsed={isInspectorCollapsed}
          isColumnManagerVisible={isColumnManagerVisible}
          isSearchInspectorVisible={isSearchInspectorVisible}
          isLayoutManagerVisible={isLayoutManagerVisible}
        />
        {links.map(link => (
          <PortalLink key={link.text} {...link} />
        ))}
      </StyledLabelContainer>
      <StyledButtonContainer>
        {extraIconButtons &&
          extraIconButtons.map(iconButton => (
            <Tooltip key={iconButton.testId} title={iconButton.tooltip}>
              <IconButton data-testid={iconButton.testId} onClick={iconButton.onClick}>
                <iconButton.Icon style={{ color: 'var(--foreground-sub-tab-icon)' }} {...iconButton.iconProps} />
              </IconButton>
            </Tooltip>
          ))}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default ToolBar;
