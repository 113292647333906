import styled from 'styled-components';

export const Link = styled.span`
  color: var(--default-blue);
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: var(--light-blue);
  }
`;
