import React, { useState } from 'react';
import { DragIndicator } from '@mui/icons-material';
import * as S from './drag-cell-render-style';

export const DragCellRender = ({ cellData, selectedAssets }: { cellData: any; selectedAssets?: any[] }) => {
  const [isBeingDragged, setIsBeingDragged] = useState<boolean>(false);
  const isAlreadyInTheCart = !selectedAssets
    ? false
    : selectedAssets.find(selectedAsset => selectedAsset._uri === cellData.row.data._uri);

  const handleOnClick = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleOnDragStart = (event: React.DragEvent<any>) => {
    event.dataTransfer.setData('rowData', JSON.stringify(cellData.row.data));
    event.stopPropagation();
    setIsBeingDragged(true);
  };

  const handleOnDragEnd = () => setIsBeingDragged(false);
  return (
    <S.Container
      onClick={handleOnClick}
      onDragStart={handleOnDragStart}
      onDragEnd={handleOnDragEnd}
      draggable={!isAlreadyInTheCart}
    >
      <S.Cell isBeingDragged={isBeingDragged} id="cell-to-drag ">
        <DragIndicator style={{ visibility: isAlreadyInTheCart ? 'hidden' : 'visible', fontSize: '16px' }} />
      </S.Cell>
    </S.Container>
  );
};
