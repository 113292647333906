import { authSelectors } from '@virtus/common/auth/reducer';
import { select, takeLatest } from 'redux-saga/effects';
import { marketmapScripts } from 'src/utils/constants';

interface ActionPayload {
  payload: { isMarketMapEnabled?: boolean };
}

export enum MARKET_MAP {
  ENABLE_MARKET_MAP = 'ENABLE_MARKET_MAP',
}

export type MarketMap = {
  isMarketMapEnabled?: boolean;
};

type EnableMarketMapAction = MARKET_MAP.ENABLE_MARKET_MAP;

export type MarketMapAction = EnableMarketMapAction;

export function* enableMarketMap(action: ActionPayload): any {
  const isGlideAuthenticated = yield select(authSelectors.isGlideAuthenticated);
  const isAuthenticated = yield select(authSelectors.isAuthenticated);
  if (isGlideAuthenticated && isAuthenticated && action.payload.isMarketMapEnabled) {
    marketmapScripts.forEach(script => {
      const scriptingElement = document.createElement('script');
      scriptingElement.src = script.scriptingUrl;
      scriptingElement.defer = true;
      scriptingElement.async = false;
      script.scriptingElement = scriptingElement as HTMLScriptElement;
      document.body.appendChild(scriptingElement);
    });
  }
}

export function* watchLoadMarketMap() {
  yield takeLatest<any>(MARKET_MAP.ENABLE_MARKET_MAP, enableMarketMap);
}

export default [watchLoadMarketMap];
