import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

export const createOrderInstrument = (body: { [key: string]: any }) => ({
  url: `${endpoints.order.newOrderInstrument}`,
  options: { method: 'POST' },
  queryKey: `createNewOrderInstrument`,
  body,
  force: true,
  update: {
    newOrderInstrument: (_: any, next: any) => next,
  },
});

export const newOrderselector = (state: RootState) => state.entities.newOrderInstrument;

export const newOrderIsPending = (state: RootState) =>
  state.queries.createNewOrderInstrument && state.queries.createNewOrderInstrument.isPending;
