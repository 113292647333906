import styled from 'styled-components';

export const Footer = styled.div<{ isLight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: ${props => (props.isLight ? 'var(--background-form-footer)' : 'var(--background-search-panel-dark)')};
  padding: 0 10px;
  border-top: ${props => (props.isLight ? 'var(--foreground-form-border)' : 'var(--inspector-footer-border-dark)')};
`;
