import { MarketmapFields } from 'src/utils/constants';

export interface MarketmapRealTimeInfo {
  columnToUse: string;
  columnToUpdate: string;
  marketmapFields: (string | number)[];
  id: string;
}

export interface MarketmapCredentialsType {
  marketmapGroup: string;
  marketmapU: string;
  marketmapP: string;
}

export interface Muse {
  about: () => {};
  alerts: (_e: any, _n: any) => {};
  config: (_e: any) => {};
  connect: (_e: any, _n: any, _t: any, _o: any) => {};
  connectWithToken: (_e: any, _n: any, _t: any, _o: any) => {};
  connectionStateChanged: (_e: any) => {};
  getBankHoliday: (_e: any, _n: any) => {};
  getMarketHoliday: (_e: any, _n: any) => {};
  isBankHoliday: (_e: any, _n: any) => {};
  isMarketHoliday: (_e: any, _n: any) => {};
  logLevel: () => {};
  monitorChains: (_e: any, _n: any) => {};
  monitorCharts: (_e: any, _n: any) => {};
  monitorCorpActions: (_e: any, _n: any) => {};
  monitorListBrowser: (_e: any) => {};
  monitorNews: (_e: any, _n: any) => {};
  monitorPrices: (_e: any, _n: any) => {};
  monitorStories: (_e: any, _n: any) => {};
  monitorSymbolsSearch: (_e: any, _n: any) => {};
  monitorTimeSales: (_e: any, _n: any) => {};
  onlineStorage: (_e: any, _n: any) => {};
  snapshotCharts: (_e: any, _n: any) => {};
  snapshotCompanyProfiles: (_e: any, _n: any) => {};
  snapshotMarketList: (_e: any) => {};
  snapshotOptionChain: (_e: any, _n: any) => {};
  snapshotTimeSales: (_e: any, _n: any) => {};
  snapshotWarrantChain: (_e: any, _n: any) => {};
  stopMonitorChains: (_e: any) => {};
  stopMonitorCharts: (_e: any) => {};
  stopMonitorCorpActions: (_e: any) => {};
  stopMonitorNews: (_e: any) => {};
  stopMonitorPrices: (_e: any) => {};
  stopMonitorStories: (_e: any) => {};
  stopMonitorTimeSales: (_e: any) => {};
}

export interface MarketmapLoginData {
  connectionId: string;
  isConnected: boolean;
  message: string;
  transportType: string;
  userName: string;
}

export interface MarketmapLivePriceDataType {
  Symbol?: string;
  Error?: string;
  2?: string | number | undefined;
  400?: string | number | undefined;
  101?: string | number | undefined;
  109?: string | number | undefined;
}

export interface MarketmapScripting {
  scriptingElement: any;
  scriptingUrl: string;
}

export type MarketMapConsumerViewType = 'assets' | 'portfolio' | 'portfolio_analyzer';

export type IMarketmapConsumerViewState = {
  [key in MarketMapConsumerViewType]: boolean;
};

export enum MarketMapColumns {
  'id' = 'id',
  'MarketMapID' = 'Market Map ID',
  'MarketMapLastPrice' = 'Market Map Last Price',
}

/**
 * For now id can be used as a key in realtime data config, since its already mapped in datasource
 */
export const marketmapRealTimeConfig: MarketmapRealTimeInfo = {
  id: MarketMapColumns.id,
  columnToUse: MarketMapColumns.MarketMapID,
  columnToUpdate: MarketMapColumns.MarketMapLastPrice,
  marketmapFields: [
    MarketmapFields.LAST_PRICE,
    MarketmapFields.SYMBOL,
    MarketmapFields.INSTRUMENT_NAME,
    MarketmapFields.NET_CHANGE,
    MarketmapFields.PERC_NET_CHANGE,
  ],
};
