import InputBase from '@mui/material/InputBase';
import Search from '@mui/icons-material/Search';
import styled from 'styled-components';

export const Input = styled(InputBase)<{ margin: string; label: string; hasFocus: boolean; textIndent?: number } & any>`
  color: var(--foreground-search-panel);
  background: var(--background-search-panel);
  width: 100%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  border: ${props => {
    if (!props.hasFocus) return 'var(--foreground-form-border)';
    if (props.hasFocus) return 'var(--foreground-form-input-focus-border)';
    return 'none';
  }};
  input {
    text-indent: ${props => {
      if (props.textIndent) return props.textIndent;
      return '23px';
    }};
    font-size: var(--layout-font-size);
    ::placeholder {
      font-size: var(--layout-font-size);
      font-weight: normal;
      color: var(--foreground-form-placeholder);
    }
  }
`;

export const Row = styled.div`
  display: flex;
  height: 35px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

export const SearchIcon = styled(Search)`
  color: var(--foreground-search-panel);
  opacity: 1;
  position: absolute;
  margin-top: 3px;
  margin-left: 5px;
  width: 21px;
  z-index: 1000;
`;
