import { put, select, putResolve, takeLatest, take } from 'redux-saga/effects';
import { Action, createAction } from 'src/reducers/createAction';
import { selectViewComponent } from 'src/reducers/components';
import { NotificationsAction } from 'src/reducers/notifications';
import { selectedFundURIs } from 'src/reducers/portfolio';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { selectCVC } from 'src/reducers/tabs';
import { DateConfig } from 'src/components/date-picker/date-picker';
import { dispatchActions } from 'src/app/store';
import { requestAsync } from 'redux-query';
import { glideQuery } from 'src/api/query';

export const COMPLIANCE_DASHBOARD_ERROR_MSG = 'Baseline date is greater than the Current date';

export type DateStoreKey = 'datePicker' | 'baseLineDate' | 'currentDate';

export enum TIME_SERIES {
  VALIDATE_DATE = 'VALIDATE_DATE',
  EXECUTE_LOAD_TESTS = 'EXECUTE_LOAD_TESTS',
}

export type TimeSeries = {
  uri?: string;
  validationCallback?: (_errorMsg: string) => void;
};

export interface DateStoreType {
  visible: boolean;
  count: number;
  data: DateConfig;
}

type ValidateDateAction = Action<typeof TIME_SERIES.VALIDATE_DATE, TimeSeries>;
type executeLoadTestsAction = Action<typeof TIME_SERIES.EXECUTE_LOAD_TESTS, TimeSeries>;

export type TimeSeriesAction = ValidateDateAction | executeLoadTestsAction;

export const validateDate = (timeSeries: TimeSeries): TimeSeriesAction =>
  createAction(TIME_SERIES.VALIDATE_DATE, timeSeries);

export const executeLoadTests = (timeSeries?: TimeSeries): TimeSeriesAction =>
  createAction(TIME_SERIES.EXECUTE_LOAD_TESTS, timeSeries as TimeSeries);

export function* validate_Date(action: TimeSeriesAction): any {
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  if (clientViewConfiguration?.uri.includes('compliance_dashboard')) {
    // The validation should trigger when user changes either baseLine Date or Current Date
    const baseLineDate = clientViewConfiguration.has_baseline_date
      ? yield select(selectViewComponent, 'baseLineDate', clientViewConfiguration.uri)
      : '';
    const currentDate = yield select(selectViewComponent, 'currentDate', clientViewConfiguration.uri);
    if (
      clientViewConfiguration.has_baseline_date &&
      new Date(baseLineDate?.data?.singleDate) > new Date(currentDate?.data?.singleDate)
    ) {
      yield put({
        type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
        payload: { errorMessage: COMPLIANCE_DASHBOARD_ERROR_MSG },
      });
      action.payload?.validationCallback?.(COMPLIANCE_DASHBOARD_ERROR_MSG);
    } else {
      action.payload?.validationCallback?.('');
    }
  }
}

/**
 * This generator will ensure that the load test request on initial page load will trigger only after
 * currentDate (from_date) and funds are set and stored in redux (as baseLineDate (to_date) can be optional)
 * to avoid any racing condition so that websocket request will always have mandatory target_uris and from_date.
 */
export function* execute_load_tests(_action: TimeSeriesAction): any {
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  if (clientViewConfiguration?.uri.includes('compliance_dashboard')) {
    let baseLineDate = clientViewConfiguration.has_baseline_date
      ? yield select(selectViewComponent, 'baseLineDate', clientViewConfiguration.uri)
      : '';
    let currentDate = yield select(selectViewComponent, 'currentDate', clientViewConfiguration.uri);
    let selectedFundsUris = yield select(selectedFundURIs, clientViewConfiguration);

    if (!selectedFundsUris) {
      const actionLinks = [
        {
          title: 'Open Fund Selector',
          onClick: () => {
            dispatchActions.components.updateView('fundSelector', clientViewConfiguration.uri, { visible: true });
          },
        },
      ];
      yield put({
        type: NotificationsAction.NOTIFICATION,
        payload: { title: '', message: 'Please select funds using Portfolio selector.', actionLinks: [...actionLinks] },
      });
    } else if (!currentDate) {
      while (!currentDate) {
        yield take();
        baseLineDate = clientViewConfiguration.has_baseline_date
          ? yield select(selectViewComponent, 'baseLineDate', clientViewConfiguration.uri)
          : '';
        currentDate = yield select(selectViewComponent, 'currentDate', clientViewConfiguration.uri);
        selectedFundsUris = yield select(selectedFundURIs, clientViewConfiguration);
      }
    } else if (currentDate && selectedFundsUris) {
      if (!baseLineDate || new Date(baseLineDate?.data?.singleDate) <= new Date(currentDate?.data?.singleDate)) {
        const queryParams = {
          uri: clientViewConfiguration?.uri,
          fund: selectedFundsUris.join(','),
          start_date: baseLineDate?.data?.singleDate,
          end_date: currentDate?.data?.singleDate,
        };
        yield putResolve(
          requestAsync(
            glideQuery({
              endpoint: '/glide/view',
              body: queryParams,
            }),
          ),
        );
      }
    }
  }
}

export function* watchValidateDate() {
  yield takeLatest<any>(TIME_SERIES.VALIDATE_DATE, validate_Date);
}

export function* watchExecuteLoadTests() {
  yield takeLatest<any>(TIME_SERIES.EXECUTE_LOAD_TESTS, execute_load_tests);
}

export default [watchValidateDate, watchExecuteLoadTests];
