import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import * as S from './glide-commentary-list.style';
import { Search as SearchIcon } from '@mui/icons-material';
import { sortByParams } from './glide-commentary-list-mapper';
import SelectMenu from '@virtus/components/SelectMenu';
import { SecondaryButton } from '@virtus/components/Buttons';
import { Footer } from '../glide-object-manager/container/glide-object-commentary-container-style';
import AddIcon from '@mui/icons-material/Add';

export interface ListingComponentProps {
  commentaryData: { [key: string]: any };
  itemTemplate: (data: any) => void;
  defaultSortBy?: { field: string; orderBy: 'asc' | 'desc' };
  searchExpr?: string[];
  groupByDropDownList?: any[];
  sortByDropDownList?: any[];
  onSelectedItem: (selectedItem: any) => void;
  groupTemplate?: (data: any) => void;
  setSelectedItemIndex: (index: number) => void;
  isSearchEnabled?: boolean;
  fromModal?: boolean;
  createNewCommentEditor?: (editor: any) => void;
  actionButtonText?: string;
}
export interface GlideListProps {
  id: number;
  title: string;
  category: string;
  commentdate: number;
  commenturi: string;
  comments: string;
  creditdetails: string;
  displayname: string;
  username: string;
}
export interface GroupedDataProps {
  key: string;
  items: GlideListProps[];
}
const GlideCommentaryList = ({
  sortByDropDownList,
  defaultSortBy,
  searchExpr,
  commentaryData,
  onSelectedItem,
  itemTemplate,
  setSelectedItemIndex,
  isSearchEnabled = true,
  fromModal,
  createNewCommentEditor,
  actionButtonText,
}: ListingComponentProps) => {
  const [isSort, setIsSort] = useState<boolean>(false);
  const [SortedListData, setSortedListData] = useState<any>([]);
  const [dataSourceList, setDataSourceList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortByDropDownValue, setSortByDropDownValue] = useState<number>(-1);
  useEffect(() => {
    filterListData(sortByDropDownValue !== -1, commentaryData, searchTerm, sortByDropDownValue);
    setDataSourceList(commentaryData);
    setSelectedItemIndex(commentaryData && commentaryData.length && commentaryData[0].id);
  }, [commentaryData]);

  const changeSortByDropdown = (event: any) => {
    setSortByDropDownValue(event.target.value);
    changeSortByDropdownValue(event.target.value);
  };

  const getFilteredDropdownValue = (lists: any, selectedId: number) => {
    if (lists && lists.length) {
      const filteredData = lists.filter((c: any) => c.id == selectedId);
      if (filteredData && filteredData.length > 0) return filteredData[0];
    }
    return [];
  };

  const changeSortByDropdownValue = (sortByOptionValue: number) => {
    if (dataSourceList && dataSourceList.length > 0) {
      if (sortByOptionValue > -1) {
        setIsSort(true);
        filterListData(true, dataSourceList, searchTerm, sortByOptionValue);
      } else {
        setIsSort(false);
        filterListData(false, dataSourceList, searchTerm, sortByOptionValue);
      }
    }
  };

  const sortFilteredData = (filteredData: any, sortByDropDownValues?: number) => {
    const dropDownSelectedValue = getFilteredDropdownValue(
      sortByDropDownList,
      sortByDropDownValues ? sortByDropDownValues : sortByDropDownValue,
    );
    const sortedArray = sortByParams(
      !dropDownSelectedValue && defaultSortBy ? defaultSortBy.orderBy : dropDownSelectedValue.orderby,
      filteredData,
      !dropDownSelectedValue && defaultSortBy ? defaultSortBy.field : dropDownSelectedValue.orderfield,
    );
    return sortedArray;
  };

  const searchIfTermExists = (data: any, id: number) => {
    const foundData = find(data, { id: id });
    return foundData;
  };

  const filterListData = (isSort: boolean, dataSourceList: any, term: string, sortByDropDownValue?: number) => {
    let filteredData: GlideListProps[] = [];
    if (term) {
      filteredData = dataSourceList.filter((lds: any) => {
        return Object.keys(lds).some((k: string) => {
          return (
            searchExpr?.includes(k) &&
            lds[k] &&
            lds[k].toString().toLowerCase().indexOf(term.toLowerCase()) > -1 &&
            !searchIfTermExists(filteredData, lds.id)
          );
        });
      });
    } else {
      filteredData = dataSourceList;
    }

    if (isSort) {
      const sortFilterData = sortFilteredData(filteredData, sortByDropDownValue);
      setSortedListData(sortFilterData);
      if (sortFilterData && sortFilterData.length > 0) {
        onSelectedItem(sortFilterData[0]);
        setSelectedItemIndex(sortFilterData[0].id);
      } else {
        onSelectedItem([]);
      }
    } else {
      if ((!term || !isSort) && defaultSortBy) {
        setSortedListData(sortByParams(defaultSortBy.orderBy, filteredData, defaultSortBy.field));
      } else {
        setSortedListData(filteredData);
      }
      if (filteredData && filteredData.length) {
        onSelectedItem(filteredData[0]);
        setSelectedItemIndex(filteredData[0].id);
      } else {
        onSelectedItem([]);
      }
    }
  };
  const createNewCommentEditorAction = () => {
    createNewCommentEditor && createNewCommentEditor(true);
  };
  const onChangeSearchTerm = (term: string) => {
    setSearchTerm(term);
    filterListData(isSort, dataSourceList, term);
  };

  const onChange = (e: any) => onChangeSearchTerm(e.target.value);
  return (
    <>
      <S.BlogOuterWrapper data-testid="glide-commentarylist-commentarycontainer">
        <S.BlogInnerWrapper fromModal={fromModal}>
          <SelectMenu
            value={sortByDropDownValue}
            onChange={changeSortByDropdown}
            name="sortByBlogs"
            options={sortByDropDownList}
            valueKey="id"
            displayKey="text"
            style={{
              flex: 1,
              marginRight: '5px',
              padding: '3px 0px 1px 10px',
              width: '100%',
              color: 'var(--foreground-form)',
              background: 'var(--background-form)',
              height: '23px',
              border: 'var(--foreground-dx-grid-action-drop-down-border)',
              borderRadius: '4px',
              fontWeight: 'normal',
            }}
            customSelectStyles={{
              select: { whiteSpace: 'normal', paddingRight: '0px', width: '100%' },
              icon: {
                color: 'var(--foreground-search-panel)',
                right: '0px',
                background: 'var(--background-form-dropdown-icon)',
                marginRight: '-1px',
                height: '23px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                '&.MuiSelect-iconOpen': {
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                },
              },
            }}
            filterType="Sort By"
            data-testid="glide-commentarylist-selectmenu-sortby"
            labelFontSize="14px"
            styledinputheight="36px"
          />
          <S.CommentButton>
            <>
              <SecondaryButton
                style={{
                  height: '24px',
                  padding: '6px 5px 6px 10px',
                  background: 'var(--background-form)',
                  border: 'var(--foreground-form-btn-border)',
                  borderRadius: '12px',
                  position: 'relative',
                  top: '-2px',
                  fontWeight: '700',
                  letterSpacing: '0px',
                  margin: '0px 0px 0px 5px',
                }}
                data-testid="glide-commenatrylist-button-newcomment"
                onClick={createNewCommentEditorAction}
              >
                {actionButtonText}
                <AddIcon
                  style={{ color: 'var(--foreground-form-link)', fontSize: '14px', marginLeft: '5px' }}
                ></AddIcon>
              </SecondaryButton>
            </>
          </S.CommentButton>
        </S.BlogInnerWrapper>
        {isSearchEnabled && (
          <S.StyledSearchInput
            key="searchInputCommentaryList"
            style={{
              padding: '10px 9px 10px 13px',
              background: 'var(--background-dx-pivotgrid-header)',
              height: '45px',
            }}
          >
            <S.StyledInput
              id="Search"
              label="Search"
              className="search-input"
              onChange={onChange}
              placeholder="Search"
              data-testid="glide-glidelist-search-text-input"
              autoFocus={true}
              flex={1}
              endAdornment={
                <SearchIcon
                  style={{ color: 'var(--foreground-form)', marginRight: '-5px', height: '100%', fontSize: '17px' }}
                />
              }
            />
          </S.StyledSearchInput>
        )}
        <S.ListItem data-testid="glide-commentarylist-list-item">
          {SortedListData.length > 0 && SortedListData.map((lds: any) => itemTemplate(lds))}
        </S.ListItem>
        <Footer />
      </S.BlogOuterWrapper>
    </>
  );
};

export default GlideCommentaryList;
