import React, { memo, useRef, useState } from 'react';
import Calendar from 'src/Calendar/Calendar';
import * as S from './date-input.style';

type EditcellrenderProps = {
  cellData: any;
};
const calendarWrapperStyle: { wrapper: React.CSSProperties } = {
  wrapper: {
    position: 'fixed',
    width: 'fit-content',
    backgroundColor: 'var(--background-calendar)',
  },
};

export const DateInputComponent: React.FC<EditcellrenderProps> = memo(({ cellData }) => {
  const [open, setOpen] = useState(false);
  const componentRef = useRef<any>();

  const OpenCalendar = (e?: any) => {
    e.stopPropagation();
    setOpen(!open);
  };
  const formattedDate = (date: Date | string): { date: string; formattedDate: string } => {
    if (date === '' || date === undefined) return { date: '', formattedDate: '' };
    const _date = new Date(date);
    const month = `0${_date.getMonth() + 1}`;
    const day = `0${_date.getDate()}`;
    const year = `${_date.getFullYear()}`;
    const _glideDate = `${month.substring(month.length - 2)}/${day.substring(day.length - 2)}/${year}`;
    const _formattedDate = new Date(date).toLocaleDateString(navigator.language);

    return {
      date: _glideDate,
      formattedDate: _formattedDate,
    };
  };
  const dateInputRef = useRef<any>();
  const onDateChange = (v: string) => {
    cellData.text = formattedDate(v).formattedDate;
    cellData.component.cellValue(cellData.rowIndex, cellData.column.dataField, formattedDate(v).formattedDate);
    dateInputRef && dateInputRef.current && dateInputRef.current.focus();
  };
  return (
    <S.DateInputWrapper ref={componentRef} displayName={cellData.text}>
      <S.StyledLabel>{cellData.text}</S.StyledLabel>
      <S.StyledCalendarIcon onClick={OpenCalendar} />

      {open && (
        <Calendar
          value={cellData.text}
          onChange={(e: any) => onDateChange(e)}
          openCalendar={() => null}
          style={calendarWrapperStyle}
        />
      )}
    </S.DateInputWrapper>
  );
});
