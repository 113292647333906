import { Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { SubMenuIcon } from 'src/icons/SubMenuIcon';
import { Route } from 'src/page/models/route';
import styled, { css } from 'styled-components';

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  margin-right: 10px;
  position: relative;
  top: 1.5px;

  & > .MuiSvgIcon-root {
    height: var(--side-megamenu-backarrow-icon-font-size);
    width: var(--side-megamenu-backarrow-icon-font-size);
  }
`;

const StyledListItem = styled(ListItem)<{
  isSubMenuItem: boolean;
  isSubMenuBackItem: boolean;
  currentPath?: string;
  path: string;
  isView: boolean;
}>`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  ${props => {
    return props.isSubMenuItem && props.isSubMenuBackItem
      ? css`
          padding-left: 5px;
        `
      : css<{ currentPath?: string; path: string; isSubMenuItem: boolean }>`
          padding-left: ${props => {
            return props.path && props.currentPath && props.path.localeCompare(props.currentPath) == 0
              ? '29px'
              : '35px';
          }};
          ${props => {
            return !props.isSubMenuItem &&
              props.path &&
              props.currentPath &&
              props.path.localeCompare(props.currentPath) == 0
              ? css`
                  border-left: var(--foreground-side-megamenu-vertical-bar);
                  background-color: var(--background-side-megamenu-item-hover) !important;
                `
              : css`
                  border-left: none;
                `;
          }};
        `;
  }}

  height: ${props => (!props.isSubMenuItem || props.isSubMenuBackItem ? '38px' : '32px')};
`;

const StyledListItemText = styled(ListItemText)<{
  isSubMenuItem: boolean;
  isSubMenuBackItem: boolean;
  isView: boolean;
}>`
  padding: 0px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-overflow: ellipsis;
  width: auto;
  white-space: nowrap;
  overflow: hidden;

  & > span {
    ${props => {
      return props.isView
        ? css`
            font-size: var(--sub-megamenu-font-size);
            font-weight: normal;
            color: var(--foreground-sub-megamenu-text);
            letter-spacing: 0.32px;
            font-family: inherit;
          `
        : css<{ isSubMenuBackItem: boolean }>`
            font-size: ${props => {
              return props.isSubMenuBackItem
                ? 'var(--sub-megamenu-header-font-size)'
                : 'var(--side-megamenu-font-size)';
            }};
            font-weight: bold;
            color: var(--foreground-text);
            letter-spacing: 0.36px;
            font-family: inherit;
          `;
    }};
  }
`;

const StyledTooltip = withStyles(Tooltip, {
  tooltip: {
    color: 'var(--foreground-tooltip)',
    backgroundColor: 'var(--background-tooltip)',
    maxWidth: 'none',
    fontWeight: 600,
  },
});

interface SideMenuItemProps extends Route {
  onClick: (fullPath?: string, subroute?: Route) => void;
  style?: React.CSSProperties;
  subroute?: Route;
  isSubMenuItem: boolean;
  isSubMenuBackItem: boolean;
  currentPath?: string;
  isView: boolean;
}

const SideMenuItem: React.FC<SideMenuItemProps> = props => {
  const [hover, setHover] = useState(false);
  const {
    fullPath,
    name,
    icon,
    onClick,
    style,
    context,
    hasSubmenu,
    isSubMenuItem,
    isSubMenuBackItem,
    path,
    currentPath,
    isView,
  } = props;
  return (
    <StyledListItem
      button={false as any}
      // This change is to bring back <li></li> tags in side menu list DOM,
      // which was missing after upgrading material UI to v4
      // (https://github.com/mui/material-ui/issues/14971)
      data-testid="sidemenu-item"
      style={{
        ...style,
        background: hover ? `var(--background-side-megamenu-item-hover)` : 'inherit',
      }}
      isSubMenuItem={isSubMenuItem}
      isSubMenuBackItem={isSubMenuBackItem}
      path={path}
      currentPath={currentPath}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => onClick(`${fullPath}`, props)}
      isView={isView}
    >
      {icon && icon.component && icon.enableInSideBar && (
        <StyledListItemIcon>
          {React.createElement(icon.component, {
            ...icon.sideBarProps,
            htmlColor: 'var(--foreground-side-megamenu-backarrow-icon)',
          })}
        </StyledListItemIcon>
      )}
      <StyledTooltip title={context || ''} placement="bottom-start">
        <StyledListItemText
          isSubMenuItem={isSubMenuItem}
          isSubMenuBackItem={isSubMenuBackItem}
          primary={name}
          primaryTypographyProps={{ color: 'inherit' }}
          data-isSubMenuItem={isSubMenuItem}
          isView={isView}
        />
      </StyledTooltip>
      {hasSubmenu && <SubMenuIcon />}
    </StyledListItem>
  );
};

export default SideMenuItem;
