export default (value: Date) =>
  new Date(value).toLocaleString('en-us', { day: '2-digit', month: 'long', year: 'numeric' });

export const getVirtusDate = (date: Date) =>
  date.toLocaleDateString('en', { month: '2-digit', day: '2-digit', year: 'numeric' });

export const getVirtusDateTime = (date: Date) =>
  date.toLocaleString('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

export const getMMDDYYYY = (date: Date, separator: string = '-') => {
  const dd = date.getDate();
  const mm = date.getMonth() + 1; // January is 0!
  const yyyy = date.getFullYear();

  let fixedDay: string = dd.toString();
  let fixedMonth: string = mm.toString();
  if (dd < 10) {
    fixedDay = `0${dd}`;
  }
  if (mm < 10) {
    fixedMonth = `0${mm}`;
  }
  return `${fixedMonth}${separator}${fixedDay}${separator}${yyyy}`;
};

export const getDate = (daysDelta: number = 0) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() + daysDelta);
  return yesterday;
};

export const getYesterday = () => getDate(-1);
