import { actionTypes } from 'redux-query';
import { endpoints } from 'src/api/constants';
import { NewObjectType } from 'src/models/glide/glideObject';
import { RootState } from 'src/reducers/rootReducer';

interface ResolveAction {
  target_uri: string | string[];
  action_uri: string;
  arguments?: object;
}

export const post = (params: ResolveAction) => ({
  url: endpoints.resolveAction.root,
  transform: (body: any) => ({ actionResult: body }),
  options: { method: 'POST' },
  body: {
    action_uri: params.action_uri,
    target_uri: params.target_uri,
    arguments: params.arguments,
  },
  queryKey: 'resolveAction',
  force: true,
  update: {
    actionResult: (_: any, next: any) => next,
  },
});

export const selector = (state: RootState) => state.entities.actionResult;
export const pending = (state: RootState) => state.queries.resolveAction && state.queries.resolveAction.isPending;

//Create new Object
export const createNewObject = ({ object_type, object_data }: NewObjectType) => ({
  url: `${endpoints.new.root}`,
  transform: (body: any) => ({ newObjectUri: { [object_type]: body?.uri[0] } }),
  options: {
    method: 'POST',
  },
  body: { object_type, object_data },
  force: true,
  queryKey: 'gNew',
  update: {
    newObjectUri: (_: any, next: any) => next,
  },
  meta: {
    notification: {
      [actionTypes.MUTATE_SUCCESS]: `${object_type} added successfully`,
    },
  },
});

export const newObjectUri = (state: RootState) => state.entities.newObjectUri;
export const isNewObjectPending = (state: RootState) => state.queries.gNew && state.queries.gNew.isPending;
