import styled from 'styled-components';

export const Cell = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: var(--default-blue);
  text-decoration: underline;
  :hover {
    color: var(--light-blue);
  }
`;
