import styled from 'styled-components';

export const StyledToolBarWrapper = styled.div`
  .dx-toolbar .dx-toolbar-items-container {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dx-toolbar-before,
  .dx-toolbar-after {
    position: absolute;
  }
  .dx-button-content {
    padding-top: 6px !important;
  }

  .dx-toolbar {
    border-bottom: var(--dx-pivotgrid-toolbar-border);
    height: 35px;
    background: var(--background-dx-pivotgrid-header);
    margin-bottom: 0;
    padding: 0 10px;
    opacity: 1;
  }

  ::-webkit-scrollbar {
    height: 5px;
  }
`;
