import React, { useEffect, useState } from 'react';
import * as S from './Search.style';

interface SearchProps {
  onChange: (value: string) => void | any;
  style?: {
    row?: React.CSSProperties;
    icon?: React.CSSProperties;
    input?: React.CSSProperties;
  };
  onClick?: (e: React.SyntheticEvent) => void;
  disableFlag?: boolean;
  resetSerachText?: boolean;
  autoFocus?: boolean;
}

export const Search = ({
  onChange,
  style,
  onClick,
  disableFlag = false,
  resetSerachText,
  autoFocus = false,
}: SearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [hasFocus, setFocus] = useState(false);
  const [newInputValue, setNewInputValue] = React.useState<string>('');
  const [isChange, setIsChange] = React.useState<boolean>(false);

  useEffect(() => {
    if (resetSerachText) {
      setSearch('');
    }
  }, [resetSerachText]);

  // changed onchange to onkeydown becase in some case of portfolio-fund-selector onchange is not getting triggerd
  // TO-DO :chanege it with permenent solution
  const OnkeyDownHandler = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    setFocus(true);
    if (search.length > 0) {
      setIsChange(false);
      return;
    }

    if (
      [
        'Tab',
        'CapsLock',
        'Shift',
        'Control',
        'Alt',
        'Meta',
        'ArrowLeft',
        'ArrowUp',
        'ArrowRight',
        'ArrowDown',
        'Enter',
        'Backspace',
        'Delete',
      ].includes(e.key) ||
      ((e.ctrlKey || e.metaKey) &&
        (e.key.toLowerCase() == 'v' || e.key.toLowerCase() == 'c' || e.key.toLowerCase() == 'x')) ||
      e.key.toLowerCase() == 'z'
    )
      setIsChange(false);
    else {
      setIsChange(true);
      setNewInputValue(e.key);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (isChange) {
      setSearch(newInputValue);
      onChange(newInputValue);
    } else {
      setSearch(val);
      onChange(val);
    }
  };

  return (
    <S.Row style={style?.row} onClick={onClick}>
      <S.SearchIcon style={style?.icon} />
      <S.Input
        autoFocus={autoFocus}
        label="Search..."
        data-testid="search-input"
        value={resetSerachText ? '' : search}
        style={style?.input}
        placeholder="Search"
        hasFocus={hasFocus}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onKeyDown={OnkeyDownHandler}
        onChange={handleOnChange}
        disabled={disableFlag}
        textIndent={style?.input?.textIndent}
      />
    </S.Row>
  );
};
