import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import React from 'react';

const styles = () => ({
  progress: {
    width: '16px !important',
    height: '16px !important',
    color: 'var(--default-blue)',
  },
});

const RunningIconAnimated = withStyles(({ classes, ...props }: any) => {
  return <CircularProgress {...props} className={classes.progress} />;
}, styles);

export default RunningIconAnimated;
