import styled from 'styled-components';
import { SecondaryButton } from '@virtus/components/Buttons';

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--background-dx-pivotgrid-header);
  border-bottom: var(--foreground-form-border);
`;

export const NewLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputContainer = styled.div`
  border: var(--foreground-form-border);
  height: 28px;
  background-color: var(--foreground-input-text);
`;

export const ScrollableContent = styled.div`
  height: 100%;
  overflow: hidden auto;
`;

export const SecondaryButtonStyled = styled(SecondaryButton)`
  background-color: var(--background-layout-create-button);
  color: var(--foreground-footer);
  font-weight: bold;
  &:hover {
    background-color: var(--background-layout-create-button-hover);
  }
`;
