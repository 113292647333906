import styled from 'styled-components';

export const ObjectCollectionCount = styled.span`
  color: var(--default-blue);
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: var(--light-blue);
  }
`;
