import { ErrorIcon, FailedIcon, PassedIcon, PausedIcon, RunningIconAnimated } from '@virtus/components/icons';
import React from 'react';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
import * as S from './scenario-cell.style';

interface ScenarioResultCellProps {
  cellData: CellData;
}

type ScenarioResultValue = 'Running' | 'Passed' | 'Failed' | 'Error' | 'Paused' | 'default';

const getHypoIcon = (value: ScenarioResultValue) => {
  const IconBaseStyle: React.CSSProperties = { width: '18px', height: '18px', marginRight: '5px' };
  const IconHypo: { [key in ScenarioResultValue]: React.ReactNode } = {
    Running: <RunningIconAnimated data-testid="running-icon" style={IconBaseStyle} />,
    Passed: <PassedIcon data-testid="passed-icon" style={IconBaseStyle} />,
    Failed: <FailedIcon data-testid="failed-icon" style={IconBaseStyle} />,
    Error: <ErrorIcon data-testid="error-icon" style={IconBaseStyle} />,
    Paused: <PausedIcon data-testid="paused-icon" style={IconBaseStyle} />,
    default: <div data-testid="no-icon" style={IconBaseStyle} />,
  };

  return IconHypo[value] || IconHypo.default;
};

export const ScenarioResultCell: React.FC<ScenarioResultCellProps> = ({ cellData }) => (
  <S.Cell id="scenario-result-cell" data-testid="scenario-result-render">
    {getHypoIcon(cellData.value as ScenarioResultValue)}
    {cellData.value?.toString() as ScenarioResultValue}
  </S.Cell>
);
