import { put, takeLatest } from 'redux-saga/effects';
import { updateComponentAction } from 'src/reducers/components';

export enum commentaryDeatilsAction {
  INIT_GLIDE_COMMENTARY__MODAL = 'INIT_GLIDE_COMMENTARY__MODAL',
  RESET_GLIDE_COMMENTARY__MODAL = 'RESET_GLIDE_COMMENTARY__MODAL',
  SET_GLIDE_COMMENTARY__MODAL = 'SET_GLIDE_COMMENTARY__MODAL',
}
export interface commentaryData {
  fieldName: String;
  commentaryUris: String;
  creditUri: String;
  instanceUri: String;
  showModal: Boolean;
}

const initialState: commentaryData = {
  fieldName: '',
  commentaryUris: '',
  creditUri: '',
  instanceUri: '',
  showModal: false,
};
export function* onClickCommentaryCell(action: any): any {
  yield put(updateComponentAction('commentary', { ...action.payload }));
}

export function* resetCommentaryData(): any {
  yield put(updateComponentAction('commentary', { ...initialState }));
}

export function* watchCommentaryCellClick() {
  yield takeLatest<any>(commentaryDeatilsAction.INIT_GLIDE_COMMENTARY__MODAL, onClickCommentaryCell);
}

export function* watchResetCommentaryData() {
  yield takeLatest<any>(commentaryDeatilsAction.RESET_GLIDE_COMMENTARY__MODAL, resetCommentaryData);
}
export const resetCommentary = () => ({
  type: commentaryDeatilsAction.RESET_GLIDE_COMMENTARY__MODAL,
});

export const initCommentaryModal = (payload: any) => ({
  type: commentaryDeatilsAction.INIT_GLIDE_COMMENTARY__MODAL,
  payload: payload,
});

export const selectCommentaryModal = (state: any): any => state.components.commentary;

export const isCommentaryDirty = (state: any): any => state.components.isCommentaryDirty.dirty;

export const getInstanceUri = (state: any): any => state.components.global.instanceUri;

export default [watchCommentaryCellClick, watchResetCommentaryData];
