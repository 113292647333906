import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';

export const TextInput = ({
  field,
  value = '',
  error,
  onChange,
  hideLabel,
  customWidth,
  type,
  multiline,
}: FormElementInputComponent) => {
  const [hasFocus, setFocus] = React.useState(false);
  if (field?.hidden) return null;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      readonly={field.readonly}
      hideLabel={hideLabel}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      {field.disabled || field.readonly ? (
        <Tooltip title={value}>
          <StyledInput
            data-testid={field.name}
            autoComplete="off"
            disabled={field.disabled || field.readonly}
            error={Boolean(error) && !(field.readonly || field.disabled)}
            onChange={onChange}
            value={value}
            name={field.name}
            $editIsEnabled={!field.disabled && !field.readonly}
            type={type || 'text'}
            multiline={multiline || false}
          />
        </Tooltip>
      ) : (
        <StyledInput
          data-testid={field.name}
          autoComplete="off"
          error={Boolean(error) && !(field.readonly || field.disabled)}
          onChange={onChange}
          value={value}
          name={field.name}
          $editIsEnabled={!field.disabled && !field.readonly}
          $hasFocus={hasFocus}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type={type || 'text'}
          multiline={multiline || false}
        />
      )}
    </FormErrorWrapper>
  );
};
