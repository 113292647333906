import CalendarIcon from 'src/icons/CalendarIcon';
import styled from 'styled-components';

export const StyledCalendarIcon = styled(CalendarIcon)<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? 'var(--foreground-calendar-disabled)' : 'var(--calendar-icon-active)')};
  cursor: pointer;
  height: 26px;
  right: 0px;
  position: relative;
  float: right;
  cursor: pointer;
`;

export const DateInputWrapper = styled.div<{
  displayName: string;
}>``;

export const StyledLabel = styled.label<{ width?: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: ${props => props.width || '150px'};
  color: var(--foreground-form);
  font-weight: bold;
  font-size: var(--form-label-font-size) !important;
`;
