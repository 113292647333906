enum ClientViewTypeLookUps {
  Grid = 'Grid',
  Pivot = 'Pivot',
}

const getFunctionParamNames = (func: any) => {
  const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
  const ARGUMENT_NAMES = /([^\s,]+)/g;
  const fnStr =
    func &&
    func
      .toString()
      .replace(STRIP_COMMENTS, '')
      .match(/([^=>]+)/)[1]; // taking string until '=>'
  /* below computation will match for object as parameters or no paramter eg.
    1. if passed : ({loan_trade_Form})=> void - it will return [loan_trade_form]
    2. if passed : ()=> true - it will return null*/
  let result = fnStr && fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')'));
  if (result && result.match(/{(.*)}/)) result = result.match(/{(.*)}/)[1].match(ARGUMENT_NAMES);
  if (!result) result = [];
  return result;
};
const formatPivotGridValue = (fieldValue: any) => {
  let val = fieldValue;
  if (
    fieldValue === undefined ||
    (fieldValue !== undefined && fieldValue !== null && fieldValue.toString() === 'NaN')
  ) {
    val = null;
  }
  return val;
};

const setGridParameterValues = (
  e: any,
  paramsList: string[],
  actualParam: { [key: string]: any },
  schema: any,
  gridType?: string,
) => {
  paramsList.forEach((param: string) => {
    let fieldData: any = [];
    if (schema && Object.keys(schema).length) {
      fieldData = schema.filter((x: any) => x.field_name === param);
    }
    if (gridType === ClientViewTypeLookUps.Pivot) {
      actualParam[param] =
        e.value() !== undefined && fieldData.length && fieldData[0].display_name
          ? formatPivotGridValue(e.value(fieldData[0].display_name))
          : null;
    } else {
      if (e.hasOwnProperty('data')) {
        actualParam[param] =
          fieldData.length && fieldData[0].display_name ? e.data[fieldData[0].display_name] : e.data[param];
      } else {
        actualParam[param] = fieldData.length && fieldData[0].display_name ? e[fieldData[0].display_name] : e[param];
      }
    }
  });
};

export const updateOnLoadCellValues = (e: any, scriptJS: string, schema: any, gridType?: string): any => {
  const ruleFn = eval(scriptJS);
  const paramsList = getFunctionParamNames(ruleFn);
  const actualParam: { [key: string]: number | any } = {};
  if (ruleFn) {
    setGridParameterValues(e, paramsList, actualParam, schema, gridType);
    const returnVal = ruleFn(actualParam);
    return returnVal;
  }
};
