import styled, { css, keyframes } from 'styled-components';

export const Container = styled.span`
  display: inline-block;
`;

export const CurrentVal = styled.span`
  display: inline-block;
`;

const priceChangeAnimation = keyframes`{
  0%   {opacity: 1;}
  100% {opacity: 0; display: none;}
}`;

export const NetChange = styled.span<{ className: string }>`
  margin-right: 10px;
  display: inline-block;
  animation: ${priceChangeAnimation} var(--priceUpdateFadeDuration) forwards;
  font-weight: bold;
  ${props =>
    props.className === 'dec' &&
    css`
      color: var(--red);
    `};
  ${props =>
    props.className === 'inc' &&
    css`
      color: var(--fis-green);
    `};
`;

export const Arrow = styled.span<{ className: string }>`
  display: inline-block;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  animation: ${priceChangeAnimation} var(--priceUpdateFadeDuration) forwards;
  ${props =>
    props.className === 'dec' &&
    css`
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
    `}
  ${props =>
    props.className === 'inc' &&
    css`
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
    `}
`;
