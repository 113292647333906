// eslint-disableno-parameter-reassignment
import React, { FunctionComponent, ReactElement } from 'react';
import { SvgGenesis, SvgGlide, SvgNexus, SvgVirtus, SvgVirtusDark } from 'src/LoadingIcon/icons';
import { StyledLoadingIcon } from 'src/LoadingIcon/LoadingIcon.style';

export enum LoadingIconType {
  Genesis = 'Genesis',
  Glide = 'Glide',
  Nexus = 'Nexus',
  Virtus = 'Virtus',
  VirtusDark = 'VirtusDark',
}

export enum LoadingIconSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extraLarge = 'extraLarge',
}

export interface LoadingIconProps {
  height?: string;
  size?: keyof LoadingIconSizes | string;
  type?: keyof LoadingIconType | string;
  text?: string;
  textRatioSize?: number;
  width?: string;
  show?: boolean;
  dataTestId?: string | undefined;
}

interface SVGIcons {
  [LoadingIconType: string]: (props: any) => ReactElement<any> | null;
}

export const SvgIcons: SVGIcons = {
  [LoadingIconType.Genesis]: SvgGenesis,
  [LoadingIconType.Glide]: SvgGlide,
  [LoadingIconType.Nexus]: SvgNexus,
  [LoadingIconType.Virtus]: SvgVirtus,
  [LoadingIconType.VirtusDark]: SvgVirtusDark,
};

const LoadingIcon: FunctionComponent<LoadingIconProps> = ({
  show,
  size = LoadingIconSizes.medium,
  type = LoadingIconType.Virtus,
  text = '',
  textRatioSize = 3,
  width,
  dataTestId,
}) => {
  if (!show) return null;
  const widthSizes: any = {
    [LoadingIconSizes.large]: '4rem',
    [LoadingIconSizes.medium]: '2rem',
    [LoadingIconSizes.small]: '1rem',
    [LoadingIconSizes.extraLarge]: '6rem',
  };
  const SvgIcon = SvgIcons[type as string];

  width = width || widthSizes[size];

  // Sync height and width to keep 1:1 aspect ratio
  return (
    <StyledLoadingIcon data-testid={dataTestId || 'loading-icon'}>
      <SvgIcon style={{ width, height: width }} />
      {text && <p style={{ fontSize: `calc(${width}/${textRatioSize})` }}>{text}</p>}
    </StyledLoadingIcon>
  );
};

export default LoadingIcon;
