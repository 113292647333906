import { Action } from 'redux';
import { RootState } from './rootReducer';
import { getInitialFormFieldsState } from 'src/components/forms/common/initialization';
import { clone, merge } from 'lodash';
import { getFieldsFromView } from 'src/mappers/common-mapper-functions';
import { validateForm, getFormGroupWithErrors, applyFieldRules } from 'src/components/forms/common/validation';
import { FormFieldsType } from 'src/components/forms/form-elements/FormElement.model';
import { FormValuesType } from 'src/components/forms/form-elements/FormElement.model';
import { groupBy } from 'lodash';

export enum creditDetailFormsAction {
  INIT_CREDIT_DETAIL_MODAL_FORM = 'INIT_CREDIT_DETAIL_MODAL_FORM',
  SET_DEFAULT_MODAL_VALUES = 'SET_DEFAULT_MODAL_VALUES',
  RESET_CREDIT_DETAIL_MODAL_FORM = 'RESET_CREDIT_DETAIL_MODAL_FORM',
  VALIDATE_MODAL_FORM = 'VALIDATE_MODAL_FORM',
  // Migration
  SET_CREDIT_DETAIL_MODAL_FORM_EDIT = 'SET_CREDIT_DETAIL_MODAL_FORM_EDIT',
  ON_CREDIT_DETAIL_MODAL_FORM_CHANGE_FIELD = 'ON_CREDIT_DETAIL_MODAL_FORM_CHANGE_FIELD',
  SAVE_CREDIT_DETAIL_MODAL_FORM = 'SAVE_CREDIT_DETAIL_MODAL_FORM',
  CREDIT_DETAIL_MODAL_FORM_DISCARD_CHANGES = 'CREDIT_DETAIL_MODAL_FORM_DISCARD_CHANGES',
  CREDIT_DETAIL_MODAL_FORM_CLOSE = 'CREDIT_DETAIL_MODAL_FORM_CLOSE',
}
export interface creditDetailForm {
  formValues: any;
  formErrors: { errors: { [key: string]: any }; valid: boolean };
  formFields: FormFieldsType | any;
  isEdit?: boolean;
  formGroups?: any;
  formChanges?: any;
  hasChanged?: boolean;
}
export interface creditDetailFormData {
  isEdit: boolean;
  formErrors: any;
  hasChanged: boolean;
  formChanges: object;
  formGroups: { [key: string]: creditDetailForm };
  initialFormGroup: object;
  // private state
  _dbSavePending: object;
}
export interface creditDetailFormAction extends Action {
  payload: any;
  type: creditDetailFormsAction;
  fieldRules?: object;
  defaultValues?: object;
  mapFieldRulesFn?: (formdata: FormData, fieldRules: object) => FormData;
}

export interface CreditDetailFormDiscardActionPayload {
  displayCreditDetailConfirmationDialog: Function;
  hideInspector: boolean;
}

export const formInitialState: creditDetailFormData = {
  formErrors: {},
  formChanges: {},
  hasChanged: false,
  isEdit: false,
  formGroups: {},
  _dbSavePending: {},
  initialFormGroup: {},
};

const initialState: creditDetailForm = {
  formValues: {},
  formErrors: { errors: {}, valid: true },
  formFields: {},
  isEdit: false,
  formGroups: {},
  formChanges: {},
};

export const getNextFormGroupState = ({
  formGroupsState,
  enableEdit,
}: {
  formGroupsState: { [key: string]: FormValuesType };
  enableEdit: boolean;
}) => {
  const nextFormGroupsState = Object.entries(formGroupsState).reduce(
    (formGroupsAcc, [formGroup, values]) => ({
      ...formGroupsAcc,
      [formGroup]: {
        ...formGroupsState[formGroup],
        formErrors: {},
        formFields: Object.entries(values.formFields).reduce(
          (formFieldsAcc: any, field: any) => ({
            ...formFieldsAcc,
            [field[0]]: {
              ...field[1],
              disabled: !enableEdit,
            },
          }),
          {},
        ),
        hasChanged: false,
      },
    }),
    {},
  );

  return nextFormGroupsState;
};

export const creditDetailForm = (state: creditDetailForm = initialState, action: creditDetailFormAction) => {
  switch (action.type) {
    case creditDetailFormsAction.INIT_CREDIT_DETAIL_MODAL_FORM: {
      const newState = clone(state);
      const { glideObject } = action.payload;
      const uriInfo = glideObject.displayView.uri;
      const fieldsObj: { [key: string]: any } = {};
      Object.entries(glideObject.data).map((acc: any) => {
        const [key, values] = acc;
        const fields = getFieldsFromView(values, glideObject?.fieldRules, uriInfo, {}, key);
        merge(fieldsObj, fields);
      }, {});
      const objectFormValues = getInitialFormFieldsState({ formFields: fieldsObj, isEditing: false });

      Object.entries(glideObject.fieldRules).map((each: any) => {
        const fieldRules: any = [];
        const [, value] = each;
        value.map((eachFieldRule: any) => {
          if (eachFieldRule.field_rule_run_on_open && eachFieldRule.enabled) {
            fieldRules.push(eachFieldRule);
          }
        });

        //Execute field rules on initial load
        const fieldRuleObject = applyFieldRules({
          fieldName: '',
          fieldRules: fieldRules,
          formValues: objectFormValues.formValues,
          isEdit: false,
          objectFormField: fieldsObj,
        });
        const fields = Object.keys(fieldRuleObject?.finalFields);
        const values = Object.keys(fieldRuleObject?.finalValues);
        Object.keys(fieldsObj).map((each: any) => {
          if (fields.includes(each)) {
            const fieldKey = Object.keys(fieldRuleObject?.finalFields[each])[0];
            fieldsObj[each] = {
              ...fieldsObj[each],
              [fieldKey]: fieldRuleObject?.finalFields[each][fieldKey],
            };
          }
        });
        Object.keys(objectFormValues.formValues).map((x: any) => {
          if (values.includes(x)) {
            objectFormValues.formValues[x] = fieldRuleObject?.finalValues[x];
          }
        });
      });
      const nextFormErrors = validateForm(
        objectFormValues.formValues,
        Object.keys(fieldsObj).length ? fieldsObj : newState?.formFields,
      );
      merge(newState, {
        isEdit: false,
        hasChanged: false,
        formFields: fieldsObj,
        formValues: objectFormValues.formValues,
        formErrors: nextFormErrors,
      });
      return newState;
    }

    case creditDetailFormsAction.SAVE_CREDIT_DETAIL_MODAL_FORM: {
      const newState = clone(state);

      const nextFormErrors = validateForm(newState?.formValues, newState?.formFields);
      const hasErrors = Object.keys(nextFormErrors?.errors).length;
      if (hasErrors) {
        console.warn('form error, saving cancelled', nextFormErrors);
        merge(newState, { formErrors: nextFormErrors, isEdit: true });
      } else {
        Object.keys(newState?.formFields as any).map((key: any) => {
          newState.formFields[key] = { ...newState.formFields[key], disabled: true };
        });
        merge(newState, {
          isEdit: false,
          hasChanged: false,
          _dbSavePending: true,
        });
      }

      return newState;
    }

    case creditDetailFormsAction.SET_CREDIT_DETAIL_MODAL_FORM_EDIT: {
      const newState = clone(state);
      const { toggleState } = action.payload;
      const newEditState = toggleState !== undefined ? toggleState : true;
      if (newEditState) {
        const nextFormGroupsState = getNextFormGroupState({
          formGroupsState: state.formGroups,
          enableEdit: newEditState,
        });

        Object.keys(newState?.formFields as any).map((x: any) => {
          newState.formFields[x] = { ...newState.formFields[x], disabled: !newEditState };
        });

        const formGroupWithErrors = getFormGroupWithErrors(nextFormGroupsState);
        merge(newState.formGroups, formGroupWithErrors);
        merge(newState.formFields, newState.formFields);
        merge(newState, { isEdit: true, hasChanged: false });
        return newState;
      } else {
        Object.keys(newState?.formFields as any).map((key: any) => {
          newState.formFields[key] = { ...newState.formFields[key], disabled: true };
        });
        // Reset form
        const fieldGroups = groupBy(newState?.formFields, (field: any) => field.groupName);
        const fieldsObj: { [key: string]: any } = {};
        Object.entries(fieldGroups).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, {}, '', {}, key);
          merge(fieldsObj, fields);
        }, {});
        const objectFormValues = getInitialFormFieldsState({ formFields: fieldsObj, isEditing: false });

        merge(newState, {
          formValues: objectFormValues.formValues,
          formFields: fieldsObj,
          formChanges: {},
          hasChanged: false,
          isEdit: false,
          formErrors: {},
        });

        return newState;
      }
    }

    case creditDetailFormsAction.ON_CREDIT_DETAIL_MODAL_FORM_CHANGE_FIELD: {
      if (!state.isEdit) return state;
      const newState = clone(state);
      const { fieldUpdate } = action.payload;

      const fieldUpdateKey = Object.keys(fieldUpdate)[0];

      const updatedFormValues = { ...newState?.formValues, ...fieldUpdate };

      //let fieldRuleObject: any = {};
      //Execute field rules on each field
      if (newState?.formFields[fieldUpdateKey]?.fieldRules) {
        const fieldRuleObject = applyFieldRules({
          fieldName: fieldUpdateKey,
          fieldRules: newState?.formFields[fieldUpdateKey]?.fieldRules,
          formValues: updatedFormValues,
          objectFormField: newState?.formFields,
          isEdit: true,
        });

        const fields = Object.keys(fieldRuleObject?.finalFields);
        const values = Object.keys(fieldRuleObject?.finalValues);

        Object.keys(newState?.formFields).map((x: any) => {
          if (fields.includes(x)) {
            const fieldKey = Object.keys(fieldRuleObject?.finalFields[x]);
            fieldKey?.map((eachKey: string) => {
              newState.formFields[x] = {
                ...newState?.formFields[x],
                [eachKey]: fieldRuleObject?.finalFields[x][eachKey],
              };
            });
          }
        });

        Object.keys(updatedFormValues as any).map((x: any) => {
          if (values.includes(x)) {
            updatedFormValues[x] = fieldRuleObject?.finalValues[x];
          }
        });
      }

      //Validate each field
      newState.formErrors = validateForm(updatedFormValues, newState?.formFields);

      merge(newState, {
        formValues: updatedFormValues,
        formFields: newState.formFields,
        formChanges: fieldUpdate,
        hasChanged: true,
        formErrors: newState.formErrors,
      });

      return newState;
    }

    case creditDetailFormsAction.RESET_CREDIT_DETAIL_MODAL_FORM:
      return { ...state, formFields: {}, formValues: {}, formErrors: { errors: {}, valid: true } };

    default:
      return state;
  }
};

export const setCreditDetailForm = (glideObject: object) => ({
  type: creditDetailFormsAction.INIT_CREDIT_DETAIL_MODAL_FORM,
  payload: { glideObject },
});

export const saveCreditDetailForm = () => ({
  type: creditDetailFormsAction.SAVE_CREDIT_DETAIL_MODAL_FORM,
});

export const changeCreditDetailFormField = (fieldUpdate: any, formGroupName: string, field?: any) => ({
  type: creditDetailFormsAction.ON_CREDIT_DETAIL_MODAL_FORM_CHANGE_FIELD,
  payload: { fieldUpdate, formGroupName, field },
});

export const setCreditDetailFormEdit = (toggleState: boolean) => ({
  type: creditDetailFormsAction.SET_CREDIT_DETAIL_MODAL_FORM_EDIT,
  payload: { toggleState },
});

export const resetCreditDetailForm = () => ({
  type: creditDetailFormsAction.RESET_CREDIT_DETAIL_MODAL_FORM,
});

export const discardCreditDetailFormChanges = (displayConfirmationDialog: Function, hideInspector: boolean) => ({
  type: creditDetailFormsAction.CREDIT_DETAIL_MODAL_FORM_DISCARD_CHANGES,
  payload: { displayCreditDetailConfirmationDialog: displayConfirmationDialog, hideInspector },
});

export const closeCreditDetailForm = (hideInspector: boolean) => ({
  type: creditDetailFormsAction.CREDIT_DETAIL_MODAL_FORM_CLOSE,
  payload: { hideInspector },
});

export const selectCreditDetailForm = (state: RootState): creditDetailForm => state.creditDetailForm;
