import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from 'src/tool-bar/tool-bar.style';
import styled from 'styled-components';

export const StyledInspectorGroup = styled.h5`
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div<{ disabled?: boolean }>`
  color: ${props => {
    return props.disabled ? 'var(--foreground-inspector-group-disabled)' : 'var(--foreground-form)';
  }};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  background: var(--background-inspector-expansion-panel-header);
  height: 30px;
  opacity: 1;
  font-size: var(--inspector-font-size);
  border-bottom: var(--foreground-inspector-border);
  border-top: var(--foreground-inspector-border);
  padding: 0px 15px 0px 5px;
  letter-spacing: 0.32px;
`;

export const Content = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
`;

export const ActionButton = styled(IconButton)`
  width: 25px;
  height: 20px;
  padding: 2px;
  margin-bottom: 5px;
  color: var(--text);
  background-color: inherit;
  border-radius: 0px;
`;

export const EditIcon: any = styled(Edit)`
  width: 15px;
  height: 15px;
  padding: 0px;
  color: var(--text);
  margin-right: 5px;
`;

export const DeleteIcon: any = styled(Delete)`
  width: 15px;
  height: 15px;
  padding: 0px;
  color: var(--text);
  margin-right: 5px;
`;
