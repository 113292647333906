// calendar constants

export const NumberOfYears = 100;
export const StartMonth = 0;
export const EndMonth = 11;

export const getYearMonthDay = (value: string | Date) => {
  const _date = new Date(value);
  const year = _date.getFullYear();
  const month = _date.getMonth();
  const day = _date.getDay();
  return {
    year,
    month,
    day,
  };
};
