import React, { useEffect, useState } from 'react';
import * as S from './modal-inspector.style';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';
import { RootState } from 'src/reducers';
import { selectGlideObjectLoading } from 'src/api/query';
import { connect } from 'react-redux';
import { dispatchActions } from 'src/app/store';
import GlideInspectorFooter from 'src/components/inspectors/glide-inspector/glide-inspector-footer';
import { DisplayViewData, DisplayViewGroup } from 'src/api/queries/display-view';
import { selectExpandedAction } from 'src/sagas/action/actions.saga';
import { GlideObject } from 'src/models/glide/glideObject';
import { ModalInspectorProps, selectCurrentObjectInView, selectStaticComponent } from 'src/reducers/components';
import {
  closeModalForm,
  discardModalFormChanges,
  modalForm,
  modalFormState,
  selectModalForm,
} from 'src/reducers/modalForm.reducer';
import { CONFIRMATION_DIALOG, backDropClick } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { activeTabSelector } from 'src/reducers/tabs';
import GlideDataContent from 'src/components/forms/glide-data-content/glide-data-content';
interface ModalInspector {
  displayName: string;
  uri: string;
  displayViewData?: DisplayViewData;
}

interface ModalComponentProps {
  objectInView: string;
}
interface ReduxProps {
  isGlideObjectLoading: any;
  modalForm: modalFormState;
  modalInspectorData: { [key: string]: DisplayViewGroup };
  modalInspector: ModalInspectorProps;
  expandedAction?: GlideObject;
  clientViewUri?: any;
  modalInspectorObjUri: string;
  currentObjectInView?: { uri: string };
}

export interface ModalInspectorReduxDispatchProps {
  discardModalFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean, objectInView: string) => void;
  closeModalForm: (hideInspector: boolean, objectInView: string) => void;
}

export const MODAL_INSPECTOR = 'modalInspector';

export const ModalInspector = ({
  modalForm,
  clientViewUri,
  modalInspectorData,
  modalInspector,
  expandedAction,
  isGlideObjectLoading,
  objectInView,
  modalInspectorObjUri,
  discardModalFormChanges,
  closeModalForm,
  currentObjectInView,
}: ReduxProps & ModalInspectorReduxDispatchProps & ModalComponentProps) => {
  if (objectInView == '' && modalInspector[modalInspectorObjUri]?.isVisible) {
    objectInView = modalInspectorObjUri;
  }

  const [objectInViews, setObjectInViews] = useState<string[]>([]);

  const closeModalInspectorDialog = (e: any) => {
    closeModalForm(true, e);
    const objectInViews_copy = [...objectInViews];
    setObjectInViews(objectInViews_copy.filter(_item => _item != e));
  };

  const { DialogComponent: QuitModalInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeModalInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  useEffect(() => {
    if (modalInspectorData) {
      // expandedAction is for global actions and for inspectorData, the actions are available in the displayViewData
      if (!modalInspectorData[objectInView]?.displayViewData?.actions && expandedAction) {
        if (modalInspectorData[objectInView]) {
          modalInspectorData[objectInView].displayViewData = { ...expandedAction?.data };
        }
      } else {
        //update modal inspector form data
        if (
          modalForm?.uri &&
          modalForm?.uri?.[objectInView] &&
          !(Object.keys(modalForm?.uri?.[objectInView]).length > 0)
        ) {
          dispatchActions.modalForm.setModalForm(modalInspectorData[objectInView], clientViewUri);
        }
      }
    }
  }, [modalInspectorData, expandedAction]);

  // The below useEffect is to handle Global actions.
  useEffect(() => {
    if (objectInView && !objectInViews.includes(objectInView)) {
      setObjectInViews([...objectInViews, objectInView]);
    }
  }, [objectInView]);

  useEffect(() => {
    if (
      currentObjectInView?.uri &&
      modalInspector[currentObjectInView?.uri] &&
      !objectInViews.includes(currentObjectInView?.uri)
    ) {
      setObjectInViews([...objectInViews, currentObjectInView?.uri]);
    }
  }, [currentObjectInView, modalInspector]);

  // The below useEffect is for specific cases where the change detection is not working for screens like Hypothetical scenario blotter (bottom panel)
  useEffect(() => {
    if (modalInspector) {
      Object.keys(modalInspector).forEach(item => {
        if (modalInspector[item]?.isVisible && modalInspectorData[item] && !objectInViews.includes(item)) {
          setObjectInViews([...objectInViews, item]);
        }
      });
    }
  }, [modalInspector, modalInspectorData]);

  if (!modalInspectorData) return null;

  return (
    <>
      <QuitModalInspector />
      {objectInViews.map(_objInView => {
        if (!modalInspectorData?.[_objInView]?.data) return null;

        const groupslength = modalInspectorData?.[_objInView]?.data
          ? Object.keys(modalInspectorData?.[_objInView]?.data).length
          : 0;

        const onClose = (_event: any, reason?: any) => {
          if (reason === backDropClick) return;
          discardModalFormChanges(displayConfirmationDialog, true, _objInView);
        };

        const onChangeField = (fieldChange: any, formGroupName: string, fieldRules?: any) => {
          dispatchActions.modalForm.changeModalFormField(fieldChange, formGroupName, fieldRules, _objInView);
        };

        if (!modalInspector[_objInView]?.isVisible) {
          //TODO: Fix this later, for the time being keeping the uri's of all global modal popup
          if (objectInView === _objInView) {
            return;
          }
          const objectInViews_copy = [...objectInViews];
          setObjectInViews(objectInViews_copy.filter(objInView => objInView != _objInView));
          return null;
        }

        return (
          <>
            <S.Dialog
              open={modalInspector[_objInView]?.isVisible}
              onClose={onClose}
              groupslength={groupslength}
              disableEnforceFocus={true}
              disableEscapeKeyDown
              id={_objInView}
            >
              {isGlideObjectLoading ? (
                <Loading show={true} type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} full />
              ) : (
                <>
                  <PanelHeader
                    showCloseBtn
                    showBackButton={false}
                    title={modalInspectorData[_objInView]?.displayName}
                    isExpanded={true}
                    tooltipText={modalInspectorData[_objInView]?.displayName}
                    onClose={onClose}
                  />
                  <S.ModalWrapper>
                    <S.Grid data-testid="modal-inspector" groups={groupslength}>
                      {Object.entries(modalInspectorData[_objInView]?.data).map(([formGroupName, value]) => {
                        const groupName = formGroupName.length > 1 ? formGroupName : '';
                        return (
                          <S.Cell $takeAllHeightAvailable $background="true" key={formGroupName}>
                            <S.CellHeader>{groupName}</S.CellHeader>
                            <GlideDataContent
                              uri={modalInspectorData[_objInView]?.uri}
                              dataTestId={modalInspectorData[_objInView]?.uri}
                              optionalStyles={{ noPadding: true, noBackground: true }}
                              hideFooter
                              form={modalForm?.uri[_objInView] as modalForm}
                              fieldRulesObject={{
                                formValues: modalForm?.uri[_objInView]?.formValues,
                                formFields: modalForm?.uri[_objInView]?.formFields,
                              }}
                              formName={formGroupName}
                              onChangeField={onChangeField}
                              formData={value}
                              isEditing={modalForm?.uri[_objInView]?.isEdit}
                            />
                          </S.Cell>
                        );
                      })}
                    </S.Grid>
                  </S.ModalWrapper>
                  {modalInspectorData[_objInView]?.displayViewData?.actions && (
                    <GlideInspectorFooter
                      actions={modalInspectorData[_objInView]?.displayViewData?.actions}
                      workflowActions={modalInspectorData[_objInView]?.displayViewData?.workflow_transitions}
                      targetInstance={modalInspectorData[_objInView]?.displayViewData?.instance}
                      isExpanded
                      isModalInspector={modalInspector[_objInView]?.isVisible}
                      instanceUri={_objInView}
                    />
                  )}
                </>
              )}
            </S.Dialog>
          </>
        );
      })}
    </>
  );
};
export const selectModalInspectorData = (state: RootState): { [key: string]: DisplayViewGroup } =>
  state.entities[MODAL_INSPECTOR];

export const selectModalInspectorObjUri = (state: RootState): any =>
  state.components[MODAL_INSPECTOR]['modalInspectorObjUri'];

const mapStateToProps = (state: RootState): ReduxProps => ({
  expandedAction: selectExpandedAction(state),
  clientViewUri: activeTabSelector(state),
  modalForm: selectModalForm(state),
  isGlideObjectLoading: selectGlideObjectLoading(state),
  modalInspectorData: selectModalInspectorData(state),
  modalInspector: selectStaticComponent(MODAL_INSPECTOR)(state),
  modalInspectorObjUri: selectModalInspectorObjUri(state),
  currentObjectInView: selectCurrentObjectInView(state),
});

const mapDispatchToProps = (dispatch: any): ModalInspectorReduxDispatchProps => ({
  discardModalFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean, objectInView: string) =>
    dispatch(discardModalFormChanges(displayConfirmationDialog, hideInspector, objectInView)),
  closeModalForm: (hideInspector: boolean, objectInView) => dispatch(closeModalForm(hideInspector, objectInView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalInspector);
