import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const DotsMenuIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 19 19" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <rect fill="fff" style={{ opacity: 0, strokeWidth: '0px' }} width="19" height="19" />
      <path
        fill="#285bc5"
        style={{ strokeWidth: '0px' }}
        d="M9.5,6.18c-.35,0-.65-.13-.91-.38-.25-.25-.38-.55-.38-.91s.13-.67.38-.92c.25-.25.55-.38.91-.38s.65.13.91.38c.25.25.38.56.38.92s-.13.65-.38.91c-.25.25-.55.38-.91.38ZM9.5,8.22c.35,0,.65.13.91.38.25.25.38.55.38.91s-.13.65-.38.91c-.25.25-.55.38-.91.38s-.65-.13-.91-.38c-.25-.25-.38-.55-.38-.91s.13-.65.38-.91c.25-.25.55-.38.91-.38ZM9.5,12.82c.35,0,.65.13.91.38.25.25.38.55.38.91s-.13.67-.38.92c-.25.25-.55.38-.91.38s-.65-.13-.91-.38c-.25-.25-.38-.56-.38-.92s.13-.65.38-.91c.25-.25.55-.38.91-.38Z"
      />
    </g>
  </SvgIcon>
);

export default DotsMenuIcon;
