import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const FailedIcon: React.FunctionComponent<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <circle cx="8" cy="8" r="7" fill="#F20000" />
      <path
        fill="#FFF"
        d="M12.5 4.769L11.231 3.5 8 6.731 4.769 3.5 3.5 4.769 6.731 8 3.5 11.231 4.769 12.5 8 9.269l3.231 3.231 1.269-1.269L9.269 8z"
      />
    </g>
  </SvgIcon>
);

export default FailedIcon;
