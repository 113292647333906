import api from "!../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../node_modules/css-loader/dist/cjs.js!./dx-pivot-grid-theme-override.css";

var options = {"attributes":{"nonce":"ZDM4ZGViNjUtMWIwYS00YjJmLTk5MjAtZjRkNmQ4MDAzMWU0"}};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};