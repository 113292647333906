import React from 'react';
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
import './DXHTMLEditor.css';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const headerValues = [false, 1, 2, 3, 4, 5];
const fontSizeOptions = {
  inputAttr: {
    'aria-label': 'Font size',
  },
};
const fontFamilyOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
const headerOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};

export interface HtmlEditorProps {
  onValueChanged: (e?: any) => void;
  content: string;
  placeholder?: string;
  error?: boolean;
  htmlEditorRef?: any;
}

export const DXHtmlEditor: React.FunctionComponent<HtmlEditorProps> = ({
  onValueChanged,
  content,
  placeholder,
  error,
  htmlEditorRef,
}) => {
  return (
    <div className="widget-container" style={{ height: 'calc(100% - 21px)' }}>
      <HtmlEditor
        style={{
          background: 'var(--background-form)',
          height: '100%',
          overflow: 'auto',
          border: error ? '2px solid red' : 'var(--foreground-form-border)',
        }}
        placeholder={placeholder}
        value={content}
        onValueChanged={onValueChanged}
        ref={htmlEditorRef}
      >
        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
          <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="separator" />
          <Item name="header" acceptedValues={headerValues} options={headerOptions} />
          <Item name="separator" />
          <Item name="color" />
          <Item name="separator" />
          <Item name="link" />
        </Toolbar>
      </HtmlEditor>
    </div>
  );
};
