import styled from 'styled-components';

export const OrderSelectionTabHeader = styled.div`
  background-color: var(--text);
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-style: solid none none none;
  border-width: 4px;
  border-color: var(--foreground-order-selection-panel-border);
  position: relative;
  flex: 2;
  height: 100%;
`;

export const TabPanelOverride = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  .dx-tabs {
    background-color: transparent;
    z-index: 1;
  }
  .dx-tabpanel {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .dx-tabpanel .dx-tabs {
    background-color: transparent;
  }
  .dx-tabpanel-container {
    margin-top: 4px !important;
    padding-top: 0px !important;
  }
  .dx-tab-content {
    text-transform: capitalize;
    font-size: 12px;
  }
  .dx-datagrid .dx-datagrid-total-footer {
    padding-bottom: 0px;
  }
`;

export const BottomPanelGridOverride = styled.div`
  height: 100%;
  .dx-datagrid-nodata {
    &::before {
      position: absolute;
      left: 49%;
      top: -40px;
    }
  }
  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    text-align: center !important;
  }
`;
