import * as Msal from 'msal';
import { Storage } from 'msal/lib-commonjs/Storage';
import { B2CConfig } from 'src/auth/index';

export interface User extends Msal.User {
  idToken: {
    aud: string;
    authTime: number;
    emails: string[];
    exp: number;
    extension_Username?: string;
    family_name: string;
    given_name: string;
    iat: number;
    iss: string;
    name: string;
    nbf: number;
    nonce: string;
    oid: string;
    sub: string;
    tfp: string;
    ver: string;
  };
}

let userAgentApplication: Msal.UserAgentApplication;

let cacheStorage: Storage;

const createAuthorityUrl = (tenantId: string, policy: string) => {
  return `https://${tenantId}.b2clogin.com/tfp/${tenantId}.onmicrosoft.com/${policy}`;
};

export const b2cLogin = (config: B2CConfig) => {
  const msalAppConfig = {
    cacheLocation: 'localStorage',
    redirectUri: `${location.protocol}//${location.host}`,
    navigateToLoginRequestUrl: false,
    // This comes directly from Microsoft's own guidance
    // https://docs.microsoft.com/en-us/azure/active-directory-b2c/b2clogin
    validateAuthority: false,
  };

  const { clientId, tenantId, b2cSigninPolicy, b2cPasswordResetPolicy } = config;

  return new Promise(resolve => {
    let handlingPasswordReset = false;
    const app = new Msal.UserAgentApplication(
      clientId,
      createAuthorityUrl(tenantId, b2cSigninPolicy),
      (errorDesc: string, token: string) => {
        if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
          // user forgot password
          // create a new instance of msal user agent app with the password reset policy
          // this is so it will generate the correct login redirect url to the password reset page
          // In lieu of useful documentation from MS, this github issue is the source of the approach taken
          // eslint-disable-next-line
          // https://github.com/Azure-Samples/active-directory-b2c-javascript-msal-singlepageapp/issues/9#issuecomment-347556074
          handlingPasswordReset = true;
          new Msal.UserAgentApplication(
            clientId,
            createAuthorityUrl(tenantId, b2cPasswordResetPolicy),
            () => null,
            msalAppConfig,
          ).loginRedirect();
        }
        return resolve(token);
      },
      msalAppConfig,
    );

    if (!handlingPasswordReset) {
      userAgentApplication = app;
    }

    // kludgy fix to complete initialization when running within a Cypress test in the build pipeline
    // Seems that MSAL's acquireTokenSilent() won't resolve if run within an iframe
    if (window.parent !== window) {
      return resolve('');
    }

    if (!userAgentApplication.isCallback(location.hash)) resolve(getAccessToken());
  });
};

export const loginRedirect = () => {
  if (!userAgentApplication) {
    throw new Error('Login attempted before authentication initialized');
  }
  deleteCacheAndCookies();
  userAgentApplication.loginRedirect();
};

export const logout = () => {
  if (!userAgentApplication) {
    throw new Error('Logout attempted before authentication initialized');
  }
  userAgentApplication.logout();
};

export const getUser = (): Msal.User => {
  if (!userAgentApplication) {
    throw new Error('getUser attempted before authentication initialized');
  }

  return userAgentApplication.getUser();
};

export const getAccessToken = async (): Promise<string> => {
  if (typeof Cypress !== 'undefined') {
    const auth = Cypress.env('auth');
    return auth.access_token;
  }
  if (!userAgentApplication) {
    throw new Error('getAccessToken attempted before authentication initialized');
  }
  try {
    return await userAgentApplication.acquireTokenSilent(['openid']);
  } catch (error) {
    // if acquireTokenSilent method raise error
    if (error.indexOf(`consent_required`) > -1 || error.indexOf(`interaction_required`) > -1) {
      userAgentApplication
        .acquireTokenPopup(['openid'])
        .then((token: any) => {
          return token;
        })
        .catch(() => {
          return '';
        });
    }
    return '';
  }
};

export const deleteCacheAndCookies = () => {
  if (userAgentApplication) {
    cacheStorage = new Storage(userAgentApplication.cacheLocation);
    cacheStorage.clearCookie();
    cacheStorage.resetCacheItems();
  }
};
