import styled, { css } from 'styled-components';
import { Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const GlideHeader = styled.div`
  margin-top: 45px;
  background-color: var(--background-tab-group);
`;

export const Tab = styled.div<{ $highlighted: boolean }>`
  display: flex;
  float: left;
  cursor: pointer;
  padding: 6px 7px;
  height: 32px;
  font-size: var(--tab-font-size);
  color: var(--foreground-tab);
  align-items: center;
  border-right: var(--foreground-tab-vertical-separator-border-right);
  ${props =>
    props.$highlighted === true &&
    css`
      background-color: var(--background-tab-active);
      border-bottom: var(--foreground-tab-active-border-bottom);
    `};
  :hover {
    background-color: var(--background-tab-active);
  }
  i.dx-icon.dx-icon-close {
    font-size: var(--tab-close-icon-font-size);
    margin-left: 10px;
    color: var(--foreground-tab-close-icon);
    cursor: pointer;
  }
  i.dx-icon.dx-icon-close:hover {
    cursor: default;
    border-radius: 20px;
  }
`;

export const DashBoardTab = styled.div<{ active: boolean }>`
  float: left;
  cursor: pointer;
  padding: 7px;
  line-height: 19px;
  background-color: var(--background-tab-group);
  ${props =>
    props.active === true &&
    css`
      background-color: var(--background-tab-active);
    `};
  :hover {
    background-color: var(--background-tab-active);
  }
`;

export const StyledTooltip = withStyles(Tooltip, {
  tooltip: {
    marginTop: '-5px',
    whiteSpace: 'pre-line',
    maxWidth: 'none',
  },
});
