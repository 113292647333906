import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import * as S from './style';
import { InspectorFooter } from '@virtus/components/Inspector';
import { TertiaryButton } from '@virtus/components/Buttons';
import DXHtmlEditor from '@virtus/components/HTMLEditor';
import styled from 'styled-components';
import { StyledWarningIcon } from '../forms/form-elements/FormElements.style';
import { mutateAsync } from 'redux-query';
import { Glide, GlideCommentaryApi } from 'src/api/queries';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RootState } from 'src/reducers';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { formatUserName } from 'src/utils/formatters';
import { ANALYST_COMMENT_CATEGORY_MAP, ANALYST_COMMENT_TYPE_MAP } from './glide-commentary.util';
import DOMPurify from 'dompurify';
import { dispatchActions } from 'src/app/store';
import { CONFIRMATION_DIALOG } from '@virtus/components/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';

export type GlideCommentaryProps = {
  title?: string;
  displayname: string;
  username: string;
  comments: string;
  commentdate: number;
  category: string;
  creditUri: string;
  addComment?: boolean;
  placeholder?: string;
  commentaryDataCallback?: (commentaryDataCallbackData?: any) => void;
  componentType?: string;
  commenturi?: string;
  fromModal: boolean;
  inspectorOpen?: boolean;
  isCommentaryDirty?: boolean;
};

export interface DefaultCommentaryRequestProps {
  type: string;
  creditUri: string;
  fromModal: boolean;
  inspectorOpen: boolean;
}
export interface DeleteCommentaryRequestProps extends DefaultCommentaryRequestProps {
  uri: string;
}
export interface AddCommentaryRequestProps extends DefaultCommentaryRequestProps {
  body: any;
}

export interface ReduxProps {
  readonly commentaryData: any;
  readonly newCommentaryUri: any;
  readonly glideSession: GlideSession;
  readonly isCreditDetailsUriFetching: boolean;
}
export interface ReduxDispatch {
  addCommentaryData: (data: AddCommentaryRequestProps) => any;
  updateCommentaryData: (data: any, type: string) => any;
  deleteCommentary: (data: DeleteCommentaryRequestProps) => any;
}

const GlideCommentaryComponent = ({
  displayname,
  username,
  comments,
  commentdate,
  category,
  addComment,
  placeholder,
  commentaryDataCallback,
  updateCommentaryData,
  addCommentaryData,
  glideSession,
  deleteCommentary,
  creditUri,
  fromModal,
  isCreditDetailsUriFetching,
  componentType = '',
  commenturi = '',
  inspectorOpen = false,
  isCommentaryDirty = false,
}: GlideCommentaryProps & ReduxDispatch & ReduxProps) => {
  const AdditionalActions = () => (
    <TertiaryButton disabled={addComment} onClick={onDelete}>
      Delete
    </TertiaryButton>
  );
  const [isEditing, setEditing] = useState(false);
  const sanitizedcomments = DOMPurify.sanitize(comments);
  const htmlEditorRef = useRef<any>(null);
  const onDelete = () => {
    if (!commenturi) return;
    deleteCommentary({
      uri: commenturi,
      type: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
      inspectorOpen,
      fromModal,
      creditUri,
    }).then(
      () =>
        commentaryDataCallback &&
        commentaryDataCallback({
          category: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
        }),
      setOpenEditor(false),
    );
  };
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState('');
  const [htmlEditorValue, setHtmlEditorValue] = useState('');
  const [errorComments, setErrorComments] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);

  const showEditor = () => addComment || openEditor;
  const primaryButtonText = () => (isEditing && showEditor() ? 'Update' : 'Add');

  const onContentChanged = (e: any) => {
    if (!(e.value == '' && addComment) && htmlEditorRef?.current?.instance.option('isDirty')) {
      dispatchActions.components.update('isCommentaryDirty', { dirty: true });
    }
    setErrorComments(false);
    setHtmlEditorValue(e.value);
  };

  const handleDisplayName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!(event.target.value == '' && addComment)) {
      dispatchActions.components.update('isCommentaryDirty', { dirty: true });
    }
    setErrorTitle(false);
    setDisplayName(event.target.value);
  };

  const discardChangesOnCancel = () => {
    commentaryDataCallback && commentaryDataCallback();
    setOpenEditor(false);
    setEditing(false);
    setHtmlEditorValue('');
    setErrorComments(false);
    setErrorTitle(false);
    dispatchActions.components.update('isCommentaryDirty', { dirty: false });
  };

  const closeCommentaryEditorDialog = () => {
    discardChangesOnCancel();
  };

  const { DialogComponent: QuitEditorCommentary, onDispatcherClick: displayConfirmDialog } = useConfirmationDialog({
    onClick: closeCommentaryEditorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const setSelectionItemDefaultValues = () => {
    setHtmlEditorValue(comments);
    setDisplayName(displayname);
    setEditing(false);
    setOpenEditor(false);
    dispatchActions.components.update('isCommentaryDirty', { dirty: false });
  };

  useEffect(() => {
    if (addComment) {
      setHtmlEditorValue('');
      setDisplayName('');
      setEditing(false);
    } else {
      setSelectionItemDefaultValues();
    }
  }, [addComment, comments]);

  const AddComments = () =>
    addCommentaryData({
      body: {
        object_type: 'analyst_comment',
        object_data: {
          analyst: 'users/' + glideSession.username,
          analyst_comment: htmlEditorValue,
          display_name: displayName,
          analyst_comment_type: ANALYST_COMMENT_CATEGORY_MAP[componentType as ANALYST_COMMENT_CATEGORY_MAP],
        },
      },
      type: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
      inspectorOpen,
      fromModal,
      creditUri,
    });

  const UpdateComments = () =>
    updateCommentaryData(
      {
        [commenturi ? commenturi : '']: {
          analyst: 'users/' + glideSession.username,
          analyst_comment: htmlEditorValue,
          display_name: displayName,
          analyst_comment_type: ANALYST_COMMENT_CATEGORY_MAP[componentType as ANALYST_COMMENT_CATEGORY_MAP],
        },
      },
      ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
    );
  const onApply = async () => {
    if (!htmlEditorValue.replace(/<[^>]+>/g, '') && !displayName) {
      setErrorComments(true);
      setErrorTitle(true);
      return;
    } else if (!htmlEditorValue.replace(/<[^>]+>/g, '') && displayName) {
      return setErrorComments(true);
    } else if (!displayName) {
      return setErrorTitle(true);
    }

    if (primaryButtonText() === 'Add') {
      AddComments().then((data: any) => {
        const uri = data ?? data.body ?? data.body.uri ?? null;
        if (uri) {
          commentaryDataCallback &&
            commentaryDataCallback({
              category: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
              htmlEditorData: htmlEditorValue,
              uri: uri[0],
              username: 'users/' + glideSession.username,
            });
        }
      });
    } else {
      setOpenEditor(false);
      dispatchActions.components.update('isCommentaryDirty', { dirty: false });
      setEditing(false);
      UpdateComments().then(
        () =>
          commentaryDataCallback &&
          commentaryDataCallback({
            category: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
            htmlEditorData: htmlEditorValue,
            uri: commenturi,
            username: 'users/' + glideSession.username,
            displayname: displayName,
          }),
      );
    }

    setErrorComments(false);
    setErrorTitle(false);
  };
  const onCancel = () => {
    if (isCommentaryDirty) {
      displayConfirmDialog();
    } else {
      discardChangesOnCancel();
    }
  };

  const Error = styled.div`
    width: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  `;

  const onEdit = () => {
    setOpenEditor(true);
    setHtmlEditorValue(comments);
    setDisplayName(displayname);
    setEditing(true);
  };

  return (
    <>
      <QuitEditorCommentary />
      {showEditor() ? (
        <S.DetailsWrapper>
          <div style={{ display: 'flex', width: '100%' }}>
            {errorTitle && (
              <Error style={{ paddingLeft: '10px', paddingBottom: '0px' }}>
                <Tooltip
                  title="Title is required"
                  style={{
                    background: 'var(--red)',
                    color: 'var(--text)',
                    fontSize: '16px',
                    marginRight: '12px',
                  }}
                >
                  <StyledWarningIcon data-testid="alert-icon" />
                </Tooltip>
              </Error>
            )}
            <S.Input
              value={displayName}
              onChange={handleDisplayName}
              placeholder="Title"
              style={{
                background: 'var(--background-form)',
                color: 'var(--foreground-form)',
                border: errorTitle ? 'var(--foreground-form-input-error-border)' : 'var(--foreground-form-border)',
                height: '23px',
                margin: '10px',
                fontSize: 'var(--form-input-font-size)',
                marginLeft: errorTitle ? '0px' : '10px',
              }}
            />
          </div>
          <S.EditCommentaryContainer>
            {errorComments && (
              <Error>
                <Tooltip
                  title="Comment is required"
                  style={{
                    background: 'var(--red)',
                    color: 'var(--text)',
                    fontSize: '16px',
                    marginRight: '12px',
                  }}
                >
                  <StyledWarningIcon data-testid="alert-icon" />
                </Tooltip>
              </Error>
            )}
            <div>
              <S.CommetryLables>Comment: </S.CommetryLables>
              <DXHtmlEditor
                onValueChanged={onContentChanged}
                content={htmlEditorValue}
                placeholder={placeholder}
                error={errorComments}
                htmlEditorRef={htmlEditorRef}
              />
            </div>
          </S.EditCommentaryContainer>
          <InspectorFooter
            onCancel={onCancel}
            disabled={false}
            onApply={onApply}
            confirmButtonText={primaryButtonText()}
            hideCancel={false}
            isLight={true}
            buttonsContainerStyle={{ justifyContent: 'end' }}
          />
        </S.DetailsWrapper>
      ) : (
        comments && (
          <S.DetailsWrapper>
            <S.FormDetailWrapper>
              <S.FormContentWrapper>
                <S.CommetryLables>Title:</S.CommetryLables>
                <Tooltip title={displayname}>
                  <S.TitleWrapper>{displayname}</S.TitleWrapper>
                </Tooltip>
              </S.FormContentWrapper>
              <S.FormContentWrapper>
                <S.CommetryLables>Created By:</S.CommetryLables>
                <S.TextWrapper weight={300} size={14}>
                  {formatUserName(username)}
                </S.TextWrapper>
              </S.FormContentWrapper>
              <S.FormContentWrapper>
                <S.CommetryLables>Created Date:</S.CommetryLables>
                <S.TextWrapper weight={300} size={14}>
                  {' '}
                  {new Date(commentdate).toLocaleString(navigator.language)}
                </S.TextWrapper>
              </S.FormContentWrapper>
              <S.FormContentWrapper>
                <S.CommetryLables>Type:</S.CommetryLables>
                <S.TextWrapper weight={300} size={14}>
                  {category}
                </S.TextWrapper>
              </S.FormContentWrapper>
              <S.CommetryLables>Comment: </S.CommetryLables>
              <S.ContentWrapper dangerouslySetInnerHTML={{ __html: sanitizedcomments }} />
            </S.FormDetailWrapper>
            <S.FooterStyled>
              <InspectorFooter
                onCancel={onCancel}
                disabled={isCreditDetailsUriFetching}
                onApply={onEdit}
                confirmButtonText="Edit"
                hideCancel={true}
                actions={AdditionalActions()}
                isLight={true}
                buttonsContainerStyle={{ justifyContent: 'end', paddingLeft: '15px' }}
              />
            </S.FooterStyled>
          </S.DetailsWrapper>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { componentType } = ownProps;
  return {
    commentaryData: GlideCommentaryApi.CommentarySelector(state, componentType),
    newCommentaryUri: GlideCommentaryApi.newCommentaryUri(state) || {},
    glideSession: authSelectors.glideSession(state),
    isCreditDetailsUriFetching: Glide.instanceUripending(state, 'credit_detail'),
  };
};

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  updateCommentaryData: (body: any, type: string) =>
    dispatch(mutateAsync(GlideCommentaryApi.updateCommentary(body, type))),
  addCommentaryData: (requestPayload: AddCommentaryRequestProps) =>
    dispatch(mutateAsync(GlideCommentaryApi.submitNewComments(requestPayload))),
  deleteCommentary: (requestPayload: DeleteCommentaryRequestProps) =>
    dispatch(mutateAsync(GlideCommentaryApi.deleteCommentary(requestPayload))),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(GlideCommentaryComponent);
