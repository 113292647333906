import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import { GlobalActionObject } from 'src/models/actions';
import { Add } from '@mui/icons-material';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { CONFIRMATION_DIALOG } from '@virtus/components/utils/constants';
import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { dispatchActions } from 'src/app/store';

export type GroupedListProps = {
  data: GlobalActionObject[];
  label?: string;
  isInspectorFormDirty?: any;
  resolveAction: (action: any, targetUri?: string) => void;
  closeInspectorForm?: (hideInspector: boolean) => void;
  isModalInspector?: any;
  setInstanceUri?: any;
  modalInspectorData: any;
};

export const GroupedList: React.FC<GroupedListProps> = ({
  data,
  label,
  isInspectorFormDirty,
  resolveAction,
  closeInspectorForm,
  isModalInspector,
  setInstanceUri,
  modalInspectorData,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();
  const closeInspectorDialog = (value: any) => {
    setSelectedValue(value);
    resolveAction(value);
    closeInspectorForm && closeInspectorForm(true);
  };
  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });
  const onChangeHandler = (_event?: any, value?: any, reason?: string) => {
    if (isInspectorFormDirty) {
      displayConfirmationDialog(value);
      setSelectedValue(null);
    } else {
      if (reason === 'selectOption') {
        if (modalInspectorData && modalInspectorData[value.data.action]) {
          dispatch({
            type: 'UPDATE_COMPONENT',
            payload: {
              componentName: 'modalInspector',
              props: {
                [value.data.action]: { isVisible: true, visible: true, uri: value['uri'] },
              },
            },
          });
          dispatchActions.modalForm.setModalForm(modalInspectorData[value.data.action]);
          dispatchActions.modalForm.setModalFormEdit(true, modalInspectorData[value.data.action]);
        } else {
          resolveAction(value);
        }
        setSelectedValue(value);
        setInstanceUri(value.data.action);
      } else if (reason == 'clear') {
        setSelectedValue(null);
      }
    }
  };
  const inputRender = (params: any) => <StyledTextField label={label} variant="filled" {...params} />;
  const renderOption = (props: any, option: any) => {
    return (
      <>
        <div {...props}>
          <Add style={{ fontSize: 'var(--auto-complete-dropdown-items-add-icon-font-size)' }}></Add>
          <span style={{ paddingLeft: '7px' }}> {option?.data?.display_name} </span>
        </div>
      </>
    );
  };

  const groupBy = (option: any) => {
    return option.data.category ? option.data.category : 'Undefined';
  };

  return (
    <>
      <QuitInspector />
      <StyledAutoComplete
        data-testid="global-actions"
        options={sortBy(data, o => o.data.category)}
        groupBy={groupBy}
        value={isModalInspector ? selectedValue : null}
        getOptionLabel={(option: any) => option?.data?.display_name}
        renderInput={inputRender}
        onChange={onChangeHandler}
        renderOption={renderOption}
      />
    </>
  );
};
export const StyledAutoComplete: any = withStyles(Autocomplete, {
  root: {
    width: '287px',
    height: '26px',
    backgroundColor: 'var(--background-auto-complete)',
    borderRadius: '4px',
    border: 'var(--foreground-auto-complete-border)',
    position: 'relative',
    left: '14px',
  },
  paper: {
    backgroundColor: 'var(--background-auto-complete-dropdown-items)',
    color: 'var(--foreground-auto-complete-dropdown-items)',
    border: 'var(--foreground-auto-complete-dropdown-border)',
    fontSize: 'var(--auto-complete-dropdown-items-font-size)',
    padding: '0px',
    margin: '0px',
    '& > ul.MuiAutocomplete-listbox': {
      paddingTop: '0px',
      paddingBottom: '0px',

      '& > li > ul > div': {
        padding: '3px 0px 3px 7px',
      },
    },
  },
  groupLabel: {
    backgroundColor: 'var(--background-auto-complete-dropdown-items)',
    color: 'var(--foreground-auto-complete-dropdown-items-group-label)',
    paddingLeft: '7px',
    paddingRight: '0px',
    fontWeight: 'bold',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  // clearIndicatorDirty: {
  //   color: 'var(--foreground-auto-complete)',
  //   position: 'absolute',
  //   top: '10px',
  //   left: '-27px',
  // },
  popupIndicator: {
    position: 'relative',
    top: '8px',
    color: 'var(--foreground-auto-complete)',
  },
  option: {
    'ul > &.MuiAutocomplete-option': {
      '&:hover': { backgroundColor: 'var(--background-auto-complete-dropdown-items-hover)' },
    },
  },
});

export const StyledTextField: any = styled(TextField)`
  & > label {
    color: var(--foreground-auto-complete);
    font-size: var(--globalActionsDropDownLabelFontSize) !important;
    top: -14px;
  }

  & > div.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiAutocomplete-inputRoot {
    padding-top: 22px;
  }

  & > label.MuiInputLabel-filled.MuiInputLabel-shrink {
    top: -10px;
  }

  & > div.MuiFilledInput-underline:after {
    border-bottom: none;
  }

  & > div {
    color: var(--foreground-auto-complete);
    font-size: var(--textFieldFontSize);
    position: absolute;
    top: -21px;

    & > input.MuiInputBase-input.MuiAutocomplete-input {
      position: relative;
      top: 0px;
      bottom: 0px;
      padding: 7px 4px 3px 4px;
    }

    & > div {
      & > button.MuiAutocomplete-popupIndicator {
        margin-right: -10px;
      }
    }
  }
`;
