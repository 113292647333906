import React from 'react';
import Loading from 'src/Loading';
import { LoadingIconProps, LoadingIconSizes, LoadingIconType } from 'src/LoadingIcon/LoadingIcon';
import styled from 'styled-components';

export const LoadingDataGrid: React.FunctionComponent<Pick<LoadingIconProps, 'show'>> = ({ show = false }) => {
  return show ? (
    <div className="-loading -active" style={{ zIndex: 0 }}>
      <SpinnerWrapper className="-loading-inner">
        <Loading show={show} type={LoadingIconType.Virtus} size={LoadingIconSizes.large} />
      </SpinnerWrapper>
    </div>
  ) : null;
};

const SpinnerWrapper = styled.div`
  top: 35% !important;
`;

export default LoadingDataGrid;
