import { Typography } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Link from 'src/Link';
import Body from 'src/page/Body';
import Page from 'src/page/Page';

export type NotFoundProps = RouteComponentProps<{ appName: string }>;

/** Not Found page */
const NotFound: React.SFC<NotFoundProps> = ({ appName }) => {
  return (
    <Page appName={appName} pageTitle="Not Found" testid="Page-NotFound">
      <Body>
        <Typography variant="h3">Sorry, page not found.</Typography>

        <Link to="/">Home</Link>
      </Body>
    </Page>
  );
};

export default NotFound;
