import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from 'tss-react/mui';
import React from 'react';
import styled from 'styled-components';

export enum layoutRegion {
  BOTTOM = 'bottom',
  POPUP = 'popup',
  INSPECTOR = 'inspector',
}

const ActionsMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
);
export const StyledActionsMenu = withStyles(ActionsMenu, {
  paper: {
    marginTop: -31,
    borderRadius: '2px',
    backgroundColor: 'var(--background-form)',
  },
});

export const StyledFormControl = withStyles(ActionsMenu, {
  paper: {
    // marginTop: -31,
    borderRadius: '2px',
    backgroundColor: 'var(--background-form)',
  },
});

const evalColor = (layout?: layoutRegion) => {
  if (!layout || layout === layoutRegion.POPUP) {
    return 'var(--foreground-form)';
  } else {
    return 'var(--foreground-form-input-search-dark)';
  }
};

export const TextButton = styled(Button)<{ layout?: layoutRegion }>`
  color: ${({ layout }) => evalColor(layout)} !important;
  text-transform: none;
  padding: 0;
  &[disabled] {
    color: var(--foreground-form);
    box-shadow: none;
    opacity: 0.5;
  }
`;

export const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean; backgroundcolor?: string; layout?: layoutRegion }>`
  width: ${({ layout }) => (layout === layoutRegion.POPUP || layout === layoutRegion.BOTTOM ? 'auto' : '180px')};
  height: 26px;
  margin: 0;
  padding: 5px;
  border-radius: 3px;
  font-size: var(--form-input-font-size);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  justify-content: space-between;
  color: ${({ disabled = false }) =>
    disabled ? 'var(--foreground-form-disabled)' : 'var(--foreground-form)'} !important;
  pointer-events: ${({ disabled = false }) => (disabled ? 'none' : 'auto')};
  & div {
    padding: 0px;
  }
  &.MuiListItem-root.Mui-selected {
    background-color: inherit;
    & svg {
      display: inline-block !important;
    }
  }
  &.MuiListItem-root.Mui-selected:hover {
    background-color: var(--background-form-dropdown-items-hover);
  }
  &:hover {
    background-color: var(--background-form-dropdown-items-hover);
  }
`;

export const StyledMenuItemText = styled.div`
  display: flex;
  padding: 0;
  justify-content: flex-start;
  padding-left: 5px;
  align-items: center;
  font-size: var(--form-input-font-size);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: var(--foreground-form);
`;
