export interface IMultiplierType {
  key: string;
  value: number;
}

export const DefaultMultiplier: IMultiplierType[] = [
  {
    key: 'M',
    value: 1000000,
  },
  { key: 'K', value: 1000 },
  { key: 'B', value: 1000000000 },
];

export const multiplyValue = (numericValue: number, multiplier: IMultiplierType) => numericValue * multiplier.value;

const getMultiplier = (lastCharacter: string): IMultiplierType[] => {
  return DefaultMultiplier.filter(keyMultiplier => lastCharacter.toLowerCase() === keyMultiplier.key.toLowerCase());
};

const getNumericValue = (value: string, multiplier: IMultiplierType[]) =>
  Number(value.replace(/,/g, '').replace(RegExp(multiplier[0].key, 'i'), ''));

export const applyMultiplier = (value: string | number) => {
  if (!value) return value;
  const lastCharacter = String(value).substr(-1);
  const multiplier = getMultiplier(lastCharacter);
  if (!multiplier.length) return String(value);
  const numericValue = getNumericValue(String(value), multiplier);
  return multiplyValue(numericValue, multiplier[0]);
};
