import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';

export const DrawerContainer = styled(Drawer)<{ style: object } & any>`
  top: 0;
  position: absolute;
  height: ${props => (props?.style?.height ? props.style.height : '100%')};
`;

export const DrawerContent = styled.div`
  display: flex;
  font-size: var(--contentFontSize);
  flex-direction: column;
  height: 100%;
  border-left: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

export const InspectorWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
`;

export const PaperStyled = styled(Paper)<{ paperStyles?: CSSStyleDeclaration }>`
  top: 0;
  position: absolute;
  width: var(--width-inspector);
  right: -430px;
  border-left: none;
  border-radius: 0px;
  border-top: var(--foreground-inspector-border);
  border-bottom: var(--foreground-inspector-border);
`;

export const RowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  height: 100%;
  background: var(--background-inspector);
`;
