import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { SyntheticEvent, memo, useEffect, useRef, useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from '../FormErrorWrapper';
import * as S from './DateInput.style';
import DayPicker, { DateUtils } from 'react-day-picker';
import formatDate from '@virtus/common/utils/dateFormater';
import { formattedDate } from 'src/sagas/date-input/date-input.saga';
import YearMonthForm from '@virtus/components/Calendar/YearMonthForm';

const initializeSelectedDate = (value: any) => {
  if (!value) return [];
  if (typeof value === 'string') value = [value];
  return value.map((item: string) => {
    return new Date(item);
  });
};

// keep the code for future need
const localizedDisplayDate = (value: string[] | string) => {
  if (Array.isArray(value)) {
    return value.map(item => {
      return formattedDate(item).formattedDate;
    });
  }
  return formattedDate(value).formattedDate;
};

export const MultipleDateInput = memo(
  ({ field, value, error, customWidth, onChange, formGroupChildrenStyle }: FormElementInputComponent) => {
    const [open, setOpen] = useState(false);
    const [selectedDays, setSelectedDays] = useState(value || ([] as any));
    const [displayDates, setDisplayDates] = useState(value || ([] as any));
    const [hasFocus, setFocus] = useState(false);
    const [month, SetMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth()));
    const dateInputRef = useRef<any>();

    useEffect(() => {
      setSelectedDays(initializeSelectedDate(value));
      setDisplayDates(localizedDisplayDate(value) || ([] as string[]));
      if (value && !Array.isArray(value)) {
        const year = new Date(initializeSelectedDate(value)).getFullYear();
        const month = new Date(initializeSelectedDate(value)).getMonth();
        SetMonth(new Date(year, month));
      }
    }, [value]);

    const onDateChange = (day: Date, { selected }: any) => {
      const selectedDaysTemp = field.isSingleDate ? [] : selectedDays;
      if (selected && !field.isSingleDate) {
        const selectedIndex = selectedDaysTemp.findIndex((selectedDay: any) => DateUtils.isSameDay(selectedDay, day));
        selectedDaysTemp.splice(selectedIndex, 1);
      } else {
        selectedDaysTemp.push(day);
      }
      // setSelectedDays(selectedDaysTemp);

      const _formatedDate: string[] = [];
      const _displayDates: string[] = [];
      selectedDaysTemp.forEach((data: any) => {
        const { date, formattedDate: localizedDate } = formattedDate(new Date(formatDate(data)));
        _formatedDate.push(date);
        _displayDates.push(localizedDate);
      });

      // setDisplayDates(_displayDates);

      onChange({
        target: {
          value: [..._formatedDate] as any,
          addEventListener: () => null,
          removeEventListener: () => null,
          dispatchEvent: () => true,
        },
      });
      dateInputRef && dateInputRef.current && dateInputRef.current.focus();
      if (!Array.isArray(value)) {
        closeCalendar();
      }
    };

    const handleYearMonthChange = (e: SyntheticEvent, year: number, month: number) => {
      e.stopPropagation();
      SetMonth(new Date(year, month));
      openCalendar(true);
    };

    const openCalendar = (open = true) => setOpen(open);
    const closeCalendar = () => {
      setOpen(false);
    };

    const modifiersStyles = {
      selected: {
        color: 'var(--background-calendar)',
        backgroundColor: 'var(--background-calendar-selected-date)',
        fontWeight: 600,
        borderRadius: 0,
      },
      today: {
        border: 'var(--foreground-calendar-today-border)',
        fontWeight: 600,
        color: 'var(--forgground-formround-form)',
      },
      disabled: { cursor: 'not-allowed', color: 'var(--modifiersDisbled)' },
    };

    const handleDisabledDays = (date: Date) => date > new Date();

    const defaultSingleDateProps = field.isSingleDate
      ? {
          showOutsideDays: true,
          toMonth: new Date(),
          disabledDays: handleDisabledDays,
          pagedNavigation: true,
        }
      : {};

    const handleOnFocus = () => {
      setFocus && setFocus(true);
    };

    const handleOnBlure = (e: SyntheticEvent) => {
      if (e.target !== e.currentTarget && (e.target as HTMLInputElement).parentElement === e.currentTarget) {
        return;
      }
      if (e.target !== e.currentTarget) setFocus(false);
    };

    return (
      <FormErrorWrapper
        displayName={field.displayName}
        name={field.name}
        customWidth={customWidth}
        formGroupChildrenStyle={formGroupChildrenStyle}
        disableErrorWarning={field.disableErrorWarning}
      >
        <S.DateInputWrapper>
          <S.DateButton
            onClick={() => openCalendar(true)}
            disabled={false}
            $error={Boolean(error) && !(field.readonly || field.disabled)}
            onFocus={() => setFocus(true)}
            onBlur={handleOnBlure}
            $hasFocus={hasFocus}
            $editIsEnabled={!field.disabled && !field.readonly}
            ref={dateInputRef}
          >
            <S.DateInputElement
              data-testid={`${field.name}-calendar-input` || 'calendar-input'}
              disabled={false}
              value={displayDates}
              readOnly
            />
            <S.CalendarContainer>
              <S.StyledCalendarIcon data-testid={`${field.name}-calendar-icon` || 'calendar-icon'} />
            </S.CalendarContainer>
          </S.DateButton>

          {open && (
            <ClickAwayListener onClickAway={closeCalendar}>
              <S.MultipleCalendarWrapper align={field.calendarAlign}>
                <DayPicker
                  selectedDays={selectedDays}
                  month={month}
                  modifiersStyles={modifiersStyles}
                  onDayClick={onDateChange}
                  {...defaultSingleDateProps}
                  onFocus={handleOnFocus}
                  captionElement={({ date, localeUtils }: any) => (
                    <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
                  )}
                />
              </S.MultipleCalendarWrapper>
            </ClickAwayListener>
          )}
        </S.DateInputWrapper>
      </FormErrorWrapper>
    );
  },
);
