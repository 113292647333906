import { InputBase } from '@mui/material';
import styled from 'styled-components';

export const DetailsWrapper = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  color: var(--foreground-form);
  height: 100%;
  width: 100%;
  background: var(--background-form);
`;

export const TitleWrapper = styled.div`
  font-weight: 200;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: var(--form-input-font-size);
  letter-spacing: 0px;
`;

export const TextWrapper = styled.div<{ size?: number; weight?: string | number; color?: any }>`
  margin-right: 20px;
  font-size: ${({ size }) => size || 12}px;
  font-weight: ${({ weight }) => weight || 'normal'};
  color: ${({ color }) => color || 'inherit'};
  letter-spacing: 0px;
`;

export const ContentWrapper = styled.div`
  padding: 0px 23px 0px 15px;
  overflow-y: auto;
  flex: 1;
  letter-spacing: 0px;
  background: var(--background-dx-pivotgrid-header);
  margin-top: 15px;
  height: calc(100% - 200px);
  font-size: var(--form-input-font-size);
  & p {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: var(--form-input-font-size);
  }
`;

export const Input = styled(InputBase)`
  color: var(--foreground-form);
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 2%;
  border: var(--foreground-form-dropdown-boder);
  &.Mui-focused {
    border: var(--foreground-form-input-focus-border);
  }
`;

export const CommetryLables = styled.div`
  color: var(--foreground-form);
  font-weight: 700;
  width: 18%;
  font-size: var(--form-input-font-size);
  letter-spacing: 0px;
  padding-left: 1px;
`;

export const FormContentWrapper = styled.div`
  display: flex;
  padding: 2px;
  align-items: baseline;
  border-bottom: var(--foreground-glide-comentary-ddl-border);
  height: 36px;
`;

export const EditCommentaryContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 10px;
  height: calc(100% - 120px);
  & + div[data-testid='inspector-footer'] {
    height: 57px;
  }
  & + div[data-testid='inspector-footer'] button {
    width: 72px;
  }
`;

export const FormDetailWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 57px);
  padding-left: 35px;
  padding-right: 30px;
  padding-top: 10px;
  & + div[class^='style__FooterStyled'] div[data-testid='inspector-footer'] {
    height: 57px;
  }
`;

export const FooterStyled = styled.div`
  height: 57px;
  & div[data-testid='inspector-footer'] section[data-testid='other-btn'] button {
    border: var(--foreground-form-btn-border);
    border-radius: 17px;
    background: var(--background-form-secondary-btn);
    color: var(--foreground-form);
    font-weight: 700;
    height: 34px;
    padding: 0px 14px;
    max-height: 34px;
    margin-right: 0px;
  }

  & div[data-testid='inspector-footer'] button[data-testid='primary-btn'] {
    width: 72px;
    margin-right: 10px;
    margin-left: 13px;
  }

  & div[data-testid='inspector-footer'] button[data-testid='secondary-btn'] {
    padding: 0px 13px;
  }
`;
