import { cancelQuery } from 'redux-query';
import { updateEntities } from 'redux-query';
import { put, putResolve, takeLatest } from 'redux-saga/effects';
import { updateComponentViewAction } from 'src/reducers/components';
import { TabsAction } from 'src/reducers/tabs';

export interface OpenViewPayload {
  data: { fullPath: string; activeView: string };
}

export enum TabActions {
  OPEN_VIEW = 'OPEN_VIEW',
}

const changeViewAction = (action: OpenViewPayload) => ({
  type: TabsAction.CHANGE_VIEW,
  data: { activeView: action.data.activeView },
});

export function* openViewSaga(action: any) {
  yield put({
    type: TabsAction.OPEN_TAB,
    path: action.data.path,
  });

  yield put(changeViewAction(action));
}

export function* openTabSaga(): any {}

export function* resetBottomPanel(action: any): any {
  yield put(cancelQuery('orderBottomPanelGrid'));
  yield putResolve(
    updateEntities({
      views: (prev: any, _: any) => ({
        ...prev,
        orderBottomPanelGrid: {
          data: [],
          schema: [],
          uri: '',
          displayViewData: {},
          fieldRules: null,
          workflowAction: [],
          webLayouts: [],
          clientViewConfiguration: {},
        },
      }),
    }),
  );
  yield put(
    updateComponentViewAction('bottomPanel', action.payload.clientViewUri, {
      instanceUri: '',
    }),
  );
  yield put(
    updateComponentViewAction('currentObjectInView', action.payload.clientViewUri, {
      uri: '',
    }),
  );
}

export function* watchOpenView() {
  yield takeLatest<any>(TabActions.OPEN_VIEW, openViewSaga);
}

export function* watchOpenTab() {
  yield takeLatest<any>(TabsAction.OPEN_TAB, openTabSaga);
}

export function* watchResetBottomPanel() {
  yield takeLatest<any>('RESET_BOTTOM_PANEL', resetBottomPanel);
}

export default [
  watchOpenView,
  watchResetBottomPanel,
  // watchOpenTab
];
