import { Button, Dialog } from '@mui/material';
import { Close } from '@mui/icons-material';
import Help from '@mui/icons-material/Help';
import React from 'react';
import styled from 'styled-components';
import { TertiaryButton as SB, DangerButton as PB } from '../Buttons';
import { ConfirmDialogProps } from './withConfirmationDialogOnClick';
import DOMPurify from 'dompurify';

const DefaultConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = ({
  headerText,
  bodyTitle,
  bodyTextContent,
  acceptButton,
  cancelButton,
  onAccept,
  onCancel,
  onClose,
  onHelp,
  show,
  showCloseButton,
  bodyContentAlign,
}: ConfirmDialogProps) => {
  const sanitizedBodyTitile = DOMPurify.sanitize(bodyTitle || '');

  return (
    <Dialog open={show} onClose={onClose} aria-labelledby="confirm-dialog" data-testid="default-confirm-dialog">
      <DefaultConfirmDialogWrapper>
        <TopBar>
          <Title>{headerText}</Title>
          <Icons>
            {onHelp && (
              <IconButton onClick={onHelp} size="large">
                <HelpIcon />
              </IconButton>
            )}
            {showCloseButton && (
              <IconButton onClick={onCancel} size="large">
                <CloseIcon />
              </IconButton>
            )}
          </Icons>
        </TopBar>
        <Content bodyContentAlign={bodyContentAlign}>
          <BodyTitle dangerouslySetInnerHTML={{ __html: sanitizedBodyTitile }}></BodyTitle>
          <BodyText>{bodyTextContent}</BodyText>
        </Content>
        <BottomBar>
          {onCancel && (
            <SecondaryButton
              data-testid={cancelButton?.dataTestId || 'cancel-btn'}
              onClick={onCancel}
              style={cancelButton && cancelButton.style}
            >
              {(cancelButton && cancelButton.text) || 'Cancel'}
            </SecondaryButton>
          )}
          <PrimaryButton
            data-testid={acceptButton?.dataTestId || 'confirm-btn'}
            onClick={onAccept}
            style={{ marginLeft: '1em', ...(acceptButton && acceptButton.style) }}
          >
            {(acceptButton && acceptButton.text) || 'Confirm'}
          </PrimaryButton>
        </BottomBar>
      </DefaultConfirmDialogWrapper>
    </Dialog>
  );
};

export const SecondaryButton = styled(SB)`
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-secondary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 23px;

  &:hover {
    background: var(--background-form-secondary-btn);
  }
`;

export const PrimaryButton = styled(PB)`
  margin-left: 25px;
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-primary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 18px;

  &:hover {
    background: var(--background-form-primary-btn);
  }
`;

const Title = styled.h4`
  font-size: var(--modal-title-font-size);
  text-align: left;
  font-family: var(--font-family-dialog-header);
`;

const DefaultConfirmDialogWrapper = styled.div`
  width: 440px;
  height: 192px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 var(--defaultConfirmDialog);
  background-color: var(--background-confirm-dialog);
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: none;
  color: var(--foregound-dialogue-content-color);
  border-radius: 4px;
  border: 5px solid var(--dialog-border);
`;

const Bar = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const BottomBar = styled(Bar)`
  justify-content: flex-end;
  background-color: var(--background-modal-footer);
  justify-content: flex-end;
  height: 48px;
  width: 100%;
  padding: 0 10px;
  border-top: var(--foreground-form-border);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: var(--font-family-dialog-header);
`;

const TopBar = styled(Bar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  width: 100%;
  background: var(--background-modal-header);
  padding-left: 20px;
  padding-right: 10px;
  color: var(--foreground-header);
  border-bottom: var(--foreground-form-border);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div<{ bodyContentAlign: string | undefined }>`
  letter-spacing: 0.4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: 0.5em 1em;
  font-family: var(--font-family);
  text-align: ${({ bodyContentAlign }) => bodyContentAlign || 'center'};
`;

const BodyTitle = styled.span`
  font-size: var(--confirmation-dialog-body-font-size);
  width: 100%;
`;

const BodyText = styled.span`
  font-size: var(--confirmation-dialog-body-font-size);
  width: 100%;
`;

export default DefaultConfirmDialog;

const IconButton: any = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: var(--foreground-tab-close-icon);
`;

const HelpIcon = styled(Help)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--text);
`;

const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--foreground-tab-close-icon);
`;
