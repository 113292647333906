import React, { useEffect, useState } from 'react';
import { Modal } from '@virtus/components/index';
import * as S from './add-instrument.style';
import { dispatchActions } from 'src/app/store';
import { backDropClick } from 'src/utils/constants';
import { SelectInput } from 'src/components/forms/form-elements/form-fields';
import { RadioButtonsType } from 'src/components/forms/form-elements/FormElement.model';
import { TextInput } from 'src/components/forms/form-elements/form-fields';
import { PrimaryButton } from '@virtus/components/Inspector/components/InspectorFooter';
import { CancelButton } from '@virtus/components/Inspector/components/InspectorFooter';
import { Footer } from '@virtus/components/Inspector/components/InspectorFooter.style';
import RadioGroupInput from 'src/components/forms/form-elements/form-fields/RadioGroupInput';
export interface AddInstrumentOverlayProps {
  modalCloseHandler: () => void;
  displayOverlay: boolean;
}

type AddinstrumentType = 'import_instruments' | 'create_manually';

interface AddInstrumentStateProps {
  addType: AddinstrumentType;
  identifier_type: string;
  identifiers: string;
}

const RadioButtons: RadioButtonsType[] = [
  { value: 'import_instruments', display_name: 'Import Instruments', disabled: false },
  {
    value: 'create_manually',
    display_name: 'Create Manually',
    disabled: true,
    visible: false,
  },
];

const initialState: AddInstrumentStateProps = {
  addType: 'import_instruments',
  identifier_type: 'marketmap_id',
  identifiers: '',
};

const AddInstrumentOverlay = ({ modalCloseHandler, displayOverlay = false }: AddInstrumentOverlayProps) => {
  const [show, setShow] = useState(false);
  const [modalState, setModalState] = useState<AddInstrumentStateProps>(initialState);
  const [errorsInstrumentForm, setErrorInstrumentForm] = useState({ identifiers: false });
  const resetAllFields = () => {
    setModalState(initialState);
  };
  const validateInstrumentForm = () => {
    let valid = true;
    if (!modalState.identifiers.trim()) {
      setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: true });
      valid = false;
    }
    return valid;
  };

  const importAllFields = () => {
    const isInstrumentFormValid = validateInstrumentForm();
    if (!isInstrumentFormValid) return;

    const identifiers = modalState.identifiers.trim().replace(/\n/g, ',');
    setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: false });
    dispatchActions.execute({
      targetUri: ['instance/mapping/market_map_data_imports'],
      arguments: { 'instance/argument/marketdataids': identifiers },
      actionUri: 'instance/actions/marketmap_feed',
    });

    closeAddInstrumentModal();
  };

  const modalFooter = (
    <Footer
      isLight={false}
      data-testid="inspector-footer"
      style={{
        background: 'var(--background-modal-footer)',
        borderTop: 'var(--foreground-form-border)',
        justifyContent: 'end',
      }}
    >
      <CancelButton data-testid="secondary-btn" onClick={resetAllFields} color="inherit">
        Reset
      </CancelButton>
      <PrimaryButton
        data-testid="primary-btn"
        onClick={importAllFields}
        disabled={false}
        variant="contained"
        color="secondary"
        type="submit"
      >
        Import
      </PrimaryButton>
    </Footer>
  );

  const closeAddInstrumentModal = (_event?: any, reason?: any) => {
    if (reason === backDropClick) return;
    modalCloseHandler();
    setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: false });
  };

  const handleFieldChange = (e: any) => {
    const field = e?.target.name;
    if (field === 'identifiers' && errorsInstrumentForm.identifiers && e.target.value)
      setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: false });
    if (field) {
      setModalState({ ...modalState, [field]: e.target.value });
    }
  };

  useEffect(() => {
    setShow(displayOverlay);
    if (displayOverlay) {
      setModalState({ ...initialState });
    }
  }, [displayOverlay]);

  const dialogStyle = { background: '#ffffff', color: '#1b252f' };
  return (
    <>
      <Modal // `disableBackdropClick` is removed by codemod.
        // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

        title="Add Instruments"
        onClose={closeAddInstrumentModal}
        show={show}
        isDraggable={true}
        dialogStyle={dialogStyle}
        hideFooter={true}
        fullWidth
      >
        <S.FormWrapper>
          <form id="add-instrument-form" data-testid="add-instrument-form">
            <RadioGroupInput
              field={{
                dataType: 'string',
                formElementType: 'radio',
                required: false,
                readonly: false,
                defaultValue: 'any',
                name: 'addType',
                displayName: 'Creation mode',
              }}
              value={modalState.addType}
              onChange={handleFieldChange}
              RadioButtons={RadioButtons}
            />
            <SelectInput
              onChange={handleFieldChange}
              field={{
                name: 'add-instrument-identifiers-select',
                displayName: 'Identifier Type',
                required: false,
                dataType: 'object',
                searchType: 'allocation_strategies',
                formElementType: 'select',
                readonly: false,
                defaultValue: { marketmap_id: 'MarketMap ID' },
                options: { marketmap_id: { name: 'MarketMap ID', value: 'marketmap_id' } },
                hidden: false,
              }}
            />
            <TextInput
              field={{
                dataType: 'text',
                name: 'identifiers',
                displayName: 'Identifiers',
                formElementType: 'string',
                required: false,
                defaultValue: undefined,
                readonly: false,
              }}
              value={modalState.identifiers}
              onChange={(e: any) => handleFieldChange(e)}
              multiline={true}
              type="text"
            />
            {modalFooter}
          </form>
        </S.FormWrapper>
      </Modal>
    </>
  );
};

export default AddInstrumentOverlay;
