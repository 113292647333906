exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./SourceSansPro/SourceSansPro-Regular.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./SourceSansPro/SourceSansPro-Regular.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./SourceSansPro/SourceSansPro-Regular.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./SourceSansPro/SourceSansPro-Bold.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./SourceSansPro/SourceSansPro-Bold.woff"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./SourceSansPro/SourceSansPro-Bold.woff2"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./TitilliumWeb/TitilliumWeb-Regular.ttf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: 'Source Sans Pro';\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format('truetype'),\n    url(" + ___CSS_LOADER_URL___1___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL___2___ + ") format('woff2');\n}\n\n@font-face {\n  font-family: 'Source Sans Pro Bold';\n  src: url(" + ___CSS_LOADER_URL___3___ + ") format('truetype'),\n    url(" + ___CSS_LOADER_URL___4___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL___5___ + ") format('woff2');\n}\n\n@font-face {\n  font-family: 'Titillium Web';\n  src: url(" + ___CSS_LOADER_URL___6___ + ") format('truetype');\n}\n", ""]);

