// App constants

import { MarketmapScripting, IMarketmapConsumerViewState } from 'src/models/marketmap/marketmap.model';

export const appName = 'Glide';

export const zIndex = {
  SearchInspector: 2,
  Calendar: 10,
  CollapseWrapperBtn: 8,
  CollapseWrapper: 11,
  SaveCancelWrapper: 4,
};

export const mapGlideObjectBgColor = (colorValue: string = '') => {
  const _colorValue = colorValue.toLowerCase();
  if (_colorValue.includes('blue')) return 'var(--blue)';
  if (_colorValue.includes('red')) return 'var(--red)';
  if (_colorValue.includes('green')) return 'var(--green)';
  return 'transparent';
};

export const snackbar = {
  AUTO_HIDE_DURATION_SECS: 15 * 1000, // 15s
};

export const amountOfVisibleActions: number = 5;
/**
 * List of marketmap fields mapped and configured to their corresponding numbers
 */
export enum MarketmapFields {
  'SYMBOL' = 'Symbol',
  'LAST_PRICE' = 2,
  'INSTRUMENT_NAME' = 400,
  'NET_CHANGE' = 101,
  'PERC_NET_CHANGE' = 109,
}

/**
 * There is issue with Array format, identified in POC, hence using Object format as response data
 */
export const marketmapOutputFormat = {
  OBJECT_FORMAT: 1,
  ARRAY_FORMAT: 2,
};

export const marketmapScripts: MarketmapScripting[] = [
  {
    scriptingElement: null,
    scriptingUrl: 'https://www.marketmapmuse.com/Scripts/jquery.min.js',
  },
  {
    scriptingElement: null,
    scriptingUrl: 'https://www.marketmapmuse.com/Scripts/jquery.signalR-2.2.0.min.js',
  },
  {
    scriptingElement: null,
    scriptingUrl: 'https://www.marketmapmuse.com/MuseJS/muse.min.js',
  },
];

export enum ViewUris {
  'clientUniverse' = 'client_universe',
  'loanUniverse' = 'loan_universe',
  'rcmCreditDashboard' = 'rcm_credit_dashboard',
  'creditDashboard' = 'creditDashboard',
  'dealsBlotter' = 'deal_pipeline',
  'orderTradeBlotter' = 'order_blotter',
  'rcmPortfolioDashboard' = 'portfolio_positions',
  'hypoBlotter' = 'hypo_blotter',
}

export enum ClientRegion {
  'BrowserRightRegion' = 'BrowserRightRegion',
}
export enum DISPLAY_VIEW {
  CREDIT = 'credit_dashboard',
  ORDER = 'order_blotter',
  DEALS = 'deal_pipeline',
  HYPOS = 'hypo_order_blotter',
  INSTRUMENT = 'instrument',
  ORDER_INSTRUMENT = 'order_instrument',
  COMPLIANCE = 'compliance',
}

/**
 * Enter all the toolbar icon names over here in the order in which we want to display them
 */
export const TOOLBAR_BEFORE_ICONS_ORDER = ['portfolioManagerButton', 'filterButton', 'Title'];
export const TOOLBAR_AFTER_ICONS_ORDER = [
  'groupPanel',
  'searchButton',
  'searchPanel',
  'auditButton',
  'switchComponent',
  'refreshButton',
  'modellingButton',
  'portfolioOrderDropdownBtn',
  'portfolioAddDropdownBtn',
  'portfolioProposedOrderButton',
  'exportButton',
  'layoutDropdown',
  'dealsAddItemButton',
  'selectCartComponent',
  'columnManagementButton',
  'columnChooserButton',
  'closeButton',
];
export const DEFAULT_TOOLBAR_ICONS_ORDER = [...TOOLBAR_BEFORE_ICONS_ORDER, ...TOOLBAR_AFTER_ICONS_ORDER];
export enum OBJECT_TYPES {
  CREDIT_DETAILS = 'credit_details',
}
export const BreadCrumbs: { [key: string]: any } = {
  credit_details: {
    previousLink: 'dealsDetailInspector',
    currentLink: 'creditDetailsInspector',
  },
};
export const ImportInstrumentsOverlayTitle = 'Import Instruments Messages';

export const BreadCrumbsWithIssuer: { [key: string]: any } = {
  credit_details: {
    previousLink: 'issuerDetailsInspector',
    currentLink: 'creditDetailsInspector',
  },
};
export const LivePricingToggles: IMarketmapConsumerViewState = {
  assets: true,
  portfolio: true,
  portfolio_analyzer: true,
};

export enum ImportInstrumentResultTypes {
  COMPLETED = 'Completed',
  PARTIALLY_COMPLETED = 'PartiallyCompleted',
  FAILED = 'Failed',
}

export const groupedDataColumn = {
  display_name: 'Grouped Data',
  dataField: 'Grouped Data',
  data_type: 'Object',
  key: 'grouped_data',
  showWhenGrouped: true,
  visibleIndex: 0,
  allowReordering: false,
  width: '250px',
  allowFiltering: false,
  allowSorting: false,
  fixed: true,
  fixedPosition: 'left',
  allowGrouping: false,
  showInColumnChooser: false,
};
export const backDropClick = 'backdropClick';
export const escapeKeyDown = 'escapeKeyDown';

export const CONFIRMATION_DIALOG = {
  headerText: 'Discard Changes?',
  bodyTextContent: 'Are you sure you want to discard the changes?',
  cancelButton: { text: 'Cancel' },
  acceptButton: { text: 'Discard' },
};

export const CONFIRM_DIALOG = {
  headerText: 'Close Position?',
  bodyTextContent: 'Are you sure you want to close the position?',
  cancelButton: { text: 'No' },
  acceptButton: { text: 'Yes' },
};

export const IDLE_TIME_OUT_DIALOG = {
  headerText: 'Time Out Alert',
  bodyTextContent: 'Do you want to continue your session?',
  cancelButton: { text: 'No' },
  acceptButton: { text: 'Yes' },
  showCloseButton: false,
};

export const IDLE_TIMEOUT_OUT_CONFIGURATION = {
  Minutes: 1,
  Seconds: 59,
  OffsetMinutes: 2,
};

export const STORE_VIEW_NAME_GLOBAL_ORDER = 'globalOrder';

export const BUTTON_WIDTH = 150;

export const GLIDE_DATA_FORM_NAME = 'action-form';
