import React from 'react';
import styled from 'styled-components';

export interface IPortalLink {
  Icon: React.ComponentType<any>;
  text: string;
  link: string;
  target: string;
  rel?: string;
}

export const PortalLink = ({ Icon, text, link, target, rel }: IPortalLink) => (
  <PortalLinkStyled href={link} target={target} rel={rel}>
    <Icon style={{ height: '18px', width: '18px', marginRight: '5px' }} /> {text}
  </PortalLinkStyled>
);

export const PortalLinkStyled = styled.a`
  background-color: var(--label);
  color: var(--text);
  height: 28px;
  width: auto;
  border-radius: 2px;
  margin-right: 5px;
  padding: 6px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
`;
