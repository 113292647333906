type FormatType = 'n' | 'p';

interface GlideFormat {
  formatType: FormatType | string;
  formatPrecision: number;
}

export const getGlideFormatParams = (format: string): GlideFormat => ({
  formatType: format.charAt(0) ?? format.charAt(0).toLowerCase(),
  formatPrecision: Number(format.charAt(format.length - 1)),
});
