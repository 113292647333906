/* eslint-disable react-hooks/rules-of-hooks */
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Sorting,
  StateStoring,
  Search,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import isEqual from 'lodash/isEqual';
import React, { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { APIRule, DxToolbarButton, OptionChangeParams } from 'src/DxDataGrid/model/DxDataGrid.model';
import { DxGridHeaderActionsAlign, DxGridHeaderItems, getOnToolbarPreparing } from 'src/DxDataGrid/toolbarManagement';
import { DataSourceType, mapSchema } from 'src/DxDataGrid/utils/mapSchema';
import { getSummaryGlide, glideExcelExport, GlideSummaryOptions } from 'src/DxDataGrid/utils/mapSchemaGlide';
import { VirtusSummaryOptions } from 'src/DxDataGrid/utils/mapSchemaVirtus';
import { getSummaryVirtus } from 'src/DxDataGrid/utils/summary/summaryVirtus';
import { ValidationRules } from './DxDataGrid.model';
import { StyledDxDataGrid } from './DxDataGrid.style';
import './themes/dx.material.virtus-light.css';
import './themes/dx.material.virtus-light-override.css';
import { RenderLookup } from './utils/edit/renderLookup';
import ErrorBoundary from 'src/error-boundary/error-boundary';
import { exportDataGrid as _exportExcel } from 'devextreme/excel_exporter';
// @ts-ignore //if removed, the file-saver gives error to install dev dependencies but after its installed there comes error for starting the application.
import { saveAs } from 'file-saver';
import { DxDataGridInstance } from './utils/customExportDataGrid/exportDataGrid.model';
import dxDataGrid from 'devextreme/ui/data_grid';
interface DxDataGridComponentsProps {
  ColumnChooser?: ColumnChooser | any;
}

export interface ILinkedColumn {
  columnName: string;
  onClick: (event: any, data: any) => void;
  condition?: any;
}

export type ExportExcelParams = {
  component: DxDataGridInstance;
  selectedRowsOnly?: boolean;
  cancel?: boolean;
};

export interface NativeDXGridProps {
  noDataText?: string;
  customSave?: (state: any) => void; // DXGridInternalState
  customLoad?: () => any; // DXGridInternalState
  onRowPrepared?: (rowPreparedEvent: any) => void;
  onContextMenuPreparing?: (e: any) => void;
  onContentReady?: (e: any) => void;
  onCellClick?: (props: any) => void;
  toolbarButtons?: any;
  hoverStateEnabled?: boolean;
  selectedRowKeys?: number[];
  columnsToHide?: string[];
  addIdColumn?: boolean;
  onOptionChanged?: (params?: Partial<OptionChangeParams>) => void;
  pageSizeOptions?: number[];
  disableStateStoring?: boolean;
  onRowValidating?: (e: any) => void;
  onRowRemoving?: (e: any) => void;
  onEditingStart?: (e: any) => void;
  onEditorPreparing?: (e: any) => void;
  onCellPrepared?: (e: any) => void;
  onGroupedCallback?: (isExpanded: boolean) => void;
  onRowCollapsed?: (e: any) => void;
  key?: any;
}

export interface DxDataGridProps extends DataGridRenderProps {
  dataSource: DataSource;
  extraColumnProps?: IExtraColumnProps;
  dataSourceType: DataSourceType;
  enableLayouts?: boolean;
  indicatorSrc?: string;
  storageKey?: string;
  tableKeyId?: string;
  skipKeyId?: boolean;
  gridTitle?: string;
  gridDescription?: string;
  loadPanelEnabled?: boolean;
  enableMultipleSelection?: boolean;
  dxDataGridProps?: NativeDXGridProps; // https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/
  dxDataGridComponentsProps?: DxDataGridComponentsProps;
  customCellRenderKeyProps?: CustomCellRenderKeyProps;
  summaryKeyProps?: [];
  borderTopColor?: string;
  onSelectionChanged?: (e: any) => void;
  onFocusedRowChanged?: (e: any) => void;
  children?: ReactNode;
  edit?: boolean;
  editMode?: 'batch' | 'cell' | 'row' | 'form' | 'popup';
  disableUpdating?: boolean;
  disableDeleting?: boolean;
  disableAdding?: boolean;
  EditObjectCellComponent?: any;
  onRowUpdated?: (e: { component?: any; element?: any; model?: any; data?: any; key?: any; error?: Error }) => any;
  onRowClick?: (props: any) => void;
  virtusSummaryOptions?: VirtusSummaryOptions;
  glideSummaryOptions?: GlideSummaryOptions;
  disableSelection?: boolean;
  showColumnHeaders?: boolean;
  pageSize?: number;
  pageSizeOptions?: number[];
  showPaging?: boolean;
  showGrouping?: boolean;
  showSearch?: boolean;
  showFilter?: boolean;
  hideHeaderFilter?: boolean;
  hideFilterPanel?: boolean;
  allowHeaderSearch?: boolean;
  showRefresh?: boolean;
  onRefresh?: () => void;
  showColumnChooser?: boolean;
  disableStateStoring?: boolean;
  exportFileName?: string;
  exportSheetName?: string;
  linkedColumns?: ILinkedColumn[];
  displayType?: string;
  id?: string;
  headerButtons?: any;
  moreButtonActions?: any;
  // skips the mapping and expects a valid Dx Schema
  byPassSchema?: boolean;
  // a custom function that returns a Summary
  summaryRender?: any;
  repaintChangesOnly?: boolean;
  useSessionStorage?: boolean;
  exportProps?: React.ComponentProps<typeof Export>;
  scrollingProps?: React.ComponentProps<typeof Scrolling>;
  alignment?: DxGridHeaderActionsAlign;
  excludeAlignment?: DxGridHeaderItems[];
  renderAsync?: boolean;
  schemaMapper?: (
    data: any,
    dataSourceType: DataSourceType,
    customCellRenderKeyProps?: CustomCellRenderKeyProps,
    linkedColumns?: ILinkedColumn[],
    displayType?: string,
    customComponents?: any,
  ) => DxColumn[];
  realTimeDataSource?: any;
  highlightChanges?: boolean;
  isFilterButtonLeftAligned?: boolean;
  toolbarIconsOrder?: string[];
  searchVisibleColumnsOnly?: boolean;
  customDxToolbarButtonsActions?: { [key: string]: () => any };
  autoExpandAll?: boolean;
  calculateCustomSummary?: any;
  toolbarButtonsFromConfig?: DxToolbarButton[];
  columnGrouped?: any;
  isAuditEnabled?: boolean;
  onAudit?: () => void;
}

// additional props for rendering that are not passed directly to the DataGrid
interface DataGridRenderProps {
  customComponents?: any;
}

export interface DataSource {
  data: any[];
  schema: any;
  schemaType?: SchemaType;
}

export enum SchemaType {
  GENESIS = 'Genesis',
}

export type DxDataType = 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'smalldatetime' | 'id';

// Dx interfaces should be provided by the DE-react lib. Temporary filling based on docs for now
export interface DxColumn {
  alignment?: undefined | 'center' | 'left' | 'right';
  key: string;
  dataType: any;
  dataField: any;
  // https://js.devexpress.com/Documentation/ApiReference/Common/Object_Structures/format/#type
  // eslint-disable-next-line max-len
  // format?: 'billions' | 'currency' | 'day' | 'decimal' | 'exponential' | 'fixedPoint' | 'largeNumber' | 'longDate' | 'longTime' | 'millions' | 'millisecond' | 'month' | 'monthAndDay' | 'monthAndYear' | 'percent' | 'quarter' | 'quarterAndYear' | 'shortDate' | 'shortTime' | 'thousands' | 'trillions' | 'year' | 'dayOfWeek' | 'hour' | 'longDateLongTime' | 'minute' | 'second' | 'shortDateShortTime';
  format?: any;
  cellRender?: (props: any) => React.ReactNode;
  editCellComponent?: (props: any) => React.ReactNode;
  editCellRender?: (props: any) => React.ReactNode;
  width?: any;
  minWidth?: any;
  allowEditing?: boolean;
  allowFiltering?: boolean;
  maxWidth?: any;
  accessor?: string;
  visible?: boolean;
  caption?: string;
  fixed?: boolean;
  fixedPosition?: string;
  sortOrder?: string;
  sortIndex?: number;
  value?: any;
  validationRules?: ValidationRules[];
  editProps?: {
    apiRules: APIRule[];
    parentValues: object;
  };
  lookups?: {
    endpoint?: string;
    values?: any[];
  };
  headerFilter?: any;
  calculateFilterExpression?: (filtervalue: any, selectedFilterOperation: any) => any | any[];
  calculateDisplayValue?: (props: any) => any;
  filterOperations?: any[];
  calculateCellValue?: (e: any) => void;
  objectType?: string; //This is glide custom property for setting searchType of search Field
  groupCellRender?: (props: any) => React.ReactNode;
  displayMultiplier?: string;
  isScheduleUTC?: boolean;
  customizeText?: (cellData: any) => string;
}

export type DxSummaryType = 'sum' | 'min' | 'max' | 'avg' | 'count' | 'custom';

// https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/summary/groupItems/#summaryType
export interface DxSummary {
  column: string;
  summaryType: DxSummaryType;
  name?: string;
  displayFormat?: string;
}

export interface CustomCellRenderKeyProps {
  [key: string]: { cellRender: (props: any) => React.ReactNode };
}
export interface IExtraColumnProps {
  [key: string]: any;
}
const getSummary = (
  dataSourceType: DataSourceType,
  dataSource: any,
  virtusSummaryOptions: any,
  glideSummaryOptions: any,
  calculateCustomSummary?: any,
) => {
  return dataSourceType === DataSourceType.VIRTUS
    ? getSummaryVirtus(dataSource.schema, virtusSummaryOptions || {})
    : getSummaryGlide(dataSource.schema, glideSummaryOptions || {}, calculateCustomSummary);
};

/* 📄 Columns (like summaries and other DxDataGrid props) can be defined either as props or passed
 * in the body as JSX. There are no performance impact with JSX and it provides more flexibility since
 * children can override it
 */
const getColumns = (
  dxColumns: DxColumn[],
  columnsToHide: string[] = [],
  extraColumnProps: IExtraColumnProps = {},
  EditObjectCellComponent?: React.ComponentClass<any>,
) => {
  const extraProps = {
    allowResizing: true,
    columnFixing: { enabled: true },
    allowSorting: true,
    editorOptions: { showClearButton: true },
  };
  return dxColumns.map((column: DxColumn) => {
    const _extraProps = { ...extraProps, ...(extraColumnProps[column.key] || {}) };
    const props = { ..._extraProps, ...column, ...getExtraProps(column) };
    return (
      <Column
        {...props}
        visible={columnsToHide.includes(column.key) ? false : column.visible !== undefined ? column.visible : true}
        {...(column.dataType === 'object' &&
          !column.lookups &&
          EditObjectCellComponent && {
            editCellComponent: _props => <EditObjectCellComponent props={_props} column={column} />,
          })}
      >
        {column.lookups && column.dataType === 'object' && RenderLookup(column.lookups.values)}
      </Column>
    );
  });
};

export const getExtraProps = (column: DxColumn) => {
  switch (column.dataType) {
    // This resolves the issue of the DataGrid View Crashing on Column Grouping.
    // case 'boolean':
    //   return {
    //     lookup: {
    //       dataSource: [
    //         { ID: false, Name: 'No' },
    //         { ID: true, Name: 'Yes' },
    //       ],
    //       displayExpr: 'Name',
    //       valueExpr: 'ID',
    //       calculateCellValue: (value: any) => value,
    //     },
    //   };
    case 'id':
      return {
        alignment: 'left',
        dataType: 'number',
      };
  }
  return {};
};

const showSelectedRows = (ref: React.RefObject<any>, selectedRowKeys: number[] = []) => {
  const hasAReferenceToTheGrid = ref && ref.current;
  const thereIsSelectedRows = selectedRowKeys && selectedRowKeys.length && selectedRowKeys[0] !== -1;
  if (hasAReferenceToTheGrid && thereIsSelectedRows) {
    ref.current.instance.navigateToRow(selectedRowKeys[0]);
  } else {
    // console.info('No selected row element to scroll to it');
  }
};

export const createExportObject = (e: any, worksheet: any, dataSource: any) => {
  return {
    component: e.component as dxDataGrid,
    worksheet,
    selectedRowsOnly: e.selectedRowsOnly,
    encodeExecutableContent: true, // ⚠️ Pen Test Issue Vulnerability ID 1000128532 - Formula Injection
    customizeCell: async (options: any) => {
      const { gridCell, excelCell } = options;
      if (gridCell?.rowType === 'header') {
        excelCell.font = { bold: true };
        excelCell.alignment = { horizontal: 'center' };
      }
      if (gridCell?.rowType === 'data') {
        glideExcelExport(gridCell, dataSource);
      }
    },
  };
};

export const DxDataGrid = React.memo(
  React.forwardRef<DataGrid, DxDataGridProps>(
    (
      {
        children,
        tableKeyId,
        skipKeyId = false,
        dataSource,
        dataSourceType,
        indicatorSrc = '',
        storageKey = 'storage',
        onSelectionChanged,
        customCellRenderKeyProps,
        extraColumnProps,
        dxDataGridProps = {},
        loadPanelEnabled = false,
        dxDataGridComponentsProps,
        virtusSummaryOptions,
        glideSummaryOptions,
        edit,
        editMode = 'batch',
        disableAdding = false,
        disableUpdating = false,
        disableDeleting = false,
        showColumnHeaders = true,
        pageSize = 20,
        pageSizeOptions,
        showPaging = true,
        showGrouping = true,
        showSearch = true,
        showFilter = true,
        hideFilterPanel = false,
        hideHeaderFilter = true, // In order not to force other implementations to use it, it must be explicitly declared to false
        allowHeaderSearch = false,
        showRefresh = true,
        isAuditEnabled = true,
        onAudit,
        onRefresh,
        enableLayouts = false,
        onRowUpdated,
        showColumnChooser = true,
        gridTitle,
        gridDescription,
        exportSheetName = 'Main Sheet',
        linkedColumns,
        borderTopColor,
        displayType = 'Default',
        id = '',
        headerButtons,
        moreButtonActions,
        byPassSchema = false,
        EditObjectCellComponent,
        summaryRender,
        enableMultipleSelection = false,
        // This setting has caused issues on both Glide and Genesis. It is not safe!
        // We already have virtual scrolling which is a major perf improvement so we don't need that
        repaintChangesOnly = false,
        disableSelection = false,
        customComponents,
        onRowClick,
        exportProps,
        scrollingProps,
        alignment,
        excludeAlignment,
        renderAsync = true,
        realTimeDataSource,
        highlightChanges = false,
        isFilterButtonLeftAligned,
        toolbarIconsOrder = [],
        schemaMapper,
        searchVisibleColumnsOnly = false,
        customDxToolbarButtonsActions = {},
        autoExpandAll = false,
        calculateCustomSummary,
        toolbarButtonsFromConfig = [],
        columnGrouped,
        exportFileName = 'DataGrid',
      },
      ref,
    ) => {
      if (!dataSource) return null;
      const [groupedColumns, setGroupedColumns] = useState<string[]>([]);
      const [gridCount, setGridCount] = useState(dataSource?.data?.length || 0);
      const keyId = useRef(tableKeyId || (!skipKeyId ? '_id_' : undefined));

      const dxColumns = useMemo(() => {
        const mapSchemaCallback = schemaMapper ? schemaMapper : mapSchema;
        return (
          (byPassSchema && dataSource.schema) ||
          mapSchemaCallback(
            dataSource,
            dataSourceType,
            customCellRenderKeyProps,
            linkedColumns,
            displayType,
            customComponents,
          )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        dataSource,
        dataSourceType,
        customCellRenderKeyProps,
        linkedColumns,
        displayType,
        customComponents,
        byPassSchema,
      ]);

      const columns = getColumns(
        dxColumns,
        dxDataGridProps.toolbarButtons?.columnsToHide ?? [],
        extraColumnProps,
        EditObjectCellComponent,
      );

      const {
        toolbarButtons = {
          add: [],
          remove: [],
          addAfter: [],
          addBefore: [],
        },
        addIdColumn,
        columnsToHide,
        onContentReady,
        onGroupedCallback,
        onRowCollapsed,
        selectedRowKeys,
        ...restDxDataGridProps
      } = useMemo<NativeDXGridProps | any>(() => dxDataGridProps, [dxDataGridProps]);

      const onGroupColumnClicked = (isExpanded: boolean) => {
        onGroupedCallback && onGroupedCallback(isExpanded);
      };
      const onToolbarPreparing = useMemo(
        () =>
          getOnToolbarPreparing({
            toolbarButtons,
            headerButtons,
            headerText: { title: gridTitle, description: gridDescription },
            showFilterButton: showFilter,
            showRefresh,
            onRefresh,
            enableLayouts,
            moreButtonActions,
            hideFilterPanel,
            hideHeaderFilter,
            alignment,
            excludeAlignment: excludeAlignment ? excludeAlignment : [],
            isFilterButtonLeftAligned,
            toolbarIconsOrder,
            repaintChangesOnly,
            customDxToolbarButtonsActions,
            toolbarButtonsFromConfig,
            columnGrouped,
            onGroupColumnClicked,
            isAuditEnabled,
            onAudit,
          }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          onRefresh,
          excludeAlignment,
          toolbarButtons,
          columnGrouped?.isGrouped,
          columnGrouped?.isExpanded,
          onAudit,
          isAuditEnabled,
        ],
      );

      const customExport = useCallback(
        async (e: ExportExcelParams) => {
          const excelModule = await import('exceljs');
          const workbook = new excelModule.Workbook();
          const worksheet = workbook.addWorksheet(exportSheetName);
          worksheet.properties.defaultColWidth = 30;
          _exportExcel(createExportObject(e, worksheet, dataSource)).finally(async () => {
            // write the file
            const buffer: any = await workbook.xlsx.writeBuffer();
            const fileName = `${exportFileName}.xlsx`;
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
          });

          e.cancel = true;
        },
        [dataSource],
      );

      const handleOnContentReady = useCallback(
        (e: any) => {
          /*
           * This is a fix for DXGrid Layouts due Templates are not re-rendered when group columns,
           * so we need to manage it in a custom state in order to update the Templates when group columns
           */
          if (e && enableLayouts) {
            const nextGroupedColumns = e.component
              .state()
              .columns?.filter((column: any) => column.groupIndex !== undefined)
              .map((column: any) => column.dataField);
            if (!isEqual(groupedColumns, nextGroupedColumns)) {
              setGroupedColumns(nextGroupedColumns);
            }
          }

          if (onContentReady) onContentReady(e);

          if (
            e.component?.totalCount() &&
            e.component?.totalCount() !== -1 &&
            e.component?.totalCount() !== gridCount
          ) {
            setGridCount(e.component.totalCount());
          }
        },
        [enableLayouts, gridCount, groupedColumns, onContentReady],
      );

      showSelectedRows(ref as React.RefObject<any>, selectedRowKeys);

      // summary can be provided as prop
      const summary =
        (summaryRender && summaryRender()) ||
        getSummary(dataSourceType, dataSource, virtusSummaryOptions, glideSummaryOptions, calculateCustomSummary);

      return (
        <div style={{ height: '100%', maxWidth: '100%' }}>
          <ErrorBoundary>
            <StyledDxDataGrid
              borderTopColor={borderTopColor}
              dataSource={realTimeDataSource ? realTimeDataSource : dataSource.data}
              width="100%"
              height="100%"
              keyExpr={keyId.current}
              ref={ref}
              key={dxDataGridProps.key}
              // onOptionChanged={dxDataGridProps?.onOptionChanged}
              repaintChangesOnly={true}
              highlightChanges={highlightChanges}
              onSelectionChanged={onSelectionChanged}
              onRowUpdated={onRowUpdated}
              onRowClick={onRowClick}
              onExporting={customExport}
              showRowLines={
                true // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/RowSelection/React/Light/ // Multiple selection is also possible. Useful for comparing delta // @ts-ignore: styled component wrapping created a TS error ignore
              }
              showColumnLines={true}
              rowAlternationEnabled={true}
              showBorders={true}
              showColumnHeaders={showColumnHeaders}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              cellHintEnabled={true}
              columnResizingMode="widget" // https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/#columnResizingMode // Specifies how the widget resizes columns. with the "widget", When a user resizes a column, the width of the widget changes.
              columnAutoWidth={true}
              columnHidingEnabled={false}
              loadPanel={
                {
                  enabled: loadPanelEnabled,
                  indicatorSrc,
                  height: 100,
                  maxHeight: 100,
                  width: 200,
                  maxWidth: 200,
                  // casting to any because DX's types are missing maxHeight and maxWidth are available and documented
                } as any
              }
              onToolbarPreparing={onToolbarPreparing}
              renderAsync={renderAsync}
              filterSyncEnabled={true}
              onContentReady={handleOnContentReady}
              onRowCollapsed={onRowCollapsed}
              {...restDxDataGridProps}
              id={id}
            >
              <Paging enabled={showPaging} defaultPageSize={showPaging ? pageSize : dataSource.data.length} />
              <Pager
                showPageSizeSelector={showPaging}
                visible={true}
                allowedPageSizes={pageSizeOptions}
                displayMode="full"
                showInfo={true}
                showNavigationButtons={true}
              />
              <Scrolling columnRenderingMode="virtual" {...scrollingProps} />
              {summary}
              {columns}
              <Export enabled={true} allowExportSelectedData={true} {...exportProps} />
              <Selection
                mode={disableSelection ? 'none' : enableMultipleSelection ? 'multiple' : 'single'}
                selectAllMode="allPages"
                showCheckBoxesMode="always"
              />
              <StateStoring
                enabled={true}
                storageKey={storageKey}
                type="custom"
                customSave={dxDataGridProps.customSave}
                customLoad={dxDataGridProps.customLoad}
              />
              <Sorting mode="multiple" />
              <HeaderFilter visible>
                <Search enabled={allowHeaderSearch} />
              </HeaderFilter>
              <FilterRow />
              <FilterPanel />
              <GroupPanel visible={showGrouping} emptyPanelText="Create Grouping" />
              <SearchPanel visible={showSearch} searchVisibleColumnsOnly={searchVisibleColumnsOnly} />
              <ColumnFixing enabled={true} />
              <Grouping autoExpandAll={autoExpandAll} contextMenuEnabled={showGrouping} />
              <SortByGroupSummaryInfo summaryItem="count" />
              <Editing
                mode={editMode}
                allowUpdating={edit && !disableUpdating}
                allowDeleting={edit && !disableDeleting}
                allowAdding={edit && !disableAdding}
              />
              <ColumnChooser
                height="300px"
                enabled={showColumnChooser}
                mode="select"
                {...(dxDataGridComponentsProps ? dxDataGridComponentsProps.ColumnChooser : {})}
              />
              {/* Children can override any of the previous components */}
              {children}
            </StyledDxDataGrid>
          </ErrorBoundary>
        </div>
      ) as ReactElement<DataGrid>;
    },
  ),
);

export default DxDataGrid;
