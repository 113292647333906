import React from 'react';
import styled from 'styled-components';

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: visible;
`;

interface ActionValue {
  id: string;
  onClick: () => void;
  style?: React.CSSProperties;
  dataTestId: string;
}

export const ActionsCell = ({ value }: { value: ActionValue[] }) => {
  const baseButtonStyles: React.CSSProperties = {
    padding: '5px',
    borderRadius: '2px',
    border: 'none',
    color: 'var(--text)',
    marginRight: '5px',
    cursor: 'pointer',
  };

  return (
    <Cell>
      {Object.entries(value).map(([key, actionValue]: [string, ActionValue]) => (
        <button
          data-testid={actionValue.dataTestId}
          id="action-cell"
          key={actionValue.id}
          onMouseDownCapture={actionValue.onClick}
          style={{ ...baseButtonStyles, ...actionValue.style }}
        >
          {key}
        </button>
      ))}
    </Cell>
  );
};
