import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  margin-top: 2px;
  color: var(--foreground-form);
  width: 100%;
  & div.DayPicker,
  & div.DayPicker-Caption div,
  & div.DayPicker-Weekday abbr {
    font-size: var(--calendar-font-size);
  }
  & div.DayPicker-Weekday abbr {
    color: var(--foreground-form);
  }
`;

export const modifiersStyles = {
  selected: {
    color: 'var(--background-calendar)',
    backgroundColor: 'var(--background-calendar-selected-date)',
    fontWeight: 600,
    borderRadius: 0,
  },
  today: {
    border: 'var(--foreground-calendar-today-border)',
    fontWeight: 600,
    color: 'var(--forgground-formround-form)',
  },
  disabled: { cursor: 'not-allowed', color: 'var(--modifiersDisbled)' },
};
