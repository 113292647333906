import React from 'react';
import { PositionWrapper } from './PositionCellRender.style';

interface CellColor {
  [position: string]: string;
}

interface PositionCellRenderProps {
  value: string;
}

// const lightTextColor: CellColor = {
//   ['Sell']: 'var(--errorLight)',
//   ['Buy']: 'var(--fis-green)',
// };

const darkTextColor: CellColor = {
  ['Sell']: 'var(--red)',
  ['Buy']: 'var(--dark-green)',
};

export const PositionCellRender = ({ value }: PositionCellRenderProps) => (
  <PositionWrapper color={darkTextColor[value]}>{value}</PositionWrapper>
);
