import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const SortIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <rect fill="#fff" style={{ strokeWidth: '0px', opacity: 0 }} width="19" height="19" />
      <path
        fill="#285bc5"
        style={{ strokeWidth: '0px' }}
        d="M6.38,14.6l1.99-1.99,1.05,1.05-3.79,3.79-3.75-3.79,1.03-1.05,1.99,1.99V1.56h1.48v13.04ZM8.84,2.69h8.29v-1.13h-8.29v1.13ZM8.84,5.49h7.3v-1.13h-7.3v1.13ZM8.84,7.14v1.12h6.3v-1.12h-6.3ZM8.84,9.94v1.12h5.31v-1.12h-5.31Z"
      />
    </g>
  </SvgIcon>
);

export default SortIcon;
