import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GlideObjectManagerComponent from 'src/components/glide-object-manager/components/glide-object-manager';
import { useDispatch } from 'react-redux';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TimeSeriesDatePicker } from 'src/components/time-series-date-picker/time-series-date-picker';
import * as S from './glide-time-series.style';
// import { requestAsync } from 'redux-query';
// import { getGlideTimeSeries } from 'src/api/queries/time-series/time-series';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { useMutation } from 'redux-query-react';
import DeleteIcon from '@virtus/components/icons/DeleteIcon';
// import { ComponentActionTypes } from 'src/reducers/components';
import { GlideTimeSeriesMutation } from 'src/api/mutations';
import { formattedDate } from 'src/sagas/date-input/date-input.saga';
import {
  EditableTabKeys,
  LayoutRegion,
  primaryActionConfig,
} from '../glide-object-manager/components/glide-object-manager.model';
import styled from 'styled-components';
import { creditDetailInpector } from 'src/sagas/glide-view.saga';
import { NotificationsAction } from 'src/reducers/notifications';
import { SecondaryButton as SB } from '@virtus/components/Buttons';
export interface GlideTimeSeriesProps {
  tabUri?: string;
  uri: string;
  seriesData: any;
  fields?: any[];
  isPending?: boolean;
  displayViewObj?: any;
}

interface overrideGridprops {
  allowDeleting?: boolean;
  usePagination?: boolean;
  skipkeyId?: boolean;
  ignoreDxSaveButton?: boolean;
  skipSchema?: boolean;
}

const overrideGridprops: overrideGridprops = {
  allowDeleting: false,
  usePagination: false,
  skipkeyId: true,
  ignoreDxSaveButton: true,
  skipSchema: true,
};

export const computeDayCount = (data: any): { date: any[]; count: number; freq: string } => {
  let date: any = [];
  let count = 0;
  let freq: string = 'B';
  if (data.dateType === 'single') {
    date = [...data.singleDate];
    count = date.length;
  } else if (data.dateType === 'range') {
    const toDate = data?.toDate && formattedDate(data.toDate).date;
    const fromDate = data?.fromDate && formattedDate(data.fromDate).date;

    date = toDate && fromDate ? [[fromDate, toDate]] : [];
    freq = data?.frequency;
  }
  return { date, count, freq };
};

export const SecondaryButton = styled(SB)<{ backgroundcolor?: string }>`
  border: var(--foreground-form-btn-border);
  border-radius: 17px;
  opacity: 1;
  background: var(--background-form-secondary-btn);
  color: var(--foreground-form);
  font-weight: bold;
  height: 34px;
  max-height: 34px;
  position: relative;
  bottom: 0px;
  padding: 8px 23px;
  box-shadow: none;
  &:hover {
    background: var(--background-form-secondary-btn);
    box-shadow: none;
  }
`;
export const GlideTimeSeries = ({
  tabUri,
  uri,
  seriesData,
  fields,
  isPending,
  displayViewObj,
}: GlideTimeSeriesProps) => {
  // const { datePicker } = useSelector(selectComponents);
  const [{ isPending: isUpdatePending }, updateGlideTimeSeries] = useMutation((data: any, updatedData: any) =>
    GlideTimeSeriesMutation.post(data, updatedData),
  );

  // const [searchString, setSearchString] = useState('');
  // const [dateCount, setDateCount] = useState(
  //   seriesData?.data[0] ? Object.keys(seriesData?.data[0]).length - 1 : datePicker.count,
  // );

  const _dates = useMemo(
    () => seriesData.schema.filter((item: any) => item.display_name !== 'Field').map((item: any) => item.display_name),
    [seriesData],
  );

  const [dateCount, setDateCount] = useState(seriesData?.data[0] ? Object.keys(seriesData?.data[0]).length - 1 : 0);
  const [dateDropDownOpen, setDateDropDownOpen] = useState(false);
  const [series_data, setSeriesData] = useState<any>(false);
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  // const [defaultDates, _] = useState(_dates);
  const [dates, setDates] = useState<any[]>([]);

  // TODO: Update with correct functionality
  const onRefresh = () => {};

  // useEffect(() => {
  //   // const dateColumnCount: number = seriesData?.data[0]
  //   //   ? Object.keys(seriesData?.data[0]).length - 1
  //   //   : datePicker.count;
  //   const dateColumnCount: number = seriesData?.data[0] ? Object.keys(seriesData?.data[0]).length - 1 : 0;
  //   if (dateColumnCount >= 0 && dateColumnCount !== dateCount) {
  //     setDateCount(dateColumnCount);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [seriesData?.data]);

  useEffect(() => {
    const series_data = seriesData.schema.map((item: any) => {
      if (item.display_name === 'Field') {
        return item;
      }
      return { ...item, display_name: formattedDate(new Date(item.display_name)).formattedDate };
    });
    setSeriesData({ ...seriesData, schema: [...series_data] });
    setDates(_dates);
  }, [seriesData]);

  useEffect(() => {
    setDateCount(dates.length);
  }, [dates]);

  useEffect(() => {
    setIsDatePickerVisible(false);
  }, [tabUri]);

  // const toggleDateSelector = (
  //   visible: boolean,
  //   { count, date, dateConfig }: { count: number; date: any; dateConfig: any },
  // ) => {
  //   dispatch({
  //     type: 'TOGGLE_COMPONENT_VISIBILITY' as ComponentActionTypes,
  //     payload: { componentName: 'datePicker', props: { visible, count, date, dateConfig } },
  //   });
  // };

  const inspectorOnCancel = () => {
    // toggleDateSelector(false, datePicker);
    setIsDatePickerVisible(false);
  };

  const inspectorOnApply = (data: any) => {
    // const { date, count, freq } = computeDayCount(data);
    // if (data.singleDate.length) {
    // const { date } = computeDayCount(data);
    // dispatch(requestAsync(getGlideTimeSeries({ uri, date, fields, freq })));
    // toggleDateSelector(false, { count, date, dateConfig: { ...data } });
    // const formattedDate = new Date(date[0]).toLocaleDateString(navigator.language);
    // setDates([...dates, formattedDate]);
    setDates([...data.singleDate]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onDateChange = (_: any) => {};

  const onDateButtonClick = () => {
    // if (datePicker.visible) {
    //   toggleDateSelector(false, datePicker);
    // } else {
    //   toggleDateSelector(true, datePicker);
    // }
    setIsDatePickerVisible(!isDatePickerVisible);
  };

  const onDateDropDownClose = () => {
    setDateDropDownOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onDateDropDownOpen = () => {
    // const { dateType } = datePicker.dateConfig;
    // if (datePicker.date.length > 0 && dateType !== 'range') {
    //   setDateDropDownOpen(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setDateDropDownOpen(true);
  };

  const parseGridData = (gridData: any): any => {
    const changedRows = gridData.filter((item: any) => item.modified);
    const modifiedData: any = {};

    changedRows.forEach((row: any) => {
      row.modifiedValues.forEach((modifiedValue: any, index: number) => {
        if (modifiedValue !== undefined) {
          const columnName: string = Object.keys(row.key)[index];
          if (!modifiedData[columnName]) {
            modifiedData[columnName] = {
              [row.key['Trade Field']]: modifiedValue,
              date: columnName,
            };
          } else {
            modifiedData[columnName][row.key['Trade Field']] = modifiedValue;
          }
        }
      });
    });

    return {
      changedRows: Object.values(modifiedData).map((item: any) => ({ ...item, date: `${item.date}T00:00:00` })),
      updatedData: changedRows.map((item: any) => item.data),
    };
  };

  const customizeColumns = useCallback(
    (columns: any[]) => {
      const index: number = columns.findIndex(({ dataField }: any) => dataField === 'Trade Field');

      if (index > -1) {
        columns[index].allowEditing = false;
        columns[index].customizeText = ({ value }: any) => {
          const data = fields?.find((item: any) => item.name === value);
          return data ? data.display_name : '';
        };
      }
    },
    [fields],
  );

  const handleOnGridViewChangesSaved = useCallback(({ changedRows, updatedData }: any) => {
    const hasChanges = Object.keys(changedRows).length > 0;
    if (hasChanges) {
      updateGlideTimeSeries({ [uri]: changedRows }, updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteDate = (selectedDate: string) => {
    const filteredDates = dates.filter((item: any) => item !== selectedDate);
    setDates([...filteredDates]);
    // toggleDateSelector(datePicker.visible, {
    //   ...datePicker,
    //   date: newDate,
    //   count: newDate.length,
    //   dateConfig: { ...datePicker.dateConfig, singleDate: newDate },
    // });
    // dispatch(requestAsync(getGlideTimeSeries({ uri, date: newDate, fields })));
  };

  const renderDateDropDown = () => {
    // const { dateType } = datePicker.dateConfig;
    // if (datePicker.date.length === 0 || dateType === 'range') {
    //   return null;
    // }
    // return datePicker.date.map((item: string, index: number) => {
    //   const date = new Date(`${item} UTC`).toISOString().split('T')[0];
    //   return (
    //     <DropdownMenuItem key={index} style={{ cursor: 'default' }}>
    //       <DropdownMenuItemText style={{ marginRight: '5px' }}>{date}</DropdownMenuItemText>
    //       <S.DeleteIconWrapper onClick={() => deleteDate(item)}>
    //         <DeleteIcon />
    //       </S.DeleteIconWrapper>
    //     </DropdownMenuItem>
    //   );
    // });

    return dates.map((item: any, index: number) => {
      // const date = new Date(`${item} UTC`).toISOString().split('T')[0];
      return (
        <DropdownMenuItem key={index} style={{ cursor: 'default' }}>
          <DropdownMenuItemText style={{ marginRight: '5px', margin: 'auto' }}>
            {formattedDate(new Date(item)).formattedDate}
          </DropdownMenuItemText>
          <S.DeleteIconWrapper onClick={() => deleteDate(item)}>
            <DeleteIcon />
          </S.DeleteIconWrapper>
        </DropdownMenuItem>
      );
    });
  };

  const isLoading = isPending || isUpdatePending;

  const onLoadTest = () => {
    if (dates.length) {
      let active_dates: string | Date[] = dates.map(item => {
        const date = new Date(item);
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      });
      active_dates = active_dates.map(item => new Date(item).toISOString()).join();
      const active_view = (tabUri as string).lastSplitValue('/');
      dispatch(creditDetailInpector({ instanceUri: uri, active_dates, active_view }));
    } else {
      dispatch({
        type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
        payload: {
          errorMessage: 'Please select dates !!!',
        },
      });
    }
  };

  return (
    <>
      <S.HeaderPanel>
        <S.DateFieldContainer>
          <S.DateInputContainer>
            <S.DateButton onClick={onDateButtonClick}>
              <S.CalendarIcon />
              <S.DateInput value={dateCount ? `${dateCount} Dates` : ''} readOnly />
            </S.DateButton>
          </S.DateInputContainer>
          {isDatePickerVisible && (
            <S.DatePickerWrapper>
              <TimeSeriesDatePicker
                onChange={onDateChange}
                dateObj={{ dateType: 'single', singleDate: dates }}
                inspectorOnApply={inspectorOnApply}
                inspectorOnCancel={inspectorOnCancel}
                calendarAlign="left"
                isSingleDate={false}
              />
            </S.DatePickerWrapper>
          )}
          <DropdownMenu
            onOpen={onDateDropDownOpen}
            onClose={onDateDropDownClose}
            button={
              <S.DateDropDown>
                {dateDropDownOpen ? (
                  <ArrowDropUpIcon style={{ fill: 'var(--foreground-form)' }} />
                ) : (
                  <ArrowDropDownIcon style={{ fill: 'var(--foreground-form)' }} />
                )}
              </S.DateDropDown>
            }
            keepMenuOpen={true}
          >
            {renderDateDropDown()}
          </DropdownMenu>
          <SecondaryButton
            style={{ marginLeft: '5px' }}
            data-testid="load-test-btn"
            onClick={onLoadTest}
            variant="contained"
            color="secondary"
          >
            Load Dates
          </SecondaryButton>
        </S.DateFieldContainer>
      </S.HeaderPanel>
      {isLoading ? (
        <Loading type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} show full />
      ) : (
        <S.StyledRowsWrapper>
          <GlideObjectManagerComponent
            layout={LayoutRegion.INSPECTOR}
            ref={gridRef}
            dataSource={series_data}
            loading={false}
            fieldName={EditableTabKeys.CAPITAL_STRUCTURE}
            onRefresh={onRefresh}
            showHeader={false}
            overrideGridViewProps={overrideGridprops}
            onGridViewChangesSaved={handleOnGridViewChangesSaved}
            parseGridData={parseGridData}
            customizeGridColumns={customizeColumns}
            editObjectIcons={primaryActionConfig[EditableTabKeys.CAPITAL_STRUCTURE]}
            removeIconsConfig={['addRowButton', 'groupPanel', 'refreshButton', 'exportButton', 'columnChooserButton']}
            displayViewData={{ displayViewData: displayViewObj }}
            showFilterButton={false}
            showRefreshButton={false}
            isSearchPanelLeftAligned={true}
          />
        </S.StyledRowsWrapper>
      )}
    </>
  );
};
