import {
  FormGroupsStateType,
  Form,
  FormGroupsType,
  FormFieldsType,
  FormFieldType,
  FormValuesType,
} from 'src/components/forms/form-elements/FormElement.model';
import { getFormFieldValue } from 'src/mappers/common-mapper-functions';

interface GetInitialFormState {
  formFields: any;
  isEditing: boolean;
}

// ✅ Tested
export const getInitialFormFieldsState = ({ formFields, isEditing }: GetInitialFormState): FormGroupsStateType => {
  return Object.keys(formFields).reduce(
    (acc, instance_uri: string) => {
      const field: FormFieldType = formFields[instance_uri];
      field.disabled = !isEditing;
      field.objectFieldName = instance_uri; // This is required to get glide/object data

      return {
        ...acc,
        formValues: {
          ...acc.formValues,
          [instance_uri]: getFormFieldValue({
            dataType: field.dataType,
            defaultValue: field.defaultValue,
          }),
        },
      };
    },
    { formErrors: {}, hasChanged: false, formFields, formValues: {}, formInitialChanges: {} },
  );
};

// ✅ Tested
export const getInitialFormState = ({ formGroups, isEditing }: { formGroups: FormGroupsType; isEditing: boolean }) =>
  Object.entries(formGroups).reduce((acc, [groupName, value]: [string, FormFieldsType]) => {
    return {
      ...acc,
      [groupName]: getInitialFormFieldsState({ formFields: value, isEditing }),
    };
  }, {});

// ✅ Tested
export const getInitialFormChanges = (form: Form) =>
  Object.entries(form).reduce(
    (acc, [_groupName, value]: [string, any]) => ({
      ...acc,
      ...value.formInitialChanges,
    }),
    {},
  );

const parseDefaultValuesForField = (actionArguments: any) => {
  const str = actionArguments.uri_;
  let result: any;
  const firstSplit = str?.split('?');
  if (firstSplit && firstSplit.length > 1) {
    const secondSplit = firstSplit[1]?.split('&');
    if (secondSplit && secondSplit.length) {
      const fieldsIndex = secondSplit[0].indexOf('=');
      const valuesIndex = secondSplit[1]?.indexOf('=');
      const fields = secondSplit[0].substring(fieldsIndex + 1).split(',');
      const values = secondSplit[1]?.substring(valuesIndex + 1)?.split(',');

      result = values.reduce((result: any, field: any, index: number) => {
        result[fields[index]] = field;
        return result;
      }, {});
    }
  }
  return result;
};

export const setDefaultValuesForFormField = (formState: FormValuesType, actionArguments: any) => {
  const defaultValueFields = parseDefaultValuesForField(actionArguments);
  if (defaultValueFields) {
    Object.entries(defaultValueFields).map((eachDefaultField: any) => {
      const [key, value] = eachDefaultField;
      if (
        !formState.formValues.hasOwnProperty(key) ||
        (formState.formValues.hasOwnProperty(key) && !formState.formValues[key])
      )
        formState.formValues[key] = value;
    });
  }
  return formState;
};
