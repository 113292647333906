import React, { useEffect, useState } from 'react';
import { Modal } from '@virtus/components/index';
import * as S from './marketmap-authentication-form.style';
import { MarketmapCredentialsType } from 'src/models/marketmap/marketmap.model';
import { backDropClick } from 'src/utils/constants';
import { TextInput } from 'src/components/forms/form-elements/form-fields';
import InspectorFooter from '@virtus/components/Inspector/components/InspectorFooter';

export type MarketmapAuthenticationFormPros = {
  displayDialog: boolean;
  onLivePriceTurnOff?: () => void;
  setMarketmapCredentials?: (_marketmapUser: MarketmapCredentialsType) => void;
  loggedInError?: string;
  isMarketmapConnectedToServer?: boolean;
};

const user_initial_state = { marketmapU: '', marketmapP: '', marketmapGroup: '' };

const MarketmapAuthenticationForm = React.memo(
  ({
    displayDialog,
    loggedInError,
    isMarketmapConnectedToServer,
    onLivePriceTurnOff,
    setMarketmapCredentials,
  }: MarketmapAuthenticationFormPros) => {
    const [marketmapUser, setMarketmapUser] = useState<MarketmapCredentialsType>(user_initial_state);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
      setShow(displayDialog);
    }, [displayDialog]);

    useEffect(() => {
      if (loggedInError) {
        setShow(true);
      }
    }, [loggedInError]);

    const resetForm = () => {
      setMarketmapUser(user_initial_state);
      setShow(false);
    };

    const onModalCloseHandler = (_event?: any, reason?: any) => {
      if (reason === backDropClick) return;
      resetForm();
      if (onLivePriceTurnOff) {
        onLivePriceTurnOff();
      }
    };

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (setMarketmapCredentials) {
        setMarketmapCredentials(marketmapUser);
      }
      setShow(false);
    };

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputField = event.target.name;
      setMarketmapUser({
        ...marketmapUser,
        [inputField]: event.target.value,
      });
    };

    const dialogStyle = { background: '#ffffff', color: '#1b252f' };

    return (
      <Modal
        title="Market Data Authentication"
        onClose={onModalCloseHandler}
        show={show}
        ariaLabelledBy="marketmap-authentication-form"
        isDraggable={true}
        dialogStyle={dialogStyle}
        hideFooter={true}
        fullWidth
      >
        <S.FormWrapper>
          <form
            id="marketmap-authentication-form"
            data-testid="marketmap-authentication-form"
            onSubmit={onSubmitHandler}
          >
            <TextInput
              field={{
                dataType: 'text',
                name: 'marketmapU',
                displayName: 'User Name',
                formElementType: 'string',
                required: false,
                defaultValue: undefined,
                readonly: false,
              }}
              value={marketmapUser.marketmapU}
              onChange={(e: any) => inputChangeHandler(e)}
            />
            <TextInput
              field={{
                dataType: 'text',
                name: 'marketmapP',
                displayName: 'Password',
                formElementType: 'string',
                required: false,
                defaultValue: undefined,
                readonly: false,
              }}
              value={marketmapUser.marketmapP}
              type="password"
              onChange={(e: any) => inputChangeHandler(e)}
            />
            <TextInput
              field={{
                dataType: 'text',
                name: 'marketmapGroup',
                displayName: 'User Group',
                formElementType: 'string',
                required: false,
                defaultValue: undefined,
                readonly: false,
              }}
              value={marketmapUser.marketmapGroup}
              onChange={(e: any) => inputChangeHandler(e)}
            />
            <S.ErrorBlock>
              {!isMarketmapConnectedToServer ? (
                <S.ErrorMsg>Failed to connect to marketmap terminals, please refresh page again...</S.ErrorMsg>
              ) : null}
              {loggedInError && isMarketmapConnectedToServer ? <S.ErrorMsg>{loggedInError}</S.ErrorMsg> : null}
            </S.ErrorBlock>
            <InspectorFooter
              confirmButtonText="Ok"
              onCancel={onModalCloseHandler}
              buttonsContainerStyle={{
                background: 'var(--background-modal-footer)',
                borderTop: 'var(--foreground-form-border)',
              }}
            />
          </form>
        </S.FormWrapper>
      </Modal>
    );
  },
);

export default MarketmapAuthenticationForm;
