import { ModalButton } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper/FormErrorWrapper';
import React, { useState, useEffect } from 'react';
import { Changes } from './model';
import GlideObjectManager, { GlideObjectManagerProps } from './container/glide-object-manager.container';
import * as S from './style';
import { LayoutRegion } from './components/glide-object-manager.model';
import { CONFIRMATION_DIALOG, backDropClick } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { dispatchActions } from 'src/app/store';
import GlideObjectCommentaryContainer from './container/glide-object-commentary-container';
export interface GlideObjectManagerFieldProps
  extends Omit<
    GlideObjectManagerProps,
    | 'loading'
    | 'getDataGrid'
    | 'updateDataGridRows'
    | 'deleteDataGridRows'
    | 'dataGrid'
    | 'dataGridLoading'
    | 'onBack'
    | 'gridData'
    | 'rowData'
    | 'getRowData'
  > {
  title: string;
  isTable?: boolean;
  enableEdit?: boolean;
  uris: string[] | undefined;
  tabsKey: string;
  onTabsClick: (tabsKey: any) => void;
  useGOMGrid: boolean;
  renderType?: string | null;
  selectedRowData?: { [key: string]: any };
  isLinkEnabled?: boolean;
}

export const GlideObjectManagerField = ({
  title,
  fieldName,
  actions,
  inspectors,
  uris,
  isTable,
  enableEdit,
  formProps,
  onGridViewChangesSaved,
  objectCollectionUri,
  tabsKey,
  onTabsClick,
  useGOMGrid,
  renderType = null,
  selectedRowData,
  isLinkEnabled,
}: GlideObjectManagerFieldProps) => {
  const [showModal, setShowModal] = useState(false);
  const [amountOfElements, setAmountOfElements] = useState(uris?.length ?? 0);
  const [displayType, setDisplayType] = useState<string>('preview');

  const closeGOMDialog = () => {
    setShowModal(false);
    dispatchActions.components.update('global', { isGomOpen: false });
    setDisplayType('preview');
  };

  const { DialogComponent: QuitGOM, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeGOMDialog,
    ...CONFIRMATION_DIALOG,
  });

  const onShowModal = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    setAmountOfElements(uris?.length ?? 0);
  }, [uris, uris?.length]);

  const handleOnGridViewChangesSaved = (changes: Changes) => {
    setAmountOfElements(changes.currentAmountOfItems);
    if (onGridViewChangesSaved) {
      onGridViewChangesSaved(changes);
    }
  };

  const handleCloseModal = (_event?: any, reason?: any) => {
    if (reason === backDropClick) return;
    //@ts-ignore
    if (window['gridInstances']['GOM'].current?.instance?.getController('editing')?.getChanges(true).length > 0) {
      displayConfirmationDialog();
    } else {
      closeGOMDialog();
    }
  };

  const handleExpansion = () => {
    setDisplayType('fullscreen');
  };

  return (
    <>
      <QuitGOM />
      {isTable ? (
        <TableButton fieldName={fieldName} onClick={(e?: any) => onShowModal(e)} />
      ) : (
        <TableModalFormField
          title={title}
          onClick={(e?: any) => (tabsKey ? onTabsClick({ key: tabsKey, renderType }) : onShowModal(e))}
          enableEdit={enableEdit}
          fieldName={fieldName}
          amountOfElements={amountOfElements}
          isLinkEnabled={isLinkEnabled}
        />
      )}
      <S.Dialog
        open={showModal}
        disableEnforceFocus={true}
        onClose={handleCloseModal}
        display_type={displayType}
        disableEscapeKeyDown
      >
        {!useGOMGrid ? (
          <GlideObjectCommentaryContainer
            commentaryUris={uris as any}
            creditUri={objectCollectionUri}
            componentType={title}
            onBack={handleCloseModal}
            fromColumn={true}
            handleExpansion={handleExpansion}
            isOpenFromGrid={false}
          />
        ) : (
          <GlideObjectManager
            layout={LayoutRegion.POPUP}
            actions={actions}
            inspectors={inspectors}
            fieldName={fieldName}
            enableEdit={enableEdit}
            formProps={formProps}
            selectedRowData={selectedRowData}
            objectCollectionUri={objectCollectionUri}
            onBack={handleCloseModal}
            onGridViewChangesSaved={handleOnGridViewChangesSaved}
            displayType={displayType}
            handleExpansion={handleExpansion}
          />
        )}
      </S.Dialog>
    </>
  );
};

interface GlideObjectModalFieldProps {
  title?: any;
  field: any;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
}
export const GlideObjectModalField = ({ title, field, onClick }: GlideObjectModalFieldProps) => {
  return (
    <>
      <ObjectModalFormField title={title} field={field} onClick={onClick} />
    </>
  );
};

interface TableButtonProps {
  fieldName: string;
  onClick: (e?: any) => void;
}

const TableButton = ({ fieldName, onClick }: TableButtonProps) => {
  if (!fieldName) return null;
  return (
    <S.ModalButton onClick={onClick} key={fieldName}>
      {fieldName}
    </S.ModalButton>
  );
};

interface TableModalFormField {
  title: string;
  onClick: (e?: any) => void;
  enableEdit?: boolean;
  fieldName: string;
  amountOfElements: number;
  isLinkEnabled?: boolean;
}
const TableModalFormField = ({
  title,
  onClick,
  enableEdit,
  fieldName,
  amountOfElements,
  isLinkEnabled,
}: TableModalFormField) => (
  <FormErrorWrapper error="" displayName={title} name={title} disabled={false} required={false} readonly={true}>
    {isLinkEnabled && amountOfElements == 0 ? (
      <S.ItemTextStyled>View ({amountOfElements})</S.ItemTextStyled>
    ) : (
      <ModalButton onClick={onClick} $editIsEnabled={enableEdit} title={`object-manager-button-${fieldName}`}>
        <S.ModalButtonText>View ({amountOfElements})</S.ModalButtonText>
      </ModalButton>
    )}
  </FormErrorWrapper>
);

interface ObjectModalFormField {
  title: any;
  onClick?: (e?: any) => void;
  field: any;
}
// ObjectModalFormField is used to render only object datatype fields.
const ObjectModalFormField = ({ title, onClick, field }: ObjectModalFormField) => (
  <FormErrorWrapper
    error=""
    displayName={field.displayName}
    name={title}
    disabled={field.disabled}
    required={field.required}
    readonly={field.readonly}
  >
    <ModalButton onClick={onClick} title={`${title}`}>
      <S.ModalButtonText>{title}</S.ModalButtonText>
    </ModalButton>
  </FormErrorWrapper>
);

export default GlideObjectManagerField;
