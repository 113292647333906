import { CssBaseline, GlobalStyles } from '@mui/material';
import 'css-vars-ponyfill';
import React from 'react';
import './fonts/fonts.css';
import './theme.css';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
export interface VirtusThemeProps {
  children: React.ReactNode;
}

/**
 * The Material UI theme to apply + CSS Reset
 *
 * Also fixes css import order so styled components 2nd, after material UI JSS
 *
 * Use one of first components at root of <App/>
 */

const globalStyles = {
  body: {
    fontFamily: 'var(--font-family)',
    backgroundColor: 'var(--background-app)', // also set bg after CssBaseline resets it
    overflow: 'hidden',
    scrollbarColor: '#60666d94 transparent' /* thumb and track color */,
    scrollbarWidth: 'thin',
  },
  h1: {
    fontSize: '24px',
    margin: 0,
    padding: 0,
    fontWeight: '400',
  },
  h2: {
    fontSize: '20px',
    margin: 0,
    padding: 0,
    fontWeight: '400',
  },
  h3: {
    fontSize: '16px',
    margin: 0,
    padding: 0,
    fontWeight: '400',
  },
  h4: {
    fontSize: '14px',
    margin: 0,
    padding: 0,
    fontWeight: '400',
  },
  h5: {
    fontSize: '12px',
    margin: 0,
    padding: 0,
    fontWeight: '400',
  },
  label: {
    fontSize: '13px !important',
    fontWeight: '400',
  },
  button: {
    fontSize: '14px !important',
    fontWeight: '400',
    fontFamily: 'var(--font-family)',
  },
  '.MuiInputBase-root, .MuiListSubheader-root, .MuiFormLabel-root, .MuiButton-root, .MuiToggleButton-root, .MuiMenuItem-root, .MuiAutocomplete-paper, .MuiTypography-root[data-testid="inspectorTitle"], .MuiTypography-root[data-testid="associatedObjectTitle"],.MuiListItemText-root[data-issubmenuitem="false"],.MuiTypography-root span[class^="RadioGroupInputstyle"],.dx-widget, .dx-widget input, .dx-menu-base, .dx-swatch-custom-pivot-grid .dx-menu-base': {
    fontFamily: 'var(--font-family)',
  },

  '.MuiMenuItem-root[data-testid="navigation-bar-select-menu-item"], #product-Name, .MuiListItemText-root[data-issubmenuitem="true"]': {
    fontFamily: 'var(--font-family-megamenu) !important',
    paddingRight: '10px',
  },
  a: {
    fontSize: '14px',
    fontWeight: '400',
  },
  p: {
    fontSize: '12px',
    fontWeight: '400',
  },
  table: {
    fontSize: '12px',
  },
  input: {
    fontSize: '12px',
  },
  '*::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    position: 'absolute',
  },

  '*::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#60666d94',
    borderRadius: '4px',
  },

  '*::-webkit-scrollbar-button': {
    display: 'none',
  },
  '*::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
  '.react-grid-item.react-grid-placeholder': {
    backgroundColor: `var(--accent) !important`,
  },
  '*': {
    outline: 'none',
  },
};

const VirtusTheme: React.SFC<VirtusThemeProps> = ({ children }) => (
  <>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
      <GlobalStyles styles={globalStyles} />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  </>
);

export default VirtusTheme;

// set default font-face too using JSS
// Therefore not just `<Typography>` material ui components have the nice font
// export default withStyles({
//   '@global': {
//     body: {
//       fontFamily: 'var(--font-family)',
//       backgroundColor: 'var(--background-app)', // also set bg after CssBaseline resets it
//       overflow: 'hidden',
//       scrollbarColor: '#60666d94 transparent' /* thumb and track color */,
//       scrollbarWidth: 'thin',
//     },
//     h1: {
//       fontSize: '24px',
//       margin: 0,
//       padding: 0,
//       fontWeight: '400',
//     },
//     h2: {
//       fontSize: '20px',
//       margin: 0,
//       padding: 0,
//       fontWeight: '400',
//     },
//     h3: {
//       fontSize: '16px',
//       margin: 0,
//       padding: 0,
//       fontWeight: '400',
//     },
//     h4: {
//       fontSize: '14px',
//       margin: 0,
//       padding: 0,
//       fontWeight: '400',
//     },
//     h5: {
//       fontSize: '12px',
//       margin: 0,
//       padding: 0,
//       fontWeight: '400',
//     },
//     label: {
//       fontSize: '13px !important',
//       fontWeight: '400',
//     },
//     button: {
//       fontSize: '14px !important',
//       fontWeight: '400',
//       fontFamily: 'var(--font-family)',
//     },
//     '.MuiInputBase-root, .MuiListSubheader-root, .MuiFormLabel-root, .MuiButton-root, .MuiToggleButton-root, .MuiMenuItem-root, .MuiAutocomplete-paper, .MuiTypography-root[data-testid="inspectorTitle"], .MuiTypography-root[data-testid="associatedObjectTitle"],.MuiListItemText-root[data-issubmenuitem="false"],.MuiTypography-root span[class^="RadioGroupInputstyle"]': {
//       fontFamily: 'var(--font-family)',
//     },

//     '.MuiMenuItem-root[data-testid="navigation-bar-select-menu-item"], #product-Name, .MuiListItemText-root[data-issubmenuitem="true"]': {
//       fontFamily: 'var(--font-family-megamenu) !important',
//       paddingRight: '10px',
//     },
//     a: {
//       fontSize: '14px',
//       fontWeight: '400',
//     },
//     p: {
//       fontSize: '12px',
//       fontWeight: '400',
//     },
//     table: {
//       fontSize: '12px',
//     },
//     input: {
//       fontSize: '12px',
//     },
//     '*::-webkit-scrollbar': {
//       width: '8px',
//       height: '8px',
//       position: 'absolute',
//     },

//     '*::-webkit-scrollbar-track': {
//       backgroundColor: 'transparent',
//     },

//     '*::-webkit-scrollbar-thumb': {
//       backgroundColor: '#60666d94',
//       borderRadius: '4px',
//     },

//     '*::-webkit-scrollbar-button': {
//       display: 'none',
//     },
//     '*::-webkit-scrollbar-corner': {
//       background: 'transparent',
//     },
//     '.react-grid-item.react-grid-placeholder': {
//       backgroundColor: `var(--accent) !important`,
//     },
//     '*': {
//       outline: 'none',
//     },
//   },
// })(VirtusTheme);
