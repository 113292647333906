import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';

type ClientEnvs = { client_envs: string[] };

export const get = () => ({
  url: endpoints.environments.root,
  transform: (body: ClientEnvs) => {
    return { clientEnvironments: body };
  },
  options: { method: 'GET' },
  queryKey: 'getClientEnvironments',
  update: {
    clientEnvironments: (_: ClientEnvs, next: ClientEnvs) => next,
  },
});

export const selector = (state: RootState) => state.entities.clientEnvironments;
export const pending = (state: RootState) =>
  state.queries.getClientEnvironments && state.queries.getClientEnvironments.isPending;
