import InspectorGroup from '@virtus/components/Inspector/components/InspectorGroup';
import React, { useEffect, useState } from 'react';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';
import Collapser from 'src/components/collapser/collapser';
import * as S from './view-inspector.style';
import { dispatchActions } from 'src/app/store';
import { RootState } from 'src/reducers';
import {
  selectCVCData,
  selectGlobalViewComponent,
  selectGlobalViewInspectorData,
  selectViewComponent,
} from 'src/reducers/components';
import {
  closeInspectorForm,
  discardInspectorFormChanges,
  inspectorForm,
  inspectorFormState,
  selectInspectorForm,
} from 'src/reducers/inspectorForm.reducer';
import { connect, useDispatch } from 'react-redux';
import { selectGlideObjectLoading, selectGlobalGlideObjectLoading } from 'src/api/query';
import { activeTabSelector } from 'src/reducers/tabs';
import GlideInspectorFooter from 'src/components/inspectors/glide-inspector/glide-inspector-footer';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { CONFIRMATION_DIALOG } from 'src/utils/constants';
import GlideDataContent from 'src/components/forms/glide-data-content/glide-data-content';

export const VIEW_INSPECTOR = 'viewInspector';

interface ViewInspectorProps {
  isGlobalViewInspector?: boolean;
  setIsInspectorLoading?: (visible: boolean) => void;
  onBack?: () => void;
}
interface ReduxProps {
  clientViewUri: string;
  isGlideObjectLoading: any;
  inspectorForm: inspectorFormState;
  viewInspectorData: any;
  viewInspectorComponent: any;
  hasChanged?: boolean;
  isGlobalGlideObjectLoading: boolean;
  globalViewInspectorData: any;
  globalViewInspectorComponent: any;
}

export interface InspectorReduxDispatchProps {
  discardInspectorFormChanges: (
    displayConfirmationDialog: Function,
    hideInspector: boolean,
    isGlobalViewInspectorFooter: boolean,
  ) => void;
  closeInspectorForm?: (hideInspector: boolean) => void;
  recordRelease?: any;
}

const ViewInspector = ({
  inspectorForm,
  viewInspectorData,
  clientViewUri,
  isGlideObjectLoading,
  viewInspectorComponent,
  isGlobalGlideObjectLoading,
  globalViewInspectorData,
  globalViewInspectorComponent,
  isGlobalViewInspector = false,
  closeInspectorForm,
  discardInspectorFormChanges,
  onBack,
  setIsInspectorLoading,
}: ViewInspectorProps & ReduxProps & InspectorReduxDispatchProps) => {
  viewInspectorData = isGlobalViewInspector ? globalViewInspectorData : viewInspectorData;
  viewInspectorComponent = isGlobalViewInspector ? globalViewInspectorComponent : viewInspectorComponent;
  isGlideObjectLoading = isGlobalViewInspector ? isGlobalGlideObjectLoading : isGlideObjectLoading;

  const dispatch = useDispatch();

  const [viewInspectorFormFields, setViewInspectorFormFields] = useState({});

  useEffect(() => {
    const _clientViewUri = isGlobalViewInspector ? 'global' : clientViewUri;
    setViewInspectorFormFields(inspectorForm?.uri[_clientViewUri]?.formFields);
  }, [inspectorForm, clientViewUri]);

  useEffect(() => {
    if (viewInspectorData && setIsInspectorLoading) setIsInspectorLoading(false);
  }, [viewInspectorData]);

  const onClose = () => {
    discardInspectorFormChanges(displayConfirmationDialog, true, isGlobalViewInspector);
  };

  const closeInspectorDialog = () => {
    closeInspectorForm && closeInspectorForm(true);
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  useEffect(() => {
    const _clientViewUri = isGlobalViewInspector ? 'global' : clientViewUri;
    if (!inspectorForm.uri[_clientViewUri]?.isEdit)
      dispatchActions.inspectorForm.setInspectorForm(viewInspectorData, _clientViewUri);
  }, [viewInspectorData, viewInspectorData?.uri]);

  const onChangeField = (fieldChange: any, formGroupName: string, fieldRules?: any) => {
    const _clientViewUri = isGlobalViewInspector ? 'global' : '';
    dispatchActions.inspectorForm.changeInspectorFormField(fieldChange, formGroupName, fieldRules, _clientViewUri);
  };

  if (!viewInspectorData || !viewInspectorComponent) return null;

  return (
    <>
      <QuitInspector />
      {!viewInspectorFormFields || !Object.keys(viewInspectorFormFields).length ? null : (
        <Collapser componentName={VIEW_INSPECTOR} isGlobalViewInspector={isGlobalViewInspector}>
          <S.DrawerContainer
            open
            variant="persistent"
            anchor="right"
            onClose={onClose}
            PaperProps={{
              component: S.PaperStyled as unknown,
              classes: { root: viewInspectorComponent?.isExpanded ? 'view-inspector-expanded' : '' },
            }}
          >
            {isGlideObjectLoading ? (
              <Loading show={true} type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} full />
            ) : (
              <>
                <S.InspectorWrapper>
                  <PanelHeader
                    showBackButton={isGlobalViewInspector ? true : false}
                    title={viewInspectorData.displayName}
                    onBack={onBack}
                    onClose={onClose}
                    showCloseBtn={true}
                    isExpanded={viewInspectorComponent.isExpanded === true}
                    tooltipText={viewInspectorData.displayName}
                    showTooltipBtn={false}
                    onExpand={() => {
                      if (isGlobalViewInspector) {
                        dispatch({
                          type: 'UPDATE_COMPONENT',
                          payload: {
                            componentName: 'global',
                            props: {
                              [VIEW_INSPECTOR]: { isExpanded: !viewInspectorComponent.isExpanded, viewInspectorData },
                            },
                          },
                        });
                      } else {
                        dispatchActions.components.updateView(VIEW_INSPECTOR, clientViewUri, {
                          isExpanded: !viewInspectorComponent.isExpanded,
                          viewInspectorData,
                        });
                      }
                    }}
                  />
                  <S.RowsWrapper data-testid={viewInspectorData.uri}>
                    {viewInspectorData?.data &&
                      Object.entries(viewInspectorData.data).map(([formGroupName, value]) => (
                        <InspectorGroup isExpanded title={formGroupName} key={formGroupName}>
                          <GlideDataContent
                            dataTestId={`${formGroupName}-view-inspector`}
                            isEditing={
                              isGlobalViewInspector
                                ? inspectorForm?.uri['global']?.isEdit
                                : inspectorForm?.uri[clientViewUri]?.isEdit
                            }
                            optionalStyles={{ noPadding: false, noBackground: false }}
                            uri={viewInspectorData.uri}
                            hideFooter
                            onChangeField={onChangeField}
                            form={
                              isGlobalViewInspector
                                ? (inspectorForm?.uri['global'] as inspectorForm)
                                : (inspectorForm?.uri[clientViewUri] as inspectorForm)
                            }
                            formName={formGroupName}
                            formData={value}
                          />
                        </InspectorGroup>
                      ))}
                  </S.RowsWrapper>
                </S.InspectorWrapper>
                <GlideInspectorFooter
                  actions={viewInspectorData.displayViewData?.actions}
                  workflowActions={viewInspectorData.displayViewData?.workflow_transitions}
                  targetInstance={viewInspectorData.displayViewData?.instance}
                  isGlobalViewInspectorFooter={isGlobalViewInspector}
                  isExpanded={false}
                  viewInspectorData={viewInspectorData}
                  isViewInspector={true}
                />
              </>
            )}
          </S.DrawerContainer>
        </Collapser>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  inspectorForm: selectInspectorForm(state),
  clientViewUri: activeTabSelector(state),
  isGlideObjectLoading: selectGlideObjectLoading(state),
  isGlobalGlideObjectLoading: selectGlobalGlideObjectLoading(state),
  // TODO: Defines types for fetched data store name
  viewInspectorData: selectCVCData(state, VIEW_INSPECTOR),
  globalViewInspectorData: selectGlobalViewInspectorData(state, VIEW_INSPECTOR),
  viewInspectorComponent: selectViewComponent(state, VIEW_INSPECTOR),
  globalViewInspectorComponent: selectGlobalViewComponent(state, VIEW_INSPECTOR),
});

const mapDispatchToProps = (dispatch: any): InspectorReduxDispatchProps => ({
  discardInspectorFormChanges: (
    displayConfirmationDialog: Function,
    hideInspector: boolean,
    isGlobalViewInspectorFooter: boolean,
  ) => dispatch(discardInspectorFormChanges(displayConfirmationDialog, hideInspector, isGlobalViewInspectorFooter)),
  closeInspectorForm: (hideInspector: boolean) => dispatch(closeInspectorForm(hideInspector)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewInspector);
