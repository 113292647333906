import React, { useState, useEffect, useRef } from 'react';
import GlideCommentaryComponent, { GlideCommentaryProps } from '../glide-commentary/glide-commentary';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Glide, GlideCommentaryApi } from 'src/api/queries';
import { RootState } from 'src/reducers';
import * as GlideContainerStyle from './glide-list-container-style';
import { sortByParams } from '../glide-commentary-list/glide-commentary-list-mapper';
import { requestAsync } from 'redux-query';
import { selectComponents } from 'src/reducers/components';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import * as S from '../glide-commentary-list/glide-commentary-list.style';
import { randomStringKey } from 'src/utils/common';
import { formatUserName } from 'src/utils/formatters';
import { groupByGlideList, placeholder, searchExpr, sortByGlideList } from './glide-list-constants';
import GlideCommentaryList from '../glide-commentary-list/glide-commentary-list-component';
import { ANALYST_COMMENT_TYPE_REQUEST_MAP } from '../glide-commentary/glide-commentary.util';
import { CONFIRMATION_DIALOG } from '@virtus/components/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { dispatchActions } from 'src/app/store';

export interface GlideListContainerProps {
  uri: string;
  componentType?: string;
  fromModal?: boolean;
  fromColumn?: boolean;
}
export interface ReduxProps {
  readonly commentaryData: any;
  readonly isPendingGlideCommentary: boolean;
  readonly isNewCommentaryPending: boolean;
  readonly creditDetailUri: any;
  readonly isCommentaryDirty: boolean;
}
export interface ReduxDispatch {
  updateAnalystComments?: (data: any) => void;
}
const GlideCommentaryDefaultValue: Omit<GlideCommentaryProps, 'fromModal' | 'creditUri'> = {
  title: '',
  displayname: '',
  username: '',
  commentdate: 0,
  category: '',
  comments: '',
};
const GlideListContainerComponent = ({
  uri,
  commentaryData,
  isPendingGlideCommentary,
  updateAnalystComments,
  isNewCommentaryPending,
  creditDetailUri,
  componentType,
  fromModal = false,
  fromColumn = false,
  isCommentaryDirty = false,
}: GlideListContainerProps & ReduxProps & ReduxDispatch) => {
  console.info(updateAnalystComments);
  const [commentEditor, setCommentEditor] = useState(false);
  const [commentaryDetails, setCommentaryDetails] = useState(sortByParams('desc', commentaryData, 'commentdate'));
  const sortData = () => {
    const sorted = sortByParams('desc', commentaryData, 'commentdate');
    return sorted[0];
  };
  const [GlideCommentaryDefaultProps, setGlideCommentaryDefaultProps] = useState(
    commentaryData && commentaryData.length > 0 ? sortData() : GlideCommentaryDefaultValue,
  );
  const { creditDetailInspector } = useSelector(selectComponents);
  const createNewCommentEditor = (showEditor: any) => {
    if (commentEditor) return;
    if (isCommentaryDirty) {
      const action = { editor: showEditor };
      displayConfirmationDialog(action);
    } else {
      setPrevSelectedItemIndex(selectedItemIndex);
      setSelectedItemIndex(-1);
      setCommentEditor(showEditor);
    }
  };
  const ref = useRef(null);
  const [prevSelectedItemIndex, setPrevSelectedItemIndex] = useState<number>(-1);
  useEffect(() => {
    if (commentaryData && commentaryData.length) {
      const sortedData = sortByParams('desc', commentaryData, 'commentdate');
      setCommentaryDetails(sortedData);
      setSelectedItemIndex(sortedData[0].id);
      setPrevSelectedItemIndex(sortedData[0].id);
      ref.current = commentaryData;
      setGlideCommentaryDefaultProps(sortedData[0]);
    } else {
      setGlideCommentaryDefaultProps(GlideCommentaryDefaultValue);
    }
  }, [commentaryData, commentaryData.length]);
  const updateComments = (commentaryDataCallbackData: any) => {
    if (!ref?.current && (!commentaryData || commentaryData.length === 0)) {
      return;
    }

    const convertModelCommentaryData: any = {};
    const commentaryDataObject = ref?.current || [...commentaryData];

    commentaryDataObject.forEach((cd: any) => {
      convertModelCommentaryData[cd.commenturi] = {
        analyst: cd.username,
        analyst_comment:
          cd.commenturi === commentaryDataCallbackData.uri ? commentaryDataCallbackData.htmlEditorData : cd.comments,
        analyst_comment_type: cd.category,
        analyst_comment_date: new Date(cd.commentdate).toLocaleString(),
        uri: cd.commenturi,
        display_name:
          cd.commenturi === commentaryDataCallbackData.uri ? commentaryDataCallbackData.displayname : cd.displayname,
        credit_details: cd.creditdetails,
      };
    });

    if (convertModelCommentaryData && creditDetailUri) {
      const CreditDetailsUpdate = {
        object_uri: creditDetailUri,
        [ANALYST_COMMENT_TYPE_REQUEST_MAP[
          commentaryDataCallbackData.category as keyof typeof ANALYST_COMMENT_TYPE_REQUEST_MAP
        ]]: { ...convertModelCommentaryData },
      };

      updateAnalystComments && updateAnalystComments(CreditDetailsUpdate);
    }
  };

  const commentaryDataCallback = (commentaryDataCallbackData?: any) => {
    setCommentEditor(false);
    setSelectedItemIndex(prevSelectedItemIndex);
    if (commentaryDataCallbackData) updateComments(commentaryDataCallbackData);
  };
  const closeCommentaryDialog = (action: any) => {
    dispatchActions.components.update('isCommentaryDirty', { dirty: false });
    if (action.editor) {
      setCommentEditor(action.editor);
      setPrevSelectedItemIndex(selectedItemIndex);
      setSelectedItemIndex(-1);
    } else {
      onSelectedItem(action.arguments);
      setSelectedItemIndex(action.arguments.id);
      setCommentEditor(false);
      setGlideCommentaryDefaultProps(action.arguments);
    }
  };

  const { DialogComponent: QuitCommentary, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeCommentaryDialog,
    ...CONFIRMATION_DIALOG,
  });
  const onSelectedItem = (args: any) => {
    if (!isCommentaryDirty) {
      setCommentEditor(false);
      setGlideCommentaryDefaultProps(args);
    }
  };
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const onSelection = (args: any) => {
    if (isCommentaryDirty) {
      const action = { arguments: args };
      displayConfirmationDialog(action);
    } else {
      onSelectedItem(args);
      setSelectedItemIndex(args.id);
      setPrevSelectedItemIndex(args.id);
    }
  };
  const shorten = (str: string, maxLen: number, separator: string = ' ') => {
    if (str.length <= maxLen) return str;
    return `${str.substr(0, str.lastIndexOf(separator, maxLen))}...`;
  };
  let leftDataTemplate: JSX.Element[] = [];
  let rightDataTemplate: JSX.Element[] = [];
  const ItemTemplate = (data: any) => {
    Object.entries(data).forEach(([key, value]: any) => {
      const randomKey = randomStringKey(64, 'Aa#');
      switch (key) {
        case 'displayname':
          leftDataTemplate.push(
            <S.BlogTitleWrapper key={randomKey} sortOrder={1}>
              {value ? value.toString() : ''}
            </S.BlogTitleWrapper>,
          );
          break;
        case 'username':
          leftDataTemplate.push(
            <S.BlogAuthorWrapper key={randomKey} sortOrder={2}>
              {value ? formatUserName(value.toString()) : ''}
            </S.BlogAuthorWrapper>,
          );
          break;
        case 'comments':
          leftDataTemplate.push(
            <S.BlogCommentsWrapper key={randomKey} sortOrder={3}>
              {value ? shorten(value.replace(/<[^>]+>/g, '').toString(), 150) : ''}
            </S.BlogCommentsWrapper>,
          );
          break;
        case 'category':
          rightDataTemplate.push(<S.TagStyle key={randomKey}>{value ? value.toString() : ''}</S.TagStyle>);
          break;
        case 'commentdate':
          rightDataTemplate.push(
            <S.DateStyle key={randomKey}>
              {value ? new Date(value).toLocaleString(navigator.language) : ''}
            </S.DateStyle>,
          );
          break;
      }
    });

    const leftSectionSorted = leftDataTemplate.sort((a, b) => {
      if (a.props.sortOrder < b.props.sortOrder) {
        return -1;
      }
      if (a.props.sortOrder > b.props.sortOrder) {
        return 1;
      }
      return 0;
    });
    const leftSection = leftSectionSorted.map((ldt: JSX.Element) => ldt);
    const rightSection = rightDataTemplate.map((ldt: JSX.Element) => ldt);
    leftDataTemplate = [];
    rightDataTemplate = [];
    return (
      <S.BlogSection key={randomStringKey(64, '#Aa')} onClick={() => onSelection(data)}>
        {/* {data.id == selectedItemIndex && <S.SelectedItemStyle />} */}
        <S.SelectedItemStyle isActive={data.id == selectedItemIndex} />
        <S.BlogLeftSection>{leftSection}</S.BlogLeftSection>
        <S.BlogRightSection>{rightSection}</S.BlogRightSection>
      </S.BlogSection>
    );
  };

  return (
    <>
      <QuitCommentary />
      {isPendingGlideCommentary || isNewCommentaryPending ? (
        <Loading type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} show full />
      ) : (
        <GlideContainerStyle.GlideListWrapper>
          <GlideCommentaryList
            itemTemplate={ItemTemplate}
            searchExpr={searchExpr}
            commentaryData={commentaryDetails}
            groupByDropDownList={groupByGlideList}
            defaultSortBy={{ field: 'commentdate', orderBy: 'desc' }}
            sortByDropDownList={sortByGlideList}
            onSelectedItem={onSelectedItem}
            setSelectedItemIndex={index => setSelectedItemIndex(index)}
            isSearchEnabled={true}
            createNewCommentEditor={createNewCommentEditor}
            actionButtonText="Add Comment"
          />
          <GlideCommentaryComponent
            {...GlideCommentaryDefaultProps}
            addComment={commentEditor}
            placeholder={placeholder}
            componentType={componentType}
            commentaryDataCallback={commentaryDataCallback}
            fromModal={fromModal}
            creditUri={fromColumn ? creditDetailUri : uri}
            inspectorOpen={creditDetailInspector.isVisible}
            isCommentaryDirty={isCommentaryDirty}
          />
        </GlideContainerStyle.GlideListWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { componentType } = ownProps;
  return {
    commentaryData: GlideCommentaryApi.CommentarySelector(state, componentType),
    isPendingGlideCommentary: GlideCommentaryApi.isPending(state),
    isNewCommentaryPending: GlideCommentaryApi.isNewCommentaryPending(state),
    creditDetailUri: Glide.ggselector(state, 'credit_detail'),
    isCommentaryDirty: GlideCommentaryApi.isCommentaryDirty(state),
  };
};
const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  updateAnalystComments: (data: any) =>
    dispatch(requestAsync(GlideCommentaryApi.updateAnalystCommentsCreditDetails(data))),
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(GlideListContainerComponent);
