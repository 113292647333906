import { IDPConfig } from 'src/auth/index';
import axios from 'axios';
import * as queryString from 'query-string';

let idpConfig: any;

const logoutChannel = new BroadcastChannel('logout');

logoutChannel.onmessage = (event: MessageEvent) => {
  if (event.data === 'logout') {
    logoutRedirect(true);
    logoutChannel.close();
  }
};

export interface TokenInformation {
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  LoginName: string;
  aud: string;
  exp: number;
  extension_Username: string;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  sub: string;
}

let tokenInformation: TokenInformation;

export const initIDPLogin = (config: IDPConfig) => {
  idpConfig = config;
};

export const loginRedirect = () => {
  const idpURL = createRedirectUrl(
    idpConfig.baseURL,
    idpConfig.firm,
    idpConfig.responseType,
    idpConfig.clientId,
    idpConfig.scope,
  );
  window.location.href = idpURL;
};

export const getIDPToken = async (code: any, config: IDPConfig): Promise<any> => {
  const url = `${config.baseURL}/${config.firm}/rest/issueToken/accesstoken`;
  const data = {
    client_id: config.clientId,
    client_secret: config.clientSecret,
    grant_type: 'authorization_code',
    code: code,
  };

  return await axios
    .post(url, queryString.stringify(data), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(`Error fetching IDP token: ${error}`, error);
      return null;
    });
};

export const logoutRedirect = (isBroadcastLogout = false) => {
  !isBroadcastLogout && logoutChannel.postMessage('logout');
  const logOutURL = createLogoutUrl(idpConfig.baseURL, idpConfig.firm, idpConfig.clientId);
  window.location.href = logOutURL;
};

// create the redirect URL in IDP understandable format
const createRedirectUrl = (baseURL: string, firm: string, responseType: string, clientId: string, scope: string) => {
  return `${baseURL}/${firm}?response_type=${responseType}&client_id=${clientId}&scope=${scope}`;
};

// create the logout URL in IDP understandable format
const createLogoutUrl = (baseURL: string, firm: string, clientId: string) => {
  return `${baseURL}/${firm}/globalLogout.html?client_id=${clientId}&t=${Date.now()}`;
};

export const setDecodedTokenInfo = (token: TokenInformation) => {
  tokenInformation = token;
};

export const getDecodedTokenInfo = (): TokenInformation => {
  return tokenInformation;
};
