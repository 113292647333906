import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions, IDPConfig } from 'src/auth';
import { initIDPLogin, loginRedirect, getIDPToken, logoutRedirect } from '../idpWrapper';
import * as queryString from 'query-string';
import { getType } from 'typesafe-actions';
import mockUser from 'src/utils/mockUser';
import { PayloadAction } from 'typesafe-actions/dist/types';
import { IDPUser } from '../reducer';
import jwt_decode from 'jwt-decode';
import { setDecodedTokenInfo } from '../idpWrapper';
import { Guid } from 'guid-typescript';
import axios from 'axios';

const RETURN_URL_KEY = 'RETURN_URL';
let globalToken = '';

function idpDoLogin(requestConfig: any): Promise<any> | null {
  const rowKey = Guid.create();
  const token = getToken();
  const config = {
    headers: { Authorization: `Bearer ${token}`, 'x-useidp': 'true' },
  };
  const gatewayIp: string = `0.0.0.0`;
  const localIp: any = ``;

  try {
    // Wrapped DoLogin API call in a try / catch in case it fails and triggers an authentication loop
    return axios.post(
      requestConfig[0].userLoginApiUrl,
      {
        Parameters: {
          PartitionKey: requestConfig[0].appName,
          RowKey: rowKey[`value`],
          UserName: requestConfig[0].emailAddress,
          LoginTimeStamp: new Date(),
        },
        MessageType: 'User Login',
        GatewayIp: gatewayIp,
        LocalIp: localIp,
      },
      config,
    );
  } catch (err) {
    console.error('DoLogin call failed');
    console.error(err);
    return null;
  }
}

function* initialize(config: IDPConfig): any {
  try {
    yield call(initIDPLogin, config);
    const deviceCode = queryString.parse(location.search);
    if (deviceCode && deviceCode.code) {
      const user: IDPUser = { name: '' };
      const isValidUser = false;
      const code = deviceCode.code;
      yield put(authActions.loginSuccess({ user, code, isValidUser }));

      // return to the requested url before login redirect (if set)
      const returnUrl = sessionStorage.getItem(RETURN_URL_KEY);
      if (returnUrl && returnUrl.length > 0) {
        history.replaceState(null, document.title, returnUrl);
      } else {
        history.replaceState(null, document.title, '/');
      }

      const token = yield call(getIDPToken, code, config);
      if (token && token.id_token) {
        const id_token = token.id_token;
        yield call(setToken, id_token);
        yield put(authActions.getAccessToken({ id_token }));

        const decodeToken: any = jwt_decode(token.id_token);
        user.name = decodeToken.FirstName + ' ' + decodeToken.LastName;
        yield call(setDecodedTokenInfo, decodeToken);
        const requestObj = [
          {
            userLoginApiUrl: config.userLoginApiUrl,
            appName: config.appName,
            emailAddress: decodeToken.EmailAddress,
          },
        ];
        const success = yield call(idpDoLogin, requestObj);
        if (success.data.isSuccess) {
          yield put(authActions.validateUser({ isValidUser: true, emailAddress: decodeToken.EmailAddress, user }));
        }
      }
    }
  } catch (e) {
    yield put(authActions.loginError(e));
  } finally {
    sessionStorage.removeItem(RETURN_URL_KEY);
  }
}

export function getToken(): string {
  return globalToken;
}

function setToken(token: string): void {
  globalToken = token;
}

function* authInit(initializeAction: PayloadAction<string, any>) {
  if (__USE_MOCK_DATA__) {
    yield put(
      authActions.loginSuccess({ user: mockUser, token: '123', isValidUser: true, glide: { token: 'glideToken-123' } }),
    );
  } else {
    yield call(initialize, initializeAction.payload);
  }
  yield put(authActions.initialized());
}

function* idpLogin() {
  if (__USE_MOCK_DATA__) {
    yield put(
      authActions.loginSuccess({ user: mockUser, token: '123', isValidUser: true, glide: { token: 'glideToken-123' } }),
    );
  } else {
    sessionStorage.setItem(RETURN_URL_KEY, location.href);
    loginRedirect();
  }
}

function* idpLogout() {
  if (__USE_MOCK_DATA__) {
    yield put(authActions.logout());
  } else {
    logoutRedirect();
  }
}

function* watchAuthInitialize() {
  if (typeof Cypress !== 'undefined') {
    const auth = Cypress.env('auth');
    yield put(authActions.initialized());
    yield put(authActions.loginSuccess({ user: mockUser, token: auth.access_token }));
    return auth.access_token;
  }
  yield takeLatest(getType(authActions.initialize), authInit);
}

function* watchIDPLogin() {
  yield takeLatest(getType(authActions.login), idpLogin);
}

function* watchIDPLogout() {
  yield takeLatest(getType(authActions.logout), idpLogout);
}

export default [watchAuthInitialize, watchIDPLogin, watchIDPLogout];
