import { RootState } from 'src/reducers/rootReducer';
import { Action } from 'redux';

export interface Inspector {
  uri: string;
  isActive: boolean;
}

export interface inspectorstate {
  moduleInspectors: Inspector[];
  activeUri: string;
  inspector_history: string[];
}

export interface inspectorStateItem {
  [key: string]: inspectorstate;
}
export interface inspectorViewState {
  views: inspectorStateItem;
}

interface inspectorActionType extends Action {
  uri: string;
  inspector_id: string;
  configUri: string;
}

const initialItemState: inspectorstate = {
  moduleInspectors: [],
  activeUri: '',
  inspector_history: [],
};

const initialState: inspectorViewState = {
  views: {},
};

export enum InspectorsAction {
  OPEN_INSPECTOR = 'OPEN_INSPECTOR',
  CLOSE_INSPECTOR = 'CLOSE_INSPECTOR',
  RESET_INSPECTOR_HISTORY = 'RESET_INSPECTOR_HISTORY',
}

const updatedStateAfterOpen = (viewState: any, action: any) => {
  const uri = action.uri;
  let moduleInspectorsCopy = [...viewState.moduleInspectors];
  moduleInspectorsCopy = moduleInspectorsCopy.map(inspector => ({
    ...inspector,
    isActive: false,
  }));
  const index = moduleInspectorsCopy.findIndex(inspector => inspector.uri === uri);
  if (index === -1) {
    const newinspector = {
      uri,
      isActive: true,
    };
    return {
      ...viewState,
      moduleInspectors: [...moduleInspectorsCopy, newinspector],
      activeUri: uri,
      inspector_history: [...viewState.inspector_history, action.uri],
    };
  } else {
    moduleInspectorsCopy[index].isActive = true;
    return {
      ...viewState,
      moduleInspectors: moduleInspectorsCopy,
      activeUri: uri,
    };
  }
};

const updatedStateAfterClose = (viewState: any) => {
  const newInspectors = [...viewState.moduleInspectors];
  let newuri: string = '';
  const index = newInspectors.findIndex((el: Inspector) => el.isActive);

  newInspectors.splice(index, 1);
  if (newInspectors.length > 0) {
    const lastItemIndex = newInspectors.length - 1;
    newInspectors[lastItemIndex].isActive = true;
    newuri = newInspectors[lastItemIndex]?.uri;
  }
  const newHistory = [...viewState.inspector_history];
  newHistory.pop();
  return {
    ...viewState,
    moduleInspectors: newInspectors,
    activeUri: newuri,
    inspector_history: [...newHistory],
  };
};

export const inspectors = (state: inspectorViewState = initialState, action: inspectorActionType) => {
  const view = action?.configUri?.lastSplitValue();

  switch (action.type) {
    case InspectorsAction.OPEN_INSPECTOR: {
      if (!state.views[view]) {
        state.views[view] = {
          moduleInspectors: [],
          activeUri: '',
          inspector_history: [],
        };
      }
      return {
        ...state,
        views: {
          ...state.views,
          [view]: updatedStateAfterOpen(state.views[view], action),
        },
      };
    }
    case InspectorsAction.CLOSE_INSPECTOR: {
      return {
        ...state,
        views: {
          ...state.views,
          [view]: updatedStateAfterClose(state.views[view]),
        },
      };
    }
    case InspectorsAction.RESET_INSPECTOR_HISTORY: {
      return {
        ...state,
        views: {
          ...state.views,
          [view]: { ...initialItemState },
        },
      };
    }
    default:
      return state;
  }
};

export const inspectorSelector = (state: RootState, configUri: string) =>
  state.inspectors.views[`${configUri.lastSplitValue()}`] || initialItemState;
