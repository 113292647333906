import styled from 'styled-components';

export const GroupHeading = styled.div`
  padding: 2%;
  font-size: var(--groupHeadingFontSize);
  color: var(--white);
  font-weight: bold;
  background-color: var(--listGroupHeading);
`;

export const MessagesMainWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

export const MessagesListWrapper = styled.h4`
  padding: 1% 2% 1% 2%;
  color: var(--white);
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
`;
