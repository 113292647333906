import formatDate from '@virtus/common/utils/dateFormater';
import React, { SyntheticEvent, useEffect } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './Calendar.css';
import YearMonthForm from './YearMonthForm';
import { CalendarWrapper, modifiersStyles } from './Calendar.style';
import { getYearMonthDay } from './constants';

export enum CalendarErrors {
  INVALID_DATE = 'Invalid Date',
  EMPTY_DATE = '--/--/--',
}

export interface DateFilterProps {
  value?: Date | string;
  style?: { [key: string]: React.CSSProperties };
  onChange: (v: string) => void;
  afterDisableDate?: Date;
  beforeDisableDate?: Date;
  setFocus?: any;
  openCalendar: any;
}

const Calendar: React.FunctionComponent<DateFilterProps> = ({
  value,
  style = {},
  onChange,
  afterDisableDate,
  beforeDisableDate,
  setFocus,
  openCalendar,
}) => {
  const [month, SetMonth] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth()));

  useEffect(() => {
    if (value && value !== 'Invalid Date') {
      const { year, month, day } = getYearMonthDay(value);
      SetMonth(new Date(year, month, day));
    }
  }, [value]);

  const handleDateChange = (newDate: Date, modifiers: any, e: SyntheticEvent) => {
    e.stopPropagation();
    if (modifiers.disabled) return;

    const _date = formatDate(newDate);
    onChange(_date);
  };

  // Prevent error if the value is not a valid date
  if (value && typeof value !== 'string' && !value.getTime()) return null;

  const calendarValue: Date | undefined =
    (typeof value === 'string' && value === CalendarErrors.INVALID_DATE) || !value ? undefined : new Date(value);

  const disabledDays =
    afterDisableDate || beforeDisableDate ? { after: afterDisableDate, before: beforeDisableDate } : undefined;

  const handleOnFocus = () => {
    setFocus && setFocus(true);
  };

  const handleYearMonthChange = (e: SyntheticEvent, year: number, month: number) => {
    e.stopPropagation();
    SetMonth(new Date(year, month));
    openCalendar(e, true);
  };

  const handleDayClick = (newDate: Date, modifiers: any, e: SyntheticEvent) => {
    handleDateChange(newDate, modifiers, e);
    openCalendar(e, false);
  };

  return (
    <CalendarWrapper data-testid="calendar" style={style.wrapper}>
      <DayPicker
        showOutsideDays
        onDayClick={handleDayClick}
        onFocus={handleOnFocus}
        month={month}
        modifiersStyles={modifiersStyles}
        selectedDays={[calendarValue]}
        disabledDays={disabledDays as any}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
        )}
      />
    </CalendarWrapper>
  );
};

export default Calendar;
