/* tslint:disable:max-line-length */
/**
 * Set Components props in app
 *
 * Type of components:
 *  - static components: most components except view components
 *  - view components: inspector, layout manager. States are saved for the client view
 *
 */
import { RootState } from 'src/reducers/rootReducer';
import { clone, merge, set } from 'lodash';
import { DateConfig } from 'src/components/date-picker/date-picker';
import { GlideObject } from 'src/models/glide/glideObject';
import { WebLayoutPermission } from 'src/api/layouts.selector';
import { GlideLayoutData } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
// import { ActionForm } from 'src/sagas/form.saga';

// New components need to be registered
export type ComponentName =
  | 'actionForm'
  | 'bottomPanel'
  | 'bottomPanelExpandedState'
  | 'columnGrouped'
  | 'columnManagerInspector'
  // | 'creditDetailsInspector'
  | 'datePicker'
  | 'dateInput'
  | 'dealsDetailInspector'
  | 'dealsInspector'
  | 'form'
  | 'global'
  | 'inspector'
  | 'viewInspector'
  | 'modalInspector'
  | 'creditDetailInspector'
  | 'layoutManager'
  | 'livePriceToggleState'
  | 'marketmapCredentials'
  | 'searchInspector'
  | 'showLayoutManagerInspector' // TODO: Remove showLayoutManagerInspector & datePicker once we get rid of hard coded pages/views
  | 'summaryPanel'
  | 'viewComponents'
  | 'hypoScenario'
  | 'newObject'
  | 'currentObjectInView' // Currently selected uri. Default for all actions
  | 'globalActions'
  | 'gridLayout'
  | 'baseLineDate'
  | 'currentDate'
  | 'fundSelector'
  | 'marketMap'
  | 'auditTrail'
  | 'recordLocked'
  | 'commentary';

export type ComponentPropsType = {
  [key in ComponentName]: any;
};

export interface ComponentProps {
  components: ComponentPropsType;
}

export interface ComponentPayload {
  componentName: ComponentName;
  clientViewUri?: string;
  props?: {
    [key: string]: any;
  };
  visible?: boolean;
  view?: { [uri: string]: object };
}

export type ComponentActionTypes =
  | 'OPEN'
  | 'CLOSE'
  | 'UPDATE_COMPONENT'
  | 'UPDATE_COMPONENT_VIEW'
  | 'SET_COMPONENT_VIEW'
  | 'TOGGLE_COMPONENT_VISIBILITY'
  | 'TOGGLE_VIEW_COMPONENT_VISIBILITY'
  | 'RESET'
  | 'RESET_COMPONENT';

export interface DatePickerProps {
  date: any[];
  dateConfig: DateConfig;
  visible: boolean;
  count: number;
}

export interface DateInputProps {
  separator: string;
  dateStringLength: number;
  placeholder: string;
}
export interface FundSelectorComponentProps {
  visible: boolean;
}

export interface ComponentGridLayout {
  selectedLayout?: GlideLayoutData;
  permissions?: WebLayoutPermission;
  filteredLayouts?: GlideLayoutData[];
  webLayouts?: any;
}

export interface ComponentPivotGridLayout extends ComponentGridLayout {
  searchText?: string;
  fieldCount?: number;
}

export interface ModalInspectorProps {
  [key: string]: { isVisible: boolean };
}

export interface CreditDetailInspectorProps {
  isVisible: boolean;
}

export interface CurrentObjectInView {
  uri: string;
}
export interface auditTrail {
  data: any;
  schema: any;
  visible: any;
  title: string;
}
export interface BottomPanel {
  isVisible: boolean;
  instanceUri: string;
  clientViewUri: string;
}

export interface Components {
  actionForm: { visible: boolean; actionUri: string };
  newObject: { visible: boolean; actionUri: string };
  activeClientViewGrid: {};
  bottomPanel: BottomPanel;
  bottomPanelExpandedState: { isExpanded: boolean };
  columnGrouped: { isExpanded: boolean; isGrouped: boolean };
  columnManagerInspector: { visible: boolean; props: {} };
  contextMenu: { visible: boolean };
  // creditDetailsInspector: { visible: boolean; tabs: any[] };
  dashboard: { dashboardId: string };
  datePicker: DatePickerProps;
  dateInput: DateInputProps;
  dealsDetailInspector: { visible: boolean };
  dealsInspector: { visible: boolean };
  form: {};
  objects: GlideObject[];
  global: {
    [key: string]: any;
    importInstrumentOverlay: { visible: boolean };
    orders: {
      rowData: any;
      active: boolean;
      disabled: boolean;
      displayOverlay: boolean;
    };
    currentClientViewUri: string;
    isGomOpen: boolean;
    instanceUri?: any;
    selectedRowData?: any;
    currentObjectInView: CurrentObjectInView;
    bottomPanel: {
      isVisible: boolean;
      instanceUri: string;
      clientViewUri: string;
    };
    bottomPanelExpandedState: { isExpanded: boolean };
    inspector: {
      isExpanded: boolean;
      isCollapsed: boolean;
      isEdit: boolean;
      uri: string;
    };
    viewInspector: {
      isExpanded: boolean;
      isCollapsed: boolean;
    };
  };
  auditTrail: { data: any; schema: any; visible: boolean; title: string };
  isCommentaryDirty: { dirty: boolean };
  recordLocked: { isLocked: boolean };
  currentObjectInView: CurrentObjectInView;
  viewInspector: {
    isExpanded: boolean;
    isCollapsed: boolean;
  };
  modalInspector: ModalInspectorProps;
  creditDetailInspector: CreditDetailInspectorProps;
  inspector: {
    isExpanded: boolean;
    isCollapsed: boolean;
    isEdit: boolean;
    uri: string;
  };
  issuerDetailsInspector: { visible: boolean };
  layoutManager: { visible: boolean };
  globalActions: { visible: boolean };
  livePriceToggleState: {
    livePriceKeys: any[];
    marketMapToggleStatus: {};
    reqCountId: number;
    isLoggedInToMarketmap: boolean;
    isMarketmapConnectedToServer: boolean;
    showMMAuthenticationForm: boolean;
  };
  marketmapCredentials: {
    marketmapP: string;
    marketmapGroup: string;
    marketmapU: string;
  };
  searchInspector: { visible: boolean };
  showLayoutManagerInspector: { visible: boolean };
  summaryPanel: { visible: boolean };
  // TODO: define the interface for what can be stored in viewComponents
  viewComponents: any;
  hypoScenario: { runStatus: string; runStatusUri: string };
  gridLayout: ComponentGridLayout | ComponentPivotGridLayout;
  baseLineDate: DatePickerProps;
  currentDate: DatePickerProps;
  fundSelector: FundSelectorComponentProps;
  marketMap: { visible: boolean };
  commentary: {};
}

export const datePickerInitialState: DatePickerProps = {
  visible: false,
  count: 0,
  date: [],
  dateConfig: {
    prevSingleDate: '',
    singleDate: '',
    toDate: '',
    fromDate: '',
    frequency: '',
    dateType: 'single',
  },
};

export const dateInputInitialState: DateInputProps = {
  separator: '',
  dateStringLength: 0,
  placeholder: '',
};

export const componentsInitialState: Components = {
  auditTrail: { data: '', schema: '', visible: false, title: '' },
  isCommentaryDirty: { dirty: false },
  recordLocked: { isLocked: false },

  // Static inspector used for links in notifications
  inspector: {
    uri: '',
    isExpanded: false,
    isCollapsed: true,
    isEdit: false,
  },
  viewInspector: {
    isExpanded: false,
    isCollapsed: true,
  },
  modalInspector: {},
  creditDetailInspector: {
    isVisible: false,
  },
  bottomPanel: { isVisible: false, instanceUri: '', clientViewUri: '' },
  actionForm: { visible: false, actionUri: '' },
  newObject: { visible: false, actionUri: '' },
  activeClientViewGrid: {},
  searchInspector: { visible: false },
  // creditDetailsInspector: { visible: false, tabs: [] },
  dealsInspector: { visible: false },
  contextMenu: { visible: false },
  columnManagerInspector: {
    visible: false,
    props: {},
  },
  datePicker: { ...datePickerInitialState },
  dateInput: { ...dateInputInitialState },
  summaryPanel: { visible: false },
  showLayoutManagerInspector: { visible: false },
  layoutManager: { visible: false },
  globalActions: { visible: false },
  dashboard: { dashboardId: '' },
  dealsDetailInspector: { visible: false },
  issuerDetailsInspector: { visible: false },
  bottomPanelExpandedState: { isExpanded: false },
  form: {},
  livePriceToggleState: {
    reqCountId: 0,
    isMarketmapConnectedToServer: false,
    isLoggedInToMarketmap: false,
    marketMapToggleStatus: {},
    showMMAuthenticationForm: false,
    livePriceKeys: [],
  },
  marketmapCredentials: {
    marketmapU: '',
    marketmapP: '',
    marketmapGroup: '',
  },
  viewComponents: {},
  objects: [],
  global: {
    orders: {
      disabled: true,
      rowData: {},
      active: false,
      displayOverlay: false,
    },
    importInstrumentOverlay: { visible: false },
    currentClientViewUri: '',
    isGomOpen: false,
    instanceUri: '',
    selectedRowData: {},
    currentObjectInView: { uri: '' },
    bottomPanel: { isVisible: false, instanceUri: '', clientViewUri: '' },
    bottomPanelExpandedState: { isExpanded: false },
    inspector: {
      uri: '',
      isExpanded: false,
      isCollapsed: true,
      isEdit: false,
    },
    viewInspector: {
      isExpanded: false,
      isCollapsed: true,
    },
  },
  columnGrouped: { isGrouped: false, isExpanded: false },
  hypoScenario: { runStatus: '', runStatusUri: '' },
  gridLayout: {
    selectedLayout: undefined,
    permissions: undefined,
    filteredLayouts: undefined,
    searchText: '',
    fieldCount: 0,
  },
  baseLineDate: { ...datePickerInitialState },
  currentDate: { ...datePickerInitialState },
  fundSelector: { visible: false },
  marketMap: { visible: false },
  currentObjectInView: { uri: '' },
  commentary: {},
};

export const components = (
  state: Components = componentsInitialState,
  action: { type: ComponentActionTypes; payload: ComponentPayload },
) => {
  switch (action.type) {
    case 'RESET':
      return componentsInitialState;
    case 'RESET_COMPONENT': {
      const { componentName } = action.payload;
      return { ...state, [state[componentName]]: componentsInitialState[componentName] };
    }
    case 'UPDATE_COMPONENT': {
      const { componentName, props } = action.payload;
      const newState = clone(state);
      merge(newState[componentName], props);
      return newState;
    }
    case 'UPDATE_COMPONENT_VIEW': {
      const { componentName, props, clientViewUri } = action.payload;
      return {
        ...state,
        viewComponents: {
          ...state.viewComponents,
          [clientViewUri as string]: {
            ...state.viewComponents[clientViewUri as string],
            [componentName]: { ...state.viewComponents[clientViewUri as string]?.[componentName], ...props },
          },
        },
      };
    }
    case 'SET_COMPONENT_VIEW': {
      const { componentName, props, clientViewUri } = action.payload;
      const newState = clone(state);
      set(newState, `viewComponents[${clientViewUri}][${componentName}]`, props);
      return newState;
    }
    case 'TOGGLE_COMPONENT_VISIBILITY': {
      const { componentName, visible, props } = action.payload;
      const newState = clone(state);
      const isVisible = visible !== undefined ? visible : !state[componentName]?.visible;
      merge(newState[componentName], { visible: isVisible, ...props });

      // Reset target_uri when closing inspector
      if (componentName === 'inspector') {
        merge(newState['actionForm'], { target_uri: null });
      }
      return newState;
    }
    case 'TOGGLE_VIEW_COMPONENT_VISIBILITY': {
      const { componentName, props, clientViewUri, visible } = action.payload;
      const newState = clone(state);
      const isVisible =
        visible !== undefined ? visible : !state.viewComponents[clientViewUri as string]?.[componentName]?.visible;
      merge(newState.viewComponents, {
        [clientViewUri as string]: {
          [componentName]: { visible: isVisible, ...props },
        },
      });
      return newState;
    }
    default:
      return state;
  }
};

export const setComponentViewAction = (componentName: ComponentName, clientViewUri: string, props: object) => ({
  type: 'SET_COMPONENT_VIEW' as ComponentActionTypes,
  payload: { componentName, clientViewUri, props },
});

export const updateComponentViewAction = (componentName: ComponentName, clientViewUri: string, props: object) => ({
  type: 'UPDATE_COMPONENT_VIEW' as ComponentActionTypes,
  payload: { componentName, clientViewUri, props },
});

export const updateComponentAction = (componentName: ComponentName, props: object) => ({
  type: 'UPDATE_COMPONENT' as ComponentActionTypes,
  payload: { componentName, props },
});

export const toggleComponentDisplayAction = (
  componentName: ComponentName,
  visible?: boolean,
  props?: { isExpanded: boolean },
) => ({
  type: 'TOGGLE_COMPONENT_VISIBILITY' as ComponentActionTypes,
  payload: { componentName, visible, props },
});

export const resetComponentAction = (componentName: ComponentName) => ({
  type: 'RESET_COMPONENT' as ComponentActionTypes,
  payload: { componentName },
});

export const selectCurrentObjectInView = (state: RootState | any): CurrentObjectInView => {
  const cvcUri = state.tabs.activeViewUri;
  return selectViewComponent(state, 'currentObjectInView', cvcUri);
};

export const selectCurrentGlobalObjectInView = (state: RootState | any): CurrentObjectInView => {
  return state?.components?.global?.currentObjectInView;
};

export const toggleComponentViewDisplayAction = (
  componentName: ComponentName,
  clientViewUri: string,
  visible?: boolean,
) => ({
  type: 'TOGGLE_VIEW_COMPONENT_VISIBILITY' as ComponentActionTypes,
  payload: { componentName, clientViewUri, visible },
});

export const selectComponents = (state: RootState): Components => state.components;
export const selectAuditTrail = (state: RootState) => state.components.auditTrail;
export const selectViewComponent = (state: RootState | any, componentName: ComponentName, clientViewUri?: string) => {
  const cvcUri = clientViewUri || state?.tabs?.activeViewUri;
  return state?.components?.viewComponents[cvcUri]?.[componentName];
};
export const selectGlobalViewComponent = (state: RootState | any, componentName: ComponentName) => {
  return state?.components?.global?.[componentName];
};

export const selectStaticComponent = (componentName: ComponentName) => (state: RootState) => {
  return state?.components?.[componentName];
};

export const selectCVCData = (state: RootState | any, componentName: ComponentName) => {
  const cvcUri = state.tabs.activeViewUri;
  if (!cvcUri) return;
  return state.entities.views?.[cvcUri]?.[componentName];
};

export const selectGlobalViewInspectorData = (state: RootState | any, componentName: ComponentName) => {
  return state.entities.views?.global?.[componentName];
};

export const discardActionFormChanges = (displayConfirmationDialog: Function) => ({
  type: 'ACTION_FORM_DISCARD_CHANGES',
  payload: { displayActionConfirmationDialog: displayConfirmationDialog },
});

export const closeActionForm = () => ({
  type: 'ACTION_FORM_CLOSE',
});
