import { GlideSession } from '@virtus/common/auth/reducer';
import { Notification } from '@virtus/components/SnackBarNotification/SnackBarContent';
import { BottomPanel, ComponentProps } from 'src/reducers/components';
export interface OrderSelectionTabsProps {
  [key: string]: any;
}

export const StaticTabOrder: string[] = [
  'fund_allocations',
  'counterparty_allocations',
  'trades',
  'order_execution_details',
];

export interface ReduxProps extends ComponentProps {
  readonly orderDetails: any;
  readonly isLoadingOrderDetails: boolean;
  readonly bottomDataGrid: any;
  glideSession?: GlideSession;
  readonly notification: Notification | null;
  readonly isBottomGridDataPending: boolean;
  readonly isExpanded?: boolean;
  readonly bottomPanelComponent?: BottomPanel;
}

export interface OrderSelectionProps {
  discardBottomPanelChanges?: boolean;
  bottomPanelFields: any;
  selectedRowData?: any;
  clientViewUri?: string;
}

export type Props = OrderSelectionProps & ReduxProps;

export enum Actions {
  'COUNTERPARTY_ALLOCATIONS_SEND' = 'counterparty_allocation_send',
  'COUNTERPARTY_ALLOCATIONS_CANCEL' = 'counterparty_allocation_cancel',
}
export const ActionUris = {
  counterparty_allocation_send: 'instance/actions/fix_feed',
  counterparty_allocation_cancel: 'instance/actions/fix_feed_cancel',
};

export type ChangeBodyType = {
  object_uri: string;
  [key: string]: object | string;
};
