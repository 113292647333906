import { updateEntities } from 'redux-query';
import { GlideObject } from 'src/models/glide/glideObject';
import { store } from 'src/app/store';
import config from 'src/config';
import { GlideNotificationType, NotificationsAction } from 'src/reducers/notifications';
import { GlideSession } from '@virtus/common/auth/reducer';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { GlideViewActions } from 'src/sagas/glide-view.saga';
import { difference, isEmpty } from 'lodash';
import { inspectorFormsAction } from 'src/reducers/inspectorForm.reducer';
import { GlideAction } from 'src/reducers/actions';
import { RootState } from 'src/reducers';
import { Argument } from 'src/models/order/glideActionArguments.api.model';
import { mapGlideObjectBgColor } from 'src/utils/constants';
import { modalFormsAction } from 'src/reducers/modalForm.reducer';

interface ActionDisplayView {
  actions: string[];
  instance: string; // same as resolved_entity_uri
  view: string;
}

export interface ActionResult {
  resolved_entity_uri: string;
  resolved_entity: GlideObject;
  display_view: ActionDisplayView;
  date: string;
  resolved_entity_type: GlideNotificationType;
  error?: string;
  data?: any;
}

const updateScenarioResultColumn = (actionResult: any) => {
  if (
    actionResult?.resolved_entity_type === 'hypo_scenarios_run_processing' ||
    actionResult?.resolved_entity_type === 'hypo_scenarios_run_complete'
  ) {
    store.dispatch({
      type: GlideViewActions.UPDATE_SCENARIO_RESULT_COLUMN,
      payload: {
        ...actionResult,
      },
    });
  }
};

// TODO: Create a static WS connection (initialised by the app) that is
// listening for completed Glide event payloads to be synced
// https://github.com/AlphaKinetic/Glide/issues/2410

// Factory to handle glide web socket action request:
// Use the web socket connection to resolve action and update reducer
// Params are those expected by the glide action arguments of the service
const webSocketClient = (ws: WebSocket) => {
  ws.onmessage = (event: any): any => {
    const actionResult: ActionResult = JSON.parse(event.data);
    if (actionResult.error) {
      updateScenarioResultColumn(actionResult);
      // VERACODE restricted output
      // console.error('actionResult.error', actionResult.error);
      return store.dispatch({
        type: NotificationsAction.ERROR_NOTIFICATION,
        payload: {
          errorMessage: actionResult.error,
        },
      });
    }
    updateScenarioResultColumn(actionResult);
    store.dispatch({
      type: NotificationsAction.ACTION_RESOLVED_NOTIFICATION,
      payload: actionResult,
    });
    store.dispatch(
      updateEntities({
        // Last action result for notification
        actionResult: () => actionResult,
        // All action results (required for handling new entities display_view)
        // Not currently used but will be
        actionResults: (actionResults: ActionResult[]) => ({
          [actionResult.resolved_entity_uri]: actionResult,
          ...actionResults,
        }),
      }),
    );

    // Action resolve on the first event except when processing a scenario run
    // if (actionResult.resolved_entity_type !== GlideNotificationTypes.hypo_scenarios_run_processing) {
    //   ws.close(1000);
    // }
  };

  return {
    onopen: ws.onopen,
    send: (args: object) => {
      // Notify via same reducer as Redux query
      store.dispatch({ type: NotificationsAction.PENDING_NOTIFICATION });

      // WS proto needs to stringify arguments
      ws.send(JSON.stringify(args));
    },

    close: () => ws.close(),
  };
};

// Returns a new web socket connection so requests are not queued on the same connection
export const actionResolver = () =>
  new Promise((resolve, reject) => {
    if (!config.glide.API_WEB_SOCKET_URL) throw ReferenceError('Missing API_WSS_URL');
    try {
      const ws = new WebSocket(config.glide.API_WEB_SOCKET_URL);
      ws.onopen = () => {
        const wsClient = webSocketClient(ws);
        resolve(wsClient);
      };
    } catch (e) {
      reject(Error(`Failed to created web socket connection ${e}`));
    }
  });

export interface ExecuteActionPayload {
  actionArguments?: { [key: string]: Argument };
  accepts_from_date?: boolean;
  uri?: string;
  date?: string | string[];
}

export interface IExecuteActionProps {
  action: ExecuteActionPayload;
  target_uri: string | string[];
  glideSession?: GlideSession;
}

export const executeAction = ({ action, target_uri }: IExecuteActionProps) => {
  const state = store.getState();
  const { token, environment } = state.auth.glideSession;

  actionResolver().then((wsClient: any) =>
    wsClient.send({
      target_uri,
      action_uri: action.uri,
      arguments: action?.actionArguments || {},
      glide_token: token,
      environment: environment,
      date: action.accepts_from_date ? action.date : null,
    }),
  );
};

export interface IGetActionsCollection {
  clientViewConfiguration: ClientViewConfigurationData;
  resolveAction: any;
  rowUri: string;
}

export interface IAction {
  text: string;
  onClick: () => void;
  testId: string;
}

interface ActionGroupTypes {
  primaryActions: GlideAction[];
  secondaryActions: GlideAction[];
  otherActions: GlideAction[];
}

// TODO: add tests
export function getActionsGroupedByType(actions: GlideAction[]): ActionGroupTypes {
  const editAction = actions.filter(action => action?.uri?.includes('edit_object'));
  const primaryActions = !isEmpty(editAction)
    ? editAction
    : actions.filter(action => action.data?.editor_button_style?.includes('blue'));
  const secondaryActions = actions.filter(action => action.data?.editor_button_style?.includes('red'));
  const otherActions =
    actions.length > 0 && actions[0]?.data ? difference(actions, primaryActions, secondaryActions) : [];
  return { primaryActions, secondaryActions, otherActions };
}

export const getSortedActionsCollection = (
  clientViewConfiguration: ClientViewConfigurationData,
  onClickHandler: (e: any) => void,
): IAction[] => {
  const cvcActionsCollection = clientViewConfiguration?.actions_collection;
  if (!cvcActionsCollection || cvcActionsCollection.length === 0) return [];
  // The following is using color scheme to create the action order
  const { primaryActions, secondaryActions, otherActions } = getActionsGroupedByType(cvcActionsCollection);
  const cvcActions = primaryActions.concat(otherActions).concat(secondaryActions);
  return cvcActions.map((action: GlideAction) => ({
    text: action.data?.display_name,
    onClick: () => {
      onClickHandler(action);
    },
    testId: `${action?.uri?.lastSplitValue()}_action`,
    bgColor: mapGlideObjectBgColor(action.data?.editor_button_style),
  }));
};

export const setInspectorForm = (glideObject: object) => ({
  type: inspectorFormsAction.INIT_INSPECTOR_FORM,
  payload: { glideObject },
});

export const setModalForm = (glideObject: object) => ({
  type: modalFormsAction.INIT_MODAL_FORM,
  payload: { glideObject },
});

export const selectActionResults = (state: RootState) => state.entities.actionResult;
export const selectActionPending = (state: RootState) =>
  state.queries.resolveAction && state.queries.resolveAction.isPending;
